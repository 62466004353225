<template>
  <v-container
    fluid
    class="pa-0"
  >
    <v-row no-gutter>
      <v-col
        cols="12"
        md="6"
        lg="4"
      >
        <ValidationProvider
          v-slot="{ errors }"
          rules="required|url"
          name="Default Product URL"
        >
          <v-text-field
            v-model.trim="productUrlLocal"
            label="Default Product URL"
            maxlength="50"
            :error-messages="errors"
          />
        </ValidationProvider>
        <div class="caption">
          Used when product doesn't have a URL.
        </div>
      </v-col>
      <v-col
        cols="12"
        md="6"
        lg="4"
      >
        <ValidationProvider
          v-slot="{ errors }"
          rules="required|url"
          name="Default Product Image URL"
        >
          <v-text-field
            v-model="productImageLocal"
            label="Default Product Image URL"
            :error-messages="errors"
          />
        </ValidationProvider>
        <div class="caption">
          Used when product doesn't have an image.
        </div>
      </v-col>
      <ignore-web-orders
        v-if="sourceType === 1 || sourceType === 8 || sourceType === 10 || sourceType === 14"
        ref="ignoreWebOrders"
        v-bind="sourceDetail"
      />
    </v-row>
    <consent
      v-if="sourceType === 8 || sourceType === 14"
      ref="consent"
      :show-phone-consent="sourceType === 8"
      v-bind="sourceDetail"
    />
    <rics
      v-if="sourceType === 1"
      ref="rics"
      v-bind="sourceDetail"
    />
    <heartland
      v-if="sourceType === 10"
      ref="heartland"
      v-bind="sourceDetail"
    />
    <runit
      v-if="sourceType === 14"
      ref="runit"
      v-bind="sourceDetail"
    />
  </v-container>
</template>

<script>
import IgnoreWebOrders from './Components/IgnoreWebOrders';
import Consent from './Components/Consent';
import Rics from './Blueshift/Rics';
import Runit from './Blueshift/Runit';
import Heartland from './Blueshift/Heartland';

export default {
  name: 'BlueshiftDestination',
  components: {
    IgnoreWebOrders,
    Consent,
    Rics,
    Runit,
    Heartland,
  },
  props: {
    sourceType: {
      type: Number,
      required: false,
      default: 0,
    },
    sourceDetail: {
      type: Object,
      required: false,
      default: null,
    },
    destinationDetail: {
      type: Object,
      required: false,
      default: null,
    },
    productUrl: {
      type: String,
      required: false,
      default: '',
    },
    productImage: {
      type: String,
      required: false,
      default: '',
    },
    errorMessage: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      productUrlLocal: this.productUrl,
      productImageLocal: this.productImage,
    };
  },
  methods: {
    getDetail() {
      switch (this.sourceType) {
        case 1:
          return {
            productUrl: this.productUrlLocal,
            productImage: this.productImageLocal,
            ...this.$refs.ignoreWebOrders.getDetail(),
            ...this.$refs.rics.getDetail(),
          };

        case 2:
          return {
            productUrl: this.productUrlLocal,
            productImage: this.productImageLocal,
          };

        case 8:
          return {
            productUrl: this.productUrlLocal,
            productImage: this.productImageLocal,
            ...this.$refs.ignoreWebOrders.getDetail(),
            ...this.$refs.consent.getDetail(),
          };

        case 10:
          return {
            productUrl: this.productUrlLocal,
            productImage: this.productImageLocal,
            ...this.$refs.ignoreWebOrders.getDetail(),
            ...this.$refs.heartland.getDetail(),
          };

        case 14:
          return {
            productUrl: this.productUrlLocal,
            productImage: this.productImageLocal,
            ...this.$refs.ignoreWebOrders.getDetail(),
            ...this.$refs.consent.getDetail(),
            ...this.$refs.runit.getDetail(),
          };

        default:
          return {
            productUrl: this.productUrlLocal,
            productImage: this.productImageLocal,
          };
      }
    },
  },
};
</script>
