<template>
  <div>
    <v-row>
      <v-col cols="12">
        <h2>
          Reports
        </h2>
      </v-col>
    </v-row>
    <v-row>
      <template v-for="(item, i) in reports">
        <report-card
          :key="i"
          :item="item"
        />
      </template>
      <v-col
        v-if="noReports"
        cols="12"
      >
        <v-alert
          border="top"
          colored-border
          type="info"
          elevation="2"
        >
          <v-row>
            <v-col>
              Reporting is not enabled for this account.
            </v-col>
          </v-row>
        </v-alert>
      </v-col>
    </v-row>
    <v-row
      v-if="systemUser && hiddenReports.length > 0"
      class="mt-5"
    >
      <v-col cols="12">
        <v-divider />
      </v-col>
      <v-col cols="12">
        <h3>
          The below reports are either not active or not turned on for this account.
          They are visible only to internal users.
        </h3>
      </v-col>
      <template v-for="(item, i) in hiddenReports">
        <report-card
          :key="i"
          :item="item"
        />
      </template>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import dashboard from '../_api/dashboard';
import ReportCard from '../_components/ReportCard';

export default {
  name: 'ReportsIndex',
  components: {
    ReportCard,
  },
  data() {
    return {
      noReports: false,
      reports: [],
      hiddenReports: [],
    };
  },
  computed: {
    ...mapGetters([
      'getActiveAccount',
      'systemUser',
    ]),
  },
  async created() {
    const resp = await dashboard.getReports(this.getActiveAccount.id);
    this.reports = resp.data;
    if (this.reports.length === 0) {
      this.noReports = true;
    }
    if (this.systemUser) {
      await this.getHiddenReports();
    }
  },
  methods: {
    async getHiddenReports() {
      const resp = await dashboard.getHiddenReports(this.getActiveAccount.id);
      this.hiddenReports = resp.data;
    },
  },
};
</script>
