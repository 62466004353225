<template>
  <div>
    <v-row>
      <v-col>
        <h2>
          Create Vertical
        </h2>
      </v-col>
    </v-row>
    <Vertical />
  </div>
</template>

<script>
import Vertical from '../../_components/Vertical';

export default {
  name: 'CreateVertical',
  components: {
    Vertical,
  },
};
</script>
