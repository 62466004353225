<template>
  <v-row>
    <v-col
      cols="12"
      lg="6"
      class="offset-lg-3"
    >
      <v-card
        class="mt-3"
        color="grey lighten-5"
      >
        <v-card-title>Account Security</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <ChangeEmailForm />
            </v-col>
            <v-col cols="12">
              <PasswordChange />
            </v-col>
            <v-col cols="12">
              <TwoFactor />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import ChangeEmailForm from './ChangeEmailForm';
import PasswordChange from './PasswordChange';
import TwoFactor from './TwoFactor';

export default {
  name: 'ProfileSecurity',
  components: {
    PasswordChange,
    TwoFactor,
    ChangeEmailForm,
  },
};
</script>
