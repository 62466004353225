import axios from '@/utils/axios';

const getUsers = (accountId) => axios.get(`/accounts/users/${accountId}`);
const inviteUser = (accountId, email) => axios.post(`/accounts/users/${accountId}`, { email });
const deleteUser = (accountId, userId) => axios.delete(`/accounts/users/${accountId}/${userId}`);
const resendInvite = (accountId, userId) => axios
  .post(`/accounts/users/${accountId}/${userId}/resend`);
const getAccount = (accountId) => axios.get(`/accounts/${accountId}`);
const getSources = (accountId) => axios.get(`/accounts/sources/${accountId}`);
const getDestinations = (accountId) => axios.get(`/accounts/destinations/${accountId}`);
const updateUserAccessLevel = (accountId, userId, accessLevel) => axios.put(
  `/accounts/users/${accountId}/${userId}/accessLevel`,
  { accessLevel },
);
const emailAccountConnection = (token, response) => axios.post(`/accounts/connections/email/${token}`, {
  response,
});
const getPermissions = (accountId, typeId, referenceId, userId) => axios.get(`/accounts/permissions/${accountId}`, {
  params: {
    typeId,
    referenceId,
    userId,
  },
});
const createPermissions = (accountId, permissions) => axios.post(`/accounts/permissions/${accountId}`, {
  permissions,
});
const impersonate = (accountId, userId) => axios.get(`/admin/users/impersonate/${accountId}/${userId}`);

export default {
  getUsers,
  inviteUser,
  deleteUser,
  resendInvite,
  getAccount,
  getSources,
  getDestinations,
  updateUserAccessLevel,
  emailAccountConnection,
  getPermissions,
  createPermissions,
  impersonate,
};
