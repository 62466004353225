<template>
  <v-row>
    <v-col cols="12">
      <ul>
        <li>Matched Products are products that match back to the brand master list of products.</li>
        <li>
          Unmatched Products are products that do not match back to the brand master list of
          products.
        </li>
        <li>
          Categorized Products are unmatched products that have gotten a primary category using the
          tagging rules.
        </li>
      </ul>
    </v-col>
    <v-col cols="12">
      <v-data-table
        :headers="headers"
        :items="accounts"
        :options.sync="options"
        :server-items-length="total"
        :loading="loading"
        :must-sort="true"
        :footer-props="{
          'items-per-page-options': []
        }"
        class="elevation-5"
        fixed-header
        height="75vh"
        @click:row="rowClick"
      >
        <template #[`item.totalProducts`]="{ item }">
          {{ new Intl.NumberFormat().format(item.totalProducts) }}
        </template>
        <template #[`item.matchedProducts`]="{ item }">
          {{ new Intl.NumberFormat().format(item.matchedProducts) }}
        </template>
        <template #[`item.matchedPercent`]="{ item }">
          {{ (item.matchedPercent * 100).toFixed(2) }}%
        </template>
        <template #[`item.unmatchedProducts`]="{ item }">
          {{ new Intl.NumberFormat().format(item.unmatchedProducts) }}
        </template>
        <template #[`item.unmatchedPercent`]="{ item }">
          {{ (item.unmatchedPercent * 100).toFixed(2) }}%
        </template>
        <template #[`item.categorizedProducts`]="{ item }">
          {{ new Intl.NumberFormat().format(item.categorizedProducts) }}
        </template>
        <template #[`item.categorizedPercent`]="{ item }">
          {{ (item.categorizedPercent * 100).toFixed(2) }}%
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import products from '../_api/products';

export default {
  name: 'ProductSummary',
  data() {
    return {
      options: {
        sortBy: [
          'categorizedPercent',
        ],
        sortDesc: [
          false,
        ],
        itemsPerPage: 50,
      },
      loading: true,
      total: 0,
      headers: [
        {
          text: 'Name',
          value: 'name',
          sortable: true,
          width: '20%',
        },
        {
          text: 'Total Products',
          value: 'totalProducts',
          sortable: true,
        },
        {
          text: 'Matched Products',
          value: 'matchedProducts',
          sortable: true,
        },
        {
          text: 'Matched Products %',
          value: 'matchedPercent',
          sortable: true,
        },
        {
          text: 'Unmatched Products',
          value: 'unmatchedProducts',
          sortable: true,
        },
        {
          text: 'Unmatched Products %',
          value: 'unmatchedPercent',
          sortable: true,
        },
        {
          text: 'Categorized Products',
          value: 'categorizedProducts',
          sortable: true,
        },
        {
          text: 'Categorized Products %',
          value: 'categorizedPercent',
          sortable: true,
        },
      ],
      accounts: [],
    };
  },
  computed: {
    ...mapGetters([
      'getActiveAccount',
    ]),
  },
  watch: {
    options: {
      handler() {
        this.getData();
      },
      deep: true,
    },
  },
  methods: {
    async rowClick(item) {
      await this.$store.dispatch('USER_CHANGE_ACCOUNT', item.id);
      this.$router.push('/product/tagging');
    },
    async getData() {
      const {
        sortBy, sortDesc, page, itemsPerPage,
      } = this.options;

      this.loading = true;
      let sortOrder;
      if (sortDesc) {
        sortOrder = sortDesc[0] === false ? 'asc' : 'desc';
      }

      const resp = await products.getAdminSummary(
        this.getActiveAccount.verticalId,
        itemsPerPage,
        (page - 1) * itemsPerPage,
        sortBy ? sortBy[0] : null,
        sortOrder,
        this.filter,
      );

      this.total = resp.data.total;
      this.accounts = resp.data.accounts;
      this.loading = false;
    },
  },
};
</script>
