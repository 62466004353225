<template>
  <v-row>
    <v-col cols="12">
      <v-data-table
        :headers="headers"
        :items="brandMappings"
        :options.sync="options"
        :server-items-length="total"
        :loading="loading"
        :must-sort="true"
        :footer-props="{
          'items-per-page-options': []
        }"
        class="elevation-5"
        fixed-header
        height="80vh"
        @click:row="rowClick"
      >
        <template #top>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            class="pa-3"
          />
        </template>

        <template #[`item.createdAt`]="{ item }">
          {{ formatCreated(item.createdAt) }}
        </template>
      </v-data-table>
    </v-col>
    <v-dialog
      v-model="mappingDialog"
      width="80vw"
      persistent
    >
      <v-card
        class="mx-auto"
        tile
      >
        <v-card-title>
          Brand Mapping for {{ sourceBrand }}
        </v-card-title>
        <v-card-text
          class="scroll"
        >
          <v-row>
            <v-col
              cols="12"
            >
              <v-select
                v-model="destinationBrand"
                :items="brands"
                label="Destination Brand"
                clearable
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <v-data-table
                :headers="accountHeaders"
                :items="accounts"
                class="elevation-5"
                fixed-header
                hide-default-footer
                disable-pagination
                height="300"
              >
                <template #[`item.count`]="{ item }">
                  {{ new Intl.NumberFormat().format(item.count) }}
                </template>
                <template #top>
                  <h3 class="ml-1">
                    Account Summary
                  </h3>
                </template>
              </v-data-table>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-data-table
                :headers="categoryHeaders"
                :items="categories"
                class="elevation-5"
                fixed-header
                hide-default-footer
                disable-pagination
                height="300"
              >
                <template #top>
                  <h3 class="ml-1">
                    Category Summary
                  </h3>
                </template>
                <template #[`item.count`]="{ item }">
                  {{ new Intl.NumberFormat().format(item.count) }}
                </template>
              </v-data-table>
            </v-col>
            <v-col cols="12">
              <v-data-table
                :headers="productHeaders"
                :items="products"
                class="elevation-5"
                fixed-header
                hide-default-footer
                disable-pagination
                height="500"
              >
                <template #top>
                  <h3 class="ml-1">
                    Product Preview
                  </h3>
                </template>
                <template #[`item.count`]="{ item }">
                  {{ new Intl.NumberFormat().format(item.count) }}
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            text
            @click="mappingDialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="saveMapping"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-overlay :value="overlay">
      <v-progress-circular
        :size="70"
        :width="7"
        indeterminate
      />
    </v-overlay>
  </v-row>
</template>

<script>
import { debounce } from 'lodash';
import brand from '../_api/brand';

export default {
  name: 'BrandMapping',
  data() {
    return {
      options: {
        sortBy: [
          'sourceBrand',
        ],
        sortDesc: [
          false,
        ],
        itemsPerPage: 100,
      },
      loading: true,
      total: 0,
      headers: [
        {
          text: 'Source Brand',
          value: 'sourceBrand',
          sortable: true,
        },
        {
          text: 'Destination Brand',
          value: 'destinationBrand',
          sortable: true,
        },
        {
          text: 'Created',
          value: 'createdAt',
          sortable: true,
        },
      ],
      search: '',
      brandMappings: [],
      brands: [],
      overlay: false,
      mappingDialog: false,
      accountHeaders: [
        {
          text: 'Account',
          value: 'account',
        },
        {
          text: 'Product Count',
          value: 'count',
        },
      ],
      accounts: [],
      categoryHeaders: [
        {
          text: 'Category',
          value: 'category',
        },
        {
          text: 'Product Count',
          value: 'count',
        },
      ],
      categories: [],
      productHeaders: [
        {
          text: 'Name',
          value: 'name',
          sortable: false,
        },
        {
          text: 'Category',
          value: 'category',
          sortable: false,
        },
        {
          text: 'Sku',
          value: 'sku',
          sortable: false,
        },
        {
          text: 'UPC',
          value: 'upc',
          sortable: false,
        },
        {
          text: 'Size',
          value: 'size',
          sortable: false,
        },
        {
          text: 'Width',
          value: 'width',
          sortable: false,
        },
        {
          text: 'Color',
          value: 'color',
          sortable: false,
        },
        {
          text: 'Style',
          value: 'style',
          sortable: false,
        },
        {
          text: 'Image Url',
          value: 'imageUrl',
          sortable: false,
        },
      ],
      products: [],
      sourceBrand: '',
      destinationBrand: '',
    };
  },
  watch: {
    options: {
      handler() {
        this.getData();
      },
      deep: true,
    },
    async search() {
      await this.filterData();
    },
  },
  async created() {
    this.filterData = debounce(this.filterData, 1000);
    const resp = await brand.getBrands();
    this.brands = resp.data;
  },
  methods: {
    async filterData() {
      if (this.options.page !== 1) {
        this.options.page = 1;
      } else {
        await this.getData();
      }
    },
    async rowClick(item) {
      this.overlay = true;
      this.sourceBrand = item.sourceBrand;
      const resp = await Promise.all([
        brand.getAccountSummary(item.sourceBrand),
        brand.getCategorySummary(item.sourceBrand),
        brand.getPreview(item.sourceBrand),
      ]);
      this.accounts = resp[0].data;
      this.categories = resp[1].data;
      this.products = resp[2].data;
      this.mappingDialog = true;
      this.destinationBrand = item.destinationBrand;
      this.overlay = false;
    },
    async getData() {
      const {
        sortBy, sortDesc, page, itemsPerPage,
      } = this.options;

      this.loading = true;
      let sortOrder;
      if (sortDesc) {
        sortOrder = sortDesc[0] === false ? 'asc' : 'desc';
      }

      const resp = await brand.getMappingSummary(
        itemsPerPage,
        (page - 1) * itemsPerPage,
        sortBy ? sortBy[0] : null,
        sortOrder,
        this.search,
      );

      this.total = resp.data.total;
      this.brandMappings = resp.data.brands;
      this.loading = false;
    },
    formatCreated(value) {
      return new Date(value).toLocaleDateString('en-us', { month: 'long', day: 'numeric', year: 'numeric' });
    },
    async saveMapping() {
      this.mappingDialog = false;
      this.overlay = true;
      await brand.updateMapping(this.sourceBrand, this.destinationBrand);
      this.overlay = false;
      await this.getData();
    },
  },
};
</script>

<style scoped>
  .scroll {
    overflow-y: scroll;
    height: 70vh;
  }
</style>
