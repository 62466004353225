<template>
  <v-row no-gutters>
    <v-col
      cols="12"
    >
      <v-checkbox
        v-model="selectedLocal"
        :label="getName()"
        :disabled="disabled || loadingHistory"
      />
    </v-col>
    <v-col
      v-show="detailComponent"
      cols="12"
    >
      <component
        :is="detailComponent"
        :id="id"
        ref="destinationDetail"
        v-bind="destination.detail"
        :source-detail="destination.detail"
        :destination-detail="destination.destinationDetail"
        :source-type="sourceType"
        :error-message="errorMessage"
      />
    </v-col>
    <v-col
      v-show="disabled"
      cols="12"
      class="text--disabled"
    >
      This destination is not a supported type for the selected source type.
    </v-col>
  </v-row>
</template>

<script>
import Mailchimp from './destinationTypes/Mailchimp';
import Zaius from './destinationTypes/Zaius';
import FacebookOffline from './destinationTypes/FacebookOffline';
import S3 from './destinationTypes/S3';
import Cadence from './destinationTypes/Cadence';
import Blueshift from './destinationTypes/Blueshift';

export default {
  name: 'SourceDestination',
  components: {
    Mailchimp,
    Zaius,
    FacebookOffline,
    S3,
    Cadence,
    Blueshift,
  },
  props: {
    id: {
      type: String,
      required: false,
      default: '',
    },
    destination: {
      type: Object,
      required: false,
      default: null,
    },
    selected: {
      type: Boolean,
      required: false,
      default: false,
    },
    sourceType: {
      type: Number,
      required: false,
      default: 0,
    },
    loadingHistory: {
      type: Boolean,
      required: false,
      default: false,
    },
    errorMessage: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      selectedLocal: this.selected,
      disabled: false,
      detailComponent: '',
      destinationTypes: [
        {
          type: 1,
          detailComponent: 'Zaius',
          allowedTypes: [],
          excludedTypes: [9, 15, 16, 17, 18, 19],
        },
        {
          type: 2,
          detailComponent: 'Mailchimp',
          allowedTypes: [1],
        },
        {
          type: 4,
          detailComponent: 'FacebookOffline',
          allowedTypes: [1, 3, 8, 10, 12, 14, 15, 19],
        },
        {
          type: 5,
          detailComponent: 'S3',
          allowedTypes: [9],
        },
        {
          type: 6,
          detailComponent: 'Cadence',
          allowedTypes: [1, 8, 10, 15, 2, 14, 16, 17],
        },
        {
          type: 7, // uqube
          detailComponent: null,
          allowedTypes: [1, 9, 3, 17],
        },
        {
          type: 8,
          detailComponent: 'Blueshift',
          allowedTypes: [1, 2, 8, 10, 14, 4, 5, 7, 18],
        },
      ],
    };
  },
  watch: {
    sourceType() {
      this.checkDestinationSource();
    },
    selectedLocal() {
      this.changeSelected();
    },
    selected() {
      this.selectedLocal = this.selected;
      this.changeSelected();
    },
  },
  mounted() {
    this.checkDestinationSource();
  },
  methods: {
    getName() {
      if (this.destination.startDate) {
        if (this.destination.loadingHistory) {
          return `${this.destination.name} (Currently loading historical data starting from ${this.destination.startDate})`;
        }
        return `${this.destination.name} (Historical data loaded from ${this.destination.startDate})`;
      }
      return this.destination.name;
    },
    checkDestinationSource() {
      const destination = this.destinationTypes.find((element) => element.type
        === this.destination.destinationType);
      if (destination.allowedTypes.length > 0) {
        if (destination.allowedTypes.includes(this.sourceType)) {
          this.disabled = false;
        } else {
          this.disabled = true;
          this.selectedLocal = false;
        }
      } else {
        this.disabled = false;
      }
      if (destination.excludedTypes && destination.excludedTypes.length > 0) {
        if (destination.excludedTypes.includes(this.sourceType)) {
          this.disabled = true;
        }
      }
    },
    changeSelected() {
      if (this.selectedLocal === true) {
        const destination = this.destinationTypes.find((element) => element.type
          === this.destination.destinationType);
        this.detailComponent = destination.detailComponent;
      } else {
        this.detailComponent = '';
      }
    },
    isSelected() {
      return this.selectedLocal;
    },
    getDetail() {
      if (this.$refs.destinationDetail) {
        return this.$refs.destinationDetail.getDetail();
      }
      return {};
    },
  },
};
</script>
