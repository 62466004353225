<template>
  <div>
    <router-view />
  </div>
</template>

<script>
import { reports } from '../_stores/admin/modules';

export default {
  name: 'ReportsLayout',
  beforeCreate() {
    if (!this.$store.hasModule(['admin', 'reports'])) {
      this.$store.registerModule(['admin', 'reports'], reports);
    }
  },
};
</script>
