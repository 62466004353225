<template>
  <v-row>
    <v-col
      cols="12"
      lg="6"
      class="offset-lg-3"
    >
      <v-card
        class="mt-3"
        color="grey lighten-5"
      >
        <v-card-title>Profile</v-card-title>
        <v-card-text>
          <ValidationObserver ref="observer">
            <v-form
              @submit.prevent="updateProfile"
            >
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    rules="required"
                    name="First Name"
                  >
                    <v-text-field
                      v-model.trim="firstName"
                      label="First Name"
                      :error-messages="errors"
                    />
                  </ValidationProvider>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <ValidationProvider
                    v-slot="{ errors }"
                    rules="required"
                    name="Last Name"
                  >
                    <v-text-field
                      v-model.trim="lastName"
                      label="Last Name"
                      :error-messages="errors"
                    />
                  </ValidationProvider>
                </v-col>
                <v-col cols="12">
                  <ValidationProvider
                    v-slot="{ errors }"
                    rules="phone"
                    name="Mobile Number"
                  >
                    <v-text-field
                      v-model.trim="mobile"
                      v-mask="'###-###-####'"
                      label="Mobile Number"
                      :error-messages="errors"
                    />
                  </ValidationProvider>
                </v-col>
                <v-col cols="12">
                  <v-btn
                    color="primary"
                    type="submit"
                  >
                    Update Profile
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </ValidationObserver>
        </v-card-text>
      </v-card>
    </v-col>
    <v-snackbar
      v-model="snackbar"
      :top="true"
      color="primary"
      timeout="5000"
    >
      Profile has been updated
      <template #action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-row>
</template>

<script>
export default {
  name: 'ProfileEdit',
  data() {
    return {
      firstName: this.$store.getters.getProfile.firstName,
      lastName: this.$store.getters.getProfile.lastName,
      mobile: this.$store.getters.getProfile.mobile,
      snackbar: false,
    };
  },
  methods: {
    async updateProfile() {
      const {
        firstName, lastName, mobile,
      } = this;
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        try {
          await this.$store.dispatch('USER_UPDATE_PROFILE', {
            firstName,
            lastName,
            mobile,
          });
          this.$zendesk.identify({
            name: `${firstName} ${lastName}`,
            email: this.$store.getters.getProfile.email,
          });
          this.$zendesk.prefill({
            name: {
              value: `${firstName} ${lastName}`,
            },
            email: {
              value: this.$store.getters.getProfile.email,
            },
          });
          this.snackbar = true;
        } catch (error) {
          //
        }
      }
    },
  },
};
</script>
