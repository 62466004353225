<template>
  <report-guide-wrapper
    shaped
    title="Downloading Data"
  >
    <v-container
      class="px-5"
    >
      <v-row>
        <v-col class="body-1 d-flex align-baseline">
          <p class="mb-0 align-self-center">
            Any table in UQ Cadence can be downloaded straight into an Excel or CSV
            spreadsheet document. You can also download any graphic (and the raw data behind it!)
            by using the same approach! To learn more click <a
              nofollow
              href="https://uqcadence.zendesk.com/hc/en-us/articles/8707173847821-Download-Export-Data"
              target="_blank"
              class="indigo--text darken-4"
            >here</a>.
          </p>
        </v-col>
      </v-row>
    </v-container>

    <slot name="navigator" />
  </report-guide-wrapper>
</template>

<script>
import ReportGuideWrapper from './ReportGuideWrapper';

export default {
  name: 'ToolbarGuide',
  components: {
    ReportGuideWrapper,
  },
};
</script>
