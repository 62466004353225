import axios from '@/utils/axios';

const getAccounts = (take, skip, sortBy, sortOrder, filter) => axios.get('/admin/accounts', {
  params: {
    take,
    skip,
    sortBy,
    sortOrder,
    filter,
  },
});
const getAccount = (id) => axios.get(`/admin/accounts/${id}`);
const createAccount = (name, accountType, settings, isHidden, parentAccountId, showPreview, verticalId) => axios.post(`/admin/accounts/${verticalId}`, {
  name, accountType, settings, isHidden, parentAccountId, showPreview, verticalId,
});
const updateAccount = (id, name, isActive, accountType, settings, isHidden, parentAccountId, showPreview, verticalId) => axios.put(`/admin/accounts/${id}`, {
  name, isActive, accountType, settings, isHidden, parentAccountId, showPreview, verticalId,
});
const getParentAccounts = (verticalId) => axios.get(`/admin/accounts/parentAccounts/${verticalId}`);

export default {
  getAccounts,
  getAccount,
  createAccount,
  updateAccount,
  getParentAccounts,
};
