import axios from '@/utils/axios';

const getBrandConnections = (id) => axios.get(`/accounts/connections/brand/${id}`);
const createBrandConnections = (id, accounts, note) => axios.post(`/accounts/connections/brand/${id}`, {
  accounts,
  note,
});
const deleteBrandConnections = (id, accounts) => axios.post(`/accounts/connections/brand/revoke/${id}`, {
  accounts,
});
const getRetailConnections = (id) => axios.get(`/accounts/connections/retailer/${id}`);
const getHistory = (id, brandAccountId) => axios.get(`/accounts/connections/retailer/history/${id}/${brandAccountId}`);
const addRetailerConnections = (id, accounts) => axios.post(`/accounts/connections/retailer/add/${id}`, {
  accounts,
});
const removeRetailerConnections = (id, accounts) => axios.post(`/accounts/connections/retailer/remove/${id}`, {
  accounts,
});
const resendBrandConnections = (id, accounts, note) => axios.post(`/accounts/connections/brand/resend/${id}`, {
  accounts,
  note,
});

export default {
  getBrandConnections,
  createBrandConnections,
  deleteBrandConnections,
  getRetailConnections,
  getHistory,
  addRetailerConnections,
  removeRetailerConnections,
  resendBrandConnections,
};
