<template>
  <v-row>
    <v-col
      cols="12"
      md="6"
      lg="4"
    >
      <ValidationProvider
        ref="urlValidation"
        v-slot="{ errors }"
        rules="required"
        name="Url"
      >
        <v-text-field
          v-model="urlLocal"
          label="Url"
          placeholder="www.example.com"
          hint="www.example.com"
          :error-messages="errors"
          :disabled="!isNew"
        />
      </ValidationProvider>
      <div class="caption">
        The url should not contain https:// or any subdirectories, it should be just the root url
        i.e.(www.example.com).
        <div v-if="!isNew">
          The url can not be changed after creation since the identifiers are based on this url.
        </div>
      </div>
    </v-col>
    <v-col
      cols="12"
      md="6"
      lg="4"
    >
      <ValidationProvider
        ref="consumerKeyValidation"
        v-slot="{ errors }"
        rules="required"
        name="Consumer Key"
      >
        <v-text-field
          v-model="consumerKeyLocal"
          label="Consumer Key"
          :error-messages="errors"
        />
      </ValidationProvider>
    </v-col>
    <v-col
      cols="12"
      md="6"
      lg="4"
    >
      <ValidationProvider
        ref="consumerSecretValidation"
        v-slot="{ errors }"
        rules="required"
        name="Consumer Secret"
      >
        <v-text-field
          v-model="consumerSecretLocal"
          label="Consumer Secret"
          :error-messages="errors"
        />
      </ValidationProvider>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'WooCommerce',
  props: {
    isNew: {
      type: Boolean,
      required: false,
      default: false,
    },
    url: {
      type: String,
      required: false,
      default: '',
    },
    consumerKey: {
      type: String,
      required: false,
      default: '',
    },
    consumerSecret: {
      type: String,
      required: false,
      default: '',
    },
    errorMessage: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      urlLocal: this.url,
      consumerKeyLocal: this.consumerKey,
      consumerSecretLocal: this.consumerSecret,
    };
  },
  watch: {
    errorMessage() {
      if (this.errorMessage === 'InvalidAccess') {
        this.$refs.urlValidation.setErrors(['Invalid Url, consumer key, or consumer secret']);
      }
      if (this.errorMessage === 'InvalidURL') {
        this.$refs.urlValidation.setErrors(['Invalid Url']);
      }
    },
  },
  methods: {
    getDetail() {
      return {
        url: this.urlLocal,
        consumerKey: this.consumerKeyLocal,
        consumerSecret: this.consumerSecretLocal,
      };
    },
  },
};
</script>
