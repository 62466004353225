<template>
  <v-alert
    :value="true"
    :class="{'ma-2': $vuetify.breakpoint.smAndDown, 'ma-5': $vuetify.breakpoint.mdAndUp}"
    type="error"
  >
    <h4>No accounts are available!</h4>
    <hr>
    <p>Your user does not belong to any accounts.</p>
  </v-alert>
</template>

<script>
export default {
  name: 'NoAccounts',
};
</script>
