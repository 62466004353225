<template>
  <v-row>
    <v-col
      cols="12"
      md="6"
      lg="4"
    >
      <ValidationProvider
        ref="accessKeyValidation"
        v-slot="{ errors }"
        rules="required"
        name="Access Key"
      >
        <v-text-field
          v-model="accessKeyLocal"
          label="Access Key"
          :error-messages="errors"
        />
      </ValidationProvider>
    </v-col>
    <v-col
      cols="12"
      md="6"
      lg="4"
    >
      <ValidationProvider
        ref="secretKeyValidation"
        v-slot="{ errors }"
        rules="required"
        name="Secret Key"
      >
        <v-text-field
          v-model="secretKeyLocal"
          label="Secret Key"
          :error-messages="errors"
        />
      </ValidationProvider>
    </v-col>
    <v-col
      cols="12"
      md="6"
      lg="4"
    >
      <ValidationProvider
        ref="bucketValidation"
        v-slot="{ errors }"
        rules="required"
        name="Bucket"
      >
        <v-text-field
          v-model="bucketLocal"
          label="Bucket"
          :error-messages="errors"
        />
      </ValidationProvider>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'S3DestinationType',
  props: {
    accessKey: {
      type: String,
      required: false,
      default: '',
    },
    secretKey: {
      type: String,
      required: false,
      default: '',
    },
    bucket: {
      type: String,
      required: false,
      default: '',
    },
    errorMessage: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      accessKeyLocal: this.accessKey,
      secretKeyLocal: this.secretKey,
      bucketLocal: this.bucket,
    };
  },
  watch: {
    errorMessage() {
      if (this.errorMessage === 'InvalidAccessKeyId') {
        this.$refs.accessKeyValidation.setErrors(['Invalid Access Key']);
      }
      if (this.errorMessage === 'SignatureDoesNotMatch') {
        this.$refs.secretKeyValidation.setErrors(['Invalid Secret Key']);
      }
      if (this.errorMessage === 'NoSuchBucket' || this.errorMessage === 'InvalidBucketName') {
        this.$refs.bucketValidation.setErrors(['Bucket Does Not Exist']);
      }
      if (this.errorMessage === 'InvalidBucket') {
        this.$refs.bucketValidation.setErrors(['Invalid Bucket']);
      }
      if (this.errorMessage === 'AccessDenied') {
        this.$refs.accessKeyValidation.setErrors(['Access Denied']);
      }
    },
  },
  methods: {
    getDetail() {
      return {
        accessKey: this.accessKeyLocal,
        secretKey: this.secretKeyLocal,
        bucket: this.bucketLocal,
      };
    },
  },
};
</script>
