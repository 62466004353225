<template>
  <v-card
    height="325px"
    width="325px"
    class="grey lighten-3 float-left ma-2"
    border="xl"
    hover
    :to="fullPath"
    align="center"
    justify="center"
  >
    <v-card-text class="black--text">
      <div>
        <v-icon
          style="font-size:100px;"
          color="secondary"
        >
          fa-regular fa-{{ item.icon }}
        </v-icon>
      </div>
      <h3 class="mt-5 text-left blue--text">
        {{ item.category ? item.category.toUpperCase() : '' }}
      </h3>
      <h2 class="mt-2 text-left">
        {{ item.title }}
      </h2>
      <div class="text-left mt-2">
        {{ item.description }}
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { kebabCase } from 'lodash';

export default {
  name: 'ReportCard',
  props: {
    item: {
      type: Object,
      required: false,
      default: null,
    },
  },
  computed: {
    fullPath() {
      return `/dashboard/${kebabCase(this.item.accountType)}/${this.item.path}`;
    },
  },
};
</script>
