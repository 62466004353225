<template>
  <div>
    <v-container>
      <v-row>
        <v-col>
          <h1 class="text-h5">
            Edit Report
          </h1>
        </v-col>
      </v-row>
    </v-container>

    <v-container>
      <report
        :vertical-id="$route.params.verticalId"
        :account-type="$route.params.accountType"
        :path="$route.params.path"
      />
    </v-container>
  </div>
</template>

<script>
import Report from '../../_components/Report';

export default {
  name: 'EditReport',
  components: {
    Report,
  },
};
</script>
