<template>
  <v-container
    fluid
    class="pa-0"
  >
    <v-row>
      <v-col
        cols="12"
        md="6"
        lg="4"
      >
        <ValidationProvider
          ref="accountValidation"
          v-slot="{ errors }"
          rules="required"
          name="Account"
        >
          <v-text-field
            v-model="accountLocal"
            label="Account"
            :error-messages="errors"
          />
        </ValidationProvider>
      </v-col>
    </v-row>
    <oauth ref="oauthControl" />
  </v-container>
</template>

<script>
import Oauth from './Oauth';

export default {
  name: 'BrightpearlSource',
  components: {
    Oauth,
  },
  props: {
    account: {
      type: String,
      required: false,
      default: '',
    },
    errorMessage: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      accountLocal: this.account,
    };
  },
  methods: {
    getDetail() {
      return {
        account: this.accountLocal,
        ...this.$refs.oauthControl.getDetail(),
      };
    },
  },
};
</script>
