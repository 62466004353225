<template>
  <v-row>
    <v-col cols="12">
      <v-data-table
        :headers="headers"
        :items="locations"
        :loading="loading"
        :must-sort="true"
        class="elevation-5"
        fixed-header
        height="80vh"
        hide-default-footer
        :disable-pagination="true"
      >
        <template #[`item.openDate`]="{ item }">
          {{ formatDate(item.openDate) }}
        </template>
        <template #[`item.closeDate`]="{ item }">
          {{ formatDate(item.closeDate) }}
        </template>
        <template #[`item.address`]="{ item }">
          <div>{{ item.street1 }}</div>
          <div>{{ item.street2 }}</div>
          <div>{{ item.city }} {{ item.state }} {{ item.zip }}</div>
        </template>
        <template #[`item.actions`]="{ item }">
          <!-- eslint-disable -->
          <v-btn
            fab
            text
            :to="`/settings/locations/edit/${item.accountSourceId}/${encodeURIComponent(item.storeId)}`"
            class="mr-2"
            x-small
          >
          <!-- eslint-enable -->
            <v-icon>
              edit
            </v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';
import locations from '../_api/locations';

export default {
  name: 'SettingsLocations',
  data() {
    return {
      loading: true,
      headers: [
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
        },
        {
          text: 'Store Id',
          value: 'storeId',
          sortable: true,
        },
        {
          text: 'Name',
          value: 'name',
          sortable: true,
        },
        {
          text: 'Address',
          value: 'address',
          sortable: false,
        },
        {
          text: 'Location Type',
          value: 'locationType',
          sortable: true,
        },
        {
          text: 'Status',
          value: 'status',
          sortable: true,
        },
        {
          text: 'Open Date',
          value: 'openDate',
          sortable: true,
        },
        {
          text: 'Close Date',
          value: 'closeDate',
          sortable: true,
        },
        {
          text: 'Entity Type',
          value: 'entityType',
          sortable: true,
        },
        {
          text: 'Market Type',
          value: 'marketType',
          sortable: true,
        },
      ],
      locations: [],
    };
  },
  computed: {
    ...mapGetters([
      'getActiveAccount',
    ]),
  },
  async mounted() {
    const result = await locations.getLocations(this.getActiveAccount.id);
    this.locations = result.data.locations;
    this.loading = false;
  },
  methods: {
    formatDate(value) {
      if (value) {
        return new Date(`${value}T00:00`).toLocaleDateString('en-us', { month: 'long', day: 'numeric', year: 'numeric' });
      }
      return null;
    },
  },
};
</script>
