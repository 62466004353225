<template>
  <v-row>
    <v-col
      cols="12"
      md="6"
      lg="4"
    >
      <v-checkbox
        v-model="includeDiscountLocal"
        label="Include Discount in Subtotal"
      />
      <div class="caption">
        When selected the discount will be subtracted from the subtotal and not just the total. For
        new implementations this should be selected as it is the recommended option.
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'ZaiusDiscountDestination',
  props: {
    includeDiscount: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      includeDiscountLocal: this.includeDiscount,
    };
  },
  methods: {
    getDetail() {
      return {
        includeDiscount: this.includeDiscountLocal,
      };
    },
  },
};
</script>
