<template>
  <v-row>
    <v-col
      cols="12"
      md="6"
      lg="4"
    >
      <v-textarea
        v-model.trim="storeOwnerLocal"
        outlined
        rows="4"
        label="Store Owners"
      />
      <div class="caption">
        Enter each store owner on a separate line. If blank then will return where store owner is
        null.
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'FleetFeetOnline',
  props: {
    storeOwner: {
      type: Array,
      required: false,
      default: () => [],
    },
    errorMessage: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      storeOwnerLocal: this.storeOwner.join('\n'),
    };
  },
  methods: {
    getDetail() {
      return {
        storeOwner: this.storeOwnerLocal.split('\n')
          .filter((x) => x !== '')
          .map((x) => x.toLowerCase().trim()),
      };
    },
  },
};
</script>
