<template>
  <div>
    <v-row>
      <v-col>
        <h2>
          Create User
        </h2>
      </v-col>
    </v-row>
    <user />
  </div>
</template>

<script>
import User from '../../_components/User';

export default {
  name: 'CreateUser',
  components: {
    User,
  },
};
</script>
