<template>
  <ValidationObserver ref="observer">
    <v-overlay :value="overlay">
      <v-progress-circular
        :size="70"
        :width="7"
        indeterminate
      />
    </v-overlay>
    <v-form
      class="mt-3"
      @submit.prevent="save"
    >
      <v-row>
        <v-col
          cols="12"
          lg="6"
          dense
        >
          <v-card
            class="mt-3"
            color="grey lighten-5"
          >
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <h3>Account Information</h3>
                </v-col>
                <v-col
                  v-if="!id"
                  cols="12"
                >
                  <ValidationProvider
                    ref="emailValidation"
                    v-slot="{ errors }"
                    rules="required|email"
                    name="Email"
                  >
                    <v-text-field
                      v-model.trim="email"
                      label="Email"
                      :error-messages="errors"
                      dense
                    />
                  </ValidationProvider>
                </v-col>
                <v-col
                  v-if="id"
                  cols="12"
                >
                  <h4>
                    {{ firstName }} {{ lastName }}
                  </h4>
                  <h4 class="font-italic">
                    {{ email }}
                  </h4>
                </v-col>
                <v-col cols="12">
                  <v-switch
                    v-model="isAdmin"
                    label="System Admin (All Permissions + Internal Users)"
                    dense
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-show="!isAdmin">
        <v-col
          cols="12"
          lg="6"
        >
          <v-card
            class="mt-3"
            color="grey lighten-5"
          >
            <v-card-text>
              <v-row dense>
                <v-col cols="12">
                  <h3>Permissions</h3>
                </v-col>
                <v-col cols="12">
                  <v-switch
                    v-model="hasDataManagement"
                    label="Data Management (Uploads + Tagging + Mapping + List)"
                    dense
                  />
                </v-col>
                <v-col cols="12">
                  <v-switch
                    v-model="hasConnectors"
                    label="Connectors"
                    dense
                  />
                </v-col>
                <v-col cols="12">
                  <v-switch
                    v-model="hasAccounts"
                    label="Accounts"
                    dense
                  />
                </v-col>
                <v-col cols="12">
                  <v-switch
                    v-model="hasReportsManagement"
                    label="Reports Management"
                    dense
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-show="!isAdmin">
        <v-col
          cols="12"
          lg="6"
        >
          <v-card
            class="mt-3"
            color="grey lighten-5"
          >
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <h3>Verticals</h3>
                </v-col>
                <v-col cols="12">
                  <v-select
                    v-model="selectedVerticals"
                    :items="verticals"
                    item-text="name"
                    item-value="id"
                    chips
                    multiple
                    deletable-chips
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-btn
            type="submit"
            color="primary"
            class="mt-2"
          >
            Save
          </v-btn>
          <v-btn
            type="submit"
            class="mt-2 ml-2"
            to="/admin/users"
          >
            Cancel
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </ValidationObserver>
</template>

<script>
import verticals from '@/api/verticals';
import users from '../_api/users';

export default {
  name: 'AdminUser',
  props: {
    id: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      overlay: false,
      firstName: '',
      lastName: '',
      email: '',
      accessLevel: 'accountManager',
      hasDataManagement: false,
      hasConnectors: false,
      hasAccounts: false,
      hasReportsManagement: false,
      verticals: [],
      selectedVerticals: [],
    };
  },
  computed: {
    isAdmin: {
      get() {
        return this.accessLevel === 'admin';
      },
      set(newValue) {
        this.accessLevel = newValue === true ? 'admin' : 'accountManager';
      },
    },
  },
  async created() {
    this.overlay = true;
    await Promise.all([this.getVerticals(), this.getUser()]);
    this.overlay = false;
  },
  methods: {
    async getVerticals() {
      const resp = await verticals.getVerticals();
      if (resp.data) {
        this.verticals = resp.data;
      }
    },
    async getUser() {
      if (this.id) {
        const resp = await users.getAdminUser(this.id);
        if (resp.data) {
          this.firstName = resp.data.firstName;
          this.lastName = resp.data.lastName;
          this.email = resp.data.email;
          this.accessLevel = resp.data.accessLevel;
          this.hasDataManagement = resp.data.hasDataManagement || false;
          this.hasConnectors = resp.data.hasConnectors || false;
          this.hasAccounts = resp.data.hasAccounts || false;
          this.hasReportsManagement = resp.data.hasReportsManagement || false;
          this.selectedVerticals = resp.data.verticals;
        } else {
          this.$router.push('/');
        }
      }
    },
    async save() {
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        try {
          if (this.id) {
            await users.updateAdminUser(
              this.id,
              this.accessLevel,
              this.hasDataManagement,
              this.hasConnectors,
              this.hasAccounts,
              this.hasReportsManagement,
              this.selectedVerticals,
            );
          } else {
            await users.invite(
              this.email,
              this.accessLevel,
              this.hasDataManagement,
              this.hasConnectors,
              this.hasAccounts,
              this.hasReportsManagement,
              this.selectedVerticals,
            );
          }
          this.$router.push('/admin/users/');
        } catch (e) {
          if (e.response && e.response.data === 'UserExists') {
            this.$refs.emailValidation.setErrors(['User already exists']);
          }
        }
      }
    },
  },
};
</script>
