<template>
  <div style="height:100%">
    <top-nav />
    <sidebar />
    <v-main style="height: 100%;">
      <router-view :key="$route.fullPath" />
    </v-main>
  </div>
</template>

<script>
import TopNav from '@/components/layout/TopNav';
import Sidebar from '@/components/layout/Sidebar';

export default {
  name: 'ViewFramework',
  components: {
    TopNav,
    Sidebar,
  },
  created() {
    this.$store.dispatch('navigation/sidebar/setOpen', this.$vuetify.breakpoint.lgAndUp);
  },
};
</script>
