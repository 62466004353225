import * as layouts from './_layouts';
import * as views from './_views';

export default [
  {
    path: 'admin',
    name: 'Admin',
    component: layouts.AdminLayout,
    redirect: 'admin/accounts',
    meta: {
      requiresSystemAccess: true,
      requireActive: false,
    },
    children: [
      {
        path: 'accounts',
        component: views.AccountList,
        meta: {
          requireAccountsPermission: true,
        },
      },
      {
        path: 'accounts/create',
        component: views.CreateAccount,
        meta: {
          requireAccountsPermission: true,
        },
      },
      {
        path: 'accounts/:id',
        component: views.EditAccount,
        meta: {
          requireAccountsPermission: true,
        },
      },
      {
        path: 'users',
        component: views.InternalUsers,
        meta: {
          requiresSystemAdmin: true,
        },
      },
      {
        path: 'users/create',
        component: views.CreateUser,
        meta: {
          requiresSystemAdmin: true,
        },
      },
      {
        path: 'users/:id',
        component: views.EditUser,
        meta: {
          requiresSystemAdmin: true,
        },
      },
      {
        path: 'productSummary',
        component: views.ProductSummary,
        meta: {
          requirePagePermission: 'retailerProductOverview',
        },
      },
      {
        path: 'brandProductSummary',
        component: views.BrandProductSummary,
        meta: {
          requirePagePermission: 'brandProductOverview',
        },
      },
      {
        path: 'emailListManagement',
        component: views.EmailListManagement,
        meta: {
          requireDataManagementPermission: true,
        },
      },
      {
        path: 'verticalManagement',
        component: views.VerticalManagement,
        meta: {
          requireSystemAdmin: true,
        },
      },
      {
        path: 'vertical/create',
        component: views.CreateVertical,
        meta: {
          requireSystemAdmin: true,
        },
      },
      {
        path: 'vertical/:id',
        component: views.EditVertical,
        meta: {
          requireSystemAdmin: true,
        },
      },
      {
        path: 'reportManagement',
        component: views.ReportManagement,
      },
      {
        path: 'reports/create',
        component: views.CreateReport,
        meta: {
          requireSystemAdmin: true,
        },
      },
      {
        path: 'reports/:verticalId/:accountType/:path',
        component: views.EditReport,
        meta: {
          requireSystemAdmin: true,
        },
      },
      {
        path: 'reports',
        component: layouts.ReportsLayout,
        meta: {
          requireSystemAdmin: true,
        },
        children: [
          {
            path: '',
            name: 'admin.reports.index',
            component: views.ReportsIndex,
          },
          {
            path: ':id/edit',
            name: 'admin.reports.edit',
            component: views.ReportsEdit,
          },
        ],
      },

    ],
  },
];
