import axios from '@/utils/axios';

const getAdminUsers = (status) => axios.get('/admin/users', {
  params: {
    status,
  },
});
const activate = (id) => axios.put(`/admin/users/${id}/activate`);
const deactivate = (id) => axios.put(`/admin/users/${id}/deactivate`);
const invite = (
  email,
  accessLevel,
  hasDataManagement,
  hasConnectors,
  hasAccounts,
  hasReportsManagement,
  verticals,
) => axios.post('/admin/users/invite', {
  email,
  accessLevel,
  hasDataManagement,
  hasConnectors,
  hasAccounts,
  hasReportsManagement,
  verticals,
});
const resendInvite = (id) => axios.post(`/admin/users/${id}/resendInvite`);
const getAdminUser = (id) => axios.get(`/admin/users/${id}`);
const updateAdminUser = (
  id,
  accessLevel,
  hasDataManagement,
  hasConnectors,
  hasAccounts,
  hasReportsManagement,
  verticals,
) => axios.put(`admin/users/${id}`, {
  accessLevel, hasDataManagement, hasConnectors, hasAccounts, hasReportsManagement, verticals,
});

export default {
  getAdminUsers,
  activate,
  deactivate,
  invite,
  resendInvite,
  getAdminUser,
  updateAdminUser,
};
