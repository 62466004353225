<template>
  <v-row>
    <v-col
      cols="12"
      md="6"
      lg="4"
    >
      <ValidationProvider
        ref="tenantTokenValidation"
        v-slot="{ errors }"
        rules="required"
        name="Tenant Token"
      >
        <v-text-field
          v-model="tenantTokenLocal"
          label="Tenant Token"
          :error-messages="errors"
        />
      </ValidationProvider>
    </v-col>
    <v-col
      cols="12"
      md="6"
      lg="4"
    >
      <ValidationProvider
        ref="userTokenValidation"
        v-slot="{ errors }"
        rules="required"
        name="User Token"
      >
        <v-text-field
          v-model="userTokenLocal"
          label="User Token"
          :error-messages="errors"
        />
      </ValidationProvider>
    </v-col>
  </v-row>
</template>

<script>

export default {
  name: 'SkuVault',
  props: {
    tenantToken: {
      type: String,
      required: false,
      default: '',
    },
    userToken: {
      type: String,
      required: false,
      default: '',
    },
    errorMessage: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      tenantTokenLocal: this.tenantToken,
      userTokenLocal: this.userToken,
    };
  },
  watch: {
    errorMessage() {
      if (this.errorMessage === 'InvalidToken') {
        this.$refs.tenantTokenValidation.setErrors(['Invalid Tenant and/or User Token']);
      }
    },
  },
  methods: {
    getDetail() {
      return {
        tenantToken: this.tenantTokenLocal,
        userToken: this.userTokenLocal,
      };
    },
  },
};
</script>
