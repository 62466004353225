import axios from '@/utils/axios';

const getAdminSummary = (verticalId, take, skip, sortBy, sortOrder) => axios.get(`/products/adminSummary/${verticalId}`, {
  params: {
    take,
    skip,
    sortBy,
    sortOrder,
  },
});
const getBrandAdminSummary = (verticalId, take, skip, sortBy, sortOrder) => axios.get(`/products/brandAdminSummary/${verticalId}`, {
  params: {
    take,
    skip,
    sortBy,
    sortOrder,
  },
});

export default {
  getAdminSummary,
  getBrandAdminSummary,
};
