<template>
  <div>
    Make sure that your Facebook Business Account has give permissions to Upper Quadrant's business
    account 164993337238214.
  </div>
</template>

<script>
export default {
  name: 'FacebookOffline',
  methods: {
    getDetail() {
      return {};
    },
  },
};
</script>
