<template>
  <v-card>
    <v-card-title
      primary-title
      class="primary white--text"
    >
      <span class="title">Create Source</span>
    </v-card-title>
    <v-card-text>
      <Source />
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import Source from '../_components/Sources/Source';

export default {
  name: 'CreateSource',
  components: {
    Source,
  },
  computed: {
    ...mapGetters([
      'getActiveAccount',
    ]),
  },
  mounted() {
    if (this.getActiveAccount.usedSources >= this.getActiveAccount.sources) {
      this.$router.push('/');
    }
  },
};
</script>
