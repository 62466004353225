<template>
  <v-container
    fluid
    class="pa-0"
  >
    <rics
      v-if="sourceType === 1"
      ref="rics"
      v-bind="sourceDetail"
    />
    <runit
      v-if="sourceType === 14"
      ref="runit"
      v-bind="sourceDetail"
    />
    <lightspeed
      v-if="sourceType === 8"
      ref="lightspeed"
      v-bind="sourceDetail"
    />
    <heartland
      v-if="sourceType === 10"
      ref="heartland"
      v-bind="sourceDetail"
    />
  </v-container>
</template>

<script>
import Rics from './Cadence/Rics';
import Runit from './Cadence/Runit';
import Lightspeed from './Cadence/Lightspeed';
import Heartland from './Cadence/Heartland';

export default {
  name: 'CadenceDestination',
  components: {
    Rics,
    Runit,
    Lightspeed,
    Heartland,
  },
  props: {
    sourceType: {
      type: Number,
      required: false,
      default: 0,
    },
    sourceDetail: {
      type: Object,
      required: false,
      default: null,
    },
    destinationDetail: {
      type: Object,
      required: false,
      default: null,
    },
    errorMessage: {
      type: String,
      required: false,
      default: '',
    },
  },
  methods: {
    getDetail() {
      switch (this.sourceType) {
        case 1:
          return this.$refs.rics.getDetail();

        case 8:
          return this.$refs.lightspeed.getDetail();

        case 10:
          return this.$refs.heartland.getDetail();

        case 14:
          return this.$refs.runit.getDetail();

        default:
          return {};
      }
    },
  },
};
</script>
