import axios from '@/utils/axios';

const getCustomFields = (apiKey) => axios.get('/sources/heartland/customFields', {
  params: {
    apiKey,
  },
});
const getLocations = (apiKey) => axios.get('/sources/heartland/locations', {
  params: {
    apiKey,
  },
});
const preview = (apiKey) => axios.get('/sources/heartland/preview', {
  params: {
    apiKey,
  },
});

export default {
  getCustomFields,
  getLocations,
  preview,
};
