const mutations = {
  AUTH_SUCCESS: (state, exp) => {
    state.exp = exp;
  },
  AUTH_LOGOUT: (state) => {
    state.exp = '';
  },
};

export default mutations;
