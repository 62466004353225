<template>
  <v-row>
    <v-col
      cols="12"
      md="6"
      lg="4"
    >
      <v-btn
        color="primary"
        @click.prevent="initAuthenticate"
      >
        Authenticate App
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'PerkvilleSourceType',
  methods: {
    async initAuthenticate() {
      const url = encodeURIComponent(`${window.location.protocol}//${window.location.host}/finalizeOauth`);
      window.location.href = `https://www.perkville.com/api/authorize/?scope=PUBLIC+ADMIN_CUSTOMER_INFO&state=${this.$route.query.t}&redirect_uri=${url}&client_id=${process.env.VUE_APP_PERKVILLE_CLIENT_ID}&response_type=code`;
    },
  },
};
</script>
