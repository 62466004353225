<template>
  <Wrapper>
    <LoginForm
      v-if="!showTwoFactor"
      @TwoFactorRequired="onShowTwoFactor"
      @LoginSuccess="loginSuccess"
    />
    <TwoFactor
      v-if="showTwoFactor"
      :email="email"
      :password="password"
      @LoginSuccess="loginSuccess"
    />
  </Wrapper>
</template>

<script>
import { mapGetters } from 'vuex';
import Wrapper from '../_components/Wrapper';
import LoginForm from '../_components/LoginForm';
import TwoFactor from '../_components/TwoFactor';

export default {
  name: 'AuthLogin',
  components: {
    LoginForm,
    TwoFactor,
    Wrapper,
  },
  data() {
    return {
      showTwoFactor: false,
      email: '',
      password: '',
    };
  },
  computed: {
    ...mapGetters([
      'getProfile',
      'getFullName',
      'getActiveAccount',
    ]),
  },
  methods: {
    onShowTwoFactor(user) {
      this.email = user.email;
      this.password = user.password;
      this.showTwoFactor = true;
    },
    loginSuccess() {
      if (this.$zendesk.zE) {
        this.setZendesk();
      } else {
        this.$zendesk.$on('loaded', () => {
          this.setZendesk();
        });
      }
      window.analytics.identify(this.getProfile.id, {
        name: this.getFullName,
        email: this.getProfile.email,
      });
      window.analytics.track('Login', {
        accountId: this.getActiveAccount.id,
        name: this.getActiveAccount.name,
      });
      window.analytics.group(this.getActiveAccount.id, {
        name: this.getActiveAccount.name,
        accountType: this.getActiveAccount.accountType,
      });
      if (this.$route.query.redirect) {
        this.$router.push(this.$route.query.redirect);
      } else {
        this.$router.push('/');
      }
    },
    setZendesk() {
      this.$zendesk.identify({
        name: this.getFullName,
        email: this.getProfile.email,
      });
      this.$zendesk.prefill({
        name: {
          value: this.getFullName,
        },
        email: {
          value: this.getProfile.email,
        },
      });
    },
  },
};
</script>
