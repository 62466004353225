<template>
  <NavList :nav-items="navItems" />
</template>

<script>
import NavList from './NavList';

export default {
  name: 'SettingsNavList',
  components: {
    NavList,
  },
  data() {
    return {
      navItems: [
        {
          text: 'SETTINGS',
          header: true,
        },
        {
          text: 'My Profile',
          icon: 'user',
          route: '/profile',
        },
        {
          text: 'Users & Permissions',
          route: '/settings/users',
          requireAccountAdmin: true,
          icon: 'users',
        },
        {
          text: 'Retail Connect',
          route: '/settings/retailConnect',
          requireAccountAdmin: true,
          requireAccountType: ['Brand'],
          icon: 'exchange',
          requirePagePermission: 'accountConnections',
        },
        {
          text: 'Data Permissions',
          route: '/settings/dataPermissions',
          requireAccountAdmin: true,
          requireAccountType: ['Retailer', 'Retailer Rollup'],
          icon: 'database',
          requirePagePermission: 'accountConnections',
        },
        {
          text: 'Locations',
          route: '/settings/locations',
          requireAccountType: ['Retailer', 'Retailer Rollup'],
          icon: 'location-dot',
          requirePagePermission: 'locations',
          requireAccountAdmin: true,
        },
        {
          text: 'Connectors',
          route: '/connectors',
          icon: 'exchange-alt',
          requireSystemPermission: 'hasConnectors',
          requireSystemUser: true,
        },
      ],
    };
  },
};
</script>
