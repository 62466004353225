<template>
  <div>
    <v-row
      v-for="(item, index) in filtersLocal"
      :key="index"
      align="center"
    >
      <v-col
        v-if="index > 0"
        cols="12"
      >
        <v-chip>
          AND
        </v-chip>
      </v-col>
      <v-col cols="12">
        <v-card
          elevation="5"
          color="grey lighten-5"
        >
          <v-card-text>
            <column-filter
              :column.sync="item.column"
              :type.sync="item.type"
              :values.sync="item.values"
              :columns="columns"
            />
          </v-card-text>
          <v-card-actions>
            <v-btn
              text
              @click="deleteFilter(index)"
            >
              Remove Filter
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-btn
          class="mx-2"
          small
          fab
          dark
          color="secondary"
          @click="addFilter"
        >
          <v-icon dark>
            mdi-plus
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ColumnFilter from './ColumnFilter';

export default {
  name: 'ColumnFilterList',
  components: {
    ColumnFilter,
  },
  props: {
    filters: {
      type: Array,
      required: false,
      default: null,
    },
    columns: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      filtersLocal: this.filters,
    };
  },
  watch: {
    filters() {
      this.filtersLocal = this.filters;
    },
  },
  mounted() {
    if (!this.filtersLocal) {
      this.filtersLocal = [
        {
          column: null,
          type: null,
          values: [],
        },
      ];
      this.$emit('update:filters', this.filtersLocal);
    }
  },
  methods: {
    addFilter() {
      this.filtersLocal.push({
        column: null,
        type: null,
        values: [],
      });
      this.$emit('update:filters', this.filtersLocal);
    },
    deleteFilter(index) {
      this.filtersLocal.splice(index, 1);
      if (this.filtersLocal.length === 0) {
        this.addFilter();
      }
      this.$emit('update:filters', this.filtersLocal);
    },
    getFilters() {
      return this.filtersLocal;
    },
  },
};
</script>
