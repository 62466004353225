import axios from '@/utils/axios';

const preview = (retailerId, apiKey) => axios.get('/sources/bridalLive/preview', {
  params: {
    retailerId,
    apiKey,
  },
});

export default {
  preview,
};
