<template>
  <span>
    <v-row>
      <ignore-web-orders
        ref="ignoreWebOrders"
        :ignore-web-orders="ignoreWebOrders"
      />
      <v-col cols="12">
        <div class="subtitle-2">
          The following are the standard Cadence product fields that need to be mapped to a
          corresponding field in Heartland. Not all of the fields may map.
        </div>
      </v-col>
      <v-col
        cols="12"
        md="6"
        lg="4"
      >
        <ValidationProvider
          v-slot="{ errors }"
          rules="heartland_unique:@UPC,@SKU,@Size,@Width,@Color,@Style"
          name="Brand"
        >
          <v-select
            v-model="brandLocal"
            :items="selectHeartlandItemColumns"
            :clearable="true"
            :error-messages="errors"
            item-text="name"
            item-value="value"
            label="Brand"
            :hint="`column: ${brandLocal}`"
            persistent-hint
          />
        </ValidationProvider>
      </v-col>
      <v-col
        cols="12"
        md="6"
        lg="4"
      >
        <ValidationProvider
          v-slot="{ errors }"
          rules="heartland_unique:@Brand,@SKU,@Size,@Width,@Color,@Style"
          name="UPC"
        >
          <v-select
            v-model="upcLocal"
            :items="selectHeartlandItemColumns"
            :clearable="true"
            :error-messages="errors"
            item-text="name"
            item-value="value"
            label="UPC"
            :hint="`column: ${upcLocal}`"
            persistent-hint
          />
        </ValidationProvider>
      </v-col>
      <v-col
        cols="12"
        md="6"
        lg="4"
      >
        <ValidationProvider
          v-slot="{ errors }"
          rules="heartland_unique:@Brand,@UPC,@Size,@Width,@Color,@Style"
          name="SKU"
        >
          <v-select
            v-model="skuLocal"
            :items="selectHeartlandItemColumns"
            :clearable="true"
            :error-messages="errors"
            item-text="name"
            item-value="value"
            label="SKU"
            :hint="`column: ${skuLocal}`"
            persistent-hint
          />
        </ValidationProvider>
      </v-col>
      <v-col
        cols="12"
        md="6"
        lg="4"
      >
        <ValidationProvider
          v-slot="{ errors }"
          rules="heartland_unique:@Brand,@UPC,@SKU,@Width,@Color,@Style"
          name="Size"
        >
          <v-select
            v-model="sizeLocal"
            :items="selectHeartlandItemColumns"
            :clearable="true"
            :error-messages="errors"
            item-text="name"
            item-value="value"
            label="Size"
            :hint="`column: ${sizeLocal}`"
            persistent-hint
          />
        </ValidationProvider>
      </v-col>
      <v-col
        cols="12"
        md="6"
        lg="4"
      >
        <ValidationProvider
          v-slot="{ errors }"
          rules="heartland_unique:@Brand,@UPC,@SKU,@Size,@Color,@Style"
          name="Width"
        >
          <v-select
            v-model="widthLocal"
            :items="selectHeartlandItemColumns"
            :clearable="true"
            :error-messages="errors"
            item-text="name"
            item-value="value"
            label="Width"
            :hint="`column: ${widthLocal}`"
            persistent-hint
          />
        </ValidationProvider>
      </v-col>
      <v-col
        cols="12"
        md="6"
        lg="4"
      >
        <ValidationProvider
          v-slot="{ errors }"
          rules="heartland_unique:@Brand,@UPC,@SKU,@Size,@Width,@Style"
          name="Color"
        >
          <v-select
            v-model="colorLocal"
            :items="selectHeartlandItemColumns"
            :clearable="true"
            :error-messages="errors"
            item-text="name"
            item-value="value"
            label="Color"
            :hint="`column: ${colorLocal}`"
            persistent-hint
          />
        </ValidationProvider>
      </v-col>
      <v-col
        cols="12"
        md="6"
        lg="4"
      >
        <ValidationProvider
          v-slot="{ errors }"
          rules="heartland_unique:@Brand,@UPC,@SKU,@Size,@Width,@Color"
          name="ProductStyle"
        >
          <v-select
            v-model="productStyleLocal"
            :items="selectHeartlandItemColumns"
            :clearable="true"
            :error-messages="errors"
            item-text="name"
            item-value="value"
            label="Style"
            :hint="`column: ${productStyleLocal}`"
            persistent-hint
          />
        </ValidationProvider>
      </v-col>
      <v-col
        cols="12"
        md="6"
        lg="4"
      >
        <v-select
          v-model="tagsLocal"
          :items="selectHeartlandItemColumns"
          :clearable="true"
          item-text="name"
          item-value="value"
          label="Tags"
          multiple
          :hint="`column(s): ${tagsLocal.join(', ')}`"
          persistent-hint
        />
        <div class="caption mt-3">
          Select all the fields that will be used to tag this product. These should be fields not
          already mapped to one of the standard columns.
        </div>
      </v-col>
    </v-row>
  </span>
</template>

<script>
import { mapState } from 'vuex';
import IgnoreWebOrders from '../Components/IgnoreWebOrders';

export default {
  name: 'CadenceHeartlandDestination',
  components: {
    IgnoreWebOrders,
  },
  props: {
    errorMessage: {
      type: String,
      required: false,
      default: '',
    },
    brand: {
      type: String,
      required: false,
      default: '',
    },
    upc: {
      type: String,
      required: false,
      default: '',
    },
    sku: {
      type: String,
      required: false,
      default: '',
    },
    size: {
      type: String,
      required: false,
      default: '',
    },
    width: {
      type: String,
      required: false,
      default: '',
    },
    color: {
      type: String,
      required: false,
      default: '',
    },
    productStyle: {
      type: String,
      required: false,
      default: '',
    },
    tags: {
      type: Array,
      required: false,
      default: () => [],
    },
    ignoreWebOrders: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      brandLocal: this.brand,
      upcLocal: this.upc,
      skuLocal: this.sku,
      sizeLocal: this.size,
      widthLocal: this.width,
      colorLocal: this.color,
      productStyleLocal: this.productStyle,
      tagsLocal: this.tags,
      ignoreWebOrdersLocal: this.ignoreWebOrders,
    };
  },
  computed: {
    ...mapState('$_sources', {
      heartlandItemColumns: (state) => state.heartlandItemColumns,
    }),
    selectHeartlandItemColumns() {
      return [
        {
          name: 'Primary Vendor',
          value: 'standard primary_vendor',
        },
        {
          name: 'Item #',
          value: 'standard public_id',
        },
      ].concat(this.heartlandItemColumns)
        .sort((a, b) => ((a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1));
    },
  },
  methods: {
    getDetail() {
      return {
        brand: this.brandLocal,
        upc: this.upcLocal,
        sku: this.skuLocal,
        size: this.sizeLocal,
        width: this.widthLocal,
        color: this.colorLocal,
        productStyle: this.productStyleLocal,
        tags: this.tagsLocal,
        ...this.$refs.ignoreWebOrders.getDetail(),
      };
    },
  },
};
</script>
