import { get } from 'lodash';

function createHashMapBy(arr, callback, thisArg = null) {
  let cb = callback;

  if (typeof callback === 'string' || Array.isArray(callback)) {
    cb = (collection) => get(collection, callback);
  }

  return arr.reduce((map, value) => {
    const m = map;

    m[cb.call(thisArg, value)] = value;

    return m;
  }, {});
}

export { createHashMapBy };
export default createHashMapBy;
