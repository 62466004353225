<template>
  <v-card>
    <v-card-title
      primary-title
      class="primary white--text"
    >
      <span class="title">Edit Destination</span>
    </v-card-title>
    <v-card-text>
      <Destination :id="$route.params.id" />
    </v-card-text>
  </v-card>
</template>

<script>
import Destination from '../_components/Destinations/Destination';

export default {
  name: 'EditDestination',
  components: {
    Destination,
  },
};
</script>
