<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        class="title"
      >
        Password
      </v-col>
    </v-row>
    <v-row
      v-if="!showEdit"
      no-gutters
    >
      <v-col cols="12">
        <v-btn
          v-show="!showEdit"
          color="secondary"
          outlined
          @click.prevent="showEdit = true"
        >
          Change Password
        </v-btn>
      </v-col>
    </v-row>
    <v-row
      v-else
      no-gutters
    >
      <v-col cols="12">
        <ValidationObserver ref="observer">
          <v-form
            @submit.prevent="changePassword"
          >
            <v-row>
              <v-col cols="12">
                <ValidationProvider
                  ref="passwordValidationProvider"
                  v-slot="{ errors }"
                  rules="required"
                  name="Current Password"
                >
                  <v-text-field
                    v-model="password"
                    :append-icon="showPassword ? 'visibility' : 'visibility_off'"
                    :type="showPassword ? 'text' : 'password'"
                    placeholder="Current Password"
                    :error-messages="errors"
                    @click:append="showPassword = !showPassword"
                  />
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <ValidationProvider
                  ref="newPasswordValidation"
                  v-slot="{ errors }"
                  rules="required"
                  name="New Password"
                  vid="New Password"
                >
                  <v-text-field
                    v-model="newPassword"
                    :append-icon="showNewPassword ? 'visibility' : 'visibility_off'"
                    :type="showNewPassword ? 'text' : 'password'"
                    placeholder="New Password"
                    :error-messages="errors"
                    @click:append="showNewPassword = !showNewPassword"
                  />
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <ValidationProvider
                  v-slot="{ errors }"
                  rules="required|confirmed:New Password"
                  name="Confirm Password"
                >
                  <v-text-field
                    v-model="confirmPassword"
                    :append-icon="showConfirmPassword ? 'visibility' : 'visibility_off'"
                    :type="showConfirmPassword ? 'text' : 'password'"
                    placeholder="Confirm Password"
                    :error-messages="errors"
                    @click:append="showConfirmPassword = !showConfirmPassword"
                  />
                </ValidationProvider>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-btn
                  color="primary"
                  type="submit"
                >
                  Change Password
                </v-btn>
                <v-btn
                  outlined
                  color="primary"
                  class="ml-1"
                  @click="cancel"
                >
                  Cancel
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <passwordValidation
                  ref="passwordValidation"
                  :password="newPassword"
                />
              </v-col>
            </v-row>
          </v-form>
        </ValidationObserver>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import PasswordValidation from '@/components/PasswordValidation';

export default {
  name: 'PasswordChange',
  components: { PasswordValidation },
  data() {
    return {
      showPassword: false,
      showNewPassword: false,
      showConfirmPassword: false,
      showEdit: false,
      password: '',
      newPassword: '',
      confirmPassword: '',
    };
  },
  methods: {
    async changePassword() {
      const { password, newPassword } = this;

      let isValid = await this.$refs.observer.validate();
      if (!this.$refs.passwordValidation.isValid()) {
        this.$refs.newPasswordValidation.setErrors(['Password does not meet the password policy']);
        isValid = false;
      }

      if (isValid) {
        try {
          await this.$store.dispatch('USER_UPDATE_PASSWORD', {
            password,
            newPassword,
          });
          this.password = '';
          this.newPassword = '';
          this.confirmPassword = '';
          this.showEdit = false;
        } catch (error) {
          if (error.response) {
            if (error.response.data === 'InvalidPassword') {
              this.$refs.passwordValidationProvider.setErrors(['Current Password is incorrect']);
            }
          }
        }
      }
    },
    cancel() {
      this.password = '';
      this.newPassword = '';
      this.confirmPassword = '';
      this.$refs.observer.reset();
      this.showEdit = false;
    },
  },
};
</script>
