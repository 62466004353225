import axios from '@/utils/axios';

const login = (user) => axios.post('/auth', user);
const reset = (email) => axios.post('/auth/reset', email);
const validateReset = (token) => axios.get(`/auth/validateReset/${token}`);
const changePassword = (token, password) => axios.put(`/auth/changePassword/${token}`, {
  password,
});
const refreshToken = () => axios.get('/user/refreshToken');
const validateAdminJoin = (token) => axios.get(`/auth/join/admin/validate/${token}`);
const adminJoin = (token, firstName, lastName, password, code) => axios
  .put(`/auth/join/admin/${token}`, {
    firstName,
    lastName,
    password,
    code,
  });
const validateJoin = (token) => axios.get(`/auth/join/validate/${token}`);
const join = (token, firstName, lastName, password) => axios.put(`/auth/join/${token}`, {
  firstName,
  lastName,
  password,
});
const logout = () => axios.delete('/auth');
const expiration = () => axios.get('/auth/expiration');

export default {
  login,
  reset,
  validateReset,
  changePassword,
  refreshToken,
  validateAdminJoin,
  adminJoin,
  validateJoin,
  join,
  logout,
  expiration,
};
