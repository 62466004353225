import api from '@/modules/admin/_api/verticals';

// ---
export default {
  namespaced: true,
  state: {
    verticals: [],
  },
  mutations: {
    SET_VERTICALS(state, verticals) {
      // Verticals are always alphabetized by name.
      state.verticals = verticals.sort((v1, v2) => {
        if (v1.name < v2.name) {
          return -1;
        }
        return 1;
      });
    },
  },
  actions: {
    init({ dispatch }, params = {}) {
      return dispatch('get', params);
    },
    async get({ commit }, params = {}) {
      const { data } = await api.getVerticals(params);

      commit('SET_VERTICALS', data);
    },
    reset({ commit }) {
      api.getVerticals.cache.clear();

      commit('SET_VERTICALS', []);
    },
    logout: {
      root: true,
      handler({ dispatch }) {
        dispatch('reset');
      },
    },
  },
};
