<template>
  <v-row>
    <v-col
      cols="12"
      md="6"
      lg="4"
    >
      <ValidationProvider
        ref="eventKeyValidation"
        v-slot="{ errors }"
        rules="required"
        name="Event API Key"
      >
        <v-text-field
          v-model="eventKeyLocal"
          label="Event API Key"
          :error-messages="errors"
        />
      </ValidationProvider>
      <div class="caption">
        You copy-paste your Blueshift Event API Key from Account Settings > API Keys > Event API Key
      </div>
    </v-col>
    <v-col
      cols="12"
      md="6"
      lg="4"
    >
      <ValidationProvider
        ref="usersKeyValidation"
        v-slot="{ errors }"
        rules="required"
        name="Users API Key"
      >
        <v-text-field
          v-model="usersKeyLocal"
          label="Users API Key"
          :error-messages="errors"
        />
      </ValidationProvider>
      <div class="caption">
        You copy-paste your Blueshift Users API Key from Account Settings > API Keys > Users API Key
      </div>
    </v-col>
    <v-col
      cols="12"
      md="6"
      lg="4"
    >
      <v-select
        v-model="eventType"
        :items="eventTypes"
        label="Event Type"
      />
      <div class="caption">
        You can create dummy events for setting up transactions. Select the event type and click the
        button below. Events will be created as dummy.user@test.com.
      </div>
      <v-btn
        color="primary"
        class="mt-2"
        @click.native="createEvent"
      >
        Create Event
      </v-btn>
    </v-col>
    <v-snackbar
      v-model="eventSuccess"
      color="primary"
      :centered="true"
      :timeout="2000"
    >
      Event Created
    </v-snackbar>
  </v-row>
</template>

<script>
import blueshift from '../../../_api/blueshift';

export default {
  name: 'BlueshiftDestinationType',
  props: {
    id: {
      type: String,
      required: false,
      default: '',
    },
    eventKey: {
      type: String,
      required: false,
      default: '',
    },
    usersKey: {
      type: String,
      required: false,
      default: '',
    },
    errorMessage: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      eventKeyLocal: this.eventKey,
      usersKeyLocal: this.usersKey,
      eventType: 'cancel',
      eventTypes: [
        'cancel',
        'return',
        'race_registration',
      ],
      eventSuccess: false,
    };
  },
  watch: {
    errorMessage() {
      if (this.errorMessage === 'InvalidEventKey') {
        this.$refs.eventKeyValidation.setErrors(['Invalid Event API Key']);
      }
      if (this.errorMessage === 'InvalidUsersKey') {
        this.$refs.usersKeyValidation.setErrors(['Invalid Users API Key']);
      }
    },
  },
  methods: {
    async createEvent() {
      try {
        await blueshift.createTestEvent(this.eventKeyLocal, this.eventType);
        this.eventSuccess = true;
      } catch (e) {
        this.$refs.eventKeyValidation.setErrors(['Invalid Event API Key']);
      }
    },
    getDetail() {
      return {
        eventKey: this.eventKeyLocal,
        usersKey: this.usersKeyLocal,
      };
    },
  },
};
</script>
