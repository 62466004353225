<template>
  <Wrapper>
    <h2 class="mb-2">
      Preference Center
    </h2>
    <div
      v-if="validToken"
      class="caption"
    >
      Changes will be made for email {{ decoded.email }}. <br>
      I would like to receive emails for the following topics: <br>
      (Check all that apply)
    </div>
    <div
      v-if="validToken && loaded"
    >
      <email-list
        ref="emailList"
        :subscribed.sync="subscribed"
        :label="label"
      />
      <v-btn
        color="primary"
        @click="save"
      >
        Update Preferences
      </v-btn>
    </div>
    <v-alert
      v-show="success"
      type="success"
      class="mt-3"
    >
      Your email preferences have been updated.
    </v-alert>
    <v-alert
      v-show="!validToken"
      type="error"
      class="mt-3"
    >
      The link is invalid.
      <a href="/">Click here</a> to go to the login page.
    </v-alert>
  </Wrapper>
</template>

<script>
import { jwtDecode } from 'jwt-decode';
import Wrapper from '../_components/Wrapper';
import EmailList from '../_components/EmailPreferences/EmailList';
import emailPreferences from '../_api/emailPreferences';

export default {
  name: 'EmailPreferences',
  components: {
    Wrapper,
    EmailList,
  },
  data() {
    return {
      validToken: true,
      success: false,
      decoded: {},
      subscribed: true,
      label: '',
      loaded: false,
    };
  },
  async mounted() {
    try {
      this.decoded = jwtDecode(this.$route.query.t);
      const resp = await emailPreferences.getPreferences(this.$route.query.t);
      this.subscribed = resp.data.subscribed;
      this.label = resp.data.name;
      this.loaded = true;
    } catch (e) {
      this.validToken = false;
    }
  },
  methods: {
    async save() {
      this.success = false;
      try {
        await emailPreferences.updatePreferences(this.$route.query.t, this.subscribed);
        this.success = true;
      } catch (e) {
        this.validToken = false;
      }
    },
  },
};
</script>
