import axios from '@/utils/axios';

const deleteDestination = (accountId, accountDestinationId) => axios.delete(`/destinations/${accountId}/${accountDestinationId}`);
const getDestination = (accountId, accountDestinationId) => axios.get(`/destinations/${accountId}/${accountDestinationId}`);
const createDestination = (accountId, name, type, detail) => axios.post(`/destinations/${accountId}/`, {
  name,
  type,
  detail,
});
const updateDestination = (accountId, accountDestinationId, name, detail) => axios.patch(`/destinations/${accountId}/${accountDestinationId}`, {
  name,
  detail,
});
const getUqubeDatabases = () => axios.get('/destinations/uqube/databases');

export default {
  deleteDestination,
  getDestination,
  createDestination,
  updateDestination,
  getUqubeDatabases,
};
