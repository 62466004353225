import axios from '@/utils/axios';

const deleteSource = (accountId, accountSourceId) => axios.delete(`/sources/${accountId}/${accountSourceId}`);
const disableSource = (accountId, accountSourceId) => axios.patch(`/sources/${accountId}/${accountSourceId}/disable`);
const enableSource = (accountId, accountSourceId) => axios.patch(`/sources/${accountId}/${accountSourceId}/enable`);
const getSource = (accountId, accountSourceId) => axios.get(`/sources/${accountId}/${accountSourceId}`);
const createSource = (accountId, name, type, detail, frequency, anchorTime, startDate, destinations) => axios.post(`/sources/${accountId}/`, {
  name,
  type,
  detail,
  frequency,
  anchorTime,
  startDate,
  destinations,
});
const updateSource = (accountId, accountSourceId, name, detail, frequency, anchorTime, destinations, pullHistory, historicalDate) => axios.patch(`/sources/${accountId}/${accountSourceId}/`, {
  name,
  detail,
  frequency,
  anchorTime,
  destinations,
  pullHistory,
  historicalDate,
});
const deleteData = (accountId) => axios.delete(`/sources/deleteData/${accountId}`);

export default {
  deleteSource,
  disableSource,
  enableSource,
  getSource,
  createSource,
  updateSource,
  deleteData,
};
