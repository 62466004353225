/* eslint-disable func-names */
/* eslint-disable no-underscore-dangle */
window.analytics = window.analytics || [];

const { analytics } = window;

if (!analytics.initialize && !analytics.invoked) {
  analytics.invoked = true;
  analytics.methods = ['trackSubmit', 'trackClick', 'trackLink', 'trackForm', 'pageview', 'identify', 'reset', 'group', 'track', 'ready', 'alias', 'debug', 'page', 'once', 'off', 'on', 'addSourceMiddleware', 'addIntegrationMiddleware', 'setAnonymousId', 'addDestinationMiddleware'];
  analytics.factory = function (e) {
    return function (...args) {
      const t = Array.prototype.slice.call(args);
      t.unshift(e);
      analytics.push(t);
      return analytics;
    };
  };

  for (let e = 0; e < analytics.methods.length; e += 1) {
    const key = analytics.methods[e];
    analytics[key] = analytics.factory(key);
  }

  analytics.load = function (key, e) {
    const t = document.createElement('script');
    t.type = 'text/javascript';
    t.async = true;
    t.src = `https://cdn.segment.com/analytics.js/v1/${key}/analytics.min.js`;
    const n = document.getElementsByTagName('script')[0];
    n.parentNode.insertBefore(t, n);
    analytics._loadOptions = e;
  };

  analytics._writeKey = process.env.VUE_APP_SEGMENT_KEY;
  analytics.SNIPPET_VERSION = '4.13.2';
  analytics.load(process.env.VUE_APP_SEGMENT_KEY);
}
