<template>
  <div>
    <v-row>
      <v-col cols="6">
        <v-btn
          to="/admin/accounts/create"
          color="primary"
        >
          + Create Account
        </v-btn>
      </v-col>
      <v-col cols="6">
        <v-text-field
          v-model="filter"
          label="Search for Account"
          prepend-inner-icon="fal fa-search"
          outlined
          dense
          hide-details
          solo
          flat
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="accounts"
          :options.sync="options"
          :server-items-length="total"
          :loading="loading"
          :must-sort="true"
          :footer-props="{
            'items-per-page-options': []
          }"
          class="elevation-5"
          @click:row="rowClick"
        >
          <template #[`item.createdAt`]="{ item }">
            {{ formatCreated(item.createdAt) }}
          </template>
          <template #[`item.isActive`]="{ item }">
            <v-checkbox
              v-model="item.isActive"
              color="success"
              disabled
            />
          </template>
          <template #[`item.isHidden`]="{ item }">
            <v-checkbox
              v-model="item.isHidden"
              color="success"
              disabled
            />
          </template>
          <template #[`item.showPreview`]="{ item }">
            <v-checkbox
              v-model="item.showPreview"
              color="success"
              disabled
            />
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { debounce } from 'lodash';
import accounts from '../../_api/accounts';

export default {
  name: 'AccountList',
  data() {
    return {
      options: {
        sortBy: [
          'name',
        ],
        sortDesc: [
          false,
        ],
        itemsPerPage: 50,
      },
      loading: true,
      total: 0,
      headers: [
        {
          text: 'Name',
          value: 'name',
          sortable: true,
          width: '20%',
        },
        {
          text: 'Account Type',
          value: 'accountType',
          sortable: true,
          width: '20%',
        },
        {
          text: 'Vertical',
          value: 'vertical',
          sortable: true,
          width: '15%',
        },
        {
          text: 'Create Date',
          value: 'createdAt',
          sortable: true,
          width: '15%',
        },
        {
          text: 'Is Active',
          value: 'isActive',
          sortable: true,
          width: '10%',
        },
        {
          text: 'Is Hidden',
          value: 'isHidden',
          sortable: true,
          width: '10%',
        },
        {
          text: 'Show Preview',
          value: 'showPreview',
          sortable: true,
          width: '10%',
        },
      ],
      accounts: [],
      filter: '',
    };
  },
  watch: {
    options() {
      this.getData();
    },
    async filter() {
      await this.filterData();
    },
  },
  created() {
    this.filterData = debounce(this.filterData, 1000);
  },
  methods: {
    rowClick(item) {
      this.$router.push(`/admin/accounts/${item.id}`);
    },
    async filterData() {
      if (this.options.page !== 1) {
        this.options.page = 1;
      } else {
        await this.getData();
      }
    },
    async getData() {
      const {
        sortBy, sortDesc, page, itemsPerPage,
      } = this.options;

      this.loading = true;
      let sortOrder;
      if (sortDesc) {
        sortOrder = sortDesc[0] === false ? 'asc' : 'desc';
      }

      const resp = await accounts.getAccounts(
        itemsPerPage,
        (page - 1) * itemsPerPage,
        sortBy ? sortBy[0] : null,
        sortOrder,
        this.filter,
      );

      this.total = resp.data.total;
      this.accounts = resp.data.accounts;
      this.loading = false;
    },
    formatCreated(value) {
      return new Date(value).toLocaleDateString('en-us', { month: 'long', day: 'numeric', year: 'numeric' });
    },
  },
};
</script>

<style>
  .gl-account-list-search-input-clear {
    right: 2em;
    z-index: 10;
  }

  .gl-account-name:hover .gl-btn-openinstance {
    display: inline!important;
  }
</style>
