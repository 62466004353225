<template>
  <div>
    <v-row>
      <v-col>
        <h2>
          Create Report
        </h2>
      </v-col>
    </v-row>
    <report />
  </div>
</template>

<script>
import Report from '../../_components/Report';

export default {
  name: 'CreateReport',
  components: {
    Report,
  },
};
</script>
