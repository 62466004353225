<template>
  <v-row>
    <v-col
      cols="12"
      md="6"
      lg="4"
    >
      <ValidationProvider
        ref="clientIdValidation"
        v-slot="{ errors }"
        rules="required"
        name="Client Id"
      >
        <v-text-field
          v-model="clientIdLocal"
          label="Client Id"
          :error-messages="errors"
        />
      </ValidationProvider>
    </v-col>
    <v-col
      cols="12"
      md="6"
      lg="4"
    >
      <ValidationProvider
        ref="clientSecretValidation"
        v-slot="{ errors }"
        rules="required"
        name="Client Secret"
      >
        <v-text-field
          v-model="clientSecretLocal"
          label="Client Secret"
          :error-messages="errors"
        />
      </ValidationProvider>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'HakuSource',
  props: {
    clientId: {
      type: String,
      required: false,
      default: '',
    },
    clientSecret: {
      type: String,
      required: false,
      default: '',
    },
    errorMessage: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      clientIdLocal: this.clientId,
      clientSecretLocal: this.clientSecret,
    };
  },
  watch: {
    errorMessage() {
      if (this.errorMessage === 'InvalidCredentials') {
        this.$refs.clientIdValidation.setErrors(['Invalid Client Id or Secret']);
      }
    },
  },
  methods: {
    getDetail() {
      return {
        clientId: this.clientIdLocal,
        clientSecret: this.clientSecretLocal,
      };
    },
  },
};
</script>
