<template>
  <v-divider
    v-if="item.divider && displayNode(item)"
    class="my-2"
  />
  <v-subheader
    v-else-if="item.header && displayNode(item)"
    :key="item.text"
    class="ml-2"
  >
    {{ item.text }}
  </v-subheader>
  <v-list-group
    v-else-if="item.children && displayNode(item)"
    :key="item.text"
    :group="item.group"
    no-action
  >
    <v-divider />
    <template #activator>
      <v-list-item-action class="mr-4">
        <v-icon>far fa-{{ item.icon }} fa-fw</v-icon>
      </v-list-item-action>
      <v-list-item-content>
        <v-list-item-title>
          {{ item.text }}
        </v-list-item-title>
      </v-list-item-content>
    </template>
    <template v-for="(child, i) in item.children">
      <v-list-item
        v-if="displayNode(child)"
        :key="i"
        link
        :to="child.route"
      >
        <v-list-item-content>
          <v-list-item-title>
            {{ child.text }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
    <v-divider />
  </v-list-group>
  <v-list-item
    v-else-if="displayNode(item)"
    :key="item.text"
    link
    :to="item.route"
  >
    <v-list-item-action class="mr-4">
      <v-icon>far fa-{{ item.icon }} fa-fw</v-icon>
    </v-list-item-action>
    <v-list-item-content>
      <v-list-item-title>
        {{ item.text }}
      </v-list-item-title>
    </v-list-item-content>
  </v-list-item>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'NavItem',
  props: {
    item: {
      type: Object,
      required: false,
      default: null,
    },
  },
  computed: {
    ...mapGetters([
      'getProfile',
      'systemUser',
      'accountAdmin',
      'getActiveAccount',
    ]),
  },
  methods: {
    displayNode(item) {
      if (item.requireSystemUser && !this.systemUser) {
        return false;
      }
      if (item.requireSystemAdmin && this.getProfile.systemAccessLevel !== 'admin') {
        return false;
      }
      if (item.requireAccountAdmin && !this.accountAdmin) {
        return false;
      }
      if (item.requireAccountType
        && !item.requireAccountType.includes(this.getActiveAccount.accountType)) {
        return false;
      }
      if (item.requireNotAccountType
        && item.requireNotAccountType.includes(this.getActiveAccount.accountType)) {
        return false;
      }
      if (item.requireSystemPermission && (this.getProfile.systemAccessLevel !== 'admin'
        && this.getProfile[item.requireSystemPermission] !== true)) {
        return false;
      }
      if (item.requirePagePermission
        && this.getActiveAccount.pages[item.requirePagePermission] !== true) {
        return false;
      }
      if (item.requireVertical && this.getActiveAccount.verticalId !== item.requireVertical) {
        return false;
      }
      return true;
    },
  },
};
</script>
