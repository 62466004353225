<template>
  <ValidationObserver ref="observer">
    <v-overlay :value="overlay">
      <v-progress-circular
        :size="70"
        :width="7"
        indeterminate
      />
    </v-overlay>
    <v-row align="end">
      <v-col
        cols="12"
        md="6"
        lg="4"
      >
        <ValidationProvider
          ref="nameValidation"
          v-slot="{ errors }"
          rules="required|max:200"
          name="Name"
        >
          <v-text-field
            v-model.trim="formData.name"
            label="Rule Name"
            :error-messages="errors"
            maxlength="200"
          />
        </ValidationProvider>
      </v-col>
      <v-col
        v-if="genderEnabled"
        cols="12"
        md="6"
        lg="4"
      >
        <ValidationProvider
          v-slot="{ errors }"
          :rules="`${isRequired ? 'required' : ''}`"
          name="Gender"
        >
          <v-select
            v-model="formData.gender"
            label="Gender"
            :items="genders"
            :error-messages="errors"
            clearable
          />
        </ValidationProvider>
      </v-col>
      <v-col
        v-if="familyEnabled"
        cols="12"
        md="6"
        lg="4"
      >
        <ValidationProvider
          v-slot="{ errors }"
          :rules="`${isRequired ? 'required|max:100' : 'max:100'}`"
          name="Family"
        >
          <v-text-field
            v-model.trim="formData.family"
            label="Family"
            maxlength="100"
            :error-messages="errors"
          />
        </ValidationProvider>
      </v-col>
      <v-col
        v-if="colorEnabled"
        cols="12"
        md="6"
        lg="4"
      >
        <ValidationProvider
          v-slot="{ errors }"
          :rules="`${isRequired ? 'required' : ''}`"
          name="Color"
        >
          <v-select
            v-model="formData.color"
            label="Primary Color"
            :items="colors"
            :error-messages="errors"
            clearable
          />
        </ValidationProvider>
      </v-col>
      <extension-field
        :value.sync="formData.extensionField1"
        :metadata="extensionField1"
        :is-required="isRequired"
      />
      <extension-field
        :value.sync="formData.extensionField2"
        :metadata="extensionField2"
        :is-required="isRequired"
      />
      <extension-field
        :value.sync="formData.extensionField3"
        :metadata="extensionField3"
        :is-required="isRequired"
      />
      <extension-field
        :value.sync="formData.extensionField4"
        :metadata="extensionField4"
        :is-required="isRequired"
      />
      <extension-field
        :value.sync="formData.extensionField5"
        :metadata="extensionField5"
        :is-required="isRequired"
      />
      <v-col
        cols="12"
        md="6"
        lg="4"
      >
        <ValidationProvider
          v-slot="{ errors }"
          :rules="`${isRequired ? 'required' : ''}`"
          name="Primary Category"
        >
          <v-select
            v-model="formData.category"
            label="Primary Category"
            :items="categories"
            item-text="name"
            :error-messages="errors"
            clearable
            return-object
          />
        </ValidationProvider>
      </v-col>
      <v-col
        v-show="category2List.length > 0"
        cols="12"
        md="6"
        lg="4"
      >
        <v-select
          v-model="formData.category2"
          :label="category2Label"
          :items="category2List"
          item-text="name"
          clearable
          return-object
        />
      </v-col>
      <v-col
        v-show="category3List.length > 0"
        cols="12"
        md="6"
        lg="4"
      >
        <v-select
          v-model="formData.category3"
          :label="category3Label"
          :items="category3List"
          item-text="name"
          clearable
          return-object
        />
      </v-col>
      <v-col
        v-show="category4List.length > 0"
        cols="12"
        md="6"
        lg="4"
      >
        <v-select
          v-model="formData.category4"
          :label="category4Label"
          :items="category4List"
          clearable
        />
      </v-col>
      <v-col
        v-show="category5List.length > 0"
        cols="12"
        md="6"
        lg="4"
      >
        <v-select
          v-model="formData.category5"
          :label="category5Label"
          :items="category5List"
          clearable
        />
      </v-col>
      <v-col
        v-show="tagTypes.length > 0"
        cols="12"
        md="6"
        lg="4"
      >
        <ValidationProvider
          ref="nameValidation"
          v-slot="{ errors }"
          name="Tags"
        >
          <v-select
            v-model="formData.tags"
            label="Tags"
            :items="tagTypes"
            :error-messages="errors"
            clearable
            multiple
            chips
            deletable-chips
          />
        </ValidationProvider>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col cols="12">
        <ValidationObserver ref="filterObserver">
          <column-filter-list
            ref="filter"
            :filters.sync="filters"
            :columns="columns"
          />
        </ValidationObserver>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="8">
        <v-btn
          color="primary"
          @click="save"
        >
          Save
        </v-btn>
        <v-btn
          class="ml-3"
          :to="$route.meta.globalRetailer === true ? '/product/globalRetailerTagging'
            : '/product/tagging'"
        >
          Cancel
        </v-btn>
      </v-col>
      <v-col
        cols="4"
        class="text-right"
      >
        <v-btn
          v-if="id"
          class="text--right"
          color="red darken-4"
          dark
          @click="deleteTagRule"
        >
          Delete
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <div class="mb-2">
          You can view all the products that match the rules by clicking the preview button.
        </div>
        <v-btn @click="preview">
          Preview
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-show="showPreview">
      <v-col cols="12">
        <preview
          :filters="previewFilters"
          :global-retailer="$route.meta.globalRetailer"
        />
      </v-col>
    </v-row>
  </ValidationObserver>
</template>

<script>
import ColumnFilterList from './ColumnFilterList';
import Preview from './Preview';
import tagging from '../_api/tagging';
import ExtensionField from './ExtensionField';
import taggingMixin from '../_mixins/taggingMixin';

export default {
  name: 'ProductTag',
  components: {
    ColumnFilterList,
    Preview,
    ExtensionField,
  },
  mixins: [taggingMixin],
  props: {
    id: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      formData: {
        name: '',
        category: null,
        category2: null,
        category3: null,
        category4: null,
        category5: null,
        tags: [],
        gender: '',
        family: '',
        color: '',
        extensionField1: '',
        extensionField2: '',
        extensionField3: '',
        extensionField4: '',
        extensionField5: '',
      },
      showPreview: false,
      filters: null,
      previewFilters: null,
      overlay: false,
      category2Label: 'Category 2',
      category3Label: 'Category 3',
      category4Label: 'Category 4',
      category5Label: 'Category 5',
      extensionField: {
        label: 'Extension Field',
        type: 'text',
        enabled: false,
        values: [],
      },
    };
  },
  computed: {
    category2List() {
      return this.getCategoryList(this.formData.category, 2);
    },
    category3List() {
      return this.getCategoryList(this.formData.category2, 3);
    },
    category4List() {
      return this.getCategoryList(this.formData.category3, 4);
    },
    category5List() {
      return this.getCategoryList(this.formData.category4, 5);
    },
    tagTypes() {
      if (this.formData.category && this.formData.category.tags) {
        return this.formData.category.tags;
      }
      return [];
    },
    genderEnabled() {
      return this.metadata.STANDARD_COLUMNS ? this.metadata.STANDARD_COLUMNS.gender : true;
    },
    familyEnabled() {
      return this.metadata.STANDARD_COLUMNS ? this.metadata.STANDARD_COLUMNS.family : true;
    },
    colorEnabled() {
      return this.metadata.STANDARD_COLUMNS ? this.metadata.STANDARD_COLUMNS.color : true;
    },
    extensionField1() {
      return this.getExtensionField(1);
    },
    extensionField2() {
      return this.getExtensionField(2);
    },
    extensionField3() {
      return this.getExtensionField(3);
    },
    extensionField4() {
      return this.getExtensionField(4);
    },
    extensionField5() {
      return this.getExtensionField(5);
    },
    isRequired() {
      return (!this.formData.category && !this.formData.gender && !this.formData.family
          && !this.formData.color && !this.formData.extensionField1
          && !this.formData.extensionField2 && !this.formData.extensionField3
          && !this.formData.extensionField4 && !this.formData.extensionField5);
    },
  },
  watch: {
    'formData.category': function category(newValue, oldValue) {
      if (newValue) {
        if (this.formData.category.isHierarchy) {
          this.category2Label = 'Category 2';
          this.category3Label = 'Category 3';
          this.category4Label = 'Category 4';
          this.category5Label = 'Category 5';
        } else {
          this.category2Label = this.formData.category.category2 ? this.formData.category.category2.name : '';
          this.category3Label = this.formData.category.category3 ? this.formData.category.category3.name : '';
          this.category4Label = this.formData.category.category4 ? this.formData.category.category4.name : '';
          this.category5Label = this.formData.category.category5 ? this.formData.category.category5.name : '';
        }
      }
      if (oldValue) {
        this.formData.category2 = null;
        this.formData.category3 = null;
        this.formData.category4 = null;
        this.formData.category5 = null;
        this.formData.tags = [];
      }
    },
    'formData.category2': function category2(newValue, oldValue) {
      if (oldValue && this.formData.category && this.formData.category.isHierarchy === true) {
        this.formData.category3 = null;
        this.formData.category4 = null;
        this.formData.category5 = null;
      }
    },
    'formData.category3': function category3(newValue, oldValue) {
      if (oldValue && this.formData.category && this.formData.category.isHierarchy === true) {
        this.formData.category4 = null;
        this.formData.category5 = null;
      }
    },
    'formData.category4': function category4(newValue, oldValue) {
      if (oldValue && this.formData.category && this.formData.category.isHierarchy === true) {
        this.formData.category5 = null;
      }
    },
  },
  async created() {
    this.overlay = true;
    await this.onCreated();
    await this.pullData();
    this.overlay = false;
  },
  methods: {
    async pullData() {
      if (this.id) {
        try {
          const resp = await tagging.getTag(this.type, this.computedAccountId, this.id);
          this.formData.name = resp.data.name;
          if (resp.data.category) {
            this.formData.category = this.getCategoryItem(this.categories, resp.data.category);
            if (this.formData.category) {
              if (this.formData.category.isHierarchy === true) {
                this.formData.category2 = this.getCategoryItem(
                  this.formData.category.categories,
                  resp.data.category2,
                );
                if (this.formData.category2 && this.formData.category2.categories) {
                  this.formData.category3 = this.getCategoryItem(
                    this.formData.category2.categories,
                    resp.data.category3,
                  );
                }
                if (this.formData.category3 && this.formData.category3.categories) {
                  this.formData.category4 = this.getCategoryItem(
                    this.formData.category3.categories,
                    resp.data.category4,
                  );
                }
                if (this.formData.category4 && this.formData.category4.categories) {
                  this.formData.category5 = this.getCategoryItem(
                    this.formData.category4.categories,
                    resp.data.category5,
                  );
                }
              } else {
                if (this.category2List.includes(resp.data.category2)) {
                  this.formData.category2 = resp.data.category2;
                }
                if (this.category3List.includes(resp.data.category3)) {
                  this.formData.category3 = resp.data.category3;
                }
                if (this.category4List.includes(resp.data.category4)) {
                  this.formData.category4 = resp.data.category4;
                }
                if (this.category5List.includes(resp.data.category5)) {
                  this.formData.category5 = resp.data.category5;
                }
              }
            }
          }
          this.formData.tags = [];
          resp.data.tags.forEach((tag) => {
            if (this.tagTypes.includes(tag)) {
              this.formData.tags.push(tag);
            }
          });
          if (this.genders.includes(resp.data.gender)) {
            this.formData.gender = resp.data.gender;
          }
          this.formData.family = resp.data.family;
          this.filters = resp.data.filters;
          if (this.colors.includes(resp.data.color)) {
            this.formData.color = resp.data.color;
          }
          this.formData.extensionField1 = resp.data.extensionField1;
          this.formData.extensionField2 = resp.data.extensionField2;
          this.formData.extensionField3 = resp.data.extensionField3;
          this.formData.extensionField4 = resp.data.extensionField4;
          this.formData.extensionField5 = resp.data.extensionField5;
        } catch (e) {
          if (this.$route.meta.globalRetailer === true) {
            this.$router.push('/product/globalRetailerTagging');
          } else {
            this.$router.push('/product/tagging');
          }
        }
      }
    },
    async preview() {
      const isValid = await this.$refs.filterObserver.validate();
      if (isValid) {
        this.showPreview = true;
        this.previewFilters = JSON.parse(JSON.stringify(this.filters));
      }
    },
    getCategoryValue(category) {
      if (!category) return null;
      if (typeof category === 'string') {
        return category;
      }
      return category.name;
    },
    async save() {
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        const cat = this.getCategoryValue(this.formData.category);
        const cat2 = this.getCategoryValue(this.formData.category2);
        const cat3 = this.getCategoryValue(this.formData.category3);
        const cat4 = this.getCategoryValue(this.formData.category4);
        const cat5 = this.getCategoryValue(this.formData.category5);

        const {
          name,
          tags,
          gender,
          family,
          color,
          extensionField1,
          extensionField2,
          extensionField3,
          extensionField4,
          extensionField5,
        } = this.formData;

        if (this.id) {
          await tagging.updateTag(
            this.type,
            this.computedAccountId,
            this.id,
            this.filters,
            name,
            tags,
            cat,
            gender,
            family,
            cat2,
            cat3,
            cat4,
            cat5,
            color,
            extensionField1,
            extensionField2,
            extensionField3,
            extensionField4,
            extensionField5,
          );
        } else {
          await tagging.createTag(
            this.type,
            this.computedAccountId,
            this.filters,
            name,
            tags,
            cat,
            gender,
            family,
            cat2,
            cat3,
            cat4,
            cat5,
            color,
            extensionField1,
            extensionField2,
            extensionField3,
            extensionField4,
            extensionField5,
          );
        }
        if (this.$route.meta.globalRetailer === true) {
          this.$router.push('/product/globalRetailerTagging');
        } else {
          this.$router.push('/product/tagging');
        }
      }
    },
    async deleteTagRule() {
      if (await this.$confirm('Are you sure you want to delete this tag rule?')) {
        await tagging.deleteTag(this.computedAccountId, this.id);
        if (this.$route.meta.globalRetailer === true) {
          this.$router.push('/product/globalRetailerTagging');
        } else {
          this.$router.push('/product/tagging');
        }
      }
    },
    getCategoryList(category, level) {
      if (this.formData.category) {
        if (this.formData.category.isHierarchy) {
          if (category && category.categories) {
            return category.categories;
          }
        } else if (this.formData.category[`category${level}`]) {
          return this.formData.category[`category${level}`].values;
        }
      }
      return [];
    },
    getCategoryItem(categories, value) {
      if (categories && categories.length > 0) {
        if (typeof categories[0] === 'string') {
          return value;
        }
        return categories.find((x) => x.name === value);
      }
      return null;
    },
    getExtensionField(index) {
      if (this.metadata && this.metadata.EXTENSION_FIELDS
        && this.metadata.EXTENSION_FIELDS[`extensionField${index}`]) {
        return {
          ...this.extensionField,
          ...this.metadata.EXTENSION_FIELDS[`extensionField${index}`],
        };
      }
      return this.extensionField;
    },
  },
};
</script>
