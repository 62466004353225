<template>
  <NavList :nav-items="navItems" />
</template>

<script>
import NavList from './NavList';

export default {
  name: 'DashboardsNavList',
  components: {
    NavList,
  },
  data() {
    return {
      navItems: [
        {
          text: 'Reports',
          route: '/reports',
          icon: 'chart-mixed',
          requireNotAccountType: ['Connectors'],
        },
      ],
    };
  },
};
</script>
