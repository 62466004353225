import axios from '@/utils/axios';

const getMappingSummary = (take, skip, sortBy, sortOrder, search) => axios.get('/products/brand/mappingSummary', {
  params: {
    take,
    skip,
    sortBy,
    sortOrder,
    search,
  },
});
const getAccountSummary = (sourceBrand) => axios.get(`/products/brand/accountSummary/${encodeURIComponent(sourceBrand)}`);
const getCategorySummary = (sourceBrand) => axios.get(`/products/brand/categorySummary/${encodeURIComponent(sourceBrand)}`);
const getPreview = (sourceBrand) => axios.get(`/products/brand/preview/${encodeURIComponent(sourceBrand)}`);
const getBrands = () => axios.get('/products/brand/');
const updateMapping = (sourceBrand, destinationBrand) => axios.put(
  `/products/brand/mapping/${encodeURIComponent(sourceBrand)}`,
  {
    destinationBrand,
  },
);

export default {
  getMappingSummary,
  getAccountSummary,
  getCategorySummary,
  getPreview,
  getBrands,
  updateMapping,
};
