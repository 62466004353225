<template>
  <v-row no-gutter>
    <v-col
      cols="12"
      md="6"
      lg="4"
    >
      <v-text-field
        v-model.trim="pathLocal"
        label="Path"
      />
      <div class="caption">
        The destination is set to a bucket. Enter the path you would like the files to be placed in.
        If this is left blank then files will be placed in the root of the bucket.
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'S3Destination',
  props: {
    errorMessage: {
      type: String,
      required: false,
      default: '',
    },
    path: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      pathLocal: this.path,
    };
  },
  methods: {
    getDetail() {
      return {
        path: this.pathLocal,
      };
    },
  },
};
</script>
