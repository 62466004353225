<template>
  <v-main>
    <v-container
      fill-height
      fluid
    >
      <v-row
        align="center"
        justify="center"
      >
        <v-col
          cols="12"
          sm="8"
          lg="6"
          xl="4"
        >
          <v-card
            :light="true"
            class="mt-2 mb-2"
          >
            <v-card-text>
              <img
                src="@/assets/images/logo_dark_text.svg"
                alt="UQ Cadence"
                class="mb-5 d-block mx-auto"
                height="37"
              >
              <main>
                <slot />
              </main>
            </v-card-text>
          </v-card>
          <footer>
            <slot name="footer" />
          </footer>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
export default {
  name: 'AuthWrapper',
};
</script>
