import axios from '@/utils/axios';

const getUser = () => axios.get('/user');
const updateProfile = (profile) => axios.put('/user/profile', profile);
const updatePassword = (profile) => axios.put('/user/password', profile);
const updateEmail = (profile) => axios.put('/user/email', profile);
const getTwoFactorInit = () => axios.get('/user/twoFactor/init');
const verifyTwoFactor = (token) => axios.post('/user/twoFactor/verify', { token });
const generateTwoFactorCodes = () => axios.put('/user/twoFactor/generateCodes');
const disableTwoFactor = () => axios.delete('/user/twoFactor');
const updateTimezone = (timeZone) => axios.put('/user/timezone', { timeZone });
const getSetting = (name) => axios.get(`/user/settings/${name}`);
const insertSetting = (name, value, accountId) => axios.post('/user/settings', { name, value, accountId });
const getAccounts = () => axios.get('/user/accounts');

export default {
  getUser,
  updateProfile,
  updateEmail,
  updatePassword,
  getTwoFactorInit,
  verifyTwoFactor,
  generateTwoFactorCodes,
  disableTwoFactor,
  updateTimezone,
  getSetting,
  insertSetting,
  getAccounts,
};
