<template>
  <v-row>
    <v-col
      cols="12"
      md="6"
      lg="4"
    >
      <v-checkbox
        v-model="loadProductUPCLocal"
        label="Load Product UPC Images"
      />
      <div class="caption">
        When selected the UPC will be used to update product images for products added by the POS
        system. Use this only when the POS system is using UPC as the product id and is not
        providing images.
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'ProductUPCDestination',
  props: {
    loadProductUPC: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      loadProductUPCLocal: this.loadProductUPC,
    };
  },
  methods: {
    getDetail() {
      return {
        loadProductUPC: this.loadProductUPCLocal,
      };
    },
  },
};
</script>
