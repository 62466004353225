/* eslint-disable camelcase */
import Vue from 'vue';
import {
  extend,
  ValidationProvider,
  ValidationObserver,
  setInteractionMode,
} from 'vee-validate';
import {
  required,
  email,
  max,
  min,
  min_value,
  numeric,
  confirmed,
  alpha_num,
  required_if,
  alpha_spaces,
  alpha_dash,
} from 'vee-validate/dist/rules';

extend('required', {
  ...required,
  message: '{_field_} is required',
});

extend('email', {
  ...email,
  message: '{_field_} is not a valid email address',
});

extend('max', {
  ...max,
  message: '{_field_} must have at most {length} letters',
});

extend('min', {
  ...min,
  message: '{_field_} must have at least {length} letters',
});

extend('min_value', {
  ...min_value,
  message: '{_field_} must be at least {min}',
});

extend('code', {
  params: ['length'],
  validate: (value, { length }) => !(value && value.length !== Number(length)),
  message: '{_field_} is {length} digits',
});

extend('numeric', {
  ...numeric,
  message: '{_field_} must be numeric',
});

extend('confirmed', {
  ...confirmed,
  message: '{_target_} and {_field_} don\'t match',
});

extend('alpha_num', {
  ...alpha_num,
  message: '{_field_} may contain only alphanumeric characters',
});

extend('required_if', {
  ...required_if,
  message: '{_field_} is required',
});

extend('alpha_num_spaces', {
  validate: (value) => /^[0-9A-Z\s]*$/i.test(value),
  message: '{_field_} may contain only alphanumeric characters or spaces',
});

extend('alpha_spaces', {
  ...alpha_spaces,
  message: '{_field_} may contain only alphabetic characters or spaces',
});

extend('heartland_unique', {
  params: ['value1', 'value2', 'value3', 'value4', 'value5', 'value6'],
  validate(value, {
    value1, value2, value3, value4, value5, value6,
  }) {
    return ![value1, value2, value3, value4, value5, value6].includes(value);
  },
  message: 'The selected field is mapped to multiple columns',
});

extend('unique_list', {
  validate(value, values) {
    return !values.includes(value);
  },
  message: 'The selected field is mapped to multiple columns',
});

extend('phone', {
  validate: (value) => value.length === 12,
  message: '{_field_} is an invalid phone number',
});

extend('url', {
  validate(value) {
    if (value) {
      // eslint-disable-next-line max-len, no-useless-escape
      return /^(https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*))?$/.test(value);
    }
    return false;
  },
  message: '{_field_} must be a valid URL',
});

extend('json', {
  validate(value) {
    if (value) {
      try {
        JSON.parse(value);
      } catch (e) {
        return false;
      }
    }
    return true;
  },
  message: '{_field_} must be valid JSON',
});

extend('alpha_dash', {
  ...alpha_dash,
  message: '{_field_} may contain only alphanumeric characters, underscores, and dashes',
});

setInteractionMode('eager');

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
