import axios from '@/utils/axios';

const getLocations = (appType, storeName, apiKey, password, accessToken) => axios.get('/sources/shopify/locations', {
  params: {
    appType,
    storeName,
    apiKey,
    password,
    accessToken,
  },
});

export default {
  getLocations,
};
