<template>
  <v-row>
    <v-col
      cols="12"
      md="6"
      lg="4"
    >
      <v-checkbox
        v-model="isAccountManagerLocal"
        label="Control Data Permissions for child accounts"
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'AdminRetailerRollup',
  props: {
    isAccountManager: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      isAccountManagerLocal: this.isAccountManager,
      reportTypes: [],
    };
  },
  methods: {
    getSettings() {
      return {
        isAccountManager: this.isAccountManagerLocal,
      };
    },
  },
};
</script>
