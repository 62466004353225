<template>
  <v-row>
    <v-col
      cols="12"
      md="6"
      lg="4"
    >
      <ValidationProvider
        ref="apiKeyValidation"
        v-slot="{ errors }"
        rules="required"
        name="Api Key"
      >
        <v-text-field
          v-model="apiKeyLocal"
          label="Api Key"
          :error-messages="errors"
        />
      </ValidationProvider>
    </v-col>
    <v-col
      cols="12"
      md="6"
      lg="4"
    >
      <timezone :timezone.sync="timezoneLocal" />
      <div class="caption">
        Waitwhile returns booking dates in local time. The location's timezone will be used to
        convert this to a full date time. If the location does not have a timezone the selected one
        here will be used instead.
      </div>
    </v-col>
  </v-row>
</template>

<script>
import Timezone from '../Timezone';

export default {
  name: 'WaitwhileSource',
  components: {
    Timezone,
  },
  props: {
    apiKey: {
      type: String,
      required: false,
      default: '',
    },
    timezone: {
      type: String,
      required: false,
      default: '',
    },
    errorMessage: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      apiKeyLocal: this.apiKey,
      timezoneLocal: this.timezone,
    };
  },
  watch: {
    errorMessage() {
      if (this.errorMessage === 'InvalidApiKey') {
        this.$refs.apiKeyValidation.setErrors(['Invalid Api Key']);
      }
    },
  },
  methods: {
    getDetail() {
      return {
        apiKey: this.apiKeyLocal,
        timezone: this.timezoneLocal,
      };
    },
  },
};
</script>
