<template>
  <report-guide-wrapper>
    <v-container
      color="white"
      class="text-center"
    >
      <v-row class="mt-2 d-flex flex-column align-center">
        <v-col>
          <strong class="text-h6 text-sm-h5 text-md-h4 indigo--text darken-4">
            Welcome to UQ Cadence Reports
          </strong>
        </v-col>
      </v-row>

      <v-row class="mb-5">
        <v-col>
          <v-icon
            x-large
            color="indigo darken-4"
          >
            mdi-chart-bar-stacked
          </v-icon>
        </v-col>
      </v-row>

      <v-row>
        <p class="px-5 mx-5 mb-5 body-1">
          With reports you can gain business insights for your store and the industry as a whole.
          This guide will walk you through many of the features of the report, so you can get the
          most out of your experience.
        </p>
      </v-row>
    </v-container>

    <slot name="navigator" />
  </report-guide-wrapper>
</template>

<script>
import ReportGuideWrapper from './ReportGuideWrapper';

export default {
  name: 'WelcomeGuide',
  components: {
    ReportGuideWrapper,
  },
};
</script>
