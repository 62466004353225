<template>
  <v-row>
    <v-col
      cols="12"
      md="6"
      lg="4"
    >
      <ValidationProvider
        ref="apiKeyValidation"
        v-slot="{ errors }"
        rules="required"
        name="Api Key"
      >
        <v-text-field
          v-model="apiKeyLocal"
          label="Api Key"
          :error-messages="errors"
        />
      </ValidationProvider>
    </v-col>
    <v-col
      cols="12"
      md="6"
      lg="4"
    >
      <timezone :timezone.sync="timezoneLocal" />
      <div class="caption">
        EnMotive returns registration dates without timezone information.  The timezone is taken
        from the race itself and used to convert the date to that timezone.  The timezone that is
        selected here will be used if the race does not have a timezone specified.
      </div>
    </v-col>
    <v-col
      cols="12"
      md="6"
      lg="4"
    >
      <ValidationProvider
        ref="tenantIdValidation"
        v-slot="{ errors }"
        name="Tenant Id"
      >
        <v-text-field
          v-model.lazy.trim="tenantIdLocal"
          label="Tenant Id"
          :error-messages="errors"
        />
      </ValidationProvider>
    </v-col>
  </v-row>
</template>

<script>
import Timezone from '../Timezone';

export default {
  name: 'EnMotive',
  components: {
    Timezone,
  },
  props: {
    apiKey: {
      type: String,
      required: false,
      default: '',
    },
    timezone: {
      type: String,
      required: false,
      default: '',
    },
    tenantId: {
      type: String,
      required: false,
      default: '',
    },
    errorMessage: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      apiKeyLocal: this.apiKey,
      timezoneLocal: this.timezone,
      tenantIdLocal: this.tenantId,
    };
  },
  watch: {
    errorMessage() {
      if (this.errorMessage === 'InvalidApiKey') {
        this.$refs.apiKeyValidation.setErrors(['Invalid Api Key']);
      }

      if (this.errorMessage === 'InvalidTenantId') {
        this.$refs.tenantIdValidation.setErrors(['Invalid TenantId']);
      }
    },
  },
  methods: {
    getDetail() {
      return {
        apiKey: this.apiKeyLocal,
        timezone: this.timezoneLocal,
        tenantId: this.tenantIdLocal,
      };
    },
  },
};
</script>
