<template>
  <v-card>
    <v-card-title
      primary-title
      class="primary white--text"
    >
      <span class="title">Create Account</span>
    </v-card-title>
    <v-card-text>
      <Account />
    </v-card-text>
  </v-card>
</template>

<script>
import Account from '../../_components/Account';

export default {
  name: 'CreateAccount',
  components: {
    Account,
  },
};
</script>
