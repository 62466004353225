<template>
  <div>
    <v-snackbar
      v-model="resendSuccess"
      color="success"
      :top="true"
      :timeout="2000"
    >
      <v-container
        fluid
        class="text-center subtitle-1"
      >
        Invite resent
      </v-container>
    </v-snackbar>
    <v-row>
      <v-col>
        <h2>
          Internal Users
        </h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="6"
        xl="4"
      >
        <v-btn
          color="primary"
          to="/admin/users/create"
        >
          + Add User
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-tabs>
          <v-tab>Members</v-tab>
          <v-tab>Deactivated</v-tab>
          <v-tab-item
            :transition="false"
            :reverse-transition="false"
          >
            <v-data-table
              :headers="activeHeaders"
              :items="activeUsers"
              :loading="loading"
              :hide-default-footer="true"
              class="elevation-5"
              disable-pagination
            >
              <template #[`item.edit`]="{ item }">
                <v-btn
                  fab
                  text
                  :to="'/admin/users/' + item.id"
                  :disabled="item.id === getProfile.id"
                  class="mr-2"
                  x-small
                >
                  <v-icon>
                    edit
                  </v-icon>
                </v-btn>
              </template>
              <template #[`item.accessLevel`]="{ item }">
                <v-checkbox
                  v-model="item.accessLevel"
                  true-value="admin"
                  false-value="accountManager"
                  color="success"
                  disabled
                />
              </template>
              <template #[`item.createdAt`]="{ item }">
                {{ formatCreated(item.createdAt) }}
              </template>
              <template #[`item.name`]="{ item }">
                <div>
                  <strong>{{ item.firstName }} {{ item.lastName }}</strong>
                </div>
                <div>
                  <em>{{ item.email }}</em>
                </div>
                <v-btn
                  v-if="item.activated === false"
                  text
                  small
                  class="pa-0"
                  color="primary"
                  @click="resendInvite(item)"
                >
                  Resend Invite
                </v-btn>
              </template>
              <template #[`item.actions`]="{ item }">
                <v-btn
                  text
                  small
                  color="grey"
                  :disabled="item.id === getProfile.id"
                  @click="deactivateMember(item)"
                >
                  Deactivate Member
                </v-btn>
              </template>
              <template #[`item.activated`]="{ item }">
                {{ item.activated ? 'Active' : 'Pending' }}
              </template>
            </v-data-table>
          </v-tab-item>
          <v-tab-item
            :transition="false"
            :reverse-transition="false"
          >
            <v-data-table
              :headers="deactivatedHeaders"
              :items="deactivatedUsers"
              :loading="loading"
              :hide-default-footer="true"
              class="elevation-5"
              disable-pagination
            >
              <template #[`item.createdAt`]="{ item }">
                {{ formatCreated(item.createdAt) }}
              </template>
              <template #[`item.name`]="{ item }">
                <div>
                  <strong>{{ item.firstName }} {{ item.lastName }}</strong>
                </div>
                <div>
                  <em>{{ item.email }}</em>
                </div>
              </template>
              <template #[`item.actions`]="{ item }">
                <v-btn
                  text
                  small
                  color="primary"
                  @click="activateMember(item)"
                >
                  Activate Member
                </v-btn>
              </template>
            </v-data-table>
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import users from '../../_api/users';

export default {
  name: 'InternalUsers',
  data() {
    return {
      loading: true,
      activeHeaders: [
        {
          text: '',
          value: 'edit',
          sortable: false,
          width: '5%',
        },
        {
          text: 'Name',
          value: 'name',
          sortable: false,
          width: '25%',
        },
        {
          text: 'Is Admin',
          value: 'accessLevel',
          sortable: false,
          width: '10%',
        },
        {
          text: 'Status',
          value: 'activated',
          sortable: false,
          width: '20%',
        },
        {
          text: 'Create Date',
          value: 'createdAt',
          sortable: false,
          width: '20%',
        },
        {
          text: '',
          value: 'actions',
          sortable: false,
          width: '20%',
        },
      ],
      deactivatedHeaders: [
        {
          text: 'Name',
          value: 'name',
          sortable: false,
          width: '33%',
        },
        {
          text: 'Create Date',
          value: 'createdAt',
          sortable: false,
          width: '33%',
        },
        {
          text: '',
          value: 'actions',
          sortable: false,
          width: '25%',
        },
      ],
      activeUsers: [],
      deactivatedUsers: [],
      email: '',
      resendSuccess: false,
    };
  },
  computed: {
    ...mapGetters([
      'getProfile',
    ]),
  },
  watch: {
    options() {
      this.getData();
    },
  },
  async mounted() {
    await this.getData();
  },
  methods: {
    async getData() {
      this.loading = true;
      const resp = await Promise.all([users.getAdminUsers('active'), users.getAdminUsers('deactivated')]);
      this.activeUsers = resp[0].data;
      this.deactivatedUsers = resp[1].data;
      this.loading = false;
    },
    formatCreated(value) {
      return new Date(value).toLocaleDateString('en-us', { month: 'long', day: 'numeric', year: 'numeric' });
    },
    async deactivateMember(user) {
      await users.deactivate(user.id);
      await this.getData();
    },
    async activateMember(user) {
      this.loading = true;
      await users.activate(user.id);
      await this.getData();
    },
    async resendInvite(user) {
      this.loading = true;
      await users.resendInvite(user.id);
      this.loading = false;
      this.resendSuccess = true;
    },
  },
};
</script>
