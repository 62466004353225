<template>
  <v-card
    flat
    light
    outlined
    color="white"
    max-width="575px"
    elevation="24"
    class="ma-3"
    style="position: absolute;"
    v-bind="$attrs"
  >
    <v-card
      elevation="12"
      v-bind="$attrs"
    >
      <v-card-title
        v-if="title"
        class="grey lighten-5"
      >
        <span class="text-h6 indigo--text darken-4">{{ title }}</span>
      </v-card-title>
      <slot />
    </v-card>
  </v-card>
</template>

<script>
export default {
  name: 'ReportGuideWrapper',
  props: {
    title: {
      type: String,
      default: '',
    },
  },
};
</script>
