<template>
  <Wrapper>
    <div class="title">
      Account Connection
    </div>
    <template #footer>
      <v-alert
        v-show="success"
        type="success"
        class="mt-3"
      >
        Your response has been recorded. <a href="/settings/dataPermissions">Click here</a> to go to
        the Data Permissions page where you can view all your active and pending connections.
      </v-alert>
      <v-alert
        v-show="failure"
        type="error"
        class="mt-3"
      >
        <!-- eslint-disable-next-line vue/no-v-html -->
        <span v-html="failureMessage" />
      </v-alert>
      <v-alert
        v-show="!validToken"
        type="error"
        class="mt-3"
      >
        The link is either invalid or has expired.
        <a href="/settings/dataPermissions">Click here</a> to go to the Brand Connect page where you
        can view all your active and pending connections.
      </v-alert>
    </template>
  </Wrapper>
</template>

<script>
import accounts from '@/api/accounts';
import Wrapper from '../_components/Wrapper';

export default {
  name: 'AccountConnection',
  components: {
    Wrapper,
  },
  data() {
    return {
      success: false,
      failure: false,
      validToken: true,
      failureMessage: '',
    };
  },
  async created() {
    if (this.$route.query.t && this.$route.query.a && (this.$route.query.a === 'a'
      || this.$route.query.a === 'r')) {
      try {
        await accounts.emailAccountConnection(this.$route.query.t, this.$route.query.a);
        this.success = true;
      } catch (e) {
        if (e.response && e.response.data === 'InvalidToken') {
          this.validToken = false;
        } else if (e.response && e.response.data === 'NotPending') {
          this.failureMessage = 'This request is no longer pending. <a href="/settings/brandConnect">Click here</a> to go to the Brand Connect page where you can view all your active and pending connections.';
          this.failure = true;
        } else {
          this.failureMessage = 'There was a problem saving your response. Please try again.';
          this.failure = true;
        }
      }
    } else {
      this.validToken = false;
    }
  },
};
</script>
