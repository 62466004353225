<template>
  <v-row>
    <v-col
      cols="12"
      md="6"
      lg="4"
    >
      <ValidationProvider
        ref="publicKeyValidation"
        v-slot="{ errors }"
        rules="required"
        name="Public Key"
      >
        <v-text-field
          v-model="publicKeyLocal"
          label="Public Key"
          :disabled="id !== ''"
          :error-messages="errors"
        />
      </ValidationProvider>
      <div class="caption">
        You copy-paste your Zaius Public Key from Account Settings > APIs > Public
      </div>
    </v-col>
    <v-col
      cols="12"
      md="6"
      lg="4"
    >
      <ValidationProvider
        ref="privateKeyValidation"
        v-slot="{ errors }"
        rules="required"
        name="Private Key"
      >
        <v-text-field
          v-model="privateKeyLocal"
          label="Private Key"
          :error-messages="errors"
        />
      </ValidationProvider>
      <div class="caption">
        You copy-paste your Zaius Public Key from Account Settings > APIs > Private
      </div>
    </v-col>
    <v-col
      cols="0"
      lg="4"
    />
    <v-col
      cols="12"
      md="6"
      lg="4"
    >
      <ValidationProvider
        ref="listFieldValidation"
        v-slot="{ errors }"
        rules="alpha_num_spaces"
        name="List Field"
      >
        <v-text-field
          v-model.trim="listField"
          label="List Field"
          append-icon="playlist_add"
          maxlength="50"
          :disabled="true"
          :error-messages="errors"
          @keydown.enter.prevent="addListField"
          @click:append="addListField"
        />
      </ValidationProvider>
      <div class="caption">
        You can enter names for boolean fields you would like added to the customer profile. You can
        configure sources to populate these fields as users are added.
      </div>
      <v-card
        outlined
        class="mt-2"
      >
        <v-list>
          <v-subheader>List Fields</v-subheader>
          <v-list-item
            v-for="item in listFieldsLocal"
            :key="item.name"
          >
            <v-list-item-action>
              <v-btn
                fab
                text
                class="mr-2"
                :disabled="item.canDelete === false"
                @click="deleteListField(item)"
              >
                <v-icon>
                  delete
                </v-icon>
              </v-btn>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ item.name }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-col>
    <v-col
      cols="12"
      md="6"
      lg="4"
    >
      <ValidationProvider
        ref="nameValidation"
        v-slot="{ errors }"
        name="Name"
      >
        <v-text-field
          v-model.trim="name"
          label="List Name"
          append-icon="playlist_add"
          :error-messages="errors"
          @keydown.enter.prevent="addList"
          @click:append="addList"
        />
      </ValidationProvider>
      <div class="caption">
        You can add new lists by entering the name below. These will be immediately be added to the
        Zaius instance. You will be able to use these to map users to the lists as they come in from
        your sources.
      </div>
      <v-card
        outlined
        class="mt-2"
      >
        <v-list>
          <v-subheader>Lists</v-subheader>
          <v-list-item
            v-for="list in lists"
            :key="list.list_id"
          >
            <v-list-item-content>
              <v-list-item-title>{{ list.name }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import destinations from '@/api/destinations';
import { debounce } from 'lodash';
import vue from 'vue';

export default {
  name: 'ZaiusDestinationType',
  props: {
    id: {
      type: String,
      required: false,
      default: '',
    },
    publicKey: {
      type: String,
      required: false,
      default: '',
    },
    privateKey: {
      type: String,
      required: false,
      default: '',
    },
    listFields: {
      type: Array,
      required: false,
      default: null,
    },
    errorMessage: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    const listFieldsLocal = [];
    if (this.listFields) {
      this.listFields.forEach((field) => {
        listFieldsLocal.push({
          canDelete: false,
          name: field,
        });
      });
    }
    return {
      publicKeyLocal: this.publicKey,
      privateKeyLocal: this.privateKey,
      errorMessageLocal: this.errorMessage,
      listField: '',
      listFieldsLocal,
      name: '',
      lists: [],
    };
  },
  watch: {
    errorMessage(errorMessage) {
      this.errorMessageLocal = errorMessage;
    },
    errorMessageLocal(errorMessage) {
      if (errorMessage === 'InvalidPrivateKey') {
        this.$refs.privateKeyValidation.setErrors(['Invalid Private Key']);
      }
      if (errorMessage === 'InvalidPublicKey') {
        this.$refs.publicKeyValidation.setErrors(['Invalid Public Key']);
      }
    },
    privateKeyLocal() {
      this.getLists();
    },
  },
  mounted() {
    this.getLists();
    this.getLists = debounce(this.getLists, 1000);
  },
  methods: {
    async getLists() {
      if (this.privateKeyLocal) {
        try {
          const result = await destinations.getZaiusLists(this.privateKeyLocal);
          vue.set(this, 'lists', result.data.lists);
        } catch (e) {
          this.errorMessageLocal = 'InvalidPrivateKey';
        }
      }
    },
    getDetail() {
      return {
        publicKey: this.publicKeyLocal,
        privateKey: this.privateKeyLocal,
        listFields: this.listFieldsLocal.map((field) => field.name),
      };
    },
    async addListField() {
      const result = await this.$refs.listFieldValidation.validate();
      if (result.valid && this.listField) {
        let duplicate = false;
        this.listFieldsLocal.forEach((field) => {
          if (field.name.toLowerCase() === this.listField.toLowerCase()) {
            duplicate = true;
          }
        });
        if (duplicate) {
          this.$refs.listFieldValidation.setErrors(['Duplicate list field']);
        } else {
          this.listFieldsLocal.push({
            canDelete: true,
            name: this.listField.replace(/  +/g, ' '),
          });
          this.listField = '';
        }
      }
    },
    deleteListField(item) {
      let matchIndex;
      this.listFieldsLocal.forEach((field, index) => {
        if (field.name === item.name) {
          matchIndex = index;
        }
      });
      if (matchIndex !== undefined) {
        this.listFieldsLocal.splice(matchIndex, 1);
      }
    },
    async addList() {
      if (this.name) {
        try {
          await destinations.addZaiusList(this.privateKeyLocal, this.name);
          await this.getLists();
          this.name = '';
        } catch (e) {
          this.$refs.nameValidation.setErrors([e.message]);
        }
      }
    },
  },
};
</script>
