import axios from '@/utils/axios';

const getVerticals = (includeMetadata) => axios.get('/admin/verticals/', {
  params: {
    includeMetadata,
  },
});
const getVertical = (verticalId) => axios.get(`/admin/verticals/${verticalId}`);
const createVertical = (verticalId, name, pages, metadata, tagging) => axios.post('/admin/verticals', {
  verticalId,
  name,
  pages,
  metadata,
  tagging,
});
const updateVertical = (verticalId, name, pages, metadata, tagging) => axios.put(`/admin/verticals/${verticalId}`, {
  name,
  pages,
  metadata,
  tagging,
});

export default {
  getVerticals,
  getVertical,
  createVertical,
  updateVertical,
};
