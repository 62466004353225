<template>
  <v-row>
    <v-col
      cols="12"
      md="6"
      lg="4"
    >
      <ValidationProvider
        ref="tokenValidation"
        v-slot="{ errors }"
        rules="required"
        name="API Key"
      >
        <v-text-field
          v-model.trim="tokenLocal"
          label="Rics API Key"
          :error-messages="errors"
        />
      </ValidationProvider>
    </v-col>
    <v-col
      cols="12"
      md="6"
      lg="4"
    >
      <timezone :timezone.sync="timezoneLocal" />
      <div class="caption">
        Rics returns transaction dates without timezone info. To correctly send this data it
        requires converting all the data to the correct timezone. The timezone that is selected here
        is used to convert all transactions to that timezone.
      </div>
    </v-col>
    <v-col
      cols="12"
      md="6"
      lg="4"
    >
      <v-checkbox
        v-model="useAccountNumberLocal"
        label="Use Account Number"
      />
      <div class="caption">
        If the user does not have a phone number associated with their account then use the account
        number as the phone number.
      </div>
    </v-col>
    <v-col
      cols="12"
      md="6"
      lg="4"
    >
      <v-checkbox
        v-model="pullInventoryLocal"
        label="Pull Inventory"
      />
      <div class="caption">
        Selecting this will also pull inventory data on a daily basis. This is currently only used
        for cadence.
      </div>
    </v-col>
    <v-col
      cols="12"
      md="6"
      lg="4"
    >
      <v-text-field
        v-model.trim="storeOwnerIdLocal"
        label="Store Owner Id"
        type="number"
      />
      <div class="caption">
        This is currently only used when connecting to UQube.
      </div>
    </v-col>
  </v-row>
</template>

<script>
import rics from '../../../_api/rics';
import Timezone from '../Timezone';

export default {
  name: 'RicsSource',
  components: {
    Timezone,
  },
  props: {
    token: {
      type: String,
      required: false,
      default: '',
    },
    timezone: {
      type: String,
      required: false,
      default: '',
    },
    useAccountNumber: {
      type: Boolean,
      required: false,
      default: false,
    },
    pullInventory: {
      type: Boolean,
      required: false,
      default: false,
    },
    storeOwnerId: {
      type: String,
      required: false,
      default: '',
    },
    errorMessage: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      tokenLocal: this.token,
      timezoneLocal: this.timezone,
      useAccountNumberLocal: this.useAccountNumber,
      pullInventoryLocal: this.pullInventory,
      storeOwnerIdLocal: this.storeOwnerId,
    };
  },
  watch: {
    errorMessage() {
      if (this.errorMessage === 'InvalidToken') {
        this.$refs.tokenValidation.setErrors(['Invalid Token']);
      }
    },
  },
  methods: {
    getDetail() {
      return {
        token: this.tokenLocal,
        timezone: this.timezoneLocal,
        useAccountNumber: this.useAccountNumberLocal,
        pullInventory: this.pullInventoryLocal,
        storeOwnerId: this.storeOwnerIdLocal,
      };
    },
    async preview() {
      if (this.tokenLocal) {
        try {
          const result = await Promise.all([
            rics.getCustomerPreview(this.tokenLocal),
            rics.getOrderPreview(this.tokenLocal, '9/1/2021', '9/2/2021'),
          ]);
          return {
            customers: result[0].data.CustomerDetails,
            orders: result[1].data.Sales,
          };
        } catch (e) {
          return null;
        }
      }
      return null;
    },
  },
};
</script>
