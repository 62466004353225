<template>
  <ValidationObserver ref="observer">
    <v-form
      class="mt-3"
      @submit.prevent="save"
    >
      <v-container
        grid-list-lg
        fluid
        class="pa-0"
      >
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <ValidationProvider
              ref="loginValidation"
              v-slot="{ errors }"
              rules="required"
              name="Login"
            >
              <v-text-field
                v-model="login"
                label="Race Roster Login"
                :error-messages="errors"
              />
            </ValidationProvider>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <ValidationProvider
              ref="passwordValidation"
              v-slot="{ errors }"
              rules="required"
              name="Password"
            >
              <v-text-field
                v-model="password"
                label="Race Roster Password"
                type="password"
                :error-messages="errors"
              />
            </ValidationProvider>
            <div class="caption">
              To authenticate your account with Race Roster they require us to send over your login
              and password info. We do not store your password.
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-btn
              color="primary"
              type="submit"
              class="mt-2"
              :disabled="showAlert"
            >
              Save
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </ValidationObserver>
</template>

<script>
import sources from '@/api/sources';

export default {
  name: 'RaceRoster',
  data() {
    return {
      login: '',
      password: '',
      showAlert: false,
    };
  },
  methods: {
    async save() {
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        try {
          await sources.finalizeOauth(this.$route.query.t, {
            login: this.login,
            password: this.password,
          });
          this.showAlert = true;
          this.$emit('Success');
        } catch (e) {
          this.$refs.loginValidation.setErrors(['Invalid Login or Password']);
        }
      }
    },
  },
};
</script>
