<template>
  <report-guide-wrapper
    shaped
    title="Report Sheets"
    :style="{
      position: 'absolute',
      bottom: !report.userBacked ? '34px' : null,
      top: report.userBacked ? '160px' : null,
      left: `${nudgeLeft}px`
    }"
  >
    <v-container
      class="px-5"
    >
      <v-row>
        <v-col class="body-2 d-flex align-baseline">
          <p class="mb-0 align-self-center">
            <span v-if="!report.userBacked">
              Many reports have tabs at the bottom (like Excel) to click through different sheets.
              Each of these "sheets" contain a different view of the data.
            </span>
            <span v-if="report.userBacked">
              Many reports have tabs at the top to click through different sheets.
              Each of these "sheets" contain a different view of the data.
            </span>
          </p>
        </v-col>
      </v-row>
    </v-container>

    <slot name="navigator" />
  </report-guide-wrapper>
</template>

<script>
import { mapState } from 'vuex';
import ReportGuideWrapper from './ReportGuideWrapper';

export default {
  name: 'TabsGuide',
  components: {
    ReportGuideWrapper,
  },
  computed: {
    ...mapState('navigation', ['sidebar']),
    ...mapState('page/report', ['report']),
    nudgeLeft() {
      return this.sidebar.open ? this.sidebar.width + 4 : 4;
    },
  },
};
</script>
