<template>
  <v-row>
    <v-col
      cols="12"
      md="6"
      lg="4"
    >
      <ValidationProvider
        ref="publicKeyValidation"
        v-slot="{ errors }"
        rules="required"
        name="Database"
      >
        <v-select
          v-model="databaseLocal"
          :items="databases"
          item-text="databaseName"
          item-value="databaseName"
          :error-messages="errors"
          label="Database"
        />
      </ValidationProvider>
    </v-col>
  </v-row>
</template>

<script>
import destinations from '../../../_api/destinations';

export default {
  name: 'UQubeDestinationType',
  props: {
    database: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      databases: [],
      databaseLocal: this.database,
    };
  },
  async created() {
    const resp = await destinations.getUqubeDatabases();
    this.databases = resp.data;
  },
  methods: {
    getDetail() {
      return {
        database: this.databaseLocal,
      };
    },
  },
};
</script>
