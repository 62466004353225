<template>
  <v-row>
    <ignore-web-orders
      ref="ignoreWebOrders"
      :ignore-web-orders="ignoreWebOrders"
    />
    <v-col
      cols="12"
      md="6"
      lg="4"
    >
      <ValidationProvider
        v-slot="{ errors }"
        rules="required"
        name="Size"
      >
        <v-select
          v-model="sizeLocal"
          :items="columns"
          :error-messages="errors"
          label="Size"
        />
      </ValidationProvider>
      <div class="caption">
        The grid row and grid column fields map to size and width. Choose the column that represents
        size and the other one will be used for width.
      </div>
    </v-col>
  </v-row>
</template>

<script>
import IgnoreWebOrders from '../Components/IgnoreWebOrders';

export default {
  name: 'CadenceRicsDestination',
  components: {
    IgnoreWebOrders,
  },
  props: {
    ignoreWebOrders: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      sizeLocal: this.size,
      columns: ['row', 'column'],
    };
  },
  methods: {
    getDetail() {
      return {
        ...this.$refs.ignoreWebOrders.getDetail(),
        size: this.sizeLocal,
      };
    },
  },
};
</script>
