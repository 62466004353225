import './bootstrap';

import Zendesk from '@dansmaculotte/vue-zendesk';
import VueMask from 'v-mask';
import Vue from 'vue';
import VueClipboard from 'vue-clipboard2';
import VuetifyConfirm from 'vuetify-confirm';

import App from './App';
import './plugins/veeValidate';
import vuetify from './plugins/vuetify';
import router from './router';
import store from './store';

import './assets/styles/wysiwyg.css';

Vue.use(VuetifyConfirm, { vuetify });
Vue.use(VueMask);
Vue.use(Zendesk, {
  key: '1888b64e-8d79-4d5f-80f7-65e35132c56e',
  settings: {
    webWidget: {
      position: {
        horizontal: 'right',
        vertical: 'top',
      },
      offset: {
        horizontal: '50px',
        mobile: {
          horizontal: '50px',
          vertical: '-5px',
        },
      },
      color: {
        launcher: '#2C2654',
      },
    },
  },
});
Vue.use(VueClipboard);

Vue.config.productionTip = false;
(async () => {
  try {
    await store.dispatch('AUTH_INIT');
  } catch (e) {
    // if this fails then just load the site
  }

  new Vue({
    vuetify,
    router,
    store,
    async mounted() {
      if (store.getters.isAuthenticated() === true) {
        store.dispatch('AUTH_REFRESH_INIT');
      }
    },
    render: (h) => h(App),
  }).$mount('#app');
})();
