<template>
  <div ref="top">
    <!-- breadcrumbs -->
    <v-breadcrumbs
      v-if="report"
      class="px-3 py-1 py-md-0"
      :items="[
        {
          text: 'Reports',
          exact: true,
          disabled: false,
          to: {
            name: 'admin.reports.index',
            query: { verticalId: report ? report.verticalId : null }
          }
        },
        {
          text: report.title,
          exact: true,
          disabled: true,
        }
      ]
      "
    />
    <!-- alerts -->
    <v-slide-y-transition
      appear
      group
    >
      <template v-for="(alert, i) in alerts">
        <v-alert
          :key="`alert-${i}`"
          text
          dismissible
          class="mt-4 mx-2"
          :type="alert.type"
        >
          {{ alert.message }}
        </v-alert>
      </template>
    </v-slide-y-transition>

    <!-- header -->
    <v-container fluid>
      <v-row>
        <v-col>
          <h1 class="font-weight-medium">
            Edit Report
          </h1>
        </v-col>
        <v-col
          class="d-flex justify-end"
          align-self="center"
        >
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn
                fab
                icon
                x-small
                outlined
                color="success"
                :loading="processing"
                v-bind="attrs"
                @click="onSaveGuide"
                v-on="on"
              >
                <v-icon>
                  mdi-content-save
                </v-icon>
              </v-btn>
            </template>
            <span>Save</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-container>

    <!-- form -->
    <ValidationObserver ref="reportForm">
      <v-form
        :disabled="loading"
        @submit.prevent="onSaveGuide"
      >
        <v-container fluid>
          <v-row>
            <v-col
              cols="12"
              sm="6"
              md="5"
              lg="4"
              xl="3"
            >
              <ValidationProvider
                v-slot="{ errors }"
                rules="required"
                name="Title"
              >
                <v-text-field
                  v-model.trim.lazy="form.title"
                  flat
                  label="Title"
                  class="text-h5"
                  :error-messages="errors"
                />
              </ValidationProvider>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              cols="12"
              sm="5"
              md="3"
              xl="2"
            >
              <ValidationProvider
                v-slot="{ errors }"
                rules="required"
                name="Category"
              >
                <v-text-field
                  v-model.trim.lazy="form.category"
                  flat
                  dense
                  label="Category"
                  :error-messages="errors"
                />
              </ValidationProvider>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <!-- eslint-disable-next-line vuejs-accessibility/label-has-for -->
              <label
                for="edit-report-description"
                style="height: auto;"
                class="v-subheader theme--light px-0 mb-2"
              >
                Description
              </label>
              <ValidationProvider
                v-slot="{ errors }"
                rules="required"
                name="Description"
              >
                <v-textarea
                  id="edit-report-description"
                  v-model.trim.lazy="form.description"
                  flat
                  solo
                  counter
                  outlined
                  auto-grow
                  maxlength="255"
                  label="Description"
                  style="border-radius: 0;"
                  :error-messages="errors"
                />
              </ValidationProvider>
            </v-col>
          </v-row>

          <!-- guide text editor -->
          <v-row dense>
            <v-col
              dense
              cols="12"
              class="d-flex"
            >
              <v-subheader
                style="height: auto;"
                class="px-0 d-inline-flex"
              >
                Guide
              </v-subheader>
              <v-spacer />
              <v-btn
                fab
                icon
                x-small
                :outlined="guideResolution === '475px'"
                @click.prevent="guideResolution = '475px'"
              >
                <v-icon>
                  mdi-cellphone
                </v-icon>
              </v-btn>
              <v-btn
                fab
                icon
                x-small
                :outlined="guideResolution === '700px'"
                @click.prevent="guideResolution = '700px'"
              >
                <v-icon>
                  mdi-monitor
                </v-icon>
              </v-btn>
              <v-btn
                fab
                icon
                x-small
                :outlined="guideResolution === ''"
                @click.prevent="guideResolution = ''"
              >
                <v-icon>
                  mdi-fullscreen
                </v-icon>
              </v-btn>
            </v-col>
            <v-col>
              <wysiwyg-editor
                v-model="form.guide"
                class="mx-auto"
                :style="{ maxWidth: guideResolution }"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-spacer />
            <v-col cols="auto">
              <v-btn
                link
                exact
                depressed
                type="submit"
                class="mr-3"
                :to="{
                  name: 'admin.reports.index',
                  query: { verticalId: report ? report.verticalId : null}
                }"
                :disabled="processing"
              >
                <v-icon
                  small
                  class="mr-2"
                >
                  mdi-arrow-left
                </v-icon>
                Back
              </v-btn>
              <v-btn
                depressed
                type="submit"
                color="success"
                :disabled="processing"
              >
                <v-icon
                  small
                  class="mr-2"
                >
                  mdi-content-save
                </v-icon>
                Save
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </ValidationObserver>
  </div>
</template>

<script>
import { pick } from 'lodash';
import { mapGetters } from 'vuex';
import WysiwygEditor from '@/components/WysiwygEditor';

export default {
  name: 'ReportsEdit',
  components: {
    WysiwygEditor,
  },
  data() {
    return {
      loading: true,
      processing: false,
      alerts: [],
      guideResolution: '',
      form: {
        title: '',
        category: '',
        description: '',
        guide: '',
      },
    };
  },
  computed: {
    ...mapGetters('admin/reports', ['getReportById']),
    report() {
      return this.getReportById(this.$route.params.id);
    },
  },
  watch: {
    report: {
      immediate: true,
      handler(newReport, oldReport) {
        if (!oldReport && newReport) {
          this.form = pick(newReport, ['title', 'category', 'description', 'guide']);
          this.loading = false;
        }
      },
    },
  },
  async created() {
    const report = await this.$store.dispatch('admin/reports/getById', this.$route.params.id);

    if (!report) {
      this.$router.go(-1);
    }
  },
  methods: {
    async onSaveGuide() {
      if (this.processing) {
        return;
      }

      this.alerts = [];
      const valid = await this.$refs.reportForm.validate();

      if (!valid) {
        this.$nextTick(() => {
          document.body.scrollIntoView({ behavior: 'smooth' });
        });
        return;
      }

      this.processing = true;
      const report = { ...this.form };

      report.guide = report.guide.trim();
      report.id = this.report.id;

      try {
        await this.$store.dispatch('admin/reports/update', report);

        this.alerts.push({
          type: 'success',
          message: 'Report successfully saved.',
        });
      } catch (e) {
        const { errors } = e.response.data;

        if (!errors) {
          this.alerts.push({
            type: 'error',
            message: 'An unknown error has occurred.',
          });
        } else {
          this.alerts.push(...errors.map((error) => ({ type: 'error', ...error })));
        }
      }

      this.processing = false;
      this.$nextTick(() => {
        document.body.scrollIntoView({ behavior: 'smooth' });
      });
    },
  },
};
</script>
