import vue from 'vue';

const mutations = {
  HEARTLAND_SETCOLUMNS: (state, data) => {
    vue.set(state, 'heartlandItemColumns', data.itemColumns);
    vue.set(state, 'heartlandCustomerColumns', data.customerColumns);
  },
  HEARTLAND_CLEARCOLUMNS: (state) => {
    vue.set(state, 'heartlandItemColumns', []);
    vue.set(state, 'heartlandCustomerColumns', []);
  },
};

export default mutations;
