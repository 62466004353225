<template>
  <ValidationObserver ref="observer">
    <v-overlay :value="overlay">
      <v-progress-circular
        :size="70"
        :width="7"
        indeterminate
      />
    </v-overlay>
    <v-row>
      <v-col
        cols="12"
        class="title"
      >
        Upload Products File
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="6"
        lg="4"
      >
        <ValidationProvider
          v-slot="{ errors }"
          rules="required"
          name="File"
          mode="lazy"
        >
          <v-file-input
            v-model="file"
            label="File input"
            :error-messages="errors"
          />
        </ValidationProvider>
      </v-col>
      <v-col
        cols="12"
        md="6"
        lg="4"
      >
        <v-btn
          color="primary"
          @click="upload"
        >
          Upload
        </v-btn>
      </v-col>
      <v-col
        cols="12"
      >
        <div class="subtitle-1">
          The layout of the file is listed below. It must be a csv file with only the columns
          specified in the order that is listed. The custom fields are optional but must be either
          present or not for all records.
        </div>
        <ol class="body-2">
          <li
            v-for="item in columns"
            :key="item"
          >
            {{ item }}
          </li>
        </ol>
      </v-col>
    </v-row>
  </ValidationObserver>
</template>

<script>
import { mapGetters } from 'vuex';
import files from '../_api/files';

export default {
  name: 'BrandUpload',
  data() {
    return {
      overlay: false,
      file: null,
      columns: [
        'Upc',
        'Name',
        'Sku',
        'Size',
        'Width',
        'Color',
        'Style',
        'Price',
        'Cost',
        'Image Url',
        'Custom Field 1',
        'Custom Field 2',
        'Custom Field 3',
        'Custom Field 4',
        'Custom Field 5',
      ],
    };
  },
  computed: {
    ...mapGetters([
      'getActiveAccount',
    ]),
  },
  methods: {
    async upload() {
      if (await this.$confirm(`Are you sure you want to upload products for account <strong>${this.getActiveAccount.name}</strong>?`)) {
        const isValid = await this.$refs.observer.validate();
        if (isValid) {
          const formData = new FormData();
          formData.append('file', this.file, this.file.name);
          this.overlay = true;
          try {
            await files.uploadBrandProduct(this.getActiveAccount.id, formData);
            this.file = null;
            this.$refs.observer.reset();
          } catch (e) {
          // do something
          }
          this.overlay = false;
        }
      }
    },
  },
};
</script>
