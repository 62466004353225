<template>
  <v-text-field
    ref="copyBox"
    v-bind="$attrs"
    :value="value"
    readonly
  >
    <template #append>
      <v-btn
        fab
        icon
        small
        @click="onCopy"
      >
        <v-icon>
          mdi-content-copy
        </v-icon>
      </v-btn>
    </template>
  </v-text-field>
</template>

<script>
export default {
  name: 'CopyBox',
  props: {
    value: {
      type: String,
      required: true,
    },
  },
  methods: {
    async onCopy() {
      const input = this.$refs.copyBox.$el.getElementsByTagName('input')[0];

      // For cosmetic purposes only.
      input.select();
      input.setSelectionRange(0, 99999);

      await navigator.clipboard.writeText(this.value);
    },
  },
};
</script>
