<template>
  <Wrapper>
    <h2 class="mb-2">
      Create Account
    </h2>
    <ValidationObserver ref="profileObserver">
      <v-form
        v-show="!showTwoFactor"
        @submit.prevent="createAccount"
      >
        <v-row dense>
          <v-col
            cols="12"
            sm="6"
          >
            <ValidationProvider
              v-slot="{ errors }"
              rules="required"
              name="First Name"
            >
              <v-text-field
                v-model.trim="firstName"
                label="First Name"
                :error-messages="errors"
              />
            </ValidationProvider>
          </v-col>
          <v-col
            cols="12"
            sm="6"
          >
            <ValidationProvider
              v-slot="{ errors }"
              rules="required"
              name="Last Name"
            >
              <v-text-field
                v-model.trim="lastName"
                label="Last Name"
                :error-messages="errors"
              />
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12">
            <ValidationProvider
              ref="passwordValidationProvider"
              v-slot="{ errors }"
              rules="required"
              name="Password"
              vid="Password"
            >
              <v-text-field
                v-model="password"
                :append-icon="showPassword ? 'visibility' : 'visibility_off'"
                :type="showPassword ? 'text' : 'password'"
                label="Password"
                role="textbox"
                :error-messages="errors"
                @click:append="showPassword = !showPassword"
              />
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12">
            <ValidationProvider
              v-slot="{ errors }"
              rules="required|confirmed:Password"
              name="Confirm Password"
            >
              <v-text-field
                v-model="confirmPassword"
                :append-icon="showConfirmPassword ? 'visibility' : 'visibility_off'"
                :type="showConfirmPassword ? 'text' : 'password'"
                label="Confirm Password"
                role="textbox"
                :error-messages="errors"
                @click:append="showConfirmPassword = !showConfirmPassword"
              />
            </ValidationProvider>
            <PasswordValidation
              ref="passwordValidation"
              :password="password"
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12">
            <v-btn
              :disabled="!validToken"
              block
              color="primary"
              type="submit"
              class="mt-2"
            >
              Next
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </ValidationObserver>
    <div v-show="showTwoFactor">
      <div>
        <h5>Install the Google Authenticator app on your phone.</h5>
        <ol>
          <li>On your phone, open your App/Play store.</li>
          <li>
            Search for
            <strong>Google Authenticator</strong>
          </li>
          <li>
            Download and install the app
            <ul>
              <li>
                <v-btn
                  text
                  small
                  class="pa-0 text-none"
                  color="primary"
                  href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en_US"
                  target="_blank"
                >
                  Download from the Google Play Store
                </v-btn>
              </li>
              <li>
                <v-btn
                  text
                  small
                  class="pa-0 text-none"
                  color="primary"
                  href="https://itunes.apple.com/gw/app/google-authenticator/id388497605?mt=8"
                  target="_blank"
                >
                  Download from the Apple App Store
                </v-btn>
              </li>
            </ul>
          </li>
        </ol>
      </div>
      <div>
        <h5>Now open and configure Google Authenticator.</h5>
        <ol>
          <li>In the Google Authenticator, touch the menu and select "Set up account"</li>
          <li>Select "Scan a barcode."</li>
          <li>Use your phone's camera to scan this barcode.</li>
        </ol>
        <div>
          <img
            :src="twoFactorImage"
            alt="Two Factor Authentication QR Code"
          >
        </div>
        <p>
          <v-btn
            text
            small
            class="pa-0 text-none"
            color="primary"
            @click.prevent="showSecret = true"
          >
            Can't scan the barcode?
          </v-btn>
        </p>
        <div v-show="showSecret">
          <p>
            When adding the account in Google Authenticator app choose "Enter a provided
            key" option and use the following.<br>
            {{ twoFactorSecret }}
          </p>
        </div>
        <p>
          Once you have scanned the barcode, enter the 6-digit verification code generated
          by the Authentication app.
        </p>
      </div>
      <ValidationObserver ref="twoFactorObserver">
        <v-form
          @submit.prevent="verify"
        >
          <ValidationProvider
            ref="codeValidation"
            v-slot="{ errors }"
            rules="required|numeric|code:6"
            name="Code"
          >
            <v-text-field
              v-model="code"
              label="6-digit code"
              role="textbox"
              :error-messages="errors"
              maxlength="6"
              outlined
            />
          </ValidationProvider>
          <v-btn
            block
            color="primary"
            type="submit"
            class="mt-2"
            :disabled="success"
          >
            Verify and Create Account
          </v-btn>
        </v-form>
      </ValidationObserver>
    </div>
    <template #footer>
      <v-alert
        v-show="!validToken"
        aria-label="Account Creation Error"
        type="error"
        class="mt-3"
      >
        {{ tokenError }}
      </v-alert>
      <v-alert
        v-show="success"
        aria-label="Account Creation Success"
        type="success"
        class="mt-3"
      >
        Your account has been created.
        <a
          class="white--text pl-1"
          href="/login"
        >
          Go to login
        </a>
      </v-alert>
    </template>
  </Wrapper>
</template>

<script>
import auth from '@/api/auth';
import PasswordValidation from '@/components/PasswordValidation';
import Wrapper from '../_components/Wrapper';

export default {
  name: 'AdminJoin',
  components: {
    PasswordValidation,
    Wrapper,
  },
  data() {
    return {
      firstName: '',
      lastName: '',
      password: '',
      confirmPassword: '',
      code: '',
      showPassword: false,
      showConfirmPassword: false,
      validToken: true,
      showTwoFactor: false,
      showSecret: false,
      twoFactorImage: null,
      twoFactorSecret: null,
      tokenError: 'The invite link is no longer valid.',
      success: false,
    };
  },
  async created() {
    try {
      const resp = await auth.validateAdminJoin(this.$route.params.token);
      this.twoFactorImage = resp.data.imageUrl;
      this.twoFactorSecret = resp.data.secret;
    } catch (error) {
      if (error.response) {
        if (error.response.data === 'InvalidToken') {
          this.tokenError = 'The invite link is no longer valid.';
        } else if (error.response.data === 'AlreadyActivated') {
          this.tokenError = 'Your account has already been activated.';
        }
      }
      this.validToken = false;
    }
  },
  methods: {
    async createAccount() {
      const isValid = await this.$refs.profileObserver.validate();
      if (isValid) {
        if (!this.$refs.passwordValidation.isValid()) {
          this.$refs.passwordValidationProvider.setErrors(['Password does not meet the password policy']);
        } else {
          this.showTwoFactor = true;
        }
      }
    },
    async verify() {
      const isValid = await this.$refs.twoFactorObserver.validate();
      if (isValid) {
        try {
          await auth.adminJoin(
            this.$route.params.token,
            this.firstName,
            this.lastName,
            this.password,
            this.code,
          );
          this.success = true;
        } catch (error) {
          if (error.response) {
            if (error.response.data === 'InvalidCode') {
              this.$refs.codeValidation.setErrors(['Invalid Code']);
            }
          }
        }
      }
    },
  },
};
</script>
