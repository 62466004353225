import auth from '@/api/auth';

let refreshInterval;

const actions = {
  AUTH_INIT: async ({ commit }) => {
    const resp = await auth.expiration();
    if (resp.data.exp) {
      commit('AUTH_SUCCESS', resp.data.exp);
    }
  },
  AUTH_REQUEST: async ({ commit, dispatch }, user) => {
    const resp = await auth.login(user);
    if (resp.data.status === 'Success') {
      commit('AUTH_SUCCESS', resp.data.exp);
      dispatch('AUTH_REFRESH_INIT');
      await Promise.all([dispatch('USER_REQUEST'), dispatch('USER_GET_ACCOUNTS')]);
    }
    return resp;
  },
  AUTH_LOGOUT: async ({ commit }) => {
    await auth.logout();
    commit('AUTH_LOGOUT');
    clearInterval(refreshInterval);
  },
  AUTH_REFRESH_INIT: ({ dispatch }) => {
    // call refresh every 30 minutes
    refreshInterval = setInterval(() => {
      dispatch('AUTH_REFRESH');
    }, 30 * 60 * 1000);
    // dispatch('AUTH_REFRESH');
  },
  AUTH_REFRESH: async ({ state, commit }) => {
    // check if it is expiring in the next hour
    if (state.exp && state.exp > Date.now() && state.exp < Date.now() + 3600000) {
      const resp = await auth.refreshToken();
      if (resp.data.exp) {
        commit('AUTH_SUCCESS', resp.data.exp);
      }
    }
  },
};

export default actions;
