import user from '@/api/user';
import reset from '@/store/utils/reset';

function initialState() {
  return {
    hasViewedReportGuide: null,
  };
}

// * This should be a sub module of the user, but the user is not currently namespaced.
export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    SET_SETTING(state, setting) {
      let { value } = setting;

      if (['true', 'false'].includes(setting.value)) {
        value = setting.value === 'true';
      }

      state[setting.name] = value;
    },
    RESET(state) {
      reset(state, initialState());
    },
  },
  actions: {
    async get({ state, commit }, name) {
      if (state[name] != null) {
        return state[name];
      }

      const { data: { data } } = await user.getSetting(name);

      commit('SET_SETTING', { name, value: data[name] });

      return state[name];
    },
    async save({ commit }, payload) {
      let { value } = payload;

      if (typeof value === 'boolean') {
        value = String(value);
      }

      await user.insertSetting(payload.name, value, payload.accountId);

      commit('SET_SETTING', { name: payload.name, value });
    },
    reset({ commit }) {
      commit('RESET');
    },

    // hooks into the root logout function to reset settings.
    AUTH_LOGOUT: {
      root: true,
      handler({ dispatch }) {
        dispatch('reset');
      },
    },
  },
};
