<template>
  <v-row>
    <v-col
      cols="12"
      md="6"
    >
      <ValidationProvider
        ref="retailerId"
        v-slot="{ errors }"
        rules="required"
        name="Retailer ID"
      >
        <v-text-field
          v-model="detail.retailerId"
          label="Retailer ID"
          :error-messages="errors"
        />
      </ValidationProvider>
    </v-col>
    <v-col
      cols="12"
      md="6"
    >
      <ValidationProvider
        ref="apiKey"
        v-slot="{ errors }"
        rules="required"
        name="API Key"
      >
        <v-text-field
          v-model="detail.apiKey"
          label="API Key"
          :error-messages="errors"
        />
      </ValidationProvider>
    </v-col>
  </v-row>
</template>

<script>
import bridalLive from '../../../_api/bridalLive';

export default {
  props: {
    retailerId: {
      type: String,
      required: false,
      default: '',
    },
    apiKey: {
      type: String,
      required: false,
      default: '',
    },
    errorMessage: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      detail: {
        retailerId: this.retailerId,
        apiKey: this.apiKey,
      },
    };
  },
  watch: {
    detail: {
      handler() {
        this.$refs.retailerId.setErrors([]);
        this.$refs.apiKey.setErrors([]);
      },
      deep: true,
    },
    errorMessage() {
      if (this.errorMessage === 'InvalidCredentials') {
        this.$refs.retailerId.setErrors(['Invalid Retailer ID or Api Key']);
        this.$refs.apiKey.setErrors(['Invalid Retailer ID or Api Key']);
      }
    },
  },
  methods: {
    getDetail() {
      return this.detail;
    },
    async preview() {
      const { retailerId, apiKey } = this.detail;

      const result = await bridalLive.preview(retailerId, apiKey);

      return result.data;
    },
  },
};
</script>
