<template>
  <v-col
    v-if="metadata.enabled"
    cols="12"
    md="6"
    lg="4"
  >
    <ValidationProvider
      v-slot="{ errors }"
      :rules="`${isRequired ? 'required' : ''}`"
      :name="metadata.label"
    >
      <v-text-field
        v-if="metadata.type === 'text'"
        v-model.trim="valueLocal"
        :label="metadata.label"
        :error-messages="errors"
        maxlength="500"
        @change="$emit('update:value', valueLocal);"
      />
      <v-select
        v-if="metadata.type === 'list'"
        v-model="valueLocal"
        :label="metadata.label"
        :items="metadata.values"
        :error-messages="errors"
        clearable
        return-object
        @change="$emit('update:value', valueLocal);"
      />
    </ValidationProvider>
  </v-col>
</template>
<script>
export default {
  name: 'ExtensionField',
  props: {
    value: {
      type: String,
      required: false,
      default: '',
    },
    isRequired: {
      type: Boolean,
      required: false,
      default: false,
    },
    metadata: {
      type: Object,
      required: true,
      default: null,
    },
  },
  data() {
    return {
      valueLocal: this.value,
    };
  },
  watch: {
    value(val) {
      this.valueLocal = val;
    },
  },
};
</script>
