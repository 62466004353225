<template>
  <v-row>
    <v-col
      cols="12"
      md="6"
      lg="4"
    >
      <v-btn
        color="primary"
        @click.prevent="initAuthenticate"
      >
        Authenticate App
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'LightspeedSourceType',
  methods: {
    async initAuthenticate() {
      window.location.href = `https://cloud.lightspeedapp.com/oauth/authorize.php?response_type=code&client_id=${process.env.VUE_APP_LIGHTSPEED_CLIENT_ID}&scope=employee:customers_read+employee:inventory_read+employee:register_read&state=${this.$route.query.t}`;
    },
  },
};
</script>
