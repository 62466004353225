<template>
  <v-container fluid>
    <v-dialog
      v-model="orderDialog"
      max-width="500"
      persistent
    >
      <v-card
        class="mx-auto"
        tile
      >
        <v-card-title>
          Set Rule Priority
        </v-card-title>
        <v-card-text
          class="scroll"
        >
          Drag and drop the rules to place them in the order you want them to run.
          <v-list
            class="pt-0 mt-5"
            two-line
          >
            <draggable
              v-model="sortRules"
              :force-fallback="true"
            >
              <v-list-item
                v-for="item in sortRules"
                :key="item.id"
                class="tile"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle v-if="item.category">
                    <strong>Primary Category</strong>: {{ item.category }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle v-if="item.tags && item.tags.length > 0">
                    <strong>Tags</strong>: {{ item.tags.join(', ') }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle v-if="item.gender">
                    <strong>Gender</strong>: {{ item.gender }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle v-if="item.family">
                    <strong>Family</strong>: {{ item.family }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle v-if="item.extensionField1">
                    <strong>{{ getExtensionFieldLabel(1) }}</strong>: {{ item.extensionField1 }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle v-if="item.extensionField2">
                    <strong>{{ getExtensionFieldLabel(2) }}</strong>: {{ item.extensionField2 }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle v-if="item.extensionField3">
                    <strong>{{ getExtensionFieldLabel(3) }}</strong>: {{ item.extensionField3 }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle v-if="item.extensionField4">
                    <strong>{{ getExtensionFieldLabel(4) }}</strong>: {{ item.extensionField4 }}
                  </v-list-item-subtitle>
                  <v-list-item-subtitle v-if="item.extensionField5">
                    <strong>{{ getExtensionFieldLabel(5) }}</strong>: {{ item.extensionField5 }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </draggable>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            text
            @click="orderDialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="saveOrder"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-overlay :value="overlay">
      <v-progress-circular
        :size="70"
        :width="7"
        indeterminate
      />
    </v-overlay>
    <v-row>
      <v-col cols="12">
        <v-btn
          color="primary"
          :to="$route.meta.globalRetailer === true ? 'globalRetailerTagging/create'
            : 'tagging/create'"
        >
          Create Tagging Rule
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="rules.length > 1">
      <v-col cols="12">
        <ul>
          <li>Rules are run in the order defined.</li>
          <li>
            A product will set field values for the first rule it matches where that particular
            field has been set.
            <ul>
              <li>Separate rules can be used to set values for additional fields for a product.</li>
            </ul>
          </li>
          <li>
            The product will get tags for all rules it matches where the primary category matches
            the product's primary category.
          </li>
          <li>
            To modify the order the rules are run in
            <a
              @click="reorder"
              @keypress.enter="reorder"
            >click here</a>.
          </li>
        </ul>
      </v-col>
    </v-row>
    <v-row
      v-if="sourceTags"
    >
      <v-col cols="12">
        To download the source tags choose one of the options below.
        <ul>
          <li>
            <a
              class="text-decoration-underline"
              @click.prevent="exportSourceTags"
              @keypress.enter="exportSourceTags"
            >All source tags</a>
          </li>
          <li>
            <a
              class="text-decoration-underline"
              @click.prevent="exportUnusedSourceTags"
              @keypress.enter="exportUnusedSourceTags"
            >Unused source tags</a>
          </li>
        </ul>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="computedHeaders"
          :items="filteredRules"
          :loading="loading"
          :disable-pagination="true"
          :expanded.sync="expanded"
          show-expand
          hide-default-footer
          fixed-header
          height="50vh"
          class="elevation-5"
        >
          <template #top>
            <v-btn
              color="primary"
              class="ml-1 mt-1"
              @click="expandAll"
            >
              Expand All
            </v-btn>
            <v-btn
              color="secondary"
              class="ml-1 mt-1"
              @click="collapseAll"
            >
              Collapse All
            </v-btn>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
              class="pa-3"
            />
          </template>
          <template #[`item.createdAt`]="{ item }">
            {{ formatDate(item.createdAt) }}
          </template>
          <template #[`item.updatedAt`]="{ item }">
            {{ formatDate(item.updatedAt) }}
          </template>
          <template #[`item.tags`]="{ item }">
            {{ item.tags.join(', ') }}
          </template>
          <template #[`item.category`]="{ item }">
            <div
              v-if="item.category"
              class="my-2"
            >
              <div>
                {{ item.category }}
              </div>
              <div v-if="item.category2">
                {{ item.category2 }}
              </div>
              <div v-if="item.category3">
                {{ item.category3 }}
              </div>
              <div v-if="item.category4">
                {{ item.category4 }}
              </div>
              <div v-if="item.category5">
                {{ item.category5 }}
              </div>
            </div>
          </template>
          <template #[`item.actions`]="{ item }">
            <v-btn
              fab
              text
              :to="$route.meta.globalRetailer === true ?
                '/product/globalRetailerTagging/edit/' + item.id
                : '/product/tagging/edit/' + item.id"
              class="mr-2"
              x-small
            >
              <v-icon>
                edit
              </v-icon>
            </v-btn>
            <v-btn
              fab
              text
              class="mr-2"
              x-small
              @click="deleteTagRule(item)"
            >
              <v-icon>
                delete
              </v-icon>
            </v-btn>
          </template>
          <template #expanded-item="{ item }">
            <td :colspan="headers.length">
              <column-filter-display
                :columns="columns"
                :filters="item.filters"
              />
            </td>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { saveAs } from 'file-saver';
import Draggable from 'vuedraggable';
import tagging from '../_api/tagging';
import ColumnFilterDisplay from '../_components/ColumnFilterDisplay';
import products from '../_api/products';
import taggingMixin from '../_mixins/taggingMixin';

export default {
  name: 'ProductTagging',
  components: {
    ColumnFilterDisplay,
    Draggable,
  },
  mixins: [taggingMixin],
  data() {
    return {
      orderDialog: false,
      loading: false,
      overlay: false,
      rules: [],
      sortRules: [],
      headers: [
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
        },
        {
          text: 'Priority',
          value: 'sortOrder',
        },
        {
          text: 'Rule Name',
          value: 'name',
        },
        {
          text: 'Categories',
          value: 'category',
        },
        {
          text: 'Tags',
          value: 'tags',
          sortable: false,
        },
        {
          text: 'Gender',
          value: 'gender',
        },
        {
          text: 'Family',
          value: 'family',
        },
        {
          text: 'Color',
          value: 'color',
        },
        {
          text: '',
          value: 'extensionField1',
        },
        {
          text: '',
          value: 'extensionField2',
        },
        {
          text: '',
          value: 'extensionField3',
        },
        {
          text: '',
          value: 'extensionField4',
        },
        {
          text: '',
          value: 'extensionField5',
        },
        {
          text: 'Created At',
          value: 'createdAt',
        },
        {
          text: 'Updated At',
          value: 'updatedAt',
        },
        {
          text: '',
          value: 'data-table-expand',
        },
      ],
      search: '',
      expanded: [],
    };
  },
  computed: {
    filteredRules() {
      if (this.search !== '') {
        return this.rules.filter((x) => {
          if (this.searchIncludes(x.name)) {
            return true;
          }
          if (this.searchIncludes(x.gender)) {
            return true;
          }
          if (this.searchIncludes(x.category)) {
            return true;
          }
          if (this.searchIncludes(x.category2)) {
            return true;
          }
          if (this.searchIncludes(x.category3)) {
            return true;
          }
          if (this.searchIncludes(x.category4)) {
            return true;
          }
          if (this.searchIncludes(x.category5)) {
            return true;
          }
          if (this.searchIncludes(x.family)) {
            return true;
          }
          if (this.searchIncludes(x.color)) {
            return true;
          }
          if (this.searchIncludes(x.extensionField1)) {
            return true;
          }
          if (this.searchIncludes(x.extensionField2)) {
            return true;
          }
          if (this.searchIncludes(x.extensionField3)) {
            return true;
          }
          if (this.searchIncludes(x.extensionField4)) {
            return true;
          }
          if (this.searchIncludes(x.extensionField5)) {
            return true;
          }
          if (this.tags) {
            if (this.searchIncludes(this.tags.toString())) {
              return true;
            }
          }
          for (let i = 0; i <= x.filters.length - 1; i += 1) {
            if (this.searchIncludes(x.filters[i].values.toString())) {
              return true;
            }
          }
          return false;
        });
      }
      return this.rules;
    },
    computedHeaders() {
      return this.headers.filter((h) => {
        if ((h.value === 'gender' || h.value === 'family' || h.value === 'color')
          && this.metadata.STANDARD_COLUMNS) {
          return this.metadata.STANDARD_COLUMNS[h.value];
        }
        if (h.value.startsWith('extensionField') && this.metadata.EXTENSION_FIELDS) {
          return this.metadata.EXTENSION_FIELDS[h.value]
            ? this.metadata.EXTENSION_FIELDS[h.value].enabled : false;
        }
        return true;
      }).map((h) => {
        if (h.value.startsWith('extensionField')) {
          return {
            ...h,
            text: this.getExtensionFieldLabel(h.value.substring(14, h.value.length)),
          };
        }
        return h;
      });
    },
  },
  async created() {
    this.overlay = true;
    await this.onCreated();
    await this.pullData();
    this.overlay = false;
  },
  methods: {
    async pullData() {
      this.loading = true;
      const resp = await tagging.getTags(this.type, this.computedAccountId);
      this.rules = resp.data;
      this.loading = false;
    },
    formatDate(value) {
      if (value) {
        return new Date(value).toLocaleDateString('en-us', { month: 'long', day: 'numeric', year: 'numeric' });
      }
      return '';
    },
    async reorder() {
      this.overlay = true;
      await this.pullData();
      this.sortRules = JSON.parse(JSON.stringify(this.rules));
      this.orderDialog = true;
      this.overlay = false;
    },
    async deleteTagRule(item) {
      if (await this.$confirm(`Are you sure you want to delete the tag rule ${item.name}?`)) {
        this.loading = true;
        await tagging.deleteTag(this.type, this.computedAccountId, item.id);
        await this.pullData();
      }
    },
    async saveOrder() {
      this.overlay = true;
      this.orderDialog = false;
      const ruleIds = this.sortRules.map((rule) => rule.id);
      await tagging.reorder(this.type, this.computedAccountId, ruleIds);
      await this.pullData();
      this.overlay = false;
    },
    exportSourceTags() {
      if (this.sourceTags) {
        const blob = new Blob(
          [this.sourceTags.join('\r\n')],
          { type: 'text/plain;charset=utf-8' },
        );
        saveAs(blob, 'Source Tags.txt');
      }
    },
    async exportUnusedSourceTags() {
      this.overlay = true;
      const resp = await products.getUnusedSourceTags(this.type, this.computedAccountId);
      if (resp.data) {
        this.overlay = false;
        let blob;
        if (resp.data === 'ALL') {
          blob = new Blob(
            [this.sourceTags.join('\r\n')],
            { type: 'text/plain;charset=utf-8' },
          );
        } else {
          blob = new Blob(
            [resp.data.join('\r\n')],
            { type: 'text/plain;charset=utf-8' },
          );
        }
        saveAs(blob, 'Unused Source Tags.txt');
      } else {
        this.overlay = false;
      }
    },
    searchIncludes(value) {
      if (value) {
        return value.toLowerCase().includes(this.search.toLowerCase());
      }
      return false;
    },
    expandAll() {
      this.expanded = this.filteredRules;
    },
    collapseAll() {
      this.expanded = [];
    },
    getExtensionFieldLabel(index) {
      if (this.metadata && this.metadata.EXTENSION_FIELDS
        && this.metadata.EXTENSION_FIELDS[`extensionField${index}`]) {
        return this.metadata.EXTENSION_FIELDS[`extensionField${index}`].label;
      }
      return '';
    },

  },
};
</script>

<style scoped>
  .tile:hover {
    background: #E0E0E0;
    cursor:move;
  }
  .tile {
    border: #E0E0E0 solid 1px;
  }
  .scroll {
    overflow-y: scroll;
    height: 70vh;
  }
</style>
