<template>
  <v-row>
    <v-col
      cols="12"
      md="6"
      lg="4"
    >
      <ValidationProvider
        ref="apiKeyValidation"
        v-slot="{ errors }"
        rules="required"
        name="Api Token"
      >
        <v-text-field
          v-model="apiKeyLocal"
          label="Api Token"
          :error-messages="errors"
        />
      </ValidationProvider>
      <div class="caption">
        The API token can be created by logging into Heartland and going to My Account -> API
        Tokens. Once there you can click the button to generate a new token.
      </div>
    </v-col>
    <v-col
      cols="12"
      md="6"
      lg="4"
    >
      <v-select
        v-model="ecomIdentifierLocal"
        :items="locations"
        item-text="name"
        item-value="id"
        label="Web Transaction Store"
        clearable
        :hint="`store id: ${ecomIdentifierLocal}`"
        persistent-hint
      />
      <div class="caption mt-3">
        Orders with from_ecommerce? flag set to true will automatically be treated as web orders. In
        addition you can choose a location that will treat all orders as web orders.
      </div>
    </v-col>
    <v-col
      cols="12"
      md="6"
      lg="4"
    >
      <v-select
        v-model="amazonIdentifierLocal"
        :items="locations"
        item-text="name"
        item-value="id"
        label="Amazon Store"
        clearable
        :hint="`store id: ${amazonIdentifierLocal}`"
        persistent-hint
      />
      <div class="caption mt-3">
        All orders coming from Amazon will automatically be ignored for sending downstream to any
        destinations.
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { debounce } from 'lodash';
import heartland from '../../../_api/heartland';

export default {
  name: 'HeartlandSource',
  props: {
    apiKey: {
      type: String,
      required: false,
      default: '',
    },
    ecomIdentifier: {
      type: Number,
      required: false,
      default: null,
    },
    amazonIdentifier: {
      type: Number,
      required: false,
      default: null,
    },
    errorMessage: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      apiKeyLocal: this.apiKey,
      ecomIdentifierLocal: this.ecomIdentifier,
      amazonIdentifierLocal: this.amazonIdentifier,
      locations: [],
    };
  },
  watch: {
    errorMessage() {
      if (this.errorMessage === 'InvalidApiKey') {
        this.$refs.apiKeyValidation.setErrors(['Api Key is either invalid or lacks all the neccessary permissions']);
      }
    },
    async apiKeyLocal() {
      this.getCustomFields();
    },
  },
  created() {
    this.$store.dispatch('$_sources/HEARTLAND_CLEARCOLUMNS');
    this.getCustomFields();
    this.getCustomFields = debounce(this.getCustomFields, 1000);
  },
  methods: {
    getDetail() {
      return {
        apiKey: this.apiKeyLocal,
        ecomIdentifier: this.ecomIdentifierLocal,
        amazonIdentifier: this.amazonIdentifierLocal,
      };
    },
    async getCustomFields() {
      if (this.apiKeyLocal) {
        try {
          const result = await Promise.all([
            heartland.getCustomFields(this.apiKeyLocal),
            heartland.getLocations(this.apiKeyLocal),
          ]);
          this.$store.dispatch('$_sources/HEARTLAND_SETCOLUMNS', result[0].data);
          this.locations = result[1].data;
        } catch (e) {
          if (e.response && e.response.data === 'InvalidApiKey') {
            this.$refs.apiKeyValidation.setErrors(['Invalid Api Key']);
          }
        }
      }
    },
    async preview() {
      if (this.apiKeyLocal) {
        try {
          const result = await heartland.preview(this.apiKeyLocal);
          return result.data;
        } catch (e) {
          if (e.response && e.response.data === 'InvalidApiKey') {
            this.$refs.apiKeyValidation.setErrors(['Invalid Api Key']);
          }
          throw (e);
        }
      }
      return null;
    },
  },
};
</script>
