<template>
  <Wrapper>
    <div class="title">
      RunSignup Validation
    </div>
    <template #footer>
      <v-alert
        v-show="showSuccess"
        type="success"
        class="mt-3"
      >
        The validation credentials have been successfully updated.
      </v-alert>
      <v-alert
        v-show="showFailure"
        type="error"
        class="mt-3"
      >
        There was a problem saving your validation credentials. Please try again.
      </v-alert>
    </template>
  </Wrapper>
</template>

<script>
import sources from '@/api/sources';
import Wrapper from '../_components/Wrapper';

export default {
  name: 'RunSignup',
  components: {
    Wrapper,
  },
  data() {
    return {
      showSuccess: false,
      showFailure: false,
    };
  },
  async mounted() {
    if (this.$route.query.oauth_token && this.$route.query.oauth_verifier && sessionStorage.getItem('runSignupToken')) {
      try {
        await sources.finalizeOauth(sessionStorage.getItem('runSignupToken'), {
          oauthToken: this.$route.query.oauth_token,
          oauthVerifier: this.$route.query.oauth_verifier,
        });
        sessionStorage.removeItem('runSignupToken');
        this.showSuccess = true;
      } catch (e) {
        this.showFailure = true;
      }
    } else {
      this.showFailure = true;
    }
  },
};
</script>
