<template>
  <div>
    <v-snackbar
      v-model="resendSuccess"
      color="success"
      :top="true"
      :timeout="2000"
    >
      <v-container
        fluid
        class="text-center subtitle-1"
      >
        Invite resent
      </v-container>
    </v-snackbar>
    <v-row>
      <v-col
        cols="12"
        md="6"
        xl="4"
      >
        <ValidationObserver ref="observer">
          <v-form @submit.prevent="invite">
            <ValidationProvider
              ref="emailValidation"
              v-slot="{ errors }"
              rules="email"
              name="Email"
            >
              <v-text-field
                v-model="email"
                solo
                placeholder="Email"
                :error-messages="errors"
              >
                <v-btn
                  slot="append"
                  color="primary"
                  type="submit"
                  :disabled="email === ''"
                >
                  Invite
                </v-btn>
              </v-text-field>
            </ValidationProvider>
          </v-form>
        </ValidationObserver>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="activeHeaders"
          :items="activeUsers"
          :loading="loading"
          :hide-default-footer="true"
          class="elevation-5"
          disable-pagination
        >
          <template #[`item.accessLevel`]="{ item }">
            <v-btn-toggle
              v-model="item.accessLevel"
              color="primary"
              mandatory
              @change="accessLevelChange(item)"
            >
              <v-btn
                value="admin"
                text
                small
                :disabled="item.id === getProfile.id"
              >
                Admin
              </v-btn>
              <v-btn
                value="user"
                text
                small
                :disabled="item.id === getProfile.id"
              >
                User
              </v-btn>
            </v-btn-toggle>
          </template>
          <template #[`item.2FA`]="{ item }">
            <v-icon
              v-if="item.twoFactorEnabled"
              color="success"
            >
              check_box
            </v-icon>
          </template>
          <template #[`item.createdAt`]="{ item }">
            {{ formatCreated(item.createdAt) }}
          </template>
          <template #[`item.name`]="{ item }">
            <div>
              <strong>{{ item.firstName }} {{ item.lastName }}</strong>
            </div>
            <div>
              <em>{{ item.email }}</em>
            </div>
            <v-btn
              v-if="item.activated === false"
              text
              small
              class="pa-0"
              color="primary"
              @click="resendInvite(item)"
            >
              Resend Invite
            </v-btn>
          </template>
          <template #[`item.actions`]="{ item }">
            <v-btn
              text
              small
              color="grey"
              :disabled="item.id === getProfile.id"
              @click="showDeleteUserModal(item)"
            >
              Delete Member
            </v-btn>
            <div v-if="systemUser">
              <v-btn
                text
                small
                color="primary"
                @click="impersonate(item)"
              >
                Impersonate
              </v-btn>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog
      v-model="deleteDialog"
      max-width="500"
      persistent
    >
      <v-card>
        <v-card-title>
          Delete User
        </v-card-title>
        <v-card-text>
          Are you sure you want to remove
          {{ deleteUser.firstName ? `${deleteUser.firstName} ${deleteUser.lastName}` :
            deleteUser.email }} from your team?
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            text
            @click="deleteDialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="deleteUserConfirm"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import accounts from '@/api/accounts';

export default {
  name: 'AccountUser',
  data() {
    return {
      email: '',
      deleteDialog: false,
      loading: false,
      resendSuccess: false,
      activeUsers: [],
      deleteUser: {},
      activeHeaders: [
        {
          text: 'Name',
          value: 'name',
          sortable: false,
          width: '20%',
        },
        {
          text: 'Access Level',
          value: 'accessLevel',
          sortable: false,
          width: '20%',
        },
        {
          text: '2FA',
          value: '2FA',
          sortable: false,
          width: '20%',
        },
        {
          text: 'Create Date',
          value: 'createdAt',
          sortable: false,
          width: '20%',
        },
        {
          text: '',
          value: 'actions',
          sortable: false,
          width: '20%',
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      'getActiveAccount',
      'getProfile',
      'systemUser',
    ]),
  },
  async mounted() {
    await this.getData();
  },
  methods: {
    async invite() {
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        try {
          await accounts.inviteUser(this.getActiveAccount.id, this.email);
          this.loading = true;
          await this.getData();
          this.loading = false;
          this.email = '';
        } catch (e) {
          if (e.response) {
            if (e.response.data === 'DuplicateUser') {
              this.$refs.emailValidation.setErrors(['The user is already a team member']);
            } else if (e.response.data === 'SystemUser') {
              this.$refs.emailValidation.setErrors(['The user can not be added to this team']);
            }
          }
        }
      }
    },
    async getData() {
      const resp = await accounts.getUsers(this.getActiveAccount.id);
      this.activeUsers = resp.data;
    },
    showDeleteUserModal(user) {
      this.deleteUser = user;
      this.deleteDialog = true;
    },
    async deleteUserConfirm() {
      await accounts.deleteUser(this.getActiveAccount.id, this.deleteUser.id);
      this.loading = true;
      await this.getData();
      this.loading = false;
      this.deleteDialog = false;
    },
    async resendInvite(user) {
      this.loading = true;
      await accounts.resendInvite(this.getActiveAccount.id, user.id);
      this.loading = false;
      this.resendSuccess = true;
    },
    formatCreated(value) {
      return new Date(value).toLocaleDateString('en-us', { month: 'long', day: 'numeric', year: 'numeric' });
    },
    async accessLevelChange(user) {
      await accounts.updateUserAccessLevel(this.getActiveAccount.id, user.id, user.accessLevel);
    },
    async impersonate(user) {
      await accounts.impersonate(this.getActiveAccount.id, user.id);
      window.location.replace('/');
    },
  },
};
</script>
