<template>
  <div>
    <!-- breadcrumbs -->
    <v-breadcrumbs
      class="px-3 py-1 py-md-0"
      :items="[
        {
          text: 'Reports',
          to: '/admin/reports'
        },
      ]
      "
    />
    <!-- header -->
    <v-container fluid>
      <v-row>
        <v-col
          cols="auto"
        >
          <h1 class="font-weight-medium">
            Reports
          </h1>
        </v-col>
        <v-col class="d-flex align-center justify-end">
          <v-tooltip left>
            <template #activator="{ on, attrs }">
              <v-btn
                fab
                icon
                x-small
                outlined
                :loading="refreshing"
                v-bind="attrs"
                v-on="on"
                @click.prevent="onRefresh"
              >
                <v-icon>
                  mdi-refresh
                </v-icon>
              </v-btn>
            </template>
            Refresh
          </v-tooltip>
        </v-col>
      </v-row>
    </v-container>

    <!-- filters -->
    <v-container fluid>
      <v-row>
        <v-col
          cols="12"
          sm="6"
          lg="5"
          xl="4"
          class="d-flex align-start"
        >
          <template v-if="verticals.length === 1">
            <h2 class="text-h6 font-weight-regular">
              {{ verticals[0].name }}
            </h2>
          </template>
          <template v-else>
            <v-select
              v-model="vertical"
              :items="verticals"
              item-text="name"
              item-value="id"
              label="Vertical"
              hide-details
              outlined
              dense
            />
          </template>
        </v-col>
        <v-spacer />
        <v-col
          cols="12"
          sm="6"
          lg="5"
          xl="4"
          class="d-flex align-center justify-end"
        >
          <v-text-field
            v-model="search"
            label="Search..."
            dense
            outlined
            clearable
            hide-details
            solo
            flat
          >
            <template #prepend-inner>
              <v-icon
                class="mr-2"
                small
              >
                fal fa-search
              </v-icon>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
    </v-container>

    <!-- data table -->
    <v-container fluid>
      <v-sheet
        outlined
      >
        <v-data-table
          id="admin-reports-table"
          :loading="loading || refreshing"
          :search="search"
          :headers="headers"
          :items="reportsByVertical"
          disable-pagination
          hide-default-footer
          @click:row="(report) => $router.push(`/admin/reports/${report.id}/edit`)"
        >
          <template #[`item.guide`]="{ item }">
            <div class="d-flex justify-center">
              <v-tooltip
                v-if="item.guide"
                top
              >
                <template #activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-check
                  </v-icon>
                </template>
                <span>Has Guide</span>
              </v-tooltip>
              <v-tooltip
                v-if="!item.guide"
                top
              >
                <template #activator="{ on, attrs }">
                  <v-icon

                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-exclamation
                  </v-icon>
                </template>
                <span>No Guide</span>
              </v-tooltip>
            </div>
          </template>
          <template #[`item.actions`]="{ item }">
            <div class="d-flex justify-end">
              <v-tooltip left>
                <template #activator="{ on, attrs }">
                  <v-btn
                    fab
                    text
                    right
                    :to="`/admin/reports/${item.id}/edit`"
                    color="blue-grey darken-3"
                    x-small
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>
                      edit
                    </v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>
            </div>
          </template>
        </v-data-table>
      </v-sheet>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { createHashMapBy } from '@/utils/arr';
import api from '../../_api/reports';

export default {
  name: 'ReportsIndex',
  data() {
    return {
      search: '',
      vertical: null,
      history: [],
      loading: true,
      refreshing: false,
      headers: [
        {
          text: 'Title',
          value: 'title',
          sortable: true,
        },
        {
          text: 'Category',
          value: 'category',
          sortable: true,
        },
        {
          text: 'Type',
          value: 'accountType',
          sortable: true,
        },
        {
          text: 'Path',
          value: 'pathName',
          sortable: true,
        },
        {
          text: 'Guide',
          value: 'guide',
          sortable: true,
          align: 'center',
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          align: 'right',
        },
      ],
    };
  },
  computed: {
    ...mapState('admin/reports', ['reports']),
    ...mapGetters('admin/reports', ['getReportsByVerticalId']),
    ...mapState('admin/verticals', ['verticals']),
    reportsByVertical() {
      return this.getReportsByVerticalId(this.vertical);
    },
  },
  watch: {
    vertical: {
      immediate: true,
      async handler(verticalId) {
        if (verticalId && !this.history.includes(verticalId)) {
          this.loading = true;
          await this.$store.dispatch('admin/reports/get', { verticalId });

          this.history.push(verticalId);
          this.$nextTick(() => {
            this.loading = false;
          });
        }
      },
    },
  },
  async created() {
    try {
      await this.$store.dispatch('admin/verticals/init');

      const verticals = createHashMapBy(this.verticals, 'id');
      const vertical = (
        verticals[this.$route.query.verticalId]
        || verticals[this.$store.state.user.activeAccount.verticalId]
      );

      await this.$store.dispatch('admin/reports/init', { verticalId: vertical.id });

      this.history = [vertical.id];
      this.vertical = vertical.id;
    } catch (e) {
      // In the event of a page error redirect and hope it works next time.
      this.$router.push('/');
    }

    this.$nextTick(() => {
      this.loading = false;
    });
  },
  methods: {
    async onRefresh() {
      this.refreshing = true;
      const params = { verticalId: this.vertical };

      api.getReports.cache.delete(JSON.stringify(params));
      await this.$store.dispatch('admin/reports/get', params);

      this.history = [this.vertical];

      this.$nextTick(() => {
        this.refreshing = false;
      });
    },
  },
};
</script>

<style lang="css" scoped>
  #admin-reports-table >>> tr {
    cursor: pointer;
  }

  #admin-reports-table >>> thead {
    background-color: #fafafa;
  }
</style>
