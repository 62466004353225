<template>
  <Wrapper>
    <div class="title">
      Forgot your password?
    </div>
    <div class="caption">
      Enter your email address to reset your password. You may need to check your spam
      folder or unblock no-reply@upperquadrant.com.
    </div>
    <ValidationObserver ref="observer">
      <v-form
        class="mt-3"
        @submit.prevent="reset"
      >
        <ValidationProvider
          ref="emailValidation"
          v-slot="{ errors }"
          rules="required|email"
          name="Email"
        >
          <v-text-field
            v-model="email"
            label="Email"
            :error-messages="errors"
            required
          />
        </ValidationProvider>
        <v-btn
          block
          :disabled="showAlert"
          color="primary"
          type="submit"
          class="mt-2"
        >
          Submit
        </v-btn>
        <div class="text-center mt-3">
          <v-btn
            text
            small
            color="primary"
            to="login"
          >
            Return to login
          </v-btn>
        </div>
      </v-form>
    </ValidationObserver>
    <template #footer>
      <v-alert
        v-show="showAlert"
        type="success"
        class="mt-3"
      >
        If your email exists in our system you will receive a password reset link at your email
        address in a few minutes.
      </v-alert>
    </template>
  </Wrapper>
</template>

<script>
import auth from '@/api/auth';
import Wrapper from '../_components/Wrapper';

export default {
  name: 'AuthResetPassword',
  components: { Wrapper },
  data() {
    return {
      showAlert: false,
      email: '',
    };
  },
  methods: {
    async reset() {
      const { email } = this;
      const isValid = await this.$refs.observer.validate();

      if (isValid) {
        await auth.reset({ email });
        this.showAlert = true;
      }
    },
  },
};
</script>
