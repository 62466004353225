import axios from '@/utils/axios';

const getSetting = (id, typeId) => axios.get(`/accounts/settings/${id}/${typeId}`);
const saveSetting = (id, typeId, value) => axios.post(`/accounts/settings/${id}`, {
  typeId,
  value,
});
const getRetailerDataPermissions = (accountId) => axios.get(`/accounts/settings/retailerDataPermissions/${accountId}`);

export default {
  getSetting,
  saveSetting,
  getRetailerDataPermissions,
};
