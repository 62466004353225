import axios from '@/utils/axios';

const preview = (url, storeId) => axios.get('/sources/runit/preview', {
  params: {
    url,
    storeId,
  },
});

export default {
  preview,
};
