<!-- eslint-disable vue/no-v-html -->
<template>
  <v-container fluid>
    <v-overlay :value="overlay">
      <v-progress-circular
        :size="70"
        :width="7"
        indeterminate
      />
    </v-overlay>
    <v-alert
      v-if="getActiveAccount.isManagedAccount"
      type="info"
      color="primary"
    >
      Data permissions are controlled by your parent account {{ getActiveAccount.parentName }}.
      You can view the current settings below but all changes are controlled by
      {{ getActiveAccount.parentName }}.
    </v-alert>
    <v-row>
      <v-col>
        <h2>
          Data Permissions
        </h2>
      </v-col>
    </v-row>
    <template v-for="item in pendingBrands">
      <v-row :key="item.accountId">
        <v-col
          cols="12"
          lg="10"
          xl="6"
        >
          <v-alert
            border="top"
            colored-border
            type="info"
            elevation="2"
          >
            <v-row dense>
              <v-col>
                You have received an invite from {{ item.name }} to share your data. Do you want to
                allow this?
              </v-col>
            </v-row>
            <v-row dense>
              <v-col>
                <v-btn
                  color="primary"
                  class="mr-2"
                  @click="approve(item)"
                >
                  Approve
                </v-btn>
                <v-btn
                  @click="deny(item)"
                >
                  Deny
                </v-btn>
              </v-col>
            </v-row>
          </v-alert>
        </v-col>
      </v-row>
    </template>

    <v-tabs>
      <v-tab>Brands</v-tab>
      <v-tab>Industry</v-tab>
      <v-tab-item
        :transition="false"
        :reverse-transition="false"
      >
        <v-row class="mt-2">
          <v-col
            cols="12"
            lg="10"
            xl="6"
          >
            Brands you connect with can view sell-through, inventory, returns, and co-op only for
            their brand. Brands can view marketshare for all brands.
            <a
              href="https://uqcadence.zendesk.com/hc/en-us/articles/4403903110541-Data-Creed"
              target="_blank"
            >
              Read the data creed to learn more.
            </a>
          </v-col>
        </v-row>
        <v-row
          dense
          style="height:40px;"
        >
          <v-col cols="12">
            <v-btn
              v-show="selected.length > 0"
              color="primary"
              text
              @click="addConnections"
            >
              Connect Selected
            </v-btn>
            <v-btn
              v-show="selected.length > 0"
              color="primary"
              text
              class="ml-4"
              @click="removeConnections"
            >
              Disconnect Selected
            </v-btn>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col
            cols="12"
            lg="10"
            xl="6"
          >
            <v-data-table
              v-model="selected"
              :headers="headers"
              :items-per-page="10"
              :items="brands"
              :expanded.sync="expanded"
              class="elevation-1 ma-1"
              :show-select="!getActiveAccount.isManagedAccount"
              show-expand
              item-key="accountId"
              single-expand
              @item-expanded="itemExpanded"
            >
              <template #[`item.connected`]="{ item }">
                <v-switch
                  v-model="item.connected"
                  inset
                  :disabled="getActiveAccount.isManagedAccount"
                  @change="changeConnectionStatus(item)"
                />
              </template>
              <template #[`item.data-table-expand`]="{isExpanded, expand}">
                <v-icon>far fa-regular fa-clock</v-icon>
                <v-btn
                  v-show="!isExpanded"
                  fab
                  plain
                  text
                  small
                  @click="expand(true)"
                >
                  <v-icon>expand_more</v-icon>
                </v-btn>
                <v-btn
                  v-if="isExpanded"
                  fab
                  plain
                  text
                  small
                  @click="expand(false)"
                >
                  <v-icon>expand_less</v-icon>
                </v-btn>
              </template>
              <template #expanded-item="{item}">
                <td :colspan="headers.length + 1">
                  <v-data-table
                    :headers="historyHeaders"
                    :items-per-page="10"
                    :items="item.history"
                    :hide-default-footer="true"
                    class="ma-2"
                  >
                    <template #[`item.createdAt`]="{ item: historyItem }">
                      {{
                        new Intl.DateTimeFormat('en-us', { dateStyle: 'long', timeStyle: 'medium' })
                          .format(new Date(historyItem.createdAt))
                      }}
                    </template>
                  </v-data-table>
                </td>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item
        :transition="false"
        :reverse-transition="false"
      >
        <template v-for="(item, i) in dataPermissions">
          <span :key="i">
            <v-row
              v-if="i > 0"
              dense
            >
              <v-col
                cols="12"
                lg="10"
                xl="6"
              >
                <hr class="mt-4 mb-4">
              </v-col>
            </v-row>
            <v-row
              :class="i === 0 ? 'mt-2' : ''"
              :dense="i > 0"
            >
              <v-col
                cols="12"
                lg="10"
                xl="6"
                class="d-flex"
              >
                <div class="float-left fill-height">
                  <v-switch
                    v-model="item.value"
                    class="ml-3"
                    inset
                    :disabled="getActiveAccount.isManagedAccount"
                    @change="changeSetting(item)"
                  />
                </div>
                <div>
                  <h3>
                    {{ item.title }}
                  </h3>
                  <div
                    class="text-body-2 mt-2"
                    v-html="item.description"
                  />
                </div>
              </v-col>
            </v-row>
          </span>
        </template>
      </v-tab-item>
    </v-tabs>

    <v-snackbar
      v-model="snackbar"
      :top="true"
      color="primary"
      timeout="5000"
    >
      {{ snackbarMessage }}
      <template #action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import connections from '../_api/connections';
import settings from '../_api/settings';

export default {
  name: 'BrandConnect',
  data() {
    return {
      overlay: true,
      snackbar: false,
      snackbarMessage: '',
      selected: [],
      expanded: [],
      brands: [],
      dataPermissions: [],
      headers: [
        {
          text: 'Brand',
          value: 'name',
        },
        {
          text: 'Connected',
          value: 'connected',
        },
        {
          width: '20%',
          text: 'History',
          value: 'data-table-expand',
        },
      ],
      historyHeaders: [
        {
          text: 'Activity',
          value: 'activity',
          sortable: false,
        },
        {
          text: 'Changed By',
          value: 'name',
          sortable: false,
        },
        {
          text: 'Date/Time',
          value: 'createdAt',
          sortable: true,
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      'getActiveAccount',
    ]),
    pendingBrands() {
      if (this.getActiveAccount.isManagedAccount) {
        return [];
      }
      return this.brands.filter((x) => x.status === 'Pending');
    },
  },
  async created() {
    const result = await Promise.all([
      settings.getRetailerDataPermissions(this.getActiveAccount.id),
      this.getConnectionData(),
      this.$store.dispatch('USER_GET_ACCOUNT', this.getActiveAccount.id),
    ]);
    this.dataPermissions = result[0].data;

    const settingValues = [];
    this.dataPermissions.forEach((perm) => {
      settingValues.push(settings.getSetting(this.getActiveAccount.id, perm.typeId));
    });

    const savedValues = await Promise.all(settingValues);
    savedValues.forEach((sv, i) => {
      this.dataPermissions[i].value = sv.data.value === 'true';
    });

    this.overlay = false;
  },
  methods: {
    async getConnectionData() {
      const resp = await connections.getRetailConnections(this.getActiveAccount.id);
      this.expanded = [];
      this.brands = resp.data.map((x) => ({
        ...x,
        connected: x.status === 'Approved',
        history: [],
        historyLoaded: false,
      }));
    },
    async approve(item) {
      await connections.addRetailerConnections(this.getActiveAccount.id, [item.accountId]);
      this.getConnectionData();
    },
    async deny(item) {
      await connections.removeRetailerConnections(this.getActiveAccount.id, [item.accountId]);
      this.getConnectionData();
    },
    async addConnections() {
      if (this.selected.length > 0) {
        let list = '<ul>';
        list += this.selected.map((account) => `<li>${account.name}</li>`).join('');
        list += '</ul>';
        if (await this.$confirm(`Are you sure you want to add the following brands?${list}`)) {
          const accounts = this.selected.map((account) => account.accountId);
          await connections.addRetailerConnections(this.getActiveAccount.id, accounts);
          this.selected = [];
          this.snackbarMessage = 'Access Added Successfully';
          this.snackbar = true;
          this.getConnectionData();
        }
      }
    },
    async removeConnections() {
      if (this.selected.length > 0) {
        let list = '<ul>';
        list += this.selected.map((account) => `<li>${account.name}</li>`).join('');
        list += '</ul>';
        if (await this.$confirm(`Are you sure you want to remove the following brands?${list}`)) {
          const accounts = this.selected.map((account) => account.accountId);
          await connections.removeRetailerConnections(this.getActiveAccount.id, accounts);
          this.selected = [];
          this.snackbarMessage = 'Access Removed Successfully';
          this.snackbar = true;
          this.getConnectionData();
        }
      }
    },
    async itemExpanded(data) {
      const { item } = data;
      if (item.historyLoaded === false) {
        const resp = await connections.getHistory(this.getActiveAccount.id, item.accountId);
        item.history = resp.data.map((x) => {
          let name;
          if (x.isSystemUser) {
            name = 'UQ Cadence Team';
          } else if (x.source === 'Retailer') {
            name = `${x.firstName} ${x.lastName}`;
          } else {
            ({ name } = item);
          }
          let activity;
          switch (x.status) {
            case 'Approved':
              activity = 'Approved Access';
              break;

            case 'Removed':
              activity = 'Removed Access';
              break;

            case 'Declined':
              activity = 'Declined Access';
              break;

            default:
              break;
          }
          return {
            activity,
            name,
            createdAt: x.createdAt,
          };
        });
        item.historyLoaded = true;
      }
    },
    async changeSetting(item) {
      await settings.saveSetting(this.getActiveAccount.id, item.typeId, item.value.toString());
    },
    async changeConnectionStatus(item) {
      if (item.connected === true) {
        await this.approve(item);
      } else {
        await this.deny(item);
      }
    },
  },
};
</script>
