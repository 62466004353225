import { render, staticRenderFns } from "./Heartland.vue?vue&type=template&id=34a04e94"
import script from "./Heartland.vue?vue&type=script&lang=js"
export * from "./Heartland.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports