<template>
  <div style="height:100%;">
    <router-view :key="$route.fullPath" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import report from '../_stores/report';

export default {
  name: 'ReportLayout',
  computed: {
    ...mapState('page/report', ['workbook']),
  },
  beforeCreate() {
    if (!this.$store.hasModule(['page', 'report'])) {
      this.$store.registerModule(['page', 'report'], report);
    }
  },
  async created() {
    try {
      await this.$store.dispatch('page/report/init', this.$route.params);
    } catch (e) {
      this.$router.push('/');
    }

    document.scrollingElement.classList.add('overflow-y-hidden-enforce');
  },
  destroyed() {
    this.$store.dispatch('page/report/reset');

    document.scrollingElement.classList.remove('overflow-y-hidden-enforce');
  },
};
</script>
