<template>
  <v-card>
    <v-card-title
      primary-title
      class="primary white--text"
    >
      <span class="title">Destinations</span>
      <v-spacer />
      <v-btn
        fab
        small
        class="mb-2"
        @click="addDestination"
      >
        <v-icon dark>
          add
        </v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-list two-line>
        <v-list-item
          v-for="item in destinations"
          :key="item.id"
        >
          <v-list-item-action>
            <v-btn
              fab
              text
              :to="'/connectors/destinations/edit/' + item.id"
              class="mr-2"
            >
              <v-icon>
                edit
              </v-icon>
            </v-btn>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ item.name }}</v-list-item-title>
            <v-list-item-subtitle class="text--primary">
              Type: {{ item.destinationTypeName }}
            </v-list-item-subtitle>
            <v-list-item-subtitle>
              connected to
              <span v-if="item.sourceCount === 1">
                1 source
              </span>
              <span v-else>
                {{ item.sourceCount }} sources
              </span>
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn
              fab
              text
              :disabled="item.sourceCount !== 0"
              @click="deleteDestination(item)"
            >
              <v-icon>
                delete
              </v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import destinations from '../_api/destinations';

export default {
  name: 'DestinationList',
  computed: {
    ...mapGetters([
      'destinations',
      'getActiveAccount',
    ]),
  },
  methods: {
    async deleteDestination(destination) {
      if (await this.$confirm(`Are you sure you want to delete destination ${destination.name}?`)) {
        await destinations.deleteDestination(this.getActiveAccount.id, destination.id);
        this.$store.dispatch('USER_GET_ACCOUNT', this.getActiveAccount.id);
        this.$store.dispatch('DESTINATIONS_LOAD', this.getActiveAccount.id);
      }
    },
    async addDestination() {
      if (await this.$confirm(`Are you sure you want to add a destination for account <strong>${this.getActiveAccount.name}</strong>?`)) {
        this.$router.push('/connectors/destinations/create');
      }
    },
  },
};
</script>
