<template>
  <span>
    <template v-for="(item, i) in navItems">
      <NavItem
        :key="i"
        :item="item"
      />
    </template>
  </span>
</template>

<script>
import NavItem from './NavItem';

export default {
  name: 'NavList',
  components: {
    NavItem,
  },
  props: {
    navItems: {
      type: Array,
      required: false,
      default: null,
    },
  },
};
</script>

<style>

</style>
