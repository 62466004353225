var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-expand-x-transition',[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-sheet',{directives:[{name:"show",rawName:"v-show",value:(_vm.value),expression:"value"}],ref:"guideWrapper",class:['ql-wrapper', _vm.expanded ? 'ql-expanded' : ''],style:({
        maxWidth: _vm.$vuetify.breakpoint.smAndDown ? '100%' : '',
        position: _vm.$vuetify.breakpoint.smAndDown ? 'absolute' : '',
        right: _vm.$vuetify.breakpoint.smAndDown ? '0' : ''
      }),attrs:{"elevation":_vm.$vuetify.breakpoint.smAndDown ? 24 : 0}},[_c('v-fade-transition',{attrs:{"appear":""}},[(!_vm.$vuetify.breakpoint.smAndDown && (_vm.expanded || hover))?_c('v-btn',{staticStyle:{"top":"8px","right":"24px"},attrs:{"fab":"","icon":"","small":"","right":"","absolute":""},on:{"click":function($event){_vm.expanded = !_vm.expanded}}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.expanded ? 'mdi-arrow-collapse' : 'mdi-arrow-expand')+" ")])],1):_vm._e()],1),_c('div',{staticClass:"pl-3 pt-3 pr-2 pb-5",style:([
          'height: calc(100vh - 132px)',
          'overflow-y: scroll',
          'border-left: 1px solid rgba(0,0,0,.12)'
        ].join(';'))},[_c('div',{staticStyle:{"max-width":"700px","margin":"0 auto"},domProps:{"innerHTML":_vm._s(_vm.guide)}})])],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }