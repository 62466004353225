<template>
  <div>
    <v-row>
      <v-col>
        <h1 class="subheading grey--text">
          Retailers
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="6"
        lg="4"
      >
        <v-text-field
          v-model="filter"
          label="Search Retailers"
          clearable
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        lg="8"
        xl="6"
      >
        <v-tabs v-model="tab">
          <v-tab>Active ({{ activeRetailers.length }}) </v-tab>
          <v-tab>Pending ({{ pendingRetailers.length }})</v-tab>
          <v-tab>Available ({{ availableRetailers.length }})</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item
            :transition="false"
            :reverse-transition="false"
          >
            <active-retailers
              :retailers="activeRetailers"
              @DataChange="activeDataChange"
            />
          </v-tab-item>
          <v-tab-item
            :transition="false"
            :reverse-transition="false"
          >
            <pending-retailers
              :retailers="pendingRetailers"
              @DataChange="pendingDataChange"
            />
          </v-tab-item>
          <v-tab-item
            :transition="false"
            :reverse-transition="false"
          >
            <available-retailers
              :retailers="availableRetailers"
              @DataChange="availableDataChange"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
    <v-snackbar
      v-model="snackbar"
      :top="true"
      color="primary"
      timeout="5000"
    >
      {{ snackbarMessage }}
      <template #action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import connections from '../_api/connections';
import ActiveRetailers from '../_components/ActiveRetailers';
import PendingRetailers from '../_components/PendingRetailers';
import AvailableRetailers from '../_components/AvailableRetailers';

export default {
  name: 'RetailConnect',
  components: {
    ActiveRetailers,
    PendingRetailers,
    AvailableRetailers,
  },
  data() {
    return {
      tab: null,
      retailers: [],
      filter: null,
      snackbar: false,
      snackbarMessage: '',
    };
  },
  computed: {
    ...mapGetters([
      'getActiveAccount',
    ]),
    filteredRetailers() {
      if (this.filter) {
        return this.retailers.filter((x) => (x.name.toLowerCase()
          .includes(this.filter.toLowerCase())));
      }
      return this.retailers;
    },
    activeRetailers() {
      return this.filteredRetailers.filter((x) => x.status === 'Approved');
    },
    pendingRetailers() {
      return this.filteredRetailers.filter((x) => x.status === 'Pending');
    },
    availableRetailers() {
      return this.filteredRetailers.filter((x) => x.status !== 'Approved' && x.status !== 'Pending');
    },
  },
  async mounted() {
    await this.getData();
  },
  methods: {
    async getData() {
      const resp = await connections.getBrandConnections(this.getActiveAccount.id);
      this.retailers = resp.data;
    },
    activeDataChange() {
      this.snackbarMessage = 'Access Removed Successfully';
      this.snackbar = true;
      this.getData();
    },
    pendingDataChange(eventType) {
      switch (eventType) {
        case 'delete':
          this.snackbarMessage = 'Access Removed Successfully';
          this.snackbar = true;
          this.getData();
          break;

        case 'resend':
          this.snackbarMessage = 'Access Request Resent Successfully';
          this.snackbar = true;
          break;

        default:
          break;
      }
    },
    availableDataChange() {
      this.snackbarMessage = 'Retail Request Sent';
      this.snackbar = true;
      this.getData();
    },
  },
};
</script>
