<template>
  <v-card>
    <v-card-title
      primary-title
    >
      <span class="title">Edit Location</span>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col
          cols="12"
          md="6"
          lg="4"
        >
          <v-text-field
            v-model="storeId"
            label="Store Id"
            disabled
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
          lg="4"
        >
          <v-text-field
            v-model.trim="name"
            label="Store Name"
            maxlength="200"
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
          lg="4"
        >
          <v-text-field
            v-model.trim="street1"
            label="Street 1"
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
          lg="4"
        >
          <v-text-field
            v-model.trim="street2"
            label="Street 2"
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
          lg="4"
        >
          <v-text-field
            v-model.trim="city"
            label="City"
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
          lg="4"
        >
          <v-text-field
            v-model.trim="state"
            label="State"
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
          lg="4"
        >
          <v-text-field
            v-model.trim="zip"
            label="Zip"
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
          lg="4"
        >
          <v-select
            v-model="locationType"
            label="Location Type"
            :items="locationTypes"
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
          lg="4"
        >
          <v-select
            v-model="entityType"
            label="Entity Type"
            :items="entityTypes"
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
          lg="4"
        >
          <v-select
            v-model="marketType"
            label="Market Type"
            :items="marketTypes"
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
          lg="4"
        >
          <v-select
            v-model="status"
            label="Status"
            :items="statuses"
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
          lg="4"
        >
          <v-menu
            v-model="openDateMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template #activator="{ on }">
              <v-text-field
                v-model="openDate"
                label="Open Date"
                prepend-icon="event"
                readonly
                clearable
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="openDate"
              no-title
              min="1990-01-01"
              @input="openDateMenu = false"
            />
          </v-menu>
        </v-col>
        <v-col
          cols="12"
          md="6"
          lg="4"
        >
          <v-menu
            v-model="closeDateMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template #activator="{ on }">
              <v-text-field
                v-model="closeDate"
                label="Close Date"
                prepend-icon="event"
                readonly
                clearable
                v-on="on"
              />
            </template>
            <v-date-picker
              v-model="closeDate"
              no-title
              min="1990-01-01"
              @input="openDateMenu = false"
            />
          </v-menu>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-btn
            color="primary"
            @click="save"
          >
            Save
          </v-btn>
          <v-btn
            class="ml-3"
            to="/settings/locations"
          >
            Cancel
          </v-btn>
        </v-col>
      </v-row>
      <v-overlay :value="overlay">
        <v-progress-circular
          :size="70"
          :width="7"
          indeterminate
        />
      </v-overlay>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import locations from '../_api/locations';

export default {
  name: 'EditLocation',
  data() {
    return {
      overlay: true,
      locationTypes: [
        'Events (Expos)',
        'Online Store',
        'Store',
        'Third-Party',
        'Warehouse',
      ],
      entityTypes: [
        'Franchise - Corp',
        'Franchise - Franchisee',
        'Independent',
      ],
      marketTypes: [
        'Rural',
        'Suburban',
        'Urban',
      ],
      statuses: [
        'Open',
        'Closed',
      ],
      openDateMenu: false,
      closeDateMenu: false,
      storeId: null,
      name: null,
      street1: null,
      street2: null,
      city: null,
      state: null,
      zip: null,
      status: null,
      entityType: null,
      marketType: null,
      openDate: null,
      closeDate: null,
      locationType: null,
    };
  },
  computed: {
    ...mapGetters([
      'getActiveAccount',
    ]),
  },
  async mounted() {
    const result = await locations.getLocation(
      this.getActiveAccount.id,
      this.$route.params.accountSourceId,
      this.$route.params.storeId,
    );
    this.storeId = this.$route.params.storeId;
    this.name = result.data.name;
    this.street1 = result.data.street1;
    this.street2 = result.data.street2;
    this.city = result.data.city;
    this.state = result.data.state;
    this.zip = result.data.zip;
    this.status = result.data.status;
    this.entityType = result.data.entityType;
    this.marketType = result.data.marketType;
    this.openDate = result.data.openDate;
    this.closeDate = result.data.closeDate;
    this.locationType = result.data.locationType;
    this.overlay = false;
  },
  methods: {
    async save() {
      try {
        this.overlay = true;
        await locations.updateLocation(
          this.getActiveAccount.id,
          this.$route.params.accountSourceId,
          this.$route.params.storeId,
          this.name,
          this.street1,
          this.street2,
          this.city,
          this.state,
          this.zip,
          this.status,
          this.entityType,
          this.marketType,
          this.openDate,
          this.closeDate,
          this.locationType,
        );
        this.overlay = false;
        this.$router.push('/settings/locations');
      } catch (e) {
        // todo: something
      }
    },
  },
};
</script>
