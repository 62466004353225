<template>
  <v-container
    fluid
    class="pb-16"
  >
    <v-row justify="center">
      <v-col
        cols="12"
        class="pl-sm-10 pr-sm-10"
      >
        <v-row>
          <v-col
            cols="12"
            lg="6"
          >
            <SourceList />
          </v-col>
          <v-col
            cols="12"
            lg="6"
          >
            <DestinationList />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col
        cols="12"
        lg="10"
        xl="6"
      >
        <v-alert
          border="top"
          colored-border
          type="error"
          elevation="2"
        >
          <v-row dense>
            <v-col>
              Clicking the button below will allow you to delete all data stored in Cadence for this
              account. Before doing so make sure that you have disabled all sources or removed
              Cadence as a destination. If you click the delete data button immediately after
              disabling the sources there is still a chance that data will be in the system as it
              could be processing currently. The deletion job will run in the background and should
              complete within 30 minutes.
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-btn
                color="error"
                @click="deleteData"
              >
                Delete Data
              </v-btn>
            </v-col>
          </v-row>
        </v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import DestinationList from '../_components/DestinationList';
import SourceList from '../_components/SourceList';
import sources from '../_api/sources';

export default {
  name: 'DashboardIndex',
  components: {
    DestinationList,
    SourceList,
  },
  computed: {
    ...mapGetters([
      'getActiveAccount',
    ]),
  },
  watch: {
    getActiveAccount(val) {
      this.$store.dispatch('DESTINATIONS_LOAD', val.id);
    },
  },
  created() {
    if (this.$store.getters.destinations.length === 0) {
      this.$store.dispatch('DESTINATIONS_LOAD', this.getActiveAccount.id);
    }
  },
  methods: {
    async deleteData() {
      if (await this.$confirm(`Are you sure you want to delete all Cadence data for ${this.getActiveAccount.name}?`)) {
        await sources.deleteData(this.getActiveAccount.id);
      }
    },
  },
};
</script>
