import ComponentWrapper from '@/views/ComponentWrapper';
import * as views from './_views';

export default [
  {
    path: 'connectors',
    name: 'Connectors',
    component: views.Dashboard,
    meta: {
      requiresSystemAccess: true,
      requireConnectorsPermission: true,
    },
  },
  {
    path: 'connectors/destinations',
    component: ComponentWrapper,
    redirect: 'connectors/destinations/create',
    meta: {
      requiresSystemAccess: true,
      requireConnectorsPermission: true,
    },
    children: [
      {
        path: 'create',
        component: views.CreateDestination,
      },
      {
        path: 'edit/:id',
        component: views.EditDestination,
      },
    ],
  },
  {
    path: 'connectors/sources',
    component: ComponentWrapper,
    redirect: 'connectors/sources/create',
    meta: {
      requiresSystemAccess: true,
      requireConnectorsPermission: true,
    },
    children: [
      {
        path: 'create',
        component: views.CreateSource,
      },
      {
        path: 'edit/:id',
        component: views.EditSource,
      },
    ],
  },
];
