import axios from '@/utils/axios';

const getLocations = (id) => axios.get(`/locations/${id}`);

const getLocation = (accountId, accountSourceId, storeId) => axios.get(`/locations/${accountId}/${accountSourceId}/${encodeURIComponent(storeId)}`);

const updateLocation = (accountId, accountSourceId, storeId, name, street1, street2, city, state, zip, status, entityType, marketType, openDate, closeDate, locationType) => axios.put(`/locations/${accountId}/${accountSourceId}/${encodeURIComponent(storeId)}`, {
  name,
  street1,
  street2,
  city,
  state,
  zip,
  status,
  entityType,
  marketType,
  openDate,
  closeDate,
  locationType,
});

export default {
  getLocations,
  getLocation,
  updateLocation,
};
