import Vue from 'vue';

/**
 * Resets all the data in state to some defaults.
 * This is not, currently, recursive.
 *
 * @param {*} state
 * @param {*} defaults
 */
export default function reset(state, defaults) {
  Object.keys(defaults).forEach((key) => {
    Vue.set(state, key, defaults[key]);
  });
}
