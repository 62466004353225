<template>
  <v-row>
    <ignore-web-orders
      ref="ignoreWebOrders"
      :ignore-web-orders="ignoreWebOrders"
    />
  </v-row>
</template>

<script>
import IgnoreWebOrders from '../Components/IgnoreWebOrders';

export default {
  name: 'CadenceLightspeedDestination',
  components: {
    IgnoreWebOrders,
  },
  props: {
    ignoreWebOrders: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    getDetail() {
      return this.$refs.ignoreWebOrders.getDetail();
    },
  },
};
</script>
