<template>
  <span>
    <v-row
      v-if="listFields.length > 0"
      class="mt-5"
    >
      <v-col
        v-if="sourceType === 1 || sourceType === 10"
        cols="12"
      >
        <v-divider />
      </v-col>
      <v-col cols="12">
        <div class="subtitle-2">
          When selected the below boolean fields will be marked as true in the customer profile when
          they are not already set.
        </div>
      </v-col>
      <v-col
        v-for="field in listFields"
        :key="field.field"
        cols="12"
        md="6"
        lg="4"
      >
        <v-checkbox
          v-model="field.selected"
          :label="field.field"
        />
      </v-col>
    </v-row>
    <v-row
      v-if="emailLists.length > 0"
      class="mt-5"
    >
      <v-col
        cols="12"
      >
        <v-divider />
      </v-col>
      <v-col cols="12">
        <div class="subtitle-2">
          When selected the below lists will have customer's email added to them provided they are
          not already on the list.
        </div>
      </v-col>
      <v-col
        v-for="list in emailLists"
        :key="list.value"
        cols="12"
        md="6"
        lg="4"
      >
        <v-checkbox
          v-model="list.selected"
          :label="list.name"
        />
      </v-col>
    </v-row>
  </span>
</template>

<script>
import vue from 'vue';
import destinations from '@/api/destinations';

export default {
  name: 'ZauisListsDestination',
  props: {
    sourceType: {
      type: Number,
      required: false,
      default: 0,
    },
    destinationDetail: {
      type: Object,
      required: false,
      default: null,
    },
    selectedLists: {
      type: Array,
      required: false,
      default: () => [],
    },
    selectedEmailLists: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      zaiusLists: [],
    };
  },
  computed: {
    listFields() {
      const data = [];
      if (this.destinationDetail.listFields) {
        this.destinationDetail.listFields.forEach((field) => {
          if (this.selectedLists.includes(field)) {
            data.push({
              selected: true,
              field,
            });
          } else {
            data.push({
              selected: false,
              field,
            });
          }
        });
      }
      return data;
    },
    emailLists() {
      const data = [];
      this.zaiusLists.forEach((list) => {
        if (this.selectedEmailLists.includes(list.list_id)) {
          data.push({
            selected: true,
            value: list.list_id,
            name: list.name,
          });
        } else {
          data.push({
            selected: false,
            value: list.list_id,
            name: list.name,
          });
        }
      });
      return data;
    },
  },
  async mounted() {
    const result = await destinations.getZaiusLists(this.destinationDetail.privateKey);
    vue.set(this, 'zaiusLists', result.data.lists);
  },
  methods: {
    getDetail() {
      const selectedLists = [];
      this.listFields.forEach((field) => {
        if (field.selected === true) {
          selectedLists.push(field.field);
        }
      });
      const selectedEmailLists = [];
      this.emailLists.forEach((list) => {
        if (list.selected === true) {
          selectedEmailLists.push(list.value);
        }
      });
      return {
        selectedLists,
        selectedEmailLists,
      };
    },
  },
};
</script>
