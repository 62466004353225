<template>
  <v-card>
    <v-card-title
      primary-title
      class="primary white--text"
    >
      <span class="title">Edit Source</span>
    </v-card-title>
    <v-card-text>
      <Source :id="$route.params.id" />
    </v-card-text>
  </v-card>
</template>

<script>
import Source from '../_components/Sources/Source';

export default {
  name: 'CreateSource',
  components: {
    Source,
  },
};
</script>
