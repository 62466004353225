<template>
  <div>
    <v-dialog
      v-model="imports.dialog"
      max-width="500"
    >
      <v-card
        class="mx-auto"
        tile
      >
        <v-card-title>
          Paste in data
        </v-card-title>
        <v-card-text>
          <ValidationProvider
            ref="importDataValidation"
            v-slot="{ errors }"
            name="Data"
          >
            <v-text-field
              v-model="imports.data"
              :error-messages="errors"
            />
          </ValidationProvider>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            @click="performImport"
          >
            Perform Import
          </v-btn>
          <v-btn
            class="ml-2"
            @click="imports.dialog = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="exports.dialog"
      max-width="500"
    >
      <v-card
        class="mx-auto"
        tile
      >
        <v-card-title>
          Copy below data
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="exports.data"
            readonly
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-overlay :value="overlay">
      <v-progress-circular
        :size="70"
        :width="7"
        indeterminate
      />
    </v-overlay>
    <ValidationObserver ref="observer">
      <v-row>
        <v-col cols="12">
          <v-btn
            color="primary"
            class="mt-2"
            @click="performExport"
          >
            Export
          </v-btn>
          <v-btn
            color="primary"
            class="mt-2 ml-2"
            @click="imports.dialog = true"
          >
            Import
          </v-btn>
        </v-col>
      </v-row>
      <v-form
        class="mt-3"
        @submit.prevent="save"
      >
        <v-row>
          <v-col
            cols="12"
            dense
          >
            <v-card
              class="mt-3"
              color="grey lighten-5"
            >
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <h3>Vertical Information</h3>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    lg="4"
                  >
                    <ValidationProvider
                      ref="idValidation"
                      v-slot="{ errors }"
                      rules="required"
                      name="Id"
                    >
                      <v-text-field
                        v-model.trim="verticalId"
                        label="verticalId"
                        :error-messages="errors"
                        :disabled="id !== ''"
                        dense
                      />
                    </ValidationProvider>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    lg="4"
                  >
                    <ValidationProvider
                      ref="nameValidation"
                      v-slot="{ errors }"
                      rules="required"
                      name="Name"
                    >
                      <v-text-field
                        v-model.trim="name"
                        label="Name"
                        :error-messages="errors"
                        dense
                      />
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            dense
          >
            <v-card
              class="mt-3"
              color="grey lighten-5"
            >
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <h3
                      style="cursor:pointer"
                      @click="pagesVisible = !pagesVisible"
                      @keypress.enter="pagesVisible = !pagesVisible"
                    >
                      Pages
                    </h3>
                  </v-col>
                </v-row>
                <v-row v-show="pagesVisible">
                  <v-col
                    cols="12"
                    md="6"
                    lg="4"
                  >
                    <v-checkbox
                      v-model="pages.retailerTagging"
                      label="Retailer Tagging"
                      dense
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    lg="4"
                  >
                    <v-checkbox
                      v-model="pages.globalTagging"
                      label="Global Retailer Tagging"
                      dense
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    lg="4"
                  >
                    <v-checkbox
                      v-model="pages.brandTagging"
                      label="Brand Tagging & Uploads"
                      dense
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    lg="4"
                  >
                    <v-checkbox
                      v-model="pages.brandMapping"
                      label="Global Brand Mapping"
                      dense
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    lg="4"
                  >
                    <v-checkbox
                      v-model="pages.retailerProductOverview"
                      label="Retailer Product Overview"
                      dense
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    lg="4"
                  >
                    <v-checkbox
                      v-model="pages.brandProductOverview"
                      label="Brand Product Overview"
                      dense
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    lg="4"
                  >
                    <v-checkbox
                      v-model="pages.accountConnections"
                      label="Retailer and Brand Account Connections"
                      dense
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    lg="4"
                  >
                    <v-checkbox
                      v-model="pages.locations"
                      label="Retailer Locations"
                      dense
                    />
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            dense
          >
            <v-card
              class="mt-3"
              color="grey lighten-5"
            >
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <h3
                      style="cursor:pointer"
                      @click="metadataVisible = !metadataVisible"
                      @keypress.enter="metadataVisible = !metadataVisible"
                    >
                      Metadata
                    </h3>
                  </v-col>
                </v-row>
                <v-row v-show="metadataVisible">
                  <v-col
                    cols="12"
                    md="6"
                    lg="4"
                  >
                    <v-textarea
                      v-model="metadata.retailerReportPermissions"
                      outlined
                      rows="4"
                      label="Account Type Retailer Report Permissions"
                      dense
                      hint="List of retailer report permissions with each value on it's own line"
                      persistent-hint
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    lg="4"
                  >
                    <v-textarea
                      v-model="metadata.brandReportPermissions"
                      outlined
                      rows="4"
                      label="Account Type Brand Report Permissions"
                      dense
                      hint="List of brand report permissions with each value on it's own line"
                      persistent-hint
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    lg="4"
                  >
                    <v-textarea
                      v-model="metadata.otherReportPermissions"
                      outlined
                      rows="4"
                      label="Account Type Other Report Permissions"
                      dense
                      hint="List of other report permissions with each value on it's own line"
                      persistent-hint
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    lg="4"
                  >
                    <v-textarea
                      v-model="metadata.retailerRollupReportPermissions"
                      outlined
                      rows="4"
                      label="Account Type Retailer Rollup Report Permissions"
                      dense
                      hint="List of other report permissions with each value on it's own line"
                      persistent-hint
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    lg="4"
                  >
                    <ValidationProvider
                      v-slot="{ errors }"
                      rules="json"
                      name="Retailer Data Permissions"
                    >
                      <!-- eslint-disable max-len -->
                      <v-textarea
                        v-model="metadata.retailerDataPermissions"
                        :error-messages="errors"
                        outlined
                        rows="10"
                        label="Retailer Data Permissions"
                        dense
                        hint="JSON that represents the fields shown in the retailer data permissions"
                        persistent-hint
                      />
                      <!-- eslint-enable max-len -->
                    </ValidationProvider>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    lg="4"
                  >
                    <ValidationProvider
                      v-slot="{ errors }"
                      rules="json"
                      name="Account Custom Fields"
                    >
                      <!-- eslint-disable max-len -->
                      <v-textarea
                        v-model="metadata.accountCustomFields"
                        :error-messages="errors"
                        outlined
                        rows="10"
                        label="Account Custom Fields"
                        dense
                        hint="JSON that represents the fields shown in the account creating/edit page"
                        persistent-hint
                      />
                      <!-- eslint-enable max-len -->
                    </ValidationProvider>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    lg="4"
                  >
                    <ValidationProvider
                      vid="reportingUserBacked"
                    >
                      <v-checkbox
                        v-model="metadata.reportingUserBacked"
                        label="Use User Backed Report Embedding"
                        hint="User Backed embedding requires a default team to be filled in"
                        persistent-hint
                      />
                    </ValidationProvider>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    lg="4"
                  >
                    <ValidationProvider
                      ref="reportTeamValidation"
                      v-slot="{ errors }"
                      rules="required_if:reportingUserBacked,true"
                      name="Reporting Sigma Team"
                    >
                      <v-text-field
                        v-model="metadata.reportingTeam"
                        label="Reporting Sigma Team"
                        hint="Create the team in Sigma with the appropriate permissions"
                        persistent-hint
                        :error-messages="errors"
                      />
                    </ValidationProvider>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            dense
          >
            <v-card
              class="mt-3"
              color="grey lighten-5"
            >
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <h3
                      style="cursor:pointer"
                      @click="taggingVisible = !taggingVisible"
                      @keypress.enter="taggingVisible = !taggingVisible"
                    >
                      Tagging
                    </h3>
                  </v-col>
                </v-row>
                <v-row v-show="taggingVisible">
                  <v-col
                    cols="12"
                    md="6"
                    lg="4"
                  >
                    <ValidationProvider
                      v-slot="{ errors }"
                      rules="json"
                      name="Brand Columns"
                    >
                      <v-textarea
                        v-model="tagging.brandColumns"
                        :error-messages="errors"
                        outlined
                        rows="10"
                        label="Brand Columns"
                        dense
                        hint="JSON that represents the columns shown in tagging overview page"
                        persistent-hint
                      />
                    </ValidationProvider>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    lg="4"
                  >
                    <ValidationProvider
                      v-slot="{ errors }"
                      rules="json"
                      name="Brand Preview"
                    >
                      <v-textarea
                        v-model="tagging.brandPreview"
                        :error-messages="errors"
                        outlined
                        rows="10"
                        label="Brand Preview"
                        dense
                        hint="JSON that represents the columns shown in tagging preview"
                        persistent-hint
                      />
                    </ValidationProvider>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    lg="4"
                  >
                    <ValidationProvider
                      v-slot="{ errors }"
                      rules="json"
                      name="Categories"
                    >
                      <v-textarea
                        v-model="tagging.categories"
                        :error-messages="errors"
                        outlined
                        rows="10"
                        label="Categories"
                        dense
                        hint="JSON that represents the category hierarchy"
                        persistent-hint
                      />
                    </ValidationProvider>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    lg="4"
                  >
                    <v-textarea
                      v-model="tagging.colors"
                      outlined
                      rows="10"
                      label="Colors"
                      dense
                      hint="List of colors used for color tagging with each value on it's own line"
                      persistent-hint
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    lg="4"
                  >
                    <ValidationProvider
                      v-slot="{ errors }"
                      rules="json"
                      name="Extension Fields"
                    >
                      <v-textarea
                        v-model="tagging.extensionFields"
                        :error-messages="errors"
                        outlined
                        rows="10"
                        label="Extension Fields"
                        dense
                        hint="JSON that represents the extension fields 1 -5 showing in tagging"
                        persistent-hint
                      />
                    </ValidationProvider>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    lg="4"
                  >
                    <!-- eslint-disable max-len -->
                    <v-textarea
                      v-model="tagging.genders"
                      outlined
                      rows="10"
                      label="Genders"
                      dense
                      hint="List of genders used for gender tagging with each value on it's own line"
                      persistent-hint
                    />
                    <!-- eslint-enable max-len -->
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    lg="4"
                  >
                    <ValidationProvider
                      v-slot="{ errors }"
                      rules="json"
                      name="Retailer Columns"
                    >
                      <v-textarea
                        v-model="tagging.retailerColumns"
                        :error-messages="errors"
                        outlined
                        rows="10"
                        label="Retailer Columns"
                        dense
                        hint="JSON that represents the columns shown in tagging overview page"
                        persistent-hint
                      />
                    </ValidationProvider>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    lg="4"
                  >
                    <ValidationProvider
                      v-slot="{ errors }"
                      rules="json"
                      name="Retailer Preview"
                    >
                      <v-textarea
                        v-model="tagging.retailerPreview"
                        :error-messages="errors"
                        outlined
                        rows="10"
                        label="Retailer Preview"
                        dense
                        hint="JSON that represents the columns shown in tagging preview"
                        persistent-hint
                      />
                    </ValidationProvider>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    lg="4"
                  >
                    <h4>Standard Columns</h4>
                    <div>
                      <v-checkbox
                        v-model="tagging.standardColumns.gender"
                        label="Gender"
                        dense
                      />
                    </div>
                    <div>
                      <v-checkbox
                        v-model="tagging.standardColumns.family"
                        label="Family"
                        dense
                      />
                    </div>
                    <div>
                      <v-checkbox
                        v-model="tagging.standardColumns.color"
                        label="Color"
                        dense
                      />
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-btn
              type="submit"
              color="primary"
              class="mt-2"
            >
              Save
            </v-btn>
            <v-btn
              type="submit"
              class="mt-2 ml-2"
              to="/admin/verticalManagement"
            >
              Cancel
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </ValidationObserver>
  </div>
</template>

<script>
import verticals from '@/api/verticals';

export default {
  name: 'AdminVertical',
  props: {
    id: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      imports: {
        data: null,
        dialog: false,
      },
      exports: {
        data: null,
        dialog: false,
      },
      pagesVisible: true,
      metadataVisible: true,
      taggingVisible: true,
      overlay: false,
      verticalId: null,
      name: null,
      pages: {
        retailerTagging: false,
        globalTagging: false,
        brandTagging: false,
        brandMapping: false,
        retailerProductOverview: false,
        brandProductOverview: false,
        accountConnections: false,
        locations: false,
      },
      metadata: {
        retailerReportPermissions: null,
        brandReportPermissions: null,
        otherReportPermissions: null,
        retailerRollupReportPermissions: null,
        retailerDataPermissions: null,
        accountCustomFields: null,
        reportingUserBacked: false,
        reportingTeam: null,
      },
      tagging: {
        brandColumns: null,
        brandPreview: null,
        categories: null,
        colors: null,
        extensionFields: null,
        genders: null,
        retailerColumns: null,
        retailerPreview: null,
        standardColumns: {
          gender: false,
          family: false,
          color: false,
        },
      },
    };
  },
  async created() {
    this.overlay = true;
    await this.getVertical();
    this.overlay = false;
  },
  methods: {
    async getVertical() {
      if (this.id) {
        this.verticalId = this.id;
        const resp = await verticals.getVertical(this.id);
        if (resp.data) {
          const { pages, metadata, tagging } = resp.data;
          this.name = resp.data.name;
          this.loadData(pages, metadata, tagging);
        } else {
          this.$router.push('/');
        }
      }
    },
    loadData(pages, metadata, tagging) {
      this.pages = pages;

      this.metadata.retailerReportPermissions = this.stringifyArray(
        metadata.retailerReportPermissions,
      );
      this.metadata.brandReportPermissions = this.stringifyArray(metadata.brandReportPermissions);
      this.metadata.otherReportPermissions = this.stringifyArray(metadata.otherReportPermissions);
      this.metadata.retailerRollupReportPermissions = this.stringifyArray(
        metadata.retailerRollupReportPermissions,
      );
      this.metadata.retailerDataPermissions = this.stringifyJSON(metadata.retailerDataPermissions);
      this.metadata.accountCustomFields = this.stringifyJSON(metadata.accountCustomFields);
      this.metadata.reportingUserBacked = metadata.reportingUserBacked || false;
      this.metadata.reportingTeam = metadata.reportingTeam;

      this.tagging.brandColumns = this.stringifyJSON(tagging.brandColumns);
      this.tagging.brandPreview = this.stringifyJSON(tagging.brandPreview);
      this.tagging.categories = this.stringifyJSON(tagging.categories);
      this.tagging.colors = this.stringifyArray(tagging.colors);
      this.tagging.extensionFields = this.stringifyJSON(tagging.extensionFields);
      this.tagging.genders = this.stringifyArray(tagging.genders);
      this.tagging.retailerColumns = this.stringifyJSON(tagging.retailerColumns);
      this.tagging.retailerPreview = this.stringifyJSON(tagging.retailerPreview);
      this.tagging.standardColumns = tagging.standardColumns;
    },
    sortObjectList(data) {
      if (data) {
        return data.sort((a, b) => ((a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1));
      }
      return null;
    },
    sortStringList(data) {
      if (data) {
        return data.sort((a, b) => ((a.toLowerCase() > b.toLowerCase()) ? 1 : -1));
      }
      return null;
    },
    sortHierarchy(category) {
      if (typeof category !== 'string') {
        if (category.categories && category.categories.length > 0) {
          if (typeof category.categories[0] === 'string') {
            this.sortStringList(category.categories);
          } else {
            this.sortObjectList(category.categories);
            category.categories.forEach((c) => {
              this.sortHierarchy(c);
            });
          }
        }
      }
    },
    sortCategories(categories) {
      if (categories) {
        categories.forEach((category) => {
          if (category.isHierarchy) {
            this.sortHierarchy(category);
          } else {
            if (category.category1) {
              this.sortStringList(category.category1.values);
            }
            if (category.category2) {
              this.sortStringList(category.category2.values);
            }
            if (category.category3) {
              this.sortStringList(category.category3.values);
            }
            if (category.category4) {
              this.sortStringList(category.category4.values);
            }
            if (category.category5) {
              this.sortStringList(category.category5.values);
            }
          }
          if (category.tags) {
            this.sortStringList(category.tags);
          }
        });
      }
    },
    sortColumns(columns) {
      if (columns) {
        columns.forEach((column) => {
          if (column.values && column.values.length > 0) {
            this.sortStringList(column.values);
          }
        });
        return columns;
      }
      return null;
    },
    splitString(data) {
      if (data) {
        return data.split('\n');
      }
      return null;
    },
    parseJSON(data) {
      if (data) {
        return JSON.parse(data);
      }
      return null;
    },
    stringifyJSON(data) {
      if (data) {
        return JSON.stringify(data, null, 2);
      }
      return null;
    },
    stringifyArray(data) {
      if (data) {
        return data.join('\n');
      }
      return null;
    },
    getData() {
      const metadata = {
        retailerReportPermissions: this.sortStringList(
          this.splitString(this.metadata.retailerReportPermissions),
        ),
        brandReportPermissions: this.sortStringList(
          this.splitString(this.metadata.brandReportPermissions),
        ),
        otherReportPermissions: this.sortStringList(
          this.splitString(this.metadata.otherReportPermissions),
        ),
        retailerRollupReportPermissions: this.sortStringList(
          this.splitString(this.metadata.retailerRollupReportPermissions),
        ),
        retailerDataPermissions: this.parseJSON(this.metadata.retailerDataPermissions),
        accountCustomFields: this.parseJSON(this.metadata.accountCustomFields),
        reportingUserBacked: this.metadata.reportingUserBacked,
        reportingTeam: this.metadata.reportingTeam,
      };
      const tagging = {
        brandColumns: this.sortColumns(this.sortObjectList(
          this.parseJSON(this.tagging.brandColumns),
        )),
        brandPreview: this.parseJSON(this.tagging.brandPreview),
        categories: this.parseJSON(this.tagging.categories),
        colors: this.sortStringList(this.splitString(this.tagging.colors)),
        extensionFields: this.parseJSON(this.tagging.extensionFields),
        genders: this.sortStringList(this.splitString(this.tagging.genders)),
        retailerColumns: this.sortColumns(this.sortObjectList(
          this.parseJSON(this.tagging.retailerColumns),
        )),
        retailerPreview: this.parseJSON(this.tagging.retailerPreview),
        standardColumns: this.tagging.standardColumns,
      };

      this.sortCategories(tagging.categories);

      return {
        verticalId: this.id || this.verticalId,
        name: this.name,
        pages: this.pages,
        metadata,
        tagging,
      };
    },
    async save() {
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        const {
          verticalId,
          name,
          pages,
          metadata,
          tagging,
        } = this.getData();

        try {
          if (this.id) {
            await verticals.updateVertical(verticalId, name, pages, metadata, tagging);
          } else {
            await verticals.createVertical(verticalId, name, pages, metadata, tagging);
          }
          this.$router.push('/admin/verticalManagement/');
        } catch (e) {
          if (e.response && e.response.data === 'DuplicateId') {
            this.$refs.idValidation.setErrors(['Duplicate Id']);
          }
          if (e.response && e.response.data === 'DuplicateName') {
            this.$refs.nameValidation.setErrors(['Duplicate Name']);
          }
        }
      }
    },
    async performExport() {
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        this.exports.data = JSON.stringify(this.getData());
        this.exports.dialog = true;
      }
    },
    performImport() {
      if (this.imports.data) {
        try {
          const data = JSON.parse(this.imports.data);
          this.verticalId = data.verticalId;
          this.name = data.name;
          this.loadData(data.pages, data.metadata, data.tagging);
          this.imports.dialog = false;
        } catch (e) {
          this.$refs.importDataValidation.setErrors(['Invalid JSON']);
        }
      }
    },
  },

};
</script>
