<template>
  <Wrapper>
    <h2 class="mb-2">
      Create Account
    </h2>
    <ValidationObserver ref="observer">
      <v-form
        @submit.prevent="createAccount"
      >
        <v-row dense>
          <v-col
            cols="12"
            sm="6"
          >
            <ValidationProvider
              v-slot="{ errors }"
              rules="required"
              name="First Name"
            >
              <v-text-field
                v-model.trim="firstName"
                label="First Name"
                :error-messages="errors"
              />
            </ValidationProvider>
          </v-col>
          <v-col
            cols="12"
            sm="6"
          >
            <ValidationProvider
              v-slot="{ errors }"
              rules="required"
              name="Last Name"
            >
              <v-text-field
                v-model.trim="lastName"
                label="Last Name"
                :error-messages="errors"
              />
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12">
            <ValidationProvider
              ref="passwordValidationProvider"
              v-slot="{ errors }"
              rules="required"
              name="Password"
              vid="Password"
            >
              <v-text-field
                v-model="password"
                :append-icon="showPassword ? 'visibility' : 'visibility_off'"
                :type="showPassword ? 'text' : 'password'"
                label="Password"
                role="textbox"
                :error-messages="errors"
                @click:append="showPassword = !showPassword"
              />
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12">
            <ValidationProvider
              v-slot="{ errors }"
              rules="required|confirmed:Password"
              name="Confirm Password"
            >
              <v-text-field
                v-model="confirmPassword"
                :append-icon="showConfirmPassword ? 'visibility' : 'visibility_off'"
                :type="showConfirmPassword ? 'text' : 'password'"
                label="Confirm Password"
                role="textbox"
                :error-messages="errors"
                @click:append="showConfirmPassword = !showConfirmPassword"
              />
            </ValidationProvider>
            <PasswordValidation
              ref="passwordValidation"
              :password="password"
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12">
            <v-btn
              :disabled="!validToken || success"
              block
              color="primary"
              type="submit"
              class="mt-2"
            >
              Create Account
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </ValidationObserver>
    <template #footer>
      <v-alert
        v-show="!validToken"
        aria-label="Account Creation Error"
        type="error"
        class="mt-3"
      >
        {{ tokenError }}
      </v-alert>
      <v-alert
        v-show="success"
        aria-label="Account Creation Success"
        type="success"
        class="mt-3"
      >
        Your account has been created.
        <a
          class="white--text pl-1"
          href="/login"
        >
          Go to login
        </a>
      </v-alert>
    </template>
  </Wrapper>
</template>

<script>
import auth from '@/api/auth';
import PasswordValidation from '@/components/PasswordValidation';
import Wrapper from '../_components/Wrapper';

export default {
  name: 'UserJoin',
  components: {
    PasswordValidation,
    Wrapper,
  },
  data() {
    return {
      firstName: '',
      lastName: '',
      password: '',
      confirmPassword: '',
      validToken: true,
      tokenError: 'The invite link is no longer valid.',
      success: false,
      showPassword: false,
      showConfirmPassword: false,
    };
  },
  async created() {
    try {
      await auth.validateJoin(this.$route.params.token);
    } catch (error) {
      if (error.response) {
        if (error.response.data === 'InvalidToken') {
          this.tokenError = 'The invite link is no longer valid.';
        } else if (error.response.data === 'AlreadyActivated') {
          this.tokenError = 'Your account has already been activated.';
        }
      }
      this.validToken = false;
    }
  },
  methods: {
    async createAccount() {
      const isValid = await this.$refs.observer.validate();

      if (isValid) {
        if (!this.$refs.passwordValidation.isValid()) {
          this.$refs.passwordValidationProvider.setErrors(['Password does not meet the password policy']);
        } else {
          await auth.join(this.$route.params.token, this.firstName, this.lastName, this.password);
          this.success = true;
        }
      }
    },
  },
};
</script>
