import axios from '@/utils/axios';

const getPreferences = (token) => axios.get('/email/preferences', {
  params: {
    token,
  },
});
const updatePreferences = (token, subscribed) => axios.put('/email/preferences', {
  token, subscribed,
});

export default {
  getPreferences,
  updatePreferences,
};
