export default {
  namespaced: true,
  state: {
    open: true,
    width: 300,
  },
  mutations: {
    SET_OPEN(state, value) {
      state.open = value;
    },
  },
  actions: {
    setOpen({ commit }, value) {
      commit('SET_OPEN', value);
    },
  },
};
