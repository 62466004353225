import axios from '@/utils/axios';

const preview = (type, id, filters, take, skip) => axios.post(`/products/preview/${type}/${id}`, {
  filters,
  take,
  skip,
});
const getUnusedSourceTags = (type, id) => axios.get(`/products/unusedSourceTags/${type}/${id}`);

export default {
  preview,
  getUnusedSourceTags,
};
