<template>
  <Wrapper>
    <div class="title">
      Oauth Validation
    </div>
    <template #footer>
      <v-alert
        v-show="showSuccess"
        type="success"
        class="mt-3"
      >
        The validation credentials have been successfully updated.
      </v-alert>
      <v-alert
        v-show="showFailure"
        type="error"
        class="mt-3"
      >
        There was a problem saving your validation credentials. Please try again.
      </v-alert>
    </template>
  </Wrapper>
</template>

<script>
import sources from '@/api/sources';
import Wrapper from '../_components/Wrapper';

export default {
  name: 'FinalizeOauth',
  components: {
    Wrapper,
  },
  data() {
    return {
      showSuccess: false,
      showFailure: false,
    };
  },
  async mounted() {
    try {
      if (this.$route.query.state && this.$route.query.code) {
        await sources.finalizeOauth(this.$route.query.state, {
          code: this.$route.query.code,
          qs: window.location.search.substr(1),
        });
        this.showSuccess = true;
      } else {
        this.showFailure = true;
      }
    } catch (e) {
      this.showFailure = true;
    }
  },
};
</script>
