import * as views from './_views';

export default [
  {
    path: '/login',
    component: views.Login,
    meta: {
      darkTheme: true,
    },
  },
  {
    path: '/reset',
    component: views.Reset,
    meta: {
      darkTheme: true,
    },
  },
  {
    path: '/changePassword',
    component: views.ChangePassword,
    meta: {
      darkTheme: true,
    },
  },
  {
    path: '/join/admin/:token',
    component: views.AdminJoin,
    meta: {
      darkTheme: true,
    },
  },
  {
    path: '/join/:token',
    component: views.UserJoin,
    meta: {
      darkTheme: true,
    },
  },
  {
    path: '/validateSource',
    component: views.SourceValidation,
    meta: {
      darkTheme: true,
    },
  },
  {
    path: '/runsignup',
    component: views.RunSignup,
    meta: {
      darkTheme: true,
    },
  },
  {
    path: '/finalizeOauth',
    component: views.FinalizeOauth,
    meta: {
      darkTheme: true,
    },
  },
  {
    path: '/accountConnection',
    component: views.AccountConnection,
    meta: {
      darkTheme: true,
    },
  },
  {
    path: '/preferences',
    component: views.EmailPreferences,
    meta: {
      darkTheme: true,
    },
  },
];
