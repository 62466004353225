<template>
  <v-row>
    <v-col
      cols="12"
      md="6"
      lg="4"
    >
      <ValidationProvider
        v-slot="{ errors }"
        rules="heartland_unique:@Category2,@Category3,@Category4"
        name="Category1"
      >
        <v-select
          v-model="category1Local"
          :items="categoryColumns"
          :clearable="true"
          :error-messages="errors"
          item-text="name"
          item-value="value"
          label="Category Level 1"
        />
      </ValidationProvider>
    </v-col>
    <v-col
      cols="12"
      md="6"
      lg="4"
    >
      <ValidationProvider
        v-slot="{ errors }"
        rules="heartland_unique:@Category1,@Category3,@Category4"
        name="Category2"
      >
        <v-select
          v-model="category2Local"
          :items="categoryColumns"
          :clearable="true"
          :disabled="!category1Local"
          :error-messages="errors"
          item-text="name"
          item-value="value"
          label="Category Level 2"
        />
      </ValidationProvider>
    </v-col>
    <v-col
      cols="12"
      md="6"
      lg="4"
    >
      <ValidationProvider
        v-slot="{ errors }"
        rules="heartland_unique:@Category1,@Category2,@Category4"
        name="Category3"
      >
        <v-select
          v-model="category3Local"
          :items="categoryColumns"
          :clearable="true"
          :disabled="!category2Local"
          :error-messages="errors"
          item-text="name"
          item-value="value"
          label="Category Level 3"
        />
      </ValidationProvider>
    </v-col>
    <v-col
      cols="12"
      md="6"
      lg="4"
    >
      <ValidationProvider
        v-slot="{ errors }"
        rules="heartland_unique:@Category1,@Category2,@Category3"
        name="Category4"
      >
        <v-select
          v-model="category4Local"
          :items="categoryColumns"
          :clearable="true"
          :disabled="!category3Local"
          :error-messages="errors"
          item-text="name"
          item-value="value"
          label="Category Level 4"
        />
      </ValidationProvider>
    </v-col>
    <v-col
      cols="12"
      md="6"
      lg="4"
    >
      <v-checkbox
        v-model="ignoreWebOrdersLocal"
        label="Ignore Web Orders"
      />
      <div class="caption">
        When selected all orders that are of type web orders will not be sent to Zaius.
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'ZauisRunitDestination',
  props: {
    errorMessage: {
      type: String,
      required: false,
      default: '',
    },
    category1: {
      type: String,
      required: false,
      default: '',
    },
    category2: {
      type: String,
      required: false,
      default: '',
    },
    category3: {
      type: String,
      required: false,
      default: '',
    },
    category4: {
      type: String,
      required: false,
      default: '',
    },
    ignoreWebOrders: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      category1Local: this.category1,
      category2Local: this.category2,
      category3Local: this.category3,
      category4Local: this.category4,
      ignoreWebOrdersLocal: this.ignoreWebOrders,
      categoryColumns: [
        {
          name: 'Department',
          value: 'Dept',
        },
        {
          name: 'Gender',
          value: 'Gender',
        },
        {
          name: 'Sub Type',
          value: 'SubType',
        },
        {
          name: 'Type',
          value: 'Typ',
        },
      ],
    };
  },
  watch: {
    category1Local() {
      if (!this.category1Local) {
        this.category2Local = '';
      }
    },
    category2Local() {
      if (!this.category2Local) {
        this.category3Local = '';
      }
    },
    category3Local() {
      if (!this.category3Local) {
        this.category4Local = '';
      }
    },
  },
  methods: {
    getDetail() {
      return {
        category1: this.category1Local,
        category2: this.category2Local,
        category3: this.category3Local,
        category4: this.category4Local,
        ignoreWebOrders: this.ignoreWebOrdersLocal,
      };
    },
  },
};
</script>
