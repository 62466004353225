<template>
  <v-container
    fluid
    fill-height
    class="pa-0"
    style="position: relative;"
  >
    <report-user-guide />
    <v-overlay
      absolute
      color="grey lighten-4"
      :value="!workbook.loaded"
    >
      <v-progress-circular
        color="secondary"
        indeterminate
        size="48"
      />
    </v-overlay>

    <v-fade-transition hide-on-leave>
      <report-header
        v-if="workbook.loaded"
        key="report-header"
      />
    </v-fade-transition>

    <div
      class="d-flex flex-row"
      :style="{
        position: 'relative',
        width: '100%',
        height: `calc(100% - ${offsetReportBy}px)`
      }"
    >
      <sigma-report
        v-if="report"
        key="sigma-report"
        :style="{
          height: '100%',
          opacity: workbook.loaded ? 1 : 0,
          transition: 'opacity 0.25s ease-in'
        }"
        :view="view"
        :report="report"
        @workbook-variables-onchange="onChangeVariable"
        @workbook-loaded="onWorkbookLoaded"
      />

      <report-help-drawer :value="help" />
    </div>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import SigmaReport from '@/components/SigmaReport';
import ReportHeader from '../_components/ReportHeader';
import ReportHelpDrawer from '../_components/ReportHelpDrawer';
import ReportUserGuide from '../_components/ReportUserGuide';

export default {
  name: 'ReportIndex',
  components: {
    ReportHeader,
    SigmaReport,
    ReportHelpDrawer,
    ReportUserGuide,
  },
  data() {
    return {
      offsetReportBy: 0,
    };
  },
  computed: {
    ...mapState('page/report', [
      'report',
      'workbook',
      'help',
      'view',
    ]),
  },
  methods: {
    onChangeVariable({ data: { workbook } }) {
      if (this.view) {
        this.$store.dispatch('page/report/setView', null);
      }

      this.$store.dispatch('page/report/workbook/update', workbook);
    },
    onWorkbookLoaded({ data: { workbook } }) {
      this.offsetReportBy = 65;

      this.$store.dispatch('page/report/workbook/init', workbook);
    },
  },
};
</script>
