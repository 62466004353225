import account from '@/api/accounts';

const actions = {
  DESTINATIONS_LOAD: async ({ commit }, accountId) => {
    const resp = await account.getDestinations(accountId);
    commit('DESTINATIONS_CHANGE', resp.data);
  },
  DESTINATIONS_CLEAR: ({ commit }) => {
    commit('DESTINATIONS_CHANGE', []);
  },
};

export default actions;
