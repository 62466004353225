<template>
  <v-row>
    <v-col
      cols="12"
      md="6"
      lg="4"
    >
      <ValidationProvider
        ref="apiKeyValidation"
        v-slot="{ errors }"
        rules="required"
        name="API Key"
      >
        <v-text-field
          v-model="apiKeyLocal"
          label="API Key"
          :error-messages="errors"
        />
      </ValidationProvider>
      <div class="caption">
        You create and copy-paste your MailChimp API Key from Account Settings > Extras > API Keys
      </div>
    </v-col>
    <v-col
      cols="12"
      md="6"
      lg="4"
    >
      <ValidationProvider
        ref="listIdValidation"
        v-slot="{ errors }"
        rules="required"
        name="Audience Id"
      >
        <v-text-field
          v-model="listIdLocal"
          label="Audience Id"
          :error-messages="errors"
        />
      </ValidationProvider>
      <div class="caption">
        You can find your Audience ID in your Mailchimp Settings pane under the Audiences tab. Go to
        Manage Audiences > Settings and click on Audience Name &amp; Defaults. The Audience ID will
        be on the right side.
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'MailchimpDestinationType',
  props: {
    apiKey: {
      type: String,
      required: false,
      default: '',
    },
    listId: {
      type: String,
      required: false,
      default: '',
    },
    errorMessage: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      apiKeyLocal: this.apiKey,
      listIdLocal: this.listId,
    };
  },
  watch: {
    errorMessage() {
      if (this.errorMessage === 'InvalidListId') {
        this.$refs.listIdValidation.setErrors(['Invalid Audience Id']);
      }
      if (this.errorMessage === 'InvalidApiKey') {
        this.$refs.apiKeyValidation.setErrors(['Invalid Api Key']);
      }
    },
  },
  methods: {
    getDetail() {
      return {
        apiKey: this.apiKeyLocal,
        listId: this.listIdLocal,
      };
    },
  },
};
</script>
