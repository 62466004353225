import ComponentWrapper from '@/views/ComponentWrapper';
import * as views from './_views';

export default [
  {
    path: 'product',
    component: ComponentWrapper,
    redirect: '/',
    children: [
      {
        path: 'tagging',
        component: views.Tagging,
        meta: {
          requireSystemUser: true,
          requiresBrandOrRetailerAccount: true,
          requireDataManagementPermission: true,
          requirePagePermission: [
            {
              permission: 'brandTagging',
              accountType: 'Brand',
            },
            {
              permission: 'retailerTagging',
              accountType: 'Retailer',
            },
          ],
        },
      },
      {
        path: 'tagging/create',
        component: views.CreateTag,
        meta: {
          requireSystemUser: true,
          requiresBrandOrRetailerAccount: true,
          requireDataManagementPermission: true,
          requirePagePermission: [
            {
              permission: 'brandTagging',
              accountType: 'Brand',
            },
            {
              permission: 'retailerTagging',
              accountType: 'Retailer',
            },
          ],
        },
      },
      {
        path: 'tagging/edit/:id',
        component: views.EditTag,
        meta: {
          requireSystemUser: true,
          requiresBrandOrRetailerAccount: true,
          requireDataManagementPermission: true,
          requirePagePermission: [
            {
              permission: 'brandTagging',
              accountType: 'Brand',
            },
            {
              permission: 'retailerTagging',
              accountType: 'Retailer',
            },
          ],
        },
      },
      {
        path: 'upload',
        component: views.BrandUpload,
        meta: {
          requireSystemUser: true,
          requiresBrandAccount: true,
          requireDataManagementPermission: true,
          requirePagePermission: 'brandTagging',
        },
      },
      {
        path: 'globalRetailerTagging',
        component: views.Tagging,
        meta: {
          requireSystemUser: true,
          globalRetailer: true,
          requireDataManagementPermission: true,
          requirePagePermission: 'globalTagging',
        },
      },
      {
        path: 'globalRetailerTagging/create',
        component: views.CreateTag,
        meta: {
          requireSystemUser: true,
          globalRetailer: true,
          requireDataManagementPermission: true,
          requirePagePermission: 'globalTagging',
        },
      },
      {
        path: 'globalRetailerTagging/edit/:id',
        component: views.EditTag,
        meta: {
          requireSystemUser: true,
          globalRetailer: true,
          requireDataManagementPermission: true,
          requirePagePermission: 'globalTagging',
        },
      },
      {
        path: 'brandMapping',
        component: views.BrandMapping,
        meta: {
          requireSystemUser: true,
          requireDataManagementPermission: true,
          requirePagePermission: 'brandMapping',
        },
      },
    ],
  },
];
