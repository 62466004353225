const actions = {
  HEARTLAND_SETCOLUMNS: ({ commit }, data) => {
    commit('HEARTLAND_SETCOLUMNS', data);
  },
  HEARTLAND_CLEARCOLUMNS: ({ commit }) => {
    commit('HEARTLAND_CLEARCOLUMNS');
  },
};

export default actions;
