<template>
  <v-container>
    <v-row>
      <v-icon class="mr-1">
        {{ getIcon(passwordLength) }}
      </v-icon>
      At least 8 characters long
    </v-row>
    <v-row>
      <v-icon class="mr-1">
        {{ getIcon(passwordLowerCase) }}
      </v-icon>
      One lowercase character
    </v-row>
    <v-row>
      <v-icon class="mr-1">
        {{ getIcon(passwordUpperCase) }}
      </v-icon>
      One uppercase character
    </v-row>
    <v-row>
      <v-icon class="mr-1">
        {{ getIcon(passwordSpecial) }}
      </v-icon>
      One number, symbol or whitespace character
    </v-row>
    <v-row>
      <v-icon class="mr-1">
        {{ getIcon(passwordWhitespace) }}
      </v-icon>
      Does not start or end with whitespace
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'PasswordValidation',
  props: {
    password: {
      type: String,
      required: true,
    },
  },
  computed: {
    passwordLength() {
      return this.password.length >= 8;
    },
    passwordLowerCase() {
      return this.password.match(/[a-z]+/);
    },
    passwordUpperCase() {
      return this.password.match(/[A-Z]+/);
    },
    passwordSpecial() {
      // eslint-disable-next-line
      return this.password.match(/[0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~` ]+/);
    },
    passwordWhitespace() {
      return this.password === this.password.trim();
    },
  },
  methods: {
    getIcon(isValid) {
      if (isValid) {
        return 'check_box';
      }
      return 'check_box_outline_blank';
    },
    isValid() {
      return this.passwordLength && this.passwordLowerCase && this.passwordUpperCase
        && this.passwordSpecial && this.passwordWhitespace;
    },
  },
};
</script>
