<template>
  <ValidationObserver>
    <v-row
      align="end"
      dense
    >
      <v-col
        cols="12"
        md="6"
        lg="4"
      >
        <ValidationProvider
          v-slot="{ errors }"
          rules="required"
          name="Column"
        >
          <v-select
            v-model="columnLocal"
            label="Column"
            :items="columns"
            item-text="name"
            item-value="value"
            :error-messages="errors"
            @change="columnChange($event, false)"
          />
        </ValidationProvider>
      </v-col>
      <v-col
        cols="12"
        md="6"
        lg="4"
      >
        <ValidationProvider
          v-slot="{ errors }"
          rules="required"
          name="Filter Type"
        >
          <v-select
            v-model="typeLocal"
            label="Filter Type"
            :disabled="!columnLocal"
            :items="columnType === 'List' ? listFilterTypes : stringFilterTypes"
            item-text="name"
            item-value="value"
            :error-messages="errors"
          />
        </ValidationProvider>
      </v-col>
      <v-col
        v-show="columnType === 'String'"
        cols="12"
        md="6"
        lg="4"
      >
        <v-btn
          text
          @click="showMulti"
        >
          Add Multiple Values
        </v-btn>
        <v-row
          v-for="(value, i) in valuesLocal"
          :key="i"
          align="center"
          no-gutters
        >
          <v-col cols="12">
            <ValidationProvider
              v-slot="{ errors }"
              rules="required"
              name="Value"
              :vid="`value${i}`"
            >
              <v-text-field
                v-model="valuesLocal[i]"
                :error-messages="errors"
                label="Value"
              >
                <template #prepend>
                  <v-icon
                    v-if="valuesLocal.length > 1"
                    @click="removeValue(i)"
                  >
                    remove_circle
                  </v-icon>
                  <v-icon
                    v-else
                    style="opacity:0;"
                  >
                    remove_circle
                  </v-icon>
                </template>
                <template #append-outer>
                  <v-btn
                    v-if="i === valuesLocal.length - 1"
                    color="secondary"
                    fab
                    x-small
                    dark
                    @click="addValue()"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                  <v-chip v-if="i !== valuesLocal.length - 1">
                    {{ typeLocal === 'not-contains' ? 'AND' : 'OR' }}
                  </v-chip>
                </template>
              </v-text-field>
            </ValidationProvider>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        v-show="columnType === 'List'"
        cols="12"
        lg="8"
      >
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <div class="text-subtitle-1">
              Available ({{ new Intl.NumberFormat().format(columnValuesFiltered.length) }})
            </div>
            <v-text-field
              v-model="availableSearch"
              placeholder="Search Available"
              clearable
            />
            <v-virtual-scroll
              :items="columnValuesFiltered"
              height="300"
              min-height="300"
              item-height="36"
              class="white"
              style="overflow-y: scroll;"
            >
              <template #default="{ item, index }">
                <v-btn
                  text
                  block
                  class="text-none"
                  style="justify-content: left;"
                  @click="availableClicked(index)"
                >
                  {{ item }}
                </v-btn>
                <v-divider />
              </template>
            </v-virtual-scroll>
            <div class="text-caption">
              Click an item to add it to the selected list
            </div>
            <div class="pt-1">
              <v-btn
                text
                small
                class="pa-0 text-none"
                color="secondary"
                @click="selectAllAvailable"
              >
                Move all filtered items to the selected list
              </v-btn>
            </div>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <div class="text-subtitle-1">
              Selected ({{ new Intl.NumberFormat().format(selectedValuesFiltered.length) }})
            </div>
            <v-text-field
              v-model="selectedSearch"
              placeholder="Search Selected"
              clearable
            />
            <v-virtual-scroll
              :items="selectedValuesFiltered"
              height="300"
              min-height="300"
              item-height="36"
              class="white"
              style="overflow-y: scroll;"
            >
              <template #default="{ item, index }">
                <v-btn
                  text
                  block
                  class="text-none"
                  style="justify-content: left;"
                  @click="selectedClicked(index)"
                >
                  {{ item }}
                </v-btn>
                <v-divider />
              </template>
            </v-virtual-scroll>
            <div class="text-caption">
              Click an item to remove it from the selected list
            </div>
            <v-btn
              text
              small
              class="pa-0 text-none"
              color="secondary"
              @click="removeAllSelected"
            >
              Remove all filtered items from the selected list
            </v-btn>
            <ValidationProvider
              ref="valuesValidation"
              v-slot="{ errors }"
              rules="required"
              name="Value"
            >
              <input
                v-model="selectedValuesText"
                type="hidden"
              >
              <div class="red--text">
                {{ errors[0] }}
              </div>
            </ValidationProvider>
          </v-col>
        </v-row>
      </v-col>
      <v-dialog
        v-model="multiDialog"
        max-width="400"
        persistent
      >
        <v-card
          class="mx-auto"
          tile
        >
          <v-card-title>
            Add Multiple Values
          </v-card-title>
          <v-card-text>
            Place each value on a new line to add multiple values to the filter.
            <v-textarea v-model="multiValues" />
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="primary"
              text
              @click="multiDialog = false"
            >
              Cancel
            </v-btn>
            <v-btn
              color="primary"
              text
              @click="addMulti"
            >
              Insert
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </ValidationObserver>
</template>

<script>
import { debounce } from 'lodash';

export default {
  name: 'ColumnFilter',
  props: {
    column: {
      type: String,
      required: false,
      default: null,
    },
    type: {
      type: String,
      required: false,
      default: null,
    },
    values: {
      type: Array,
      required: false,
      default: () => [''],
    },
    columns: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      stringFilterTypes: [
        {
          name: 'Begins With',
          value: 'begins',
        },
        {
          name: 'Ends With',
          value: 'ends',
        },
        {
          name: 'Equals',
          value: 'equals',
        },
        {
          name: 'Contains',
          value: 'contains',
        },
        {
          name: 'Does Not Contain',
          value: 'not-contains',
        },
      ],
      listFilterTypes: [
        {
          name: 'Contains',
          value: 'in',
        },
        {
          name: 'Does Not Contain',
          value: 'not-in',
        },
      ],
      multiDialog: false,
      multiValues: '',
      columnLocal: this.column,
      typeLocal: this.type,
      valuesLocal: this.values,
      columnType: null,
      columnValues: [],
      availableSearch: null,
      selectedSearch: null,
      hasSorted: false,
    };
  },
  computed: {
    columnValuesFiltered() {
      return this.columnValues.filter((x) => !this.valuesLocal.includes(x))
        .filter((x) => (this.availableSearch ? x.toLowerCase()
          .includes(this.availableSearch.toLowerCase()) : true));
    },
    selectedValuesFiltered() {
      return this.valuesLocal.filter((x) => (this.selectedSearch ? x.toLowerCase()
        .includes(this.selectedSearch.toLowerCase()) : true));
    },
    selectedValuesText() {
      return this.valuesLocal.length > 0 ? 'a' : null;
    },
  },
  watch: {
    column() {
      this.columnLocal = this.column;
      this.columnChange(this.column);
    },
    type() {
      this.typeLocal = this.type;
    },
    values() {
      this.valuesLocal = this.values;
      if (this.hasSorted === false) {
        this.sortValues();
        this.hasSorted = true;
      }
    },
    columnLocal() {
      this.$emit('update:column', this.columnLocal);
    },
    typeLocal() {
      this.$emit('update:type', this.typeLocal);
    },
    valuesLocal() {
      this.$emit('update:values', this.valuesLocal);
    },
  },
  mounted() {
    if (this.column) {
      this.columnChange(this.column, true);
    }
    if (this.values.length > 0) {
      this.sortValues();
      this.hasSorted = true;
    }
  },
  created() {
    this.sortValues = debounce(this.sortValues, 1000);
  },
  methods: {
    addValue() {
      this.valuesLocal.push('');
      this.$emit('update:values', this.valuesLocal);
    },
    removeValue(index) {
      this.valuesLocal.splice(index, 1);
      if (this.valuesLocal.length === 0) {
        this.addValue();
      }
      this.$emit('update:values', this.valuesLocal);
    },
    showMulti() {
      this.multiValues = '';
      this.multiDialog = true;
    },
    addMulti() {
      this.valuesLocal.push(...this.multiValues.split('\n'));
      if (this.valuesLocal.length > 1 && this.valuesLocal[0] === '') {
        this.valuesLocal.splice(0, 1);
      }
      if (this.valuesLocal.length > 1 && this.valuesLocal[this.valuesLocal.length - 1] === '') {
        this.valuesLocal.splice(this.valuesLocal.length - 1, 1);
      }
      this.multiDialog = false;
    },
    columnChange(value, isLoad) {
      if (value) {
        const column = this.columns.find((x) => x.value === value);
        this.columnType = column.type;
        this.columnValues = column.values || [];
        this.availableSearch = '';
        this.selectedSearch = '';
        if (isLoad === false) {
          if (column.type === 'String') {
            this.valuesLocal = [''];
          } else {
            this.valuesLocal = [];
          }
        } else if (column.type !== 'String') {
          const matchedValues = [];
          this.values.forEach((val) => {
            if (this.columnValues.includes(val)) {
              matchedValues.push(val);
            }
          });
          this.$emit('update:values', matchedValues);
        }
      } else {
        this.columnType = null;
      }
    },
    selectAllAvailable() {
      this.columnValuesFiltered.forEach((item) => {
        this.valuesLocal.push(item);
        this.sortValues();
        this.availableSearch = '';
      });
    },
    availableClicked(index) {
      this.valuesLocal.push(this.columnValuesFiltered[index]);
      this.sortValues();
    },
    selectedClicked(index) {
      this.valuesLocal.splice(index, 1);
    },
    removeAllSelected() {
      this.selectedValuesFiltered.forEach((item) => {
        this.valuesLocal.splice(this.valuesLocal.findIndex((x) => x === item), 1);
        this.selectedSearch = '';
      });
    },
    sortValues() {
      if (this.valuesLocal) {
        this.valuesLocal.sort((a, b) => ((a.toLowerCase() > b.toLowerCase()) ? 1 : -1));
      }
    },
  },
};
</script>
