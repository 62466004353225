<template>
  <div>
    <div
      v-show="!showRecovery"
      class="text-xs-center"
    >
      <h3 class="mb-2">
        Enter the verification code.
      </h3>
      Let's make sure it's really you. Use the verification code from your authenticator app.
      <ValidationObserver ref="codeObserver">
        <v-form
          class="mt-5"
          @submit.prevent="login"
        >
          <ValidationProvider
            ref="codeValidation"
            v-slot="{ errors }"
            rules="required|numeric|code:6"
            name="Code"
          >
            <v-text-field
              v-model="code"
              aria-label="Two Factor Authentication Code"
              placeholder="Enter 6-digit code"
              maxlength="6"
              :error-messages="errors"
            />
          </ValidationProvider>
          <div class="text-xs-center">
            <v-btn
              type="submit"
              color="primary"
              size="lg"
              class="mt-4"
            >
              Log in to your account
            </v-btn>
          </div>
          <div class="text-xs-center mt-3">
            <a
              class="primary--text"
              @click.prevent="showRecovery = true"
              @keydown.enter="showRecovery = true"
            >
              Use a recovery code
            </a>
          </div>
        </v-form>
      </ValidationObserver>
    </div>
    <div
      v-show="showRecovery"
      class="text-xs-center"
    >
      <h3 class="mb-2">
        Recovery Code.
      </h3>
      The recovery codes were provided to you when you enabled two-factor authentication.
      <ValidationObserver ref="recoveryObserver">
        <v-form
          class="mt-5"
          @submit.prevent="login"
        >
          <ValidationProvider
            ref="recoveryValidation"
            v-slot="{ errors }"
            rules="required|numeric|code:8"
            name="Code"
          >
            <v-text-field
              v-model="recoveryCode"
              placeholder="Enter 8-digit code"
              maxlength="8"
              :error-messages="errors"
            />
          </ValidationProvider>
          <div class="text-xs-center">
            <v-btn
              type="submit"
              color="primary"
              size="lg"
              class="mt-4"
            >
              Log in to your account
            </v-btn>
          </div>
          <div class="text-xs-center mt-3">
            <a
              class="primary--text"
              href="mailto:support@upperquadrant.com"
            >
              Don't have a recovery code? Submit a recovery request.
            </a>
          </div>
        </v-form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TwoFactor',
  props: {
    email: {
      type: String,
      required: true,
    },
    password: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      code: '',
      recoveryCode: '',
      showRecovery: false,
    };
  },
  methods: {
    async login() {
      const { email, password } = this;
      let { code } = this;
      const { recoveryCode } = this;
      let isValid;

      if (this.showRecovery) {
        isValid = await this.$refs.recoveryObserver.validate();
        code = null;
      } else {
        isValid = await this.$refs.codeObserver.validate();
      }

      if (isValid) {
        try {
          const resp = await this.$store.dispatch(
            'AUTH_REQUEST',
            {
              email, password, code, recoveryCode,
            },
          );
          if (resp.data.status === 'Success') {
            this.$emit('LoginSuccess');
          }
        } catch (error) {
          if (error.response) {
            if (error.response.data === 'InvalidCode') {
              this.$refs.codeValidation.setErrors(['Invalid code']);
            } else if (error.response.data === 'InvalidRecoveryCode') {
              this.$refs.recoveryValidation.setErrors(['Invalid code']);
            } else if (error.response.data === 'AccountLocked') {
              if (this.showRecovery) {
                this.$refs.recoveryValidation.setErrors(['Account locked out for too many failed attempts']);
              } else {
                this.$refs.codeValidation.setErrors(['Account locked out for too many failed attempts']);
              }
            }
          }
        }
      }
    },
  },
};
</script>

<style scoped>
  a {
    text-decoration: none;
  }
</style>
