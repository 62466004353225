<template>
  <div>
    <v-row>
      <v-col>
        <h2>
          Vertical Management
        </h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="6"
        xl="4"
      >
        <v-btn
          color="primary"
          to="/admin/vertical/create"
        >
          + Add Vertical
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="verticals"
          :loading="loading"
          class="elevation-5"
          disable-pagination
          hide-default-footer
          @click:row="rowClick"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import verticals from '@/api/verticals';

export default {
  name: 'VerticalManagement',
  data() {
    return {
      loading: false,
      headers: [
        {
          text: 'Id',
          value: 'id',
          sortable: true,
        },
        {
          text: 'Name',
          value: 'name',
          sortable: true,
        },
      ],
      verticals: [],
    };
  },
  async created() {
    this.loading = true;
    const resp = await verticals.getVerticals();
    this.verticals = resp.data;
    this.loading = false;
  },
  methods: {
    rowClick(item) {
      this.$router.push(`/admin/vertical/${item.id}`);
    },
  },
};
</script>
