import axiosExternal from 'axios';

const getZaiusLists = (apiKey) => axiosExternal.get('https://api.zaius.com/v3/lists', {
  headers: {
    'x-api-key': apiKey,
  },
});
const addZaiusList = (apiKey, name) => axiosExternal.post(
  'https://api.zaius.com/v3/lists',
  {
    name,
  },
  {
    headers: {
      'x-api-key': apiKey,
    },
  },
);

export default {
  getZaiusLists,
  addZaiusList,
};
