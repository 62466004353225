<template>
  <div>
    <v-row>
      <v-col>
        <h2>
          Report Management
        </h2>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-btn
          color="primary"
          to="/admin/reports/create"
        >
          + Add Report
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="reports"
          :loading="loading"
          class="elevation-5"
          group-by="verticalName"
          disable-pagination
          hide-default-footer
        >
          <template #[`item.edit`]="{ item }">
            <v-btn
              fab
              text
              :to="getRoute(item)"
              class="mr-2"
              x-small
            >
              <v-icon>
                edit
              </v-icon>
            </v-btn>
          </template>
          <template #[`item.requireSystemUser`]="{ item }">
            <v-checkbox
              v-model="item.requireSystemUser"
              disabled
              color="primary"
            />
          </template>
          <template #[`item.isActive`]="{ item }">
            <v-checkbox
              v-model="item.isActive"
              disabled
              color="primary"
            />
          </template>
          <template #[`item.delete`]="{ item }">
            <v-btn
              fab
              text
              class="mr-2"
              x-small
              @click="deleteReport(item)"
            >
              <v-icon>
                delete
              </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { kebabCase } from 'lodash';
import reports from '../../_api/reports';

export default {
  name: 'ReportManagement',
  data() {
    return {
      loading: false,
      headers: [
        {
          value: 'edit',
        },
        {
          text: 'Vertical',
          value: 'verticalName',
          sortable: true,
        },
        {
          text: 'Reference Name',
          value: 'referenceName',
          sortable: true,
        },
        {
          text: 'Account Type',
          value: 'accountType',
          sortable: true,
        },
        {
          text: 'Path',
          value: 'pathName',
          sortable: true,
        },
        {
          text: 'Title',
          value: 'title',
          sortable: true,
        },
        {
          text: 'Category',
          value: 'category',
          sortable: true,
        },
        {
          text: 'Require System User',
          value: 'requireSystemUser',
          sortable: true,
        },
        {
          text: 'Is Active',
          value: 'isActive',
          sortable: true,
        },
        {
          value: 'delete',
        },
      ],
      reports: [],
    };
  },
  async created() {
    this.loading = true;
    await this.loadData();
    this.loading = false;
  },
  methods: {
    async loadData() {
      const resp = await reports.oldGetReports();
      this.reports = resp.data.data;
    },
    getRoute(item) {
      return `/admin/reports/${item.verticalId}/${kebabCase(item.accountType)}/${item.pathName}`;
    },
    async deleteReport(report) {
      if (await this.$confirm(`Are you sure you want to delete the <b>${report.accountType}</b> report <b>${report.title}</b> in vertical <b>${report.verticalName}</b>?`)) {
        this.loading = true;
        await reports.deleteReport(
          report.verticalId,
          kebabCase(report.accountType),
          report.pathName,
        );
        await this.loadData();
        this.loading = false;
      }
    },
  },
};
</script>
