import api from '@/modules/admin/_api/reports';
import { createHashMapBy } from '@/utils/arr';
import Vue from 'vue';

// ---
export default {
  namespaced: true,
  state: {
    loaded: false,
    // This stashes all the reports that have been acquired. If you need a specific stubset
    // you must filter them yourself.
    reports: [],
  },
  mutations: {
    SET_LOADED(state, loaded) {
      state.loaded = loaded;
    },
    SET_REPORTS(state, reports) {
      state.reports = reports;
    },
    UPDATE_REPORT(state, report) {
      const idx = state.reports.findIndex((r) => r.id === report.id);

      Vue.set(state.reports, idx, report);
    },
  },
  getters: {
    getReportsByVerticalId: (state) => (verticalId) => state.reports.filter(
      (report) => report.verticalId === verticalId,
    ),
    getReportById: (state) => (id) => state.reports.find((report) => report.id === id),
  },
  actions: {
    async init({ state, dispatch, commit }, params = {}) {
      if (!state.loaded) {
        await dispatch('get', params);

        commit('SET_LOADED', true);
      }
    },
    async get({ state, commit }, params = {}) {
      const { data: { data: reports } } = await api.getReports(params);

      if (state.reports.length > 0) {
        commit('SET_REPORTS', Object.values({
          ...createHashMapBy(state.reports, 'id'),
          ...createHashMapBy(reports, 'id'),
        }));
      } else {
        commit('SET_REPORTS', reports);
      }
    },
    async getById({ state, commit }, id) {
      let report = state.reports.find((r) => r.id === id);

      if (!report) {
        const { data: { data } } = await api.getReportById(id);

        report = data;
        commit('SET_REPORTS', state.reports.concat(report));
      }

      return report;
    },
    async update({ commit, state }, data) {
      const { id } = data;
      const payload = { ...data };

      payload.guide = payload.guide.trim();
      delete payload.id;

      await api.patchReport(id, payload);

      const report = {
        ...state.reports.find((r) => r.id === id),
        ...payload,
      };

      commit('UPDATE_REPORT', report);

      return report;
    },
    reset({ commit }) {
      api.getReports.cache.clear();
      api.getReportById.cache.clear();

      commit('SET_REPORTS', []);
      commit('SET_LOADED', false);
    },
    logout: {
      root: true,
      handler({ dispatch }) {
        dispatch('reset');
      },
    },
  },
};
