import axios from '@/utils/axios';

const validate = (token) => axios.get(`/sources/validation/${token}`);
const getRunSignupRequestToken = () => axios.get('/sources/runSignup/requestToken');
const finalizeOauth = (token, detail) => axios.patch(`/sources/oauth/${token}`, detail);

export default {
  validate,
  getRunSignupRequestToken,
  finalizeOauth,
};
