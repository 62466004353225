<template>
  <div>
    This will allow data to flow into the cadence platform.
  </div>
</template>

<script>
export default {
  name: 'CadenceDestinationType',
  methods: {
    getDetail() {
      return {};
    },
  },
};
</script>
