<template>
  <v-row>
    <v-col
      cols="12"
      md="6"
      lg="4"
    >
      <v-checkbox
        v-model="createOrdersLocal"
        label="Create Orders"
      />
      <div class="caption">
        When selected registration events will also create an order event.
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'ZauisRaceDestination',
  props: {
    createOrders: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      createOrdersLocal: this.createOrders,
    };
  },
  methods: {
    getDetail() {
      return {
        createOrders: this.createOrdersLocal,
      };
    },
  },
};
</script>
