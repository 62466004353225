<template>
  <v-navigation-drawer
    v-model="drawerLocal"
    app
    aria-label="UQ Cadence Side Navigation"
    :clipped="$vuetify.breakpoint.lgAndUp"
    :width="sidebar.width"
  >
    <v-list
      dense
      class="pt-0"
    >
      <dashboards />
      <brand-products />
      <retailer-products />
      <settings />
      <system-admin v-if="systemUser" />
      <div style="height:30px;" />
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import Settings from './Sidebar/Settings';
import SystemAdmin from './Sidebar/SystemAdmin';
import BrandProducts from './Sidebar/BrandProducts';
import RetailerProducts from './Sidebar/RetailerProducts';
import Dashboards from './Sidebar/Dashboards';

export default {
  name: 'SidebarNav',
  components: {
    Settings,
    SystemAdmin,
    BrandProducts,
    RetailerProducts,
    Dashboards,
  },
  computed: {
    ...mapState('navigation', ['sidebar']),
    ...mapGetters([
      'systemUser',
    ]),
    drawerLocal: {
      get() {
        return this.sidebar.open;
      },
      set(value) {
        this.$store.dispatch('navigation/sidebar/setOpen', value);
      },
    },
  },
};
</script>
