<template>
  <v-row>
    <v-col
      cols="12"
      md="6"
      lg="4"
    >
      <ValidationProvider
        ref="urlValidation"
        v-slot="{ errors }"
        rules="required"
        name="Url"
      >
        <v-text-field
          v-model="urlLocal"
          label="Url"
          placeholder="example.teamworkinsight.com"
          hint="example.teamworkinsight.com"
          :error-messages="errors"
        />
      </ValidationProvider>
      <div class="caption">
        The url should not contain https:// or any subdirectories, it should be just the root url
        i.e.(example.teamworkinsight.com).
      </div>
    </v-col>
    <v-col
      cols="12"
      md="6"
      lg="4"
    >
      <ValidationProvider
        ref="apiKeyValidation"
        v-slot="{ errors }"
        rules="required"
        name="API Key"
      >
        <v-text-field
          v-model="apiKeyLocal"
          label="API Key"
          :error-messages="errors"
        />
      </ValidationProvider>
    </v-col>
    <v-col
      cols="12"
      md="6"
      lg="4"
    >
      <ValidationProvider
        v-slot="{ errors }"
        rules="required"
        name="Item Identifier"
      >
        <v-select
          v-model="itemIdentifierLocal"
          :items="itemIdentifiers"
          :error-messages="errors"
          label="Item Identifier"
        />
      </ValidationProvider>
      <div class="caption">
        The field selected here will be used as the product id.
      </div>
    </v-col>
    <v-col
      cols="12"
      md="6"
      lg="4"
    >
      <timezone :timezone.sync="timezoneLocal" />
      <div class="caption">
        Teamwork returns transaction dates without timezone info. To correctly send this data it
        requires converting all the data to the correct timezone. The timezone that is selected here
        is used to convert all transactions to that timezone.
      </div>
    </v-col>
  </v-row>
</template>

<script>
import Timezone from '../Timezone';

export default {
  name: 'TeamworkSource',
  components: {
    Timezone,
  },
  props: {
    url: {
      type: String,
      required: false,
      default: '',
    },
    apiKey: {
      type: String,
      required: false,
      default: '',
    },
    itemIdentifier: {
      type: String,
      required: false,
      default: 'PLU',
    },
    timezone: {
      type: String,
      required: false,
      default: '',
    },
    errorMessage: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      urlLocal: this.url,
      apiKeyLocal: this.apiKey,
      itemIdentifierLocal: this.itemIdentifier,
      itemIdentifiers: [
        'PLU', 'ExternalId', 'CLU', 'UPC',
      ],
      timezoneLocal: this.timezone,
    };
  },
  watch: {
    errorMessage() {
      if (this.errorMessage === 'InvalidAccess') {
        this.$refs.urlValidation.setErrors(['Invalid URL or token']);
      }
    },
  },
  methods: {
    getDetail() {
      return {
        url: this.urlLocal,
        apiKey: this.apiKeyLocal,
        itemIdentifier: this.itemIdentifierLocal,
        timezone: this.timezoneLocal,
      };
    },
  },
};
</script>
