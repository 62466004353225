import vue from 'vue';

const mutations = {
  USER_SUCCESS: (state, user) => {
    state.profileLoaded = true;
    state.profile.id = user.id;
    state.profile.firstName = user.firstName;
    state.profile.lastName = user.lastName;
    state.profile.email = user.email;
    state.profile.timeZone = user.timeZone;
    state.profile.systemAccessLevel = user.systemAccessLevel || '';
    state.profile.twoFactorEnabled = user.twoFactorEnabled;
    state.profile.mobile = user.mobile;
    state.profile.hasReportsManagement = user.hasReportsManagement;
    state.profile.hasDataManagement = user.hasDataManagement;
    state.profile.hasConnectors = user.hasConnectors;
    state.profile.hasAccounts = user.hasAccounts;
    vue.set(state.profile, 'twoFactorRecoveryCodes', user.twoFactorRecoveryCodes || []);
  },
  USER_UPDATE_PROFILE_SUCCESS: (state, profile) => {
    state.profile.firstName = profile.firstName;
    state.profile.lastName = profile.lastName;
    state.profile.mobile = profile.mobile;
  },
  USER_UPDATE_EMAIL_SUCCESS: (state, profile) => {
    state.profile.email = profile.email;
  },
  USER_TWO_FACTOR_ADD: (state, resp) => {
    state.profile.twoFactorEnabled = true;
    vue.set(state.profile, 'twoFactorRecoveryCodes', resp.data.codes);
  },
  USER_TWO_FACTOR_CODE_UPDATE: (state, resp) => {
    vue.set(state.profile, 'twoFactorRecoveryCodes', resp.data.codes);
  },
  USER_TWO_FACTOR_DISABLED: (state) => {
    state.profile.twoFactorEnabled = false;
    vue.set(state.profile, 'twoFactorRecoveryCodes', []);
  },
  USER_UPDATE_TIMEZONE_SUCCESS: (state, resp) => {
    state.profile.timeZone = resp.data.timeZone;
  },
  USER_GET_ACCOUNTS_SUCCESS: (state, accounts) => {
    state.accountsLoaded = true;
    vue.set(state, 'accounts', accounts);
  },
  USER_CHANGE_ACCOUNT_SUCCESS: (state, account) => {
    vue.set(state, 'activeAccount', {
      id: account.id,
      name: account.name,
      isActive: account.isActive,
      accessLevel: account.accessLevel,
      accountType: account.accountType,
      settings: account.settings || {},
      parentName: account.parentName,
      isManagedAccount: account.isManagedAccount,
      showPreview: account.showPreview,
      duplicateName: account.duplicateName,
      vertical: account.vertical,
      verticalId: account.verticalId,
      pages: account.pages || {},
    });
  },
  AUTH_LOGOUT: (state) => {
    state.profile = {};
    state.activeAccount = {};
    state.accountsLoaded = false;
    state.profileLoaded = false;
    state.accounts = [];
  },
};

export default mutations;
