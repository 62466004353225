import ComponentWrapper from '@/views/ComponentWrapper';
import * as layouts from './_layouts';
import * as views from './_views';

export default [
  {
    path: 'dashboard',
    component: layouts.ReportLayout,
    redirect: '/',
    children: [
      {
        path: ':accountType/:reportSlug',
        component: views.Report,
      },
    ],
  },
  {
    path: 'reports',
    component: ComponentWrapper,
    meta: {
      requireActive: true,
      isInternal: false,
    },
    children: [
      {
        path: '',
        name: 'Reports',
        component: views.Reports,
      },
    ],
  },
  {
    path: 'internalReports',
    component: ComponentWrapper,
    meta: {
      requireActive: false,
      requireSystemUser: true,
    },
    children: [
      {
        path: '',
        name: 'InternalReports',
        component: views.InternalReports,
      },
    ],
  },
];
