import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
  profileLoaded: false,
  profile: {
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    timeZone: '',
    systemAccessLevel: '',
    twoFactorEnabled: false,
    twoFactorRecoveryCodes: [],
    mobile: '',
    hasReportsManagement: false,
    hasDataManagement: false,
    hasConnectors: false,
    hasAccounts: false,
  },
  accountsLoaded: false,
  accounts: [],
  activeAccount: {
    id: null,
    name: null,
    isActive: null,
    accessLevel: null,
    accountType: null,
    settings: {},
    parentName: null,
    isManagedAccount: false,
    showPreview: false,
    duplicateName: false,
    vertical: '',
    verticalId: null,
    pages: {},
  },
};

export default {
  state,
  actions,
  getters,
  mutations,
};
