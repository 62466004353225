<template>
  <v-data-table
    :headers="headers"
    :items="products"
    :loading="loading"
    :options.sync="options"
    :server-items-length="previewCount"
    :footer-props="{
      'items-per-page-options': []
    }"
    :fixed-header="true"
    height="400"
    class="elevation-5"
  >
    <template #[`item.sourceTags`]="{ item }">
      {{ item.sourceTags ? item.sourceTags.join(', ') : '' }}
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import products from '../_api/products';

export default {
  name: 'ProductPreview',
  props: {
    filters: {
      type: Array,
      required: false,
      default: null,
    },
    globalRetailer: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      options: {
        sortBy: [
          'name',
        ],
        sortDesc: [
          false,
        ],
        itemsPerPage: 100,
      },
      products: [],
      previewCount: 0,
    };
  },
  computed: {
    ...mapGetters([
      'getActiveAccount',
    ]),
    ...mapState('$_tagging', {
      metadata: (state) => state.metadata,
    }),
    headers() {
      if (this.$route.meta.globalRetailer === true) {
        return this.metadata.RETAILER_PREVIEW || [];
      } if (this.getActiveAccount.accountType === 'Brand') {
        return this.metadata.BRAND_PREVIEW || [];
      }
      return this.metadata.RETAILER_PREVIEW || [];
    },
    computedAccountId() {
      return this.$route.meta.globalRetailer === true ? this.getActiveAccount.verticalId
        : this.getActiveAccount.id;
    },
    type() {
      return this.$route.meta.globalRetailer === true ? 'global' : 'account';
    },
  },
  watch: {
    filters() {
      this.options.page = 1;
      this.loading = true;
      this.previewChange();
    },
    options() {
      this.previewChange();
    },
  },
  methods: {
    async previewChange() {
      if (this.filters && this.filters.length > 0) {
        const {
          page, itemsPerPage,
        } = this.options;
        this.loading = true;
        const resp = await products.preview(
          this.type,
          this.computedAccountId,
          this.filters,
          itemsPerPage,
          (page - 1) * itemsPerPage,
        );
        this.products = resp.data.products;
        this.previewCount = resp.data.total;
        this.loading = false;
      }
    },
  },
};
</script>
