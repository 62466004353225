<template>
  <span>
    <v-row>
      <v-col
        cols="12"
        md="6"
        lg="4"
      >
        <v-checkbox
          v-model="ignoreWebOrdersLocal"
          label="Ignore Web Orders"
        />
        <div class="caption">
          When selected all orders that have from_ecommerce? flag marked as true will not be sent to
          Zaius.
        </div>
      </v-col>
      <v-col cols="12">
        <div class="subtitle-2">
          The following are the standard Zaius product fields that need to be mapped to a
          corresponding field in Heartland. Not all of the fields may map.
        </div>
      </v-col>
      <v-col
        cols="12"
        md="6"
        lg="4"
      >
        <ValidationProvider
          ref="brandValidation"
          v-slot="{ errors }"
          rules="heartland_unique:@UPC,@SKU,@Category1,@Category2,@Category3,@Category4"
          name="Brand"
        >
          <v-select
            v-model="brandLocal"
            :items="selectHeartlandItemColumns"
            :clearable="true"
            :error-messages="errors"
            item-text="name"
            item-value="value"
            label="Brand"
            :hint="`column: ${brandLocal}`"
            persistent-hint
          />
        </ValidationProvider>
      </v-col>
      <v-col
        cols="12"
        md="6"
        lg="4"
      >
        <ValidationProvider
          v-slot="{ errors }"
          rules="heartland_unique:@Brand,@SKU,@Category1,@Category2,@Category3,@Category4"
          name="UPC"
        >
          <v-select
            v-model="upcLocal"
            :items="selectHeartlandItemColumns"
            :clearable="true"
            :error-messages="errors"
            item-text="name"
            item-value="value"
            label="UPC"
            :hint="`column: ${upcLocal}`"
            persistent-hint
          />
        </ValidationProvider>
      </v-col>
      <v-col
        cols="12"
        md="6"
        lg="4"
      >
        <ValidationProvider
          v-slot="{ errors }"
          rules="heartland_unique:@Brand,@UPC,@Category1,@Category2,@Category3,@Category4"
          name="SKU"
        >
          <v-select
            v-model="skuLocal"
            :items="selectHeartlandItemColumns"
            :clearable="true"
            :error-messages="errors"
            item-text="name"
            item-value="value"
            label="SKU"
            :hint="`column: ${skuLocal}`"
            persistent-hint
          />
        </ValidationProvider>
      </v-col>
      <v-col
        cols="12"
        md="6"
        lg="4"
      >
        <ValidationProvider
          v-slot="{ errors }"
          rules="heartland_unique:@Brand,@UPC,@SKU,@Category2,@Category3,@Category4"
          name="Category1"
        >
          <v-select
            v-model="category1Local"
            :items="selectHeartlandItemColumns"
            :clearable="true"
            :error-messages="errors"
            item-text="name"
            item-value="value"
            label="Category Level 1"
            :hint="`column: ${category1Local}`"
            persistent-hint
          />
        </ValidationProvider>
      </v-col>
      <v-col
        cols="12"
        md="6"
        lg="4"
      >
        <ValidationProvider
          v-slot="{ errors }"
          rules="heartland_unique:@Brand,@UPC,@SKU,@Category1,@Category3,@Category4"
          name="Category2"
        >
          <v-select
            v-model="category2Local"
            :items="selectHeartlandItemColumns"
            :clearable="true"
            :disabled="!category1Local"
            :error-messages="errors"
            item-text="name"
            item-value="value"
            label="Category Level 2"
            :hint="`column: ${category2Local}`"
            persistent-hint
          />
        </ValidationProvider>
      </v-col>
      <v-col
        cols="12"
        md="6"
        lg="4"
      >
        <ValidationProvider
          v-slot="{ errors }"
          rules="heartland_unique:@Brand,@UPC,@SKU,@Category1,@Category2,@Category4"
          name="Category3"
        >
          <v-select
            v-model="category3Local"
            :items="selectHeartlandItemColumns"
            :clearable="true"
            :disabled="!category2Local"
            :error-messages="errors"
            item-text="name"
            item-value="value"
            label="Category Level 3"
            :hint="`column: ${category3Local}`"
            persistent-hint
          />
        </ValidationProvider>
      </v-col>
      <v-col
        cols="12"
        md="6"
        lg="4"
      >
        <ValidationProvider
          v-slot="{ errors }"
          rules="heartland_unique:@Brand,@UPC,@SKU,@Category1,@Category2,@Category3"
          name="Category4"
        >
          <v-select
            v-model="category4Local"
            :items="selectHeartlandItemColumns"
            :clearable="true"
            :disabled="!category3Local"
            :error-messages="errors"
            item-text="name"
            item-value="value"
            label="Category Level 4"
            :hint="`column: ${category4Local}`"
            persistent-hint
          />
        </ValidationProvider>
      </v-col>
    </v-row>
    <v-row
      v-if="customItemColumns.length > 0"
    >
      <v-col
        cols="12"
      >
        <v-divider />
      </v-col>
      <v-col cols="12">
        <div class="subtitle-2">
          The following custom fields when selected will be added as a custom field to the Zaius
          products table. Fields that are mapped above can not be added as a custom field.
        </div>
      </v-col>
      <v-col
        v-for="field in customItemColumns"
        :key="field.value"
        cols="12"
        md="6"
        lg="4"
      >
        <v-checkbox
          v-model="field.selected"
          :label="field.name"
          :disabled="field.disabled"
          :hint="`column: ${field.value}`"
          persistent-hint
        />
      </v-col>
    </v-row>
    <v-row
      v-if="customCustomerColumns.length > 0"
    >
      <v-col
        cols="12"
      >
        <v-divider />
      </v-col>
      <v-col cols="12">
        <div class="subtitle-2">
          The following custom fields when selected will be added as a custom field to the Zaius
          customers table.
        </div>
      </v-col>
      <v-col
        v-for="field in customCustomerColumns"
        :key="field.value"
        cols="12"
        md="6"
        lg="4"
      >
        <v-checkbox
          v-model="field.selected"
          :label="field.name"
          :hint="`column: ${field.value}`"
          persistent-hint
        />
      </v-col>
    </v-row>
  </span>
</template>

<script>
import vue from 'vue';
import { mapState } from 'vuex';

export default {
  name: 'ZaiusHeartlandDestination',
  props: {
    errorMessage: {
      type: String,
      required: false,
      default: '',
    },
    brand: {
      type: String,
      required: false,
      default: '',
    },
    upc: {
      type: String,
      required: false,
      default: '',
    },
    sku: {
      type: String,
      required: false,
      default: '',
    },
    category1: {
      type: String,
      required: false,
      default: '',
    },
    category2: {
      type: String,
      required: false,
      default: '',
    },
    category3: {
      type: String,
      required: false,
      default: '',
    },
    category4: {
      type: String,
      required: false,
      default: '',
    },
    selectedItemColumns: {
      type: Array,
      required: false,
      default: () => [],
    },
    selectedCustomerColumns: {
      type: Array,
      required: false,
      default: () => [],
    },
    ignoreWebOrders: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      brandLocal: this.brand,
      upcLocal: this.upc,
      skuLocal: this.sku,
      category1Local: this.category1,
      category2Local: this.category2,
      category3Local: this.category3,
      category4Local: this.category4,
      customItemColumns: [],
      ignoreWebOrdersLocal: this.ignoreWebOrders,
    };
  },
  computed: {
    ...mapState('$_sources', {
      heartlandItemColumns: (state) => state.heartlandItemColumns,
      heartlandCustomerColumns: (state) => state.heartlandCustomerColumns,
    }),
    selectHeartlandItemColumns() {
      return [
        {
          name: 'Primary Vendor',
          value: 'standard primary_vendor',
        },
        {
          name: 'Item #',
          value: 'standard public_id',
        },
      ].concat(this.heartlandItemColumns)
        .sort((a, b) => ((a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1));
    },
    customCustomerColumns() {
      const data = [];
      this.heartlandCustomerColumns.forEach((column) => {
        let selected = false;
        if (this.selectedCustomerColumns.includes(column.value)) {
          selected = true;
        }
        data.push({
          selected,
          ...column,
        });
      });
      return data.sort((a, b) => ((a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1));
    },
  },
  watch: {
    heartlandItemColumns() {
      this.buildCustomItemColumns();
    },
    brandLocal() {
      this.changeHeartlandItemStatus();
    },
    upcLocal() {
      this.changeHeartlandItemStatus();
    },
    skuLocal() {
      this.changeHeartlandItemStatus();
    },
    category1Local() {
      if (!this.category1Local) {
        this.category2Local = '';
      }
      this.changeHeartlandItemStatus();
    },
    category2Local() {
      if (!this.category2Local) {
        this.category3Local = '';
      }
      this.changeHeartlandItemStatus();
    },
    category3Local() {
      if (!this.category3Local) {
        this.category4Local = '';
      }
      this.changeHeartlandItemStatus();
    },
    category4Local() {
      this.changeHeartlandItemStatus();
    },
  },
  async mounted() {
    this.buildCustomItemColumns();
  },
  methods: {
    buildCustomItemColumns() {
      const data = [];
      this.heartlandItemColumns.forEach((column) => {
        let selected = false;
        let disabled = false;
        if (this.selectedItemColumns.includes(column.value)) {
          selected = true;
        }
        if (this.brandLocal === column.value) {
          disabled = true;
          selected = false;
        }
        data.push({
          selected,
          disabled,
          ...column,
        });
      });
      this.customItemColumns = data
        .sort((a, b) => ((a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1));
      this.changeHeartlandItemStatus();
    },
    changeHeartlandItemStatus() {
      const values = [this.brandLocal, this.upcLocal, this.skuLocal, this.category1Local,
        this.category2Local, this.category3Local, this.category4Local];
      this.customItemColumns.forEach((column) => {
        if (values.includes(column.value)) {
          vue.set(column, 'selected', false);
          vue.set(column, 'disabled', true);
        } else {
          vue.set(column, 'disabled', false);
        }
      });
    },
    getDetail() {
      const selectedItemColumns = [];
      this.customItemColumns.forEach((column) => {
        if (column.selected === true) {
          selectedItemColumns.push(column.value);
        }
      });
      const selectedCustomerColumns = [];
      this.customCustomerColumns.forEach((column) => {
        if (column.selected === true) {
          selectedCustomerColumns.push(column.value);
        }
      });
      return {
        brand: this.brandLocal,
        upc: this.upcLocal,
        sku: this.skuLocal,
        category1: this.category1Local,
        category2: this.category2Local,
        category3: this.category3Local,
        category4: this.category4Local,
        selectedItemColumns,
        selectedCustomerColumns,
        ignoreWebOrders: this.ignoreWebOrdersLocal,
      };
    },
  },
};
</script>
