<template>
  <v-row v-if="systemUser">
    <v-col
      cols="12"
      md="6"
      lg="4"
    >
      <ValidationProvider
        v-slot="{ errors }"
        :rules="{ required: { allowFalse: false } }"
        name="Send Validation Email"
        :disabled="isNew === false"
      >
        <v-checkbox
          v-model="sendEmail"
          label="Send Validation Email"
          :error-messages="errors"
        />
      </ValidationProvider>
      <div class="caption">
        Select this if you would like to send an email to a user to enter their validation
        credentials.  This will allow you to set up the source but not finalize it until the user
        has gone and entered their credentials.
      </div>
    </v-col>
    <v-col
      cols="12"
      md="6"
      lg="4"
    >
      <ValidationProvider
        ref="emailValidation"
        v-slot="{ errors }"
        rules="required|email"
        name="Email"
        :disabled="sendEmail === false"
      >
        <v-text-field
          v-model="email"
          label="Email"
          :error-messages="errors"
        />
      </ValidationProvider>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'OauthSource',
  props: {
    isNew: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      sendEmail: false,
      email: '',
    };
  },
  computed: {
    ...mapGetters([
      'systemUser',
    ]),
  },
  methods: {
    getDetail() {
      return {
        sendEmail: this.sendEmail,
        email: this.email,
      };
    },
  },
};
</script>
