<template>
  <div class="mt-5 mx-4 pb-16">
    <router-view />
  </div>
</template>

<script>
import admin from '../_stores/admin';

export default {
  name: 'AdminLayout',
  beforeCreate() {
    if (!this.$store.hasModule('admin')) {
      this.$store.registerModule('admin', admin);
    }
  },
};
</script>
