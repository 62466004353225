<template>
  <div class="ma-5 pb-16">
    <ProfileEdit />
    <Security />
  </div>
</template>

<script>
import store from '@/store';
import ProfileEdit from '../_components/ProfileEdit';
import Security from '../_components/Security';

export default {
  name: 'UserProfile',
  components: {
    ProfileEdit,
    Security,
  },
  async beforeRouteEnter(to, from, next) {
    await store.dispatch('USER_REQUEST');
    await next();
  },
};
</script>
