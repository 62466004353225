<template>
  <Wrapper>
    <h2 class="mb-2">
      Create Account
    </h2>
    <ValidationObserver ref="observer">
      <v-form
        @submit.prevent="changePassword"
      >
        <v-row dense>
          <v-col cols="12">
            <ValidationProvider
              ref="passwordValidationProvider"
              v-slot="{ errors }"
              rules="required"
              name="Password"
              vid="Password"
            >
              <v-text-field
                v-model="password"
                :append-icon="showPassword ? 'visibility' : 'visibility_off'"
                :type="showPassword ? 'text' : 'password'"
                label="Password"
                role="textbox"
                :error-messages="errors"
                @click:append="showPassword = !showPassword"
              />
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12">
            <ValidationProvider
              v-slot="{ errors }"
              rules="required|confirmed:Password"
              name="Confirm Pasword"
            >
              <v-text-field
                v-model="confirmPassword"
                :append-icon="showConfirmPassword ? 'visibility' : 'visibility_off'"
                :type="showConfirmPassword ? 'text' : 'password'"
                label="Confirm Password"
                role="textbox"
                :error-messages="errors"
                @click:append="showConfirmPassword = !showConfirmPassword"
              />
            </ValidationProvider>
            <PasswordValidation
              ref="passwordValidation"
              :password="password"
            />
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12">
            <v-btn
              :disabled="!validToken || success"
              block
              color="primary"
              type="submit"
              class="mt-2"
            >
              Change Password
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </ValidationObserver>
    <template #footer>
      <v-alert
        v-show="!validToken"
        aria-label="Invalid Reset Password Token"
        type="error"
        class="mt-3"
      >
        The reset password token has either expired or been previously used.
        <a
          class="white--text pl-1"
          href="/login"
        >
          Go to login
        </a>
      </v-alert>
      <v-alert
        v-show="success"
        aria-label="Password Reset Success"
        type="success"
        class="mt-3"
      >
        Your password has been successfully updated.
        <a
          class="white--text pl-1"
          href="/login"
        >
          Go to login
        </a>
      </v-alert>
    </template>
  </Wrapper>
</template>

<script>
import auth from '@/api/auth';
import PasswordValidation from '@/components/PasswordValidation';
import Wrapper from '../_components/Wrapper';

export default {
  name: 'ChangePassword',
  components: {
    PasswordValidation,
    Wrapper,
  },
  data() {
    return {
      password: '',
      confirmPassword: '',
      validToken: true,
      success: false,
      showPassword: false,
      showConfirmPassword: false,
    };
  },
  async created() {
    if (this.$route.query.t) {
      try {
        await auth.validateReset(this.$route.query.t);
      } catch (e) {
        this.validToken = false;
      }
    } else {
      this.validToken = false;
    }
  },
  methods: {
    async changePassword() {
      const isValid = await this.$refs.observer.validate();

      if (isValid) {
        if (!this.$refs.passwordValidation.isValid()) {
          this.$refs.passwordValidationProvider.setErrors(['Password does not meet the password policy']);
        } else {
          await auth.changePassword(this.$route.query.t, this.password);
          this.success = true;
        }
      }
    },
  },
};
</script>
