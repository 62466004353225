<template>
  <v-row>
    <v-col
      cols="12"
      md="6"
      lg="4"
    >
      <ValidationProvider
        ref="storeHashValidation"
        v-slot="{ errors }"
        rules="required"
        name="Store Hash"
      >
        <v-text-field
          v-model="storeHashLocal"
          label="Store Hash"
          :error-messages="errors"
        />
      </ValidationProvider>
    </v-col>
    <v-col
      cols="12"
      md="6"
      lg="4"
    >
      <ValidationProvider
        ref="accessTokenValidation"
        v-slot="{ errors }"
        rules="required"
        name="Access Token"
      >
        <v-text-field
          v-model="accessTokenLocal"
          label="Access Token"
          :error-messages="errors"
        />
      </ValidationProvider>
    </v-col>
    <v-col
      cols="12"
      md="6"
      lg="4"
    >
      <ValidationProvider
        ref="clientIdValidation"
        v-slot="{ errors }"
        rules="required"
        name="Client Id"
      >
        <v-text-field
          v-model="clientIdLocal"
          label="Client Id"
          :error-messages="errors"
        />
      </ValidationProvider>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'BigCommerce',
  props: {
    storeHash: {
      type: String,
      required: false,
      default: '',
    },
    accessToken: {
      type: String,
      required: false,
      default: '',
    },
    clientId: {
      type: String,
      required: false,
      default: '',
    },
    errorMessage: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      storeHashLocal: this.storeHash,
      accessTokenLocal: this.accessToken,
      clientIdLocal: this.clientId,
    };
  },
  watch: {
    errorMessage() {
      if (this.errorMessage === 'InvalidHash') {
        this.$refs.storeHashValidation.setErrors(['Invalid Store Hash']);
      }
      if (this.errorMessage === 'InvalidToken') {
        this.$refs.accessTokenValidation.setErrors(['Invalid Access Token or Client Id']);
      }
    },
  },
  methods: {
    getDetail() {
      return {
        storeHash: this.storeHashLocal,
        accessToken: this.accessTokenLocal,
        clientId: this.clientIdLocal,
      };
    },
  },
};
</script>
