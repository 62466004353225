<template>
  <v-container
    fluid
    class="pa-0"
  >
    <v-row>
      <v-col
        cols="12"
        md="6"
        lg="4"
      >
        <v-select
          v-model="ecomIdentifierTypeLocal"
          :items="identifierTypes"
          label="Web Transaction Identifier Type"
        />
        <div class="caption">
          Choose the type of identifier that is used for web transactions. This will be used in
          conjuction with the web transaction identifier field. Both fields must be filled in to
          identify web transactions.
          <ul>
            <li>Shop is shopId at the root of the order</li>
            <li>Register is registerId at the root of the order</li>
            <li>
              Tender Type is found in the order at SalePayments->SalePayment->PaymentType->type
            </li>
            <li>
              Tender Name is found in the order at SalePayments->SalePayment->PaymentType->name
            </li>
            <li>Reference Source is referenceNumberSource at the root of the order</li>
            <li>
              Reference Number is referenceNumber at the root of the order. This will match the
              given text to the beginnnig of the reference number and does not need to match the
              whole string.
            </li>
            <li>
              Salesperson is not shown directly in the data. The order data shows an employeeID
              that is used when pulling the data to map back to the salesperson's name.
            </li>
          </ul>
        </div>
      </v-col>
      <v-col
        cols="12"
        md="6"
        lg="4"
      >
        <v-textarea
          v-model.trim="ecomIdentifierLocal"
          outlined
          rows="4"
          label="Web Transaction Identifier"
        />
        <div class="caption">
          If applicable, enter the shop id, register id, tender type, tender name, or reference
          source that all web transactions are associated with. Enter each value as a new row.
        </div>
      </v-col>
      <v-col
        cols="12"
        md="6"
        lg="4"
      >
        <v-textarea
          v-model.trim="inventoryStoreIgnoreLocal"
          outlined
          rows="4"
          label="Inventory Ignore"
        />
        <div class="caption">
          Enter the store id for any store where the inventory should be ignored. This should be
          used when a store rolls up all other stores or is no longer active.
        </div>
      </v-col>
    </v-row>
    <oauth
      ref="oauthControl"
      :is-new="isNew"
    />
  </v-container>
</template>

<script>
import lightspeed from '../../../_api/lightspeed';
import Oauth from './Oauth';

export default {
  name: 'LightspeedSource',
  components: {
    Oauth,
  },
  props: {
    id: {
      type: String,
      required: false,
      default: '',
    },
    isNew: {
      type: Boolean,
      required: false,
      default: false,
    },
    ecomIdentifierType: {
      type: String,
      required: false,
      default: 'shop',
    },
    ecomIdentifier: {
      type: Array,
      required: false,
      default: () => [],
    },
    inventoryStoreIgnore: {
      type: Array,
      required: false,
      default: () => [],
    },
    errorMessage: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      ecomIdentifierTypeLocal: this.ecomIdentifierType,
      ecomIdentifierLocal: this.ecomIdentifier.join('\n'),
      inventoryStoreIgnoreLocal: this.inventoryStoreIgnore.join('\n'),
      identifierTypes: [
        'reference source',
        'reference number',
        'register',
        'salesperson',
        'shop',
        'tender name',
        'tender type',
      ],
    };
  },
  methods: {
    getDetail() {
      return {
        ecomIdentifierType: this.ecomIdentifierTypeLocal,
        ecomIdentifier: this.ecomIdentifierLocal.split('\n')
          .filter((x) => x !== '')
          .map((x) => x.toLowerCase().trim()),
        inventoryStoreIgnore: this.inventoryStoreIgnoreLocal.split('\n')
          .filter((x) => x !== '')
          .map((x) => x.toLowerCase().trim()),
        ...this.$refs.oauthControl.getDetail(),
      };
    },
    async preview() {
      if (this.id) {
        try {
          const result = await lightspeed.preview(this.id);
          return result.data;
        } catch (e) {
        // ignore
        }
      }
      return null;
    },
  },
};
</script>
