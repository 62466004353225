<template>
  <div data-testid="uq-login-form">
    <div class="title">
      Login
    </div>
    <ValidationObserver ref="observer">
      <v-form
        class="mt-3"
        @submit.prevent="login"
      >
        <ValidationProvider
          ref="emailValidation"
          v-slot="{ errors }"
          rules="required|email"
          name="Email"
        >
          <v-text-field
            v-model="email"
            label="Email"
            :error-messages="errors"
            required
          />
        </ValidationProvider>
        <ValidationProvider
          v-slot="{ errors }"
          rules="required"
          name="Password"
        >
          <v-text-field
            v-model="password"
            role="textbox"
            label="Password"
            :append-icon="showPassword ? 'visibility' : 'visibility_off'"
            :type="showPassword ? 'text' : 'password'"
            :error-messages="errors"
            @click:append="showPassword = !showPassword"
          />
        </ValidationProvider>
        <v-btn
          text
          small
          color="primary"
          to="reset"
          class="ma-0 pa-0"
        >
          Forgot password?
        </v-btn>
        <v-btn
          block
          color="primary"
          type="submit"
          class="mt-2"
        >
          Login
        </v-btn>
      </v-form>
    </ValidationObserver>
  </div>
</template>

<script>
export default {
  name: 'LoginForm',
  data() {
    return {
      email: '',
      password: '',
      showPassword: false,
    };
  },
  methods: {
    async login() {
      const { email, password } = this;
      const isValid = await this.$refs.observer.validate();

      if (isValid) {
        try {
          const resp = await this.$store.dispatch('AUTH_REQUEST', { email, password });
          if (resp.data.status === 'Success') {
            this.$emit('LoginSuccess');
          } else if (resp.data.status === 'TwoFactorRequired') {
            this.$emit('TwoFactorRequired', {
              email,
              password,
            });
          }
        } catch (error) {
          if (error.response) {
            if (error.response.data === 'InvalidEmailPassword') {
              this.$refs.emailValidation.setErrors(['Invalid email or password']);
            } else if (error.response.data === 'AccountLocked') {
              this.$refs.emailValidation.setErrors(['Account locked out for too many failed attempts']);
            }
          }
        }
      }
    },
  },
};
</script>
