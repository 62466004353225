<template>
  <v-container
    fluid
    class="pa-0"
  >
    <v-row>
      <v-col
        cols="12"
        md="6"
        lg="4"
      >
        <ValidationProvider
          v-slot="{ errors }"
          rules="required"
          name="Store Name"
        >
          <v-text-field
            v-model="storeNameLocal"
            label="Store Name"
            suffix=".myshopify.com"
            :error-messages="errors"
            :disabled="!isNew"
          />
        </ValidationProvider>
      </v-col>
      <v-col
        cols="12"
        md="6"
        lg="4"
      >
        <v-select
          v-model="appTypeLocal"
          :items="appTypes"
          label="App Type"
        />
        <div class="caption">
          Starting in 2022 private apps have been deprecated. All new implementations should use
          custom apps.
        </div>
      </v-col>
      <v-col
        v-if="appTypeLocal === 'private'"
        cols="12"
        md="6"
        lg="4"
      >
        <ValidationProvider
          ref="apiKeyValidation"
          v-slot="{ errors }"
          rules="required"
          name="API Key"
        >
          <v-text-field
            v-model="apiKeyLocal"
            label="API Key"
            :error-messages="errors"
          />
        </ValidationProvider>
      </v-col>
      <v-col
        v-if="appTypeLocal === 'private'"
        cols="12"
        md="6"
        lg="4"
      >
        <ValidationProvider
          v-slot="{ errors }"
          rules="required"
          name="Password"
        >
          <v-text-field
            v-model="passwordLocal"
            label="Password"
            :error-messages="errors"
          />
        </ValidationProvider>
      </v-col>
      <v-col
        v-if="appTypeLocal === 'custom'"
        cols="12"
        md="6"
        lg="4"
      >
        <ValidationProvider
          ref="accessTokenValidation"
          v-slot="{ errors }"
          rules="required"
          name="AccessToken"
        >
          <v-text-field
            v-model="accessTokenLocal"
            label="Access Token"
            :error-messages="errors"
          />
        </ValidationProvider>
      </v-col>
      <v-col
        cols="12"
        md="6"
        lg="4"
      >
        <v-select
          v-model="ignoredInventoryLocationsLocal"
          :items="locations"
          item-text="name"
          item-value="id"
          label="Ignored Inventory Locations"
          clearable
          persistent-hint
          multiple
        />
        <div class="caption mt-3">
          Selected locations will not have their inventory sent to any destinations.
        </div>
      </v-col>
      <v-col
        cols="12"
        md="6"
        lg="4"
      >
        <v-checkbox
          v-model="isGqlSalespersonFetchEnabledLocal"
          label="Include Salesperson"
        />
        <div class="caption mt-3">
          The app must be created and installed on a Shopify Plus or Advanced store, and
          the owner must contact Shopify support to enable <i>read_users</i> access.
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        v-if="appTypeLocal === 'custom'"
        cols="12"
        md="6"
      >
        To enable the pulling of data a custom app must be created in the shopify store. Follow
        these steps to enable that.
        <ol>
          <li>From your Shopify admin, go to Apps.</li>
          <li>Click Develop apps.</li>
          <li>Click Allow custom app development.</li>
          <li>
            Read the warning and information provided, and then click Allow custom app development.
          </li>
          <li>Click Create a custom app.</li>
          <li>
            In the modal window, enter the App name and select an App developer. The app developer
            can be the store owner, or any staff or collaborator account with the Develop apps
            permission.
          </li>
          <li>Click Create app.</li>
          <li>Click configure Admin API scopes.</li>
          <li>
            Give the following permissions
            <ul>
              <li>read_customers</li>
              <li>read_inventory</li>
              <li>read_locations</li>
              <li>read_orders</li>
              <li>read_products</li>
            </ul>
          </li>
          <li>Click Save.</li>
          <li>Click Install app.</li>
          <li>In the modal window, click Install app.</li>
          <li>Under Admin API access token click reveal token once</li>
          <li>Copy the token into the access token field above.</li>
        </ol>
      </v-col>
      <v-col
        v-if="appTypeLocal === 'private'"
        cols="12"
        md="6"
      >
        To enable the pulling of data a private app must be created in the shopify store. Follow
        these steps to enable that.
        <ol>
          <li>
            Log in to your Shopify Store Admin account <a
              href="https://accounts.shopify.com/store-login"
              target="_blank"
            >here</a>.
          </li>
          <li>
            In the left menu bar, click Apps, and then, Manage private apps at the bottom of the
            next screen.
          </li>
          <li>
            If prompted click to enable creating private apps.
          </li>
          <li>
            Click Create new private app.
          </li>
          <li>
            In app details:
            <ol>
              <li>Provide a name for the private app (UQ Cadence).</li>
              <li>
                In Emergency developer email, enter the email address that Shopify should use
                for contacting you regarding your app (shopify@upperquadrant.com).
              </li>
            </ol>
          </li>
          <li>
            Under Admin API, click Show inactive Admin API permissions to view the list of Shopify
            API resources and the permissions you can configure for them.
          </li>
          <li>
            Specify Read Access permission for the following resources:
            <ul>
              <li>Customers</li>
              <li>Inventory</li>
              <li>Locations</li>
              <li>Orders</li>
              <li>Products</li>
            </ul>
          </li>
          <li>Click Save. Click Create app in the confirmation dialog box.</li>
          <li>Copy the API Key and Password fields into the boxes above.</li>
        </ol>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { debounce } from 'lodash';
import shopify from '../../../_api/shopify';

export default {
  name: 'ShopifySource',
  props: {
    isNew: {
      type: Boolean,
      required: false,
      default: false,
    },
    storeName: {
      type: String,
      required: false,
      default: '',
    },
    apiKey: {
      type: String,
      required: false,
      default: '',
    },
    password: {
      type: String,
      required: false,
      default: '',
    },
    ignoredInventoryLocations: {
      type: Array,
      required: false,
      default: () => [],
    },
    isGqlSalespersonFetchEnabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    appType: {
      type: String,
      required: false,
      default: 'custom',
    },
    accessToken: {
      type: String,
      required: false,
      default: '',
    },
    errorMessage: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      storeNameLocal: this.storeName,
      apiKeyLocal: this.apiKey,
      passwordLocal: this.password,
      locations: [],
      ignoredInventoryLocationsLocal: this.ignoredInventoryLocations,
      isGqlSalespersonFetchEnabledLocal: this.isGqlSalespersonFetchEnabled,
      appTypeLocal: this.appType,
      accessTokenLocal: this.accessToken,
      appTypes: [
        'custom',
        'private',
      ],
    };
  },
  watch: {
    errorMessage() {
      if (this.errorMessage === 'InvalidAuth') {
        this.$refs.apiKeyValidation.setErrors(['Invalid Store Name, API Key, Password, or Access Level']);
      }
    },
    async storeNameLocal() {
      await this.getLocations();
    },
    async apiKeyLocal() {
      await this.getLocations();
    },
    async passwordLocal() {
      await this.getLocations();
    },
    async appTypeLocal() {
      await this.getLocations();
    },
    async accessTokenLocal() {
      await this.getLocations();
    },
  },
  created() {
    this.getLocations();
    this.getLocations = debounce(this.getLocations, 1000);
  },
  methods: {
    getDetail() {
      return {
        storeName: this.storeNameLocal,
        apiKey: this.apiKeyLocal,
        password: this.passwordLocal,
        ignoredInventoryLocations: this.ignoredInventoryLocationsLocal,
        isGqlSalespersonFetchEnabled: this.isGqlSalespersonFetchEnabledLocal,
        appType: this.appTypeLocal,
        accessToken: this.accessTokenLocal,
      };
    },
    async getLocations() {
      if (this.storeNameLocal && ((this.appTypeLocal === 'private' && this.apiKeyLocal && this.passwordLocal)
        || (this.appTypeLocal === 'custom' && this.accessTokenLocal))) {
        try {
          const result = await shopify.getLocations(
            this.appTypeLocal,
            this.storeNameLocal,
            this.apiKeyLocal,
            this.passwordLocal,
            this.accessTokenLocal,
          );
          this.locations = result.data;
        } catch (e) {
          if (e.response && e.response.data === 'InvalidAuth') {
            if (this.appTypeLocal === 'private') {
              this.$refs.apiKeyValidation.setErrors(['Invalid Store Name, API Key, Password, or Access Level']);
            } else {
              this.$refs.accessTokenValidation.setErrors(['Invalid Store Name, Access Token, or Access Level']);
            }
          }
          throw (e);
        }
      }
    },
  },
};
</script>
