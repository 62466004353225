<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        class="title"
      >
        Two-Factor Authentication
      </v-col>
    </v-row>
    <div v-if="!getProfile.twoFactorEnabled">
      <v-row no-gutters>
        <v-col cols="12">
          <v-btn
            outlined
            color="secondary"
            @click.stop="getInit"
          >
            Enable Two-Factor
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          class="caption mt-3 mt-lg-0"
        >
          Two-factor authentication provides stronger security by requiring a second step of
          verification when you sign in. In addition to your password, you'll also need a code
          generated by a registered device (the Google Authenticator app on your phone).
        </v-col>
      </v-row>
    </div>
    <div v-if="getProfile.twoFactorEnabled">
      <v-row no-gutters>
        <v-col cols="12">
          <v-btn
            v-if="!systemUser"
            color="red darken-4"
            dark
            @click.prevent="showDisable = true"
          >
            Disable Two-Factor
          </v-btn>
          <v-btn
            v-if="systemUser"
            outlined
            color="secondary"
            @click.stop="getInit"
          >
            Set up new device
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          class="caption mt-3 mt-lg-0"
        >
          Two-factor authentication provides stronger security by requiring a second step of
          verification when you sign in. In addition to your password, you'll also need a code
          generated by a registered device (the Google Authenticator app on your phone).

          <v-row
            class="mt-4"
            no-gutters
          >
            <v-col class="subtitle-1 font-weight-bold">
              Recovery Codes
            </v-col>
            <v-col class="text-muted">
              <v-tooltip left>
                <template #activator="{ on }">
                  <v-btn
                    text
                    icon
                    v-on="on"
                    @click.prevent="generate"
                  >
                    <v-icon>refresh</v-icon>
                  </v-btn>
                </template>
                <span>Generate new recovery codes</span>
              </v-tooltip>
              <v-tooltip right>
                <template #activator="{ on }">
                  <v-btn
                    text
                    icon
                    v-on="on"
                    @click.prevent="download"
                  >
                    <v-icon>save_alt</v-icon>
                  </v-btn>
                </template>
                <span>Download recovery codes</span>
              </v-tooltip>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col>
              <div class="caption">
                If you lose your phones or can't get codes via Google Authenticator, you can use
                recovery codes to login. It is highly recommended you
                <a
                  class="primary--text"
                  @click.prevent="download"
                  @keypress.enter="download"
                >
                  download
                </a>
                and secure your recovery codes. A recovery code can only be used once.
              </div>

              <ul class="mt-3 pl-0">
                <li
                  v-for="item in getFormattedCodes"
                  :key="item"
                >
                  {{ item }}
                </li>
              </ul>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
    <v-dialog
      v-model="showAdd"
      max-width="500"
      persistent
    >
      <ValidationObserver ref="observer">
        <v-form
          @submit.prevent="verify"
        >
          <v-card>
            <v-card-title>
              Enabling Two-Factor Authentication
            </v-card-title>
            <v-card-text class="pb-0">
              <div>
                <h5>Install the Google Authenticator app on your phone.</h5>
                <ol>
                  <li>On your phone, open your App/Play store.</li>
                  <li>
                    Search for
                    <strong>Google Authenticator</strong>
                  </li>
                  <li>
                    Download and install the app
                    <ul>
                      <li>
                        <v-btn
                          text
                          small
                          class="pa-0 text-none"
                          color="primary"
                          href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en_US"
                          target="_blank"
                        >
                          Download from the Google Play Store
                        </v-btn>
                      </li>
                      <li>
                        <v-btn
                          text
                          small
                          class="pa-0 text-none"
                          color="primary"
                          href="https://itunes.apple.com/gw/app/google-authenticator/id388497605?mt=8"
                          target="_blank"
                        >
                          Download from the Apple App Store
                        </v-btn>
                      </li>
                    </ul>
                  </li>
                </ol>
              </div>
              <div>
                <h5>Now open and configure Google Authenticator.</h5>
                <ol>
                  <li>In the Google Authenticator, touch the menu and select "Set up account"</li>
                  <li>Select "Scan a barcode."</li>
                  <li>Use your phone's camera to scan this barcode.</li>
                </ol>
                <div>
                  <img
                    :src="twoFactorImage"
                    alt="Two-Factor Image"
                  >
                </div>
                <p>
                  <v-btn
                    text
                    small
                    class="pa-0 text-none"
                    color="primary"
                    @click.prevent="showSecret = true"
                  >
                    Can't scan the barcode?
                  </v-btn>
                </p>
                <div v-show="showSecret">
                  <p>
                    When adding the account in Google Authenticator app choose "Enter a provided
                    key" option and use the following.<br>
                    {{ twoFactorSecret }}
                  </p>
                </div>
                <p>
                  Once you have scanned the barcode, enter the 6-digit verification code generated
                  by the Authentication app.
                </p>
              </div>
              <ValidationProvider
                ref="codeValidation"
                v-slot="{ errors }"
                rules="required|numeric|code:6"
                name="Code"
              >
                <v-text-field
                  v-model="code"
                  label="6-digit code"
                  :error-messages="errors"
                  maxlength="6"
                  outlined
                />
              </ValidationProvider>
            </v-card-text>
            <v-card-actions>
              <v-btn
                color="primary"
                type="submit"
              >
                Verify and Save
              </v-btn>
              <v-btn
                outlined
                color="primary"
                class="ml-1"
                @click="cancel"
              >
                Cancel
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </ValidationObserver>
    </v-dialog>
    <v-dialog
      v-model="showDisable"
      max-width="500"
      persistent
    >
      <v-card>
        <v-card-text class="text-center pt-3">
          <div>
            <v-icon
              color="red darken-4"
              x-large
            >
              warning
            </v-icon>
          </div>
          <div class="title text--darken-4 red--text">
            Disable Two-Factor Authentication
          </div>
          <div class="subtitle mt-2">
            Warning: removes a security layer
          </div>
        </v-card-text>
        <v-card-actions class="pb-3">
          <div class="text-center flex">
            <v-btn
              dark
              color="red darken-4"
              @click.prevent="disable"
            >
              Disable Two-Factor
            </v-btn>
            <v-btn
              outlined
              color="grey"
              class="ml-1"
              @click="showDisable = false"
            >
              Cancel
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import api from '@/api/user';
import { saveAs } from 'file-saver';
import { mapGetters } from 'vuex';

export default {
  name: 'TwoFactor',
  data() {
    return {
      twoFactorImage: null,
      twoFactorSecret: '',
      code: '',
      showSecret: false,
      showAdd: false,
      showDisable: false,
    };
  },
  computed: {
    ...mapGetters([
      'getProfile',
      'getFormattedCodes',
      'systemUser',
    ]),
  },
  methods: {
    async getInit() {
      const result = await api.getTwoFactorInit();
      this.twoFactorImage = result.data.imageUrl;
      this.twoFactorSecret = result.data.secret;
      this.showAdd = true;
    },
    async verify() {
      try {
        const isValid = await this.$refs.observer.validate();

        if (isValid) {
          await this.$store.dispatch('USER_VERIFY_TWO_FACTOR', this.code);
          this.code = '';
          this.showAdd = false;
          this.showSecret = false;
        }
      } catch (error) {
        if (error.response) {
          if (error.response.data === 'InvalidToken') {
            this.$refs.codeValidation.setErrors(['Invalid code']);
          }
        }
      }
    },
    cancel() {
      this.code = '';
      this.$refs.observer.reset();
      this.showAdd = false;
    },
    async download() {
      const blob = new Blob(
        [this.getFormattedCodes.join('\r\n')],
        { type: 'text/plain;charset=utf-8' },
      );
      saveAs(blob, 'UQ Cadence Recovery Codes.txt');
    },
    async generate() {
      await this.$store.dispatch('USER_GENERATE_TWO_FACTOR_CODES');
    },
    async disable() {
      await this.$store.dispatch('USER_DISABLE_TWO_FACTOR');
      this.showDisable = false;
    },
  },
};
</script>

<style scoped>
  ul {
    list-style: none;
  }
</style>
