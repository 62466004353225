<template>
  <v-col
    cols="12"
    md="6"
    lg="4"
  >
    <v-checkbox
      v-model="ignoreWebOrdersLocal"
      label="Ignore Web Orders"
    />
    <div class="caption">
      When selected all orders that are of type web orders will not be loaded.
    </div>
  </v-col>
</template>

<script>
export default {
  name: 'IgnoreWebOrders',
  props: {
    ignoreWebOrders: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      ignoreWebOrdersLocal: this.ignoreWebOrders,
    };
  },
  methods: {
    getDetail() {
      return {
        ignoreWebOrders: this.ignoreWebOrdersLocal,
      };
    },
  },
};
</script>
