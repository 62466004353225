<template>
  <v-container
    fluid
    class="pa-0"
  >
    <v-row v-if="sourceType === 1 || sourceType === 8">
      <ignore-web-orders
        ref="ignoreWebOrders"
        v-bind="sourceDetail"
      />
    </v-row>
    <race
      v-if="sourceType === 4 || sourceType === 5 || sourceType === 7"
      ref="race"
      v-bind="sourceDetail"
    />
    <consent
      v-if="sourceType === 8 || sourceType === 14"
      ref="consent"
      :show-phone-consent="sourceType === 8"
      v-bind="sourceDetail"
    />
    <heartland
      v-if="sourceType === 10"
      ref="heartland"
      v-bind="sourceDetail"
    />
    <product-u-p-c
      v-if="sourceType === 2"
      ref="productUPC"
      v-bind="sourceDetail"
    />
    <discount
      v-if="sourceType === 1 || sourceType === 2"
      ref="discount"
      v-bind="sourceDetail"
    />
    <runit
      v-if="sourceType === 14"
      ref="runit"
      v-bind="sourceDetail"
    />
    <lists
      ref="lists"
      :source-type="sourceType"
      :destination-detail="destinationDetail"
      v-bind="sourceDetail"
    />
  </v-container>
</template>

<script>
import Heartland from './Zaius/Heartland';
import Lists from './Zaius/Lists';
import Race from './Zaius/Race';
import Consent from './Components/Consent';
import ProductUPC from './Zaius/ProductUPC';
import Discount from './Zaius/Discount';
import Runit from './Zaius/Runit';
import IgnoreWebOrders from './Components/IgnoreWebOrders';

export default {
  name: 'ZaiusDestination',
  components: {
    Heartland,
    Lists,
    Race,
    Consent,
    ProductUPC,
    Discount,
    Runit,
    IgnoreWebOrders,
  },
  props: {
    sourceType: {
      type: Number,
      required: false,
      default: 0,
    },
    sourceDetail: {
      type: Object,
      required: false,
      default: null,
    },
    destinationDetail: {
      type: Object,
      required: false,
      default: null,
    },
    errorMessage: {
      type: String,
      required: false,
      default: '',
    },
  },
  methods: {
    getDetail() {
      const listData = {
        ...this.$refs.lists.getDetail(),
      };

      switch (this.sourceType) {
        case 1:
          return {
            ...this.$refs.ignoreWebOrders.getDetail(),
            ...this.$refs.discount.getDetail(),
            ...listData,
          };

        case 2:
          return {
            ...this.$refs.productUPC.getDetail(),
            ...this.$refs.discount.getDetail(),
            ...listData,
          };

        case 4:
        case 5:
        case 7:
          return {
            ...this.$refs.race.getDetail(),
            ...listData,
          };

        case 8:
          return {
            ...this.$refs.ignoreWebOrders.getDetail(),
            ...this.$refs.consent.getDetail(),
            ...listData,
          };

        case 10: {
          return {
            ...this.$refs.heartland.getDetail(),
            ...listData,
          };
        }

        case 14: {
          return {
            ...this.$refs.runit.getDetail(),
            ...this.$refs.consent.getDetail(),
            ...listData,
          };
        }

        default:
          return listData;
      }
    },
  },
};
</script>
