<!-- eslint-disable vue/no-v-html -->
<template>
  <v-expand-x-transition>
    <v-hover v-slot="{ hover }">
      <v-sheet
        v-show="value"
        ref="guideWrapper"
        :class="['ql-wrapper', expanded ? 'ql-expanded' : '']"
        :elevation="$vuetify.breakpoint.smAndDown ? 24 : 0"
        :style="{
          maxWidth: $vuetify.breakpoint.smAndDown ? '100%' : '',
          position: $vuetify.breakpoint.smAndDown ? 'absolute' : '',
          right: $vuetify.breakpoint.smAndDown ? '0' : ''
        }"
      >
        <v-fade-transition appear>
          <v-btn
            v-if="!$vuetify.breakpoint.smAndDown && (expanded || hover)"
            fab
            icon
            small
            right
            absolute
            style="top: 8px; right: 24px;"
            @click="expanded = !expanded"
          >
            <v-icon>
              {{ expanded ? 'mdi-arrow-collapse' : 'mdi-arrow-expand' }}
            </v-icon>
          </v-btn>
        </v-fade-transition>

        <div
          class="pl-3 pt-3 pr-2 pb-5"
          :style="[
            'height: calc(100vh - 132px)',
            'overflow-y: scroll',
            'border-left: 1px solid rgba(0,0,0,.12)'
          ].join(';')"
        >
          <div
            style="max-width: 700px; margin: 0 auto"
            v-html="guide"
          />
        </div>
      </v-sheet>
    </v-hover>
  </v-expand-x-transition>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'ReportHelpDrawer',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      expanded: false,
    };
  },
  computed: {
    ...mapState('page/report', {
      guide: ({ report }) => report.guide,
    }),
  },
  watch: {
    value(newValue, oldValue) {
      if (oldValue && !newValue && this.expanded) {
        this.$nextTick(() => {
          this.expanded = false;
        });
      }
    },
  },
};
</script>

<style lang="css" scoped>
  .ql-wrapper {
    width: 100%;
    max-width: 475px;
    top: 0;
    left: 0;
  }
  .ql-wrapper.ql-expanded {
    max-width: initial;
    position: absolute;
    right: 0;
  }
</style>
