import axios from '@/utils/axios';

/*
  TYPE is either global or account based on whether we are doing global tagging or account level
  ID represents either the vertical id or the account id depending on the type
*/
const getTags = (type, id) => axios.get(`/products/tagging/${type}/${id}`);
const getTag = (type, id, tagRuleId) => axios.get(`/products/tagging/${type}/${id}/${tagRuleId}`);
const createTag = (type, id, filters, name, tags, category, gender, family, category2, category3, category4, category5, color, extensionField1, extensionField2, extensionField3, extensionField4, extensionField5) => axios.post(`/products/tagging/${type}/${id}`, {
  filters,
  name,
  tags,
  category,
  gender,
  family,
  category2,
  category3,
  category4,
  category5,
  color,
  extensionField1,
  extensionField2,
  extensionField3,
  extensionField4,
  extensionField5,
});
const updateTag = (type, id, tagRuleId, filters, name, tags, category, gender, family, category2, category3, category4, category5, color, extensionField1, extensionField2, extensionField3, extensionField4, extensionField5) => axios.put(`/products/tagging/${type}/${id}/${tagRuleId}`, {
  filters,
  name,
  tags,
  category,
  gender,
  family,
  category2,
  category3,
  category4,
  category5,
  color,
  extensionField1,
  extensionField2,
  extensionField3,
  extensionField4,
  extensionField5,
});
const deleteTag = (type, id, tagRuleId) => axios.delete(`/products/tagging/${type}/${id}/${tagRuleId}`);
const reorder = (type, id, ruleIds) => axios.put(`/products/tagging/${type}/${id}/reorder`, {
  ruleIds,
});
const getLists = (type, id) => axios.get(`/products/tagging/${type}/${id}/lists`);
const getMetadata = (verticalId) => axios.get(`/products/tagging/metadata/${verticalId}`);

export default {
  getTags,
  getTag,
  createTag,
  updateTag,
  deleteTag,
  reorder,
  getLists,
  getMetadata,
};
