import * as views from './_views';

export default [
  {
    path: 'deactivated',
    component: views.DeactivatedAccount,
    meta: {
      requiresDeactivated: true,
    },
  },
  {
    path: 'noAccounts',
    component: views.NoAccounts,
    meta: {
      requiresNoAccounts: true,
    },
  },
  {
    path: 'accountChange',
    component: views.AccountChange,
  },
];
