import vue from 'vue';

function assignLists(state) {
  if (state.metadata && state.lists) {
    state.metadata.BRAND_COLUMNS.forEach((column) => {
      if (column.type === 'List' && column.source) {
        vue.set(column, 'values', state.lists[column.source]);
      }
    });
    state.metadata.RETAILER_COLUMNS.forEach((column) => {
      if (column.type === 'List' && column.source) {
        vue.set(column, 'values', state.lists[column.source]);
      }
    });
  }
}

const mutations = {
  TAGGING_SETACCOUNTID: (state, accountId) => {
    state.accountId = accountId;
  },
  TAGGING_SETTYPE: (state, type) => {
    state.type = type;
  },
  TAGGING_SETLISTS: (state, lists) => {
    Object.keys(lists).forEach((key) => {
      lists[key].sort((a, b) => ((a.toLowerCase() > b.toLowerCase()) ? 1 : -1));
    });
    vue.set(state, 'lists', lists);
    assignLists(state);
  },
  TAGGING_SETMETADATA: (state, data) => {
    state.verticalId = data.verticalId;
    vue.set(state, 'metadata', data.metadata);
    assignLists(state);
  },
};

export default mutations;
