<template>
  <NavList :nav-items="navItems" />
</template>

<script>
import { mapGetters } from 'vuex';
import NavList from './NavList';

export default {
  name: 'SystemAdmin',
  components: {
    NavList,
  },
  data() {
    return {
      navItems: [
        {
          divider: true,
        },
        {
          text: 'INTERNAL GLOBAL SETTINGS',
          header: true,
        },
        {
          text: 'Accounts',
          route: '/admin/accounts',
          icon: 'heart',
          requireSystemPermission: 'hasAccounts',
        },
        {
          text: 'Reports Admin',
          icon: 'chart-simple',
          route: '/admin/reports',
          requireSystemPermission: 'hasReportsManagement',
        },
        {
          text: 'Internal Reports',
          route: '/internalReports',
          icon: 'chart-user',
        },
        {
          text: 'Email List Management',
          route: '/admin/emailListManagement',
          icon: 'list',
          requireSystemPermission: 'hasDataManagement',
        },
        {
          text: 'TAGGING & MAPPING',
          header: true,
          requireSystemPermission: 'hasDataManagement',
        },
        {
          text: 'Global Retailer Tagging',
          route: '/product/globalRetailerTagging',
          icon: 'tags',
          requireSystemPermission: 'hasDataManagement',
          requirePagePermission: 'globalTagging',
        },
        {
          text: 'Global Brand Mapping',
          route: '/product/brandMapping',
          icon: 'chart-tree-map',
          requireSystemPermission: 'hasDataManagement',
          requirePagePermission: 'brandMapping',
        },
        {
          text: 'Retailer Product Overview',
          route: '/admin/productSummary',
          icon: 'store',
          requireSystemPermission: 'hasDataManagement',
          requirePagePermission: 'retailerProductOverview',
        },
        {
          text: 'Brand Product Overview',
          route: '/admin/brandProductSummary',
          icon: 'copyright',
          requireSystemPermission: 'hasDataManagement',
          requirePagePermission: 'brandProductOverview',
        },
        {
          text: 'SYSTEM ADMIN',
          header: true,
          requireSystemAdmin: true,
        },
        {
          text: 'Admin Users',
          route: '/admin/users',
          icon: 'users',
          requireSystemAdmin: true,
        },
        {
          text: 'Report Management',
          icon: 'file-chart-column',
          route: '/admin/reportManagement',
          requireSystemAdmin: true,
        },
        {
          text: 'Vertical Management',
          route: '/admin/verticalManagement',
          icon: 'rectangle-vertical-history',
          requireSystemAdmin: true,
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      'systemUser',
    ]),
  },
};
</script>
