<template>
  <v-row>
    <v-col
      v-if="showEmailConsent"
      cols="12"
      md="6"
      lg="4"
    >
      <ValidationProvider
        v-slot="{ errors }"
        rules="required"
        name="Email Consent"
      >
        <v-select
          v-model="emailConsentLocal"
          :items="emailConsentList"
          :error-messages="errors"
          label="Email Consent"
        />
      </ValidationProvider>
      <div class="caption">
        Choose how email consent is handled.
        <ul>
          <li><b>Ignore</b> will do nothing with the consent flags from the source</li>
          <li>
            <b>Global</b> will set the email address global consent if it has not already been set
          </li>
        </ul>
      </div>
    </v-col>
    <v-col
      v-if="showPhoneConsent"
      cols="12"
      md="6"
      lg="4"
    >
      <ValidationProvider
        v-slot="{ errors }"
        rules="required"
        name="Phone Consent"
      >
        <v-select
          v-model="phoneConsentLocal"
          :items="phoneConsentList"
          :error-messages="errors"
          label="Phone Consent"
        />
      </ValidationProvider>
      <div class="caption">
        Choose how phone consent is handled.
        <ul>
          <li><b>Ignore</b> will do nothing with the consent flags from the source</li>
          <li>
            <b>Global</b> will set the phone number global consent if it has not already been set
          </li>
          <li>
            <b>Opt-In</b> will set the phone number global consent only for user's who have opted in
            and will do nothing for user's who have opted out
          </li>
        </ul>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'ContactConsent',
  props: {
    emailConsent: {
      type: String,
      required: false,
      default: '',
    },
    phoneConsent: {
      type: String,
      required: false,
      default: '',
    },
    showEmailConsent: {
      type: Boolean,
      required: false,
      default: true,
    },
    showPhoneConsent: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      emailConsentList: ['Ignore', 'Global'],
      phoneConsentList: ['Ignore', 'Global', 'Opt-In'],
      emailConsentLocal: this.emailConsent,
      phoneConsentLocal: this.phoneConsent,
    };
  },
  methods: {
    getDetail() {
      const data = {};
      if (this.showEmailConsent) {
        data.emailConsent = this.emailConsentLocal;
      }
      if (this.showPhoneConsent) {
        data.phoneConsent = this.phoneConsentLocal;
      }
      return data;
    },
  },
};
</script>
