<template>
  <v-container
    v-if="sourceType === 1"
    fluid
    class="pa-0"
  >
    <v-row no-gutter>
      <v-col
        cols="12"
        md="6"
        lg="4"
      >
        <ValidationProvider
          v-slot="{ errors }"
          rules="required|alpha_num"
          name="Store Id"
        >
          <v-text-field
            v-model.trim="storeIdLocal"
            label="Store Id"
            placeholder="rics"
            maxlength="50"
            :error-messages="errors"
          />
        </ValidationProvider>
        <div class="caption">
          The unique identifier for the store.
        </div>
      </v-col>
      <v-col
        cols="12"
        md="6"
        lg="4"
      >
        <ValidationProvider
          v-slot="{ errors }"
          rules="required"
          name="Store Name"
        >
          <v-text-field
            v-model="storeNameLocal"
            label="Store Name"
            placeholder="rics"
            :error-messages="errors"
          />
        </ValidationProvider>
        <div class="caption">
          The display name of the store.
        </div>
      </v-col>
      <v-col
        cols="12"
        md="6"
        lg="4"
      >
        <v-checkbox
          v-model="ignoreWebOrdersLocal"
          label="Ignore Web Orders"
        />
        <div class="caption">
          When selected all orders that are of type web orders will not be sent to Mailchimp.
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'MailchimpDestination',
  props: {
    sourceType: {
      type: Number,
      required: false,
      default: 0,
    },
    errorMessage: {
      type: String,
      required: false,
      default: '',
    },
    storeId: {
      type: String,
      required: false,
      default: '',
    },
    storeName: {
      type: String,
      required: false,
      default: '',
    },
    ignoreWebOrders: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      storeIdLocal: this.storeId,
      storeNameLocal: this.storeName,
      ignoreWebOrdersLocal: this.ignoreWebOrders,
    };
  },
  methods: {
    getDetail() {
      return {
        storeId: this.storeIdLocal,
        storeName: this.storeNameLocal,
        ignoreWebOrders: this.ignoreWebOrdersLocal,
      };
    },
  },
};
</script>
