<template>
  <v-container fluid>
    <v-row>
      <v-col
        cols="12"
        class="text-subtitle-1"
      >
        The following filters will be applied.
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <ul>
          <li
            v-for="(filter, i) in filters"
            :key="i"
          >
            <span v-if="i > 0">
              and
            </span>
            <strong>{{ getName(filter.column) }}</strong> {{ getFilterType(filter.type) }}
            <span v-if="filter.values.length > 1">
              one of the following
            </span>
            <ul>
              <li
                v-for="(value, x) in filter.values"
                :key="x"
              >
                {{ value }}
              </li>
            </ul>
          </li>
        </ul>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'ColumnFilterDisplay',
  props: {
    filters: {
      type: Array,
      required: false,
      default: null,
    },
    columns: {
      type: Array,
      required: false,
      default: null,
    },
  },
  methods: {
    getName(value) {
      return this.columns.find((column) => column.value === value).name;
    },
    getFilterType(type) {
      switch (type) {
        case 'begins':
          return 'Begins With';

        case 'ends':
          return 'Ends With';

        case 'contains':
          return 'Contains';

        case 'not-contains':
          return 'Does Not Contain';

        case 'equals':
          return 'Equals';

        case 'in':
          return 'Contains';

        case 'not-in':
          return 'Does Not Contain';

        default:
          return '';
      }
    },
  },
};
</script>
