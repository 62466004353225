<template>
  <v-card>
    <v-card-title
      primary-title
      class="primary white--text"
    >
      <span class="title">Edit Account</span>
    </v-card-title>
    <v-card-text>
      <Account :id="$route.params.id" />
    </v-card-text>
  </v-card>
</template>

<script>
import Account from '../../_components/Account';

export default {
  name: 'EditAccount',
  components: {
    Account,
  },
};
</script>
