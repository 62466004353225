<template>
  <ValidationObserver ref="observer">
    <v-overlay :value="overlay">
      <v-progress-circular
        :size="70"
        :width="7"
        indeterminate
      />
    </v-overlay>
    <form
      class="mt-3"
      @submit.prevent="save"
    >
      <v-container
        grid-list-lg
        fluid
      >
        <v-row>
          <v-col
            cols="12"
            md="6"
            lg="4"
          >
            <ValidationProvider
              v-slot="{ errors }"
              rules="required"
              name="Name"
            >
              <v-text-field
                v-model="name"
                label="Name"
                :error-messages="errors"
              />
            </ValidationProvider>
          </v-col>
          <v-col
            cols="12"
            md="6"
            lg="4"
          >
            <ValidationProvider
              v-slot="{ errors }"
              rules="required"
              name="Destination Type"
            >
              <v-select
                v-model="destinationType"
                :items="sortedTypes"
                :error-messages="errors"
                :disabled="id !== ''"
                item-text="name"
                item-value="value"
                label="Destination Type"
                @change="destinationChange"
              />
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-card
          v-if="detailComponent"
          outlined
        >
          <v-card-text>
            <span class="title">{{ componentTitle }} Detail</span>
            <component
              :is="detailComponent"
              :id="id"
              ref="destinationDetail"
              v-bind="detail"
              :error-message="errorMessage"
            />
          </v-card-text>
        </v-card>
      </v-container>
      <v-btn
        color="primary"
        type="submit"
        class="mt-2"
      >
        Save
      </v-btn>
      <v-btn
        type="button"
        class="mt-2 ml-2"
        to="/connectors"
      >
        Cancel
      </v-btn>
    </form>
  </ValidationObserver>
</template>

<script>
import { mapGetters } from 'vuex';
import Zaius from './types/Zaius';
import Mailchimp from './types/Mailchimp';
import FacebookOffline from './types/FacebookOffline';
import S3 from './types/S3';
import Cadence from './types/Cadence';
import UQube from './types/UQube';
import Blueshift from './types/Blueshift';
import destinations from '../../_api/destinations';

export default {
  name: 'DestinationRoot',
  components: {
    Zaius,
    Mailchimp,
    FacebookOffline,
    S3,
    Cadence,
    UQube,
    Blueshift,
  },
  props: {
    id: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      overlay: false,
      name: '',
      componentTitle: '',
      detailComponent: '',
      destinationType: '',
      typeList: [
        {
          name: 'Zaius',
          component: 'Zaius',
          value: 1,
        },
        {
          name: 'Mailchimp',
          component: 'Mailchimp',
          value: 2,
        },
        {
          name: 'Facebook Offline',
          component: 'FacebookOffline',
          value: 4,
        },
        {
          name: 'S3',
          component: 'S3',
          value: 5,
        },
        {
          name: 'Cadence',
          component: 'Cadence',
          value: 6,
        },
        {
          name: 'UQube',
          component: 'UQube',
          value: 7,
        },
        {
          name: 'Blueshift',
          component: 'Blueshift',
          value: 8,
        },
      ],
      detail: {},
      errorMessage: '',
    };
  },
  computed: {
    ...mapGetters([
      'getActiveAccount',
    ]),
    sortedTypes() {
      const copyList = this.typeList;
      return copyList.sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
    },
  },
  async created() {
    if (this.id) {
      this.overlay = true;
      const resp = await destinations.getDestination(this.getActiveAccount.id, this.id);
      if (resp.data && Object.keys(resp.data).length !== 0) {
        this.destinationType = resp.data.destinationType;
        this.name = resp.data.name;
        this.detail = resp.data.detail;
        this.destinationChange(this.destinationType);
        this.overlay = false;
      } else {
        this.$router.push('/');
      }
    }
  },
  methods: {
    destinationChange(value) {
      const type = this.typeList.find((item) => item.value === value);
      if (type) {
        this.detailComponent = type.component;
        this.componentTitle = type.name;
      } else {
        this.detailComponent = '';
        this.componentTitle = '';
      }
    },
    async save() {
      this.errorMessage = '';
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        this.overlay = true;
        try {
          if (this.id) {
            await destinations.updateDestination(
              this.getActiveAccount.id,
              this.id,
              this.name,
              this.$refs.destinationDetail.getDetail(),
            );
          } else {
            await destinations.createDestination(
              this.getActiveAccount.id,
              this.name,
              this.destinationType,
              this.$refs.destinationDetail.getDetail(),
            );
          }
          this.$store.dispatch('DESTINATIONS_LOAD', this.getActiveAccount.id);
          this.$router.push('/connectors');
        } catch (error) {
          this.overlay = false;
          if (error.response) {
            this.errorMessage = error.response.data;
          }
        }
      }
    },
  },
};
</script>
