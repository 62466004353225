<template>
  <ValidationProvider
    v-slot="{ errors }"
    rules="required"
    name="Timezone"
  >
    <v-select
      v-model="timezoneLocal"
      :items="timezones"
      :error-messages="errors"
      item-text="name"
      item-value="value"
      label="Timezone"
      @change="$emit('update:timezone', timezoneLocal);"
    />
  </ValidationProvider>
</template>

<script>
export default {
  name: 'TimezoneSelect',
  props: {
    timezone: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      timezoneLocal: this.timezone,
      timezones: [
        {
          name: 'Eastern Time',
          value: 'America/New_York',
        },
        {
          name: 'Central Time',
          value: 'America/Chicago',
        },
        {
          name: 'Mountain Time',
          value: 'America/Denver',
        },
        {
          name: 'Pacific Time',
          value: 'America/Los_Angeles',
        },
        {
          name: 'Alaska Time',
          value: 'America/Anchorage',
        },
        {
          name: 'Hawaii-Aleutian Time',
          value: 'Pacific/Honolulu',
        },
      ],
    };
  },
};
</script>
