import store from '@/store';
import Framework from '@/views/Framework';
import Vue from 'vue';
import Router from 'vue-router';
import { routes as accountState } from './modules/accountState';
import { routes as admin } from './modules/admin';
import { routes as auth } from './modules/auth';
import { routes as connectors } from './modules/connectors';
import { routes as dashboards } from './modules/dashboards';
import { routes as products } from './modules/products';
import { routes as profile } from './modules/profile';
import { routes as settings } from './modules/settings';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    ...auth,
    {
      path: '/',
      component: Framework,
      meta: {
        requiresAuth: true,
      },
      children: [
        ...accountState,
        ...connectors,
        ...admin,
        ...profile,
        ...settings,
        ...products,
        ...dashboards,
      ],
    },
    {
      path: '*',
      redirect: '/',
    },
  ],
});

function setZendesk() {
  router.app.$zendesk.identify({
    name: store.getters.getFullName,
    email: store.getters.getProfile.email,
  });
  router.app.$zendesk.prefill({
    name: {
      value: store.getters.getFullName,
    },
    email: {
      value: store.getters.getProfile.email,
    },
  });
}

router.beforeEach(async (to, from, next) => {
  if (store.getters.isAuthenticated() && !store.getters.isProfileLoaded) {
    await Promise.all([store.dispatch('USER_REQUEST'), store.dispatch('USER_GET_ACCOUNTS')]);
    if (router.app.$zendesk.zE) {
      setZendesk();
    } else {
      router.app.$zendesk.$on('loaded', () => {
        setZendesk();
      });
    }
    window.analytics.identify(store.getters.getProfile.id, {
      name: store.getters.getFullName,
      email: store.getters.getProfile.email,
    });
  }

  const account = store.getters.getActiveAccount;

  if (to.matched.some((record) => (record.meta.requireActive === false))) {
    // ignore check for routes that don't require active account
  } else if (store.getters.isAuthenticated()) {
    if (store.getters.getUserAccounts.length === 0) {
      if (to.path !== '/noAccounts') {
        return next('/noAccounts');
      }
      return next();
    } if (account.isActive === false) {
      if (to.path !== '/deactivated') {
        return next('/deactivated');
      }
      return next();
    }
  }

  if (to.path === '/' && account.accountType === 'Connectors'
    && store.getters.systemUser === true && store.getters.getProfile.hasConnectors === true) {
    return next('/connectors');
  }

  if (to.path === '/' && account.accountType !== 'Connectors') {
    return next('/reports');
  }

  if (to.matched.some((record) => record.meta.requiresAuth) && !store.getters.isAuthenticated()) {
    return next({
      path: '/login',
      query: { redirect: to.fullPath },
    });
  }

  if (to.matched.some((record) => record.meta.requiresAccountAdmin)) {
    if (store.getters.accountAdmin === false) {
      return next('/');
    }
  }

  if (to.matched.some((record) => record.meta.requiresSystemAccess)) {
    if (store.getters.systemUser === false) {
      return next('/');
    }
  }

  if (to.matched.some((record) => record.meta.requiresSystemAdmin)) {
    if (store.getters.getProfile.systemAccessLevel !== 'admin') {
      return next('/');
    }
  }

  if (to.matched.some((record) => record.meta.requireAccountsPermission)) {
    if (store.getters.getProfile.systemAccessLevel !== 'admin' && store.getters.getProfile.hasAccounts !== true) {
      return next('/');
    }
  }

  if (to.matched.some((record) => record.meta.requireConnectorsPermission)) {
    if (store.getters.getProfile.systemAccessLevel !== 'admin' && store.getters.getProfile.hasConnectors !== true) {
      return next('/');
    }
  }

  if (to.matched.some((record) => record.meta.requireDataManagementPermission)) {
    if (store.getters.getProfile.systemAccessLevel !== 'admin' && store.getters.getProfile.hasDataManagement !== true) {
      return next('/');
    }
  }

  if (to.matched.some((record) => record.meta.requiresDeactivated)) {
    if (account.isActive !== false) {
      return next('/');
    }
  }

  if (to.matched.some((record) => record.meta.requiresNoAccounts)) {
    if (store.getters.getUserAccounts.length !== 0) {
      return next('/');
    }
  }

  if (to.matched.some((record) => record.meta.requiresBrandAccount)) {
    if (account.accountType !== 'Brand') {
      return next('/');
    }
  }

  if (to.matched.some((record) => record.meta.requiresRetailerAccount)) {
    if (account.accountType !== 'Retailer') {
      return next('/');
    }
  }

  if (to.matched.some((record) => record.meta.requiresBrandOrRetailerAccount)) {
    if (account.accountType !== 'Brand' && account.accountType !== 'Retailer') {
      return next('/');
    }
  }

  let validPagePermission = true;
  to.matched.every((record) => {
    if (record.meta.requirePagePermission) {
      if (typeof record.meta.requirePagePermission === 'string') {
        validPagePermission = account.pages[record.meta.requirePagePermission] || false;
      } else {
        record.meta.requirePagePermission.every((permission) => {
          if (permission.accountType === account.accountType) {
            validPagePermission = account.pages[permission.permission] || false;
          }
          return validPagePermission;
        });
      }
    }
    return validPagePermission;
  });
  if (!validPagePermission) {
    return next('/');
  }

  let validVertical = true;
  to.matched.every((record) => {
    if (record.meta.requireVertical && record.meta.requireVertical !== account.verticalId) {
      validVertical = false;
    }
    return validVertical;
  });
  if (!validVertical) {
    return next('/');
  }

  return next();
});

router.afterEach((to) => {
  document.body.className = to.matched.map((record) => (record.meta.bodyClass ? record.meta.bodyClass : '')).join(' ');
  let darkTheme = false;
  to.matched.forEach((record) => {
    if (record.meta.darkTheme === true) {
      darkTheme = true;
    }
  });
  router.app.$vuetify.theme.dark = darkTheme;
  if (to.fullPath !== '/accountChange') {
    window.analytics.page(to.fullPath);
  }
});

export default router;
