<template>
  <v-card>
    <v-card-title
      primary-title
    >
      <span class="title">Edit Tag Rule</span>
    </v-card-title>
    <v-card-text>
      <Tag :id="$route.params.id" />
    </v-card-text>
  </v-card>
</template>

<script>
import Tag from '../_components/Tag';

export default {
  name: 'EditTag',
  components: {
    Tag,
  },
};
</script>
