import account from '@/api/accounts';
import user from '@/api/user';

const actions = {
  USER_REQUEST: async ({ commit, dispatch }) => {
    try {
      const resp = await user.getUser();
      commit('USER_SUCCESS', resp.data);
      if (!resp.data.timeZone) {
        let timezone;
        try {
          timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        } catch (err) {
          timezone = 'America/New_York';
        }
        if (timezone) {
          await dispatch('USER_UPDATE_TIMEZONE', timezone);
        }
      }
    } catch (err) {
      dispatch('logout', null, { root: true });
    }
  },
  USER_UPDATE_PROFILE: async ({ commit }, profile) => {
    await user.updateProfile(profile);
    commit('USER_UPDATE_PROFILE_SUCCESS', profile);
  },
  USER_UPDATE_PASSWORD: async (context, profile) => {
    await user.updatePassword(profile);
  },
  USER_UPDATE_EMAIL: async ({ commit }, profile) => {
    await user.updateEmail(profile);
    commit('USER_UPDATE_EMAIL_SUCCESS', profile);
  },
  USER_VERIFY_TWO_FACTOR: async ({ commit }, token) => {
    const resp = await user.verifyTwoFactor(token);
    commit('USER_TWO_FACTOR_ADD', resp);
  },
  USER_GENERATE_TWO_FACTOR_CODES: async ({ commit }) => {
    const resp = await user.generateTwoFactorCodes();
    commit('USER_TWO_FACTOR_CODE_UPDATE', resp);
  },
  USER_DISABLE_TWO_FACTOR: async ({ commit }) => {
    await user.disableTwoFactor();
    commit('USER_TWO_FACTOR_DISABLED');
  },
  USER_UPDATE_TIMEZONE: async ({ commit }, timeZone) => {
    const resp = await user.updateTimezone(timeZone);
    commit('USER_UPDATE_TIMEZONE_SUCCESS', resp);
  },
  USER_GET_ACCOUNTS: async ({ commit, dispatch }) => {
    const resp = await user.getAccounts();
    commit('USER_GET_ACCOUNTS_SUCCESS', resp.data);
    if (resp.data.length === 1) {
      await dispatch('USER_CHANGE_ACCOUNT', resp.data[0].id);
    } else if (resp.data.length > 1) {
      let accountId;
      resp.data.forEach((localAccount) => {
        if (localAccount.default === true) {
          accountId = localAccount.id;
        }
      });
      if (!accountId) {
        accountId = resp.data[0].id;
      }
      await dispatch('USER_CHANGE_ACCOUNT', accountId);
    }
  },
  USER_CHANGE_ACCOUNT: async ({ state, commit, dispatch }, accountId) => {
    if (accountId !== state.activeAccount.id) {
      const resp = await account.getAccount(accountId);
      commit('USER_CHANGE_ACCOUNT_SUCCESS', resp.data);
      user.insertSetting('lastAccount', resp.data.id);
      await dispatch('DESTINATIONS_CLEAR');
    }
  },
  USER_GET_ACCOUNT: async ({ commit }, accountId) => {
    const resp = await account.getAccount(accountId);
    commit('USER_CHANGE_ACCOUNT_SUCCESS', resp.data);
  },
  ADMIN_UPDATE_ACCOUNT: async ({ state, commit }, accountId) => {
    if (accountId === state.activeAccount.id) {
      const resp = await account.getAccount(accountId);
      commit('USER_CHANGE_ACCOUNT_SUCCESS', resp.data);
    }
  },
};

export default actions;
