import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
});

instance.defaults.withCredentials = true;

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401 && error.config.url.indexOf('/auth') === -1) {
      if (error.response.data === 'Authentication Error') {
        window.location = `/login?redirect=${encodeURI(window.location.pathname)}`;
      } else {
        window.location = '/';
      }
    }
    return Promise.reject(error);
  },
);

export default instance;
