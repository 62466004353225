<template>
  <v-row>
    <v-col
      cols="12"
      md="6"
      lg="4"
    >
      <ValidationProvider
        ref="brandValidation"
        v-slot="{ errors }"
        rules="required"
        name="Brand"
      >
        <v-text-field
          v-model.trim="brandLocal"
          :error-messages="errors"
          label="Brand"
        />
        <div
          v-if="savedAccountType === 'Brand'"
          class="caption"
        >
          Updating the brand name can take several hours before it is reflected in reporting. During
          this time the old brand name will still appear and reporting for the brand will not return
          data.
        </div>
      </ValidationProvider>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'AdminBrand',
  props: {
    brand: {
      type: String,
      required: false,
      default: '',
    },
    savedAccountType: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      brandLocal: this.brand,
    };
  },
  methods: {
    getSettings() {
      return {
        brand: this.brandLocal,
      };
    },
    handleError(message) {
      if (message === 'DuplicateBrand') {
        this.$refs.brandValidation.setErrors(['Duplicate brand, a brand may only be assigned to one account per vertical.']);
      }
    },
  },
};
</script>
