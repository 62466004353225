<template>
  <v-card>
    <v-card-title
      primary-title
      class="primary white--text"
    >
      <span class="title">
        Sources
      </span>
      <v-spacer />
      <v-btn
        fab
        small
        class="mb-2"
        @click="addSource"
      >
        <v-icon dark>
          add
        </v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-list two-line>
        <v-list-item
          v-for="item in sources"
          :key="item.id"
        >
          <v-list-item-action>
            <v-btn
              fab
              text
              :to="'/connectors/sources/edit/' + item.id"
              class="mr-2"
            >
              <v-icon>
                edit
              </v-icon>
            </v-btn>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              {{ item.name }}
              <span v-if="item.active === false">(disabled)</span>
            </v-list-item-title>
            <v-list-item-subtitle class="text--primary">
              Type: {{ item.sourceName }}
            </v-list-item-subtitle>
            <v-list-item-subtitle v-if="item.isSetup">
              historical data loaded from {{ item.startDate }}
            </v-list-item-subtitle>
            <v-list-item-subtitle v-if="!item.isSetup">
              setup has not been completed
            </v-list-item-subtitle>
            <v-list-item-subtitle v-if="item.isSetup">
              next run time: {{ formatRunTime(item.nextRunTime) }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn
              fab
              text
              @click="deleteSource(item)"
            >
              <v-icon>
                delete
              </v-icon>
            </v-btn>
          </v-list-item-action>
          <v-list-item-action>
            <v-switch
              v-model="item.active"
              @change="changeSourceState(item)"
            />
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-card-text>
    <v-snackbar
      v-model="snackbar"
      color="error"
      :top="true"
      :timeout="-1"
    >
      <v-container
        fluid
        class="subtitle-1"
      >
        This source can not be deleted because it has data stored in Cadence. If you want to
        delete this source you must delete all cadence data for this account. You can alternatively
        disable this source or remove cadence as a destination if it has multiple destinations set
        up.
      </v-container>
      <template #action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import account from '@/api/accounts';
import sources from '../_api/sources';

export default {
  name: 'SourcesList',
  data() {
    return {
      sources: [],
      snackbar: false,
    };
  },
  computed: {
    ...mapGetters([
      'getActiveAccount',
      'getProfile',
    ]),
  },
  watch: {
    getActiveAccount(val) {
      this.getData(val.id);
    },
  },
  created() {
    if (this.getActiveAccount.id) {
      this.getData(this.getActiveAccount.id);
    }
  },
  methods: {
    async getData(accountId) {
      const resp = await account.getSources(accountId);
      this.sources = resp.data;
    },
    async deleteSource(source) {
      if (await this.$confirm(`Are you sure you want to delete source ${source.name}?`)) {
        try {
          await sources.deleteSource(this.getActiveAccount.id, source.id);
          this.$store.dispatch('USER_GET_ACCOUNT', this.getActiveAccount.id);
          this.$store.dispatch('DESTINATIONS_LOAD', this.getActiveAccount.id);
        } catch (e) {
          if (e.response && e.response.data === 'DataConstraint') {
            this.snackbar = true;
          }
        }
      }
    },
    async changeSourceState(source) {
      if (source.active === true) {
        if (await this.$confirm(`Are you sure you want to enable source ${source.name}?`)) {
          await sources.enableSource(this.getActiveAccount.id, source.id);
        } else {
          const changedSource = this.sources.find((x) => x.id === source.id);
          changedSource.active = false;
        }
      } else if (await this.$confirm(`Are you sure you want to disable source ${source.name}?`)) {
        await sources.disableSource(this.getActiveAccount.id, source.id);
      } else {
        const changedSource = this.sources.find((x) => x.id === source.id);
        changedSource.active = true;
      }
    },
    formatRunTime(runTime) {
      if (runTime) {
        return new Date(runTime).toLocaleString();
      }
      return 'currently loading historical data';
    },
    async addSource() {
      if (await this.$confirm(`Are you sure you want to add a source for account <strong>${this.getActiveAccount.name}</strong>?`)) {
        this.$router.push('/connectors/sources/create');
      }
    },
  },
};
</script>
