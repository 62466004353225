<template>
  <v-row>
    <v-col
      cols="12"
      md="6"
      lg="4"
    >
      <v-btn
        color="primary"
        @click.prevent="initAuthenticate"
      >
        Authenticate App
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'BrightpearlSourceType',
  props: {
    account: {
      type: String,
      required: false,
      default: '',
    },
  },
  methods: {
    async initAuthenticate() {
      const url = encodeURIComponent(`${window.location.protocol}//${window.location.host}/finalizeOauth`);
      window.location.href = `https://oauth.brightpearl.com/authorize/${this.account}?response_type=code&client_id=${process.env.VUE_APP_BRIGHTPEARL_CLIENT_ID}&redirect_uri=${url}&state=${this.$route.query.t}`;
    },
  },
};
</script>
