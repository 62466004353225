<template>
  <v-checkbox
    v-model="subscribedLocal"
    color="success"
    :label="label"
    @change="$emit('update:subscribed', subscribedLocal);"
  />
</template>

<script>
export default {
  name: 'EmailList',
  props: {
    subscribed: {
      type: Boolean,
      required: false,
      default: true,
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      subscribedLocal: this.subscribed,
    };
  },
};
</script>
