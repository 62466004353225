import tagging from '../../_api/tagging';

const actions = {
  TAGGING_SETACCOUNTID: ({ commit }, accountId) => {
    commit('TAGGING_SETACCOUNTID', accountId);
  },
  TAGGING_SETTYPE: ({ commit }, type) => {
    commit('TAGGING_SETTYPE', type);
  },
  TAGGING_SETLISTS: ({ commit }, lists) => {
    commit('TAGGING_SETLISTS', lists);
  },
  TAGGING_GETMETADATA: async ({ commit }, verticalId) => {
    const resp = await tagging.getMetadata(verticalId);
    commit('TAGGING_SETMETADATA', {
      verticalId,
      metadata: resp.data,
    });
  },
};

export default actions;
