const getters = {
  isAuthenticated: (state) => () => {
    if (state.exp) {
      if (state.exp < (Date.now() / 1000)) {
        return false;
      }
      return true;
    }
    return false;
  },
};

export default getters;
