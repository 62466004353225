import axios from '@/utils/axios';
import { memoize } from 'lodash';

/**
 * Fetches the verticals, memoizing the promise based on the params provided.
 *
 * @param {{ includeMetaData?: string }} params
 */
const getVerticals = memoize(
  async (params = {}) => axios.get('/admin/verticals', params),
  (params) => JSON.stringify(params),
);

export default {
  getVerticals,
};
