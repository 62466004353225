<template>
  <div>
    <v-row>
      <v-col cols="12">
        <h2>
          Internal Reports
        </h2>
      </v-col>
    </v-row>
    <v-row>
      <template v-for="(item, i) in reports">
        <report-card
          :key="i"
          :item="item"
        />
      </template>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import dashboard from '../_api/dashboard';
import ReportCard from '../_components/ReportCard';

export default {
  name: 'InternalReports',
  components: {
    ReportCard,
  },
  data() {
    return {
      reports: [],
      hiddenReports: [],
    };
  },
  computed: {
    ...mapGetters([
      'getActiveAccount',
    ]),
  },
  async created() {
    const resp = await dashboard.getInternalReports(this.getActiveAccount.id);
    this.reports = resp.data;
  },
};
</script>
