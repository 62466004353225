import { mapGetters, mapState } from 'vuex';
import store from '../_store/tagging';
import tagging from '../_api/tagging';

export default {
  computed: {
    ...mapGetters([
      'getActiveAccount',
    ]),
    ...mapState('$_tagging', {
      accountId: (state) => state.accountId,
      taggingType: (state) => state.type,
      lists: (state) => state.lists,
      verticalId: (state) => state.verticalId,
      metadata: (state) => state.metadata,
      categories: (state) => state.metadata.CATEGORIES,
      genders: (state) => state.metadata.GENDERS,
      colors: (state) => state.metadata.COLORS,
      sourceTags: (state) => state.lists.sourceTags,
    }),
    computedAccountId() {
      return this.$route.meta.globalRetailer === true ? this.getActiveAccount.verticalId
        : this.getActiveAccount.id;
    },
    type() {
      return this.$route.meta.globalRetailer === true ? 'global' : 'account';
    },
    columns() {
      if (this.$route.meta.globalRetailer === true) {
        return this.metadata.RETAILER_COLUMNS || [];
      }
      if (this.getActiveAccount.accountType === 'Brand') {
        return this.metadata.BRAND_COLUMNS || [];
      }
      return this.metadata.RETAILER_COLUMNS || [];
    },
  },
  beforeCreate() {
    const STORE_KEY = '$_tagging';
    if (this.$store.hasModule(STORE_KEY) === false) {
      this.$store.registerModule(STORE_KEY, store);
    }
  },
  methods: {
    async onCreated() {
      if (!this.getActiveAccount.verticalId) {
        this.$router.push('/');
        return;
      }
      if (this.verticalId !== this.getActiveAccount.verticalId) {
        await this.$store.dispatch('$_tagging/TAGGING_GETMETADATA', this.getActiveAccount.verticalId);
      }
      if (this.accountId !== this.computedAccountId || this.taggingType !== this.type) {
        const lists = await tagging.getLists(this.type, this.computedAccountId);
        this.$store.dispatch('$_tagging/TAGGING_SETACCOUNTID', this.computedAccountId);
        this.$store.dispatch('$_tagging/TAGGING_SETTYPE', this.type);
        this.$store.dispatch('$_tagging/TAGGING_SETLISTS', lists.data);
      }
    },
  },
};
