<template>
  <Wrapper>
    <div class="title">
      Validation Request
    </div>
    <div
      v-show="sourceTypeName !== ''"
      class="subtitle-1"
    >
      To finish setting up {{ sourceTypeName }} the following validation needs to be done.
    </div>
    <component
      :is="detailComponent"
      ref="sourceDetail"
      v-bind="detail"
      @Success="success"
      @Failure="failure"
    />
    <template #footer>
      <v-alert
        v-show="!validToken"
        type="error"
        class="mt-3"
      >
        The link is either invalid or has expired.
      </v-alert>
      <v-alert
        v-show="showAlert"
        type="success"
        class="mt-3"
      >
        The validation credentials have been successfully updated.
      </v-alert>
      <v-alert
        v-show="showFailure"
        type="error"
        class="mt-3"
      >
        There was a problem saving your validation credentials. Please try again.
      </v-alert>
    </template>
  </Wrapper>
</template>

<script>
import sources from '@/api/sources';
import Wrapper from '../_components/Wrapper';
import RaceRoster from '../_components/SourceType/RaceRoster';
import RunSignup from '../_components/SourceType/RunSignup';
import Lightspeed from '../_components/SourceType/Lightspeed';
import Perkville from '../_components/SourceType/Perkville';
import Brightpearl from '../_components/SourceType/Brightpearl';
import Shopify from '../_components/SourceType/Shopify';

export default {
  name: 'SourceValidation',
  components: {
    Wrapper,
    RaceRoster,
    RunSignup,
    Lightspeed,
    Perkville,
    Brightpearl,
    Shopify,
  },
  data() {
    return {
      showAlert: false,
      validToken: true,
      showFailure: false,
      detailComponent: '',
      sourceTypeName: '',
      detail: null,
    };
  },
  async created() {
    if (this.$route.query.t) {
      try {
        const resp = await sources.validate(this.$route.query.t);
        this.detail = resp.data.detail;
        switch (resp.data.sourceType) {
          case 4:
            this.sourceTypeName = 'Race Roster';
            this.detailComponent = 'RaceRoster';
            break;

          case 5:
            this.sourceTypeName = 'RunSignup';
            this.detailComponent = 'RunSignup';
            break;

          case 8:
            this.sourceTypeName = 'Lightspeed';
            this.detailComponent = 'Lightspeed';
            break;

          case 11:
            this.sourceTypeName = 'Perkville';
            this.detailComponent = 'Perkville';
            break;

          case 9:
            this.sourceTypeName = 'Brightpearl';
            this.detailComponent = 'Brightpearl';
            break;

          case 15:
            this.sourceTypeName = 'Shopify';
            this.detailComponent = 'Shopify';
            break;

          default:
            break;
        }
      } catch (e) {
        this.validToken = false;
      }
    } else {
      this.validToken = false;
    }
  },
  methods: {
    success() {
      this.showFailure = false;
      this.showAlert = true;
    },
    failure() {
      this.showFailure = true;
    },
  },
};
</script>
