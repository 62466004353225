import axios from 'axios';

const getCustomerPreview = (token) => axios.post('https://enterprise.ricssoftware.com/api/Customer/GetCustomerDetails', {
  Take: 100,
}, {
  headers: {
    token,
  },
});
const getOrderPreview = (token, startDate, endDate) => axios.post('https://enterprise.ricssoftware.com/api/POS/GetPOSTransaction', {
  Take: 100,
  BatchStartDate: startDate,
  BatchEndDate: endDate,
  TicketDateStart: startDate,
  TicketDateEnd: endDate,
}, {
  headers: {
    token,
  },
});

export default {
  getCustomerPreview,
  getOrderPreview,
};
