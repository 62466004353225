<template>
  <v-row>
    <v-col
      cols="12"
      md="6"
      lg="4"
    >
      <v-btn
        color="primary"
        @click.prevent="initAutheticate"
      >
        Authenticate App
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import sources from '@/api/sources';

export default {
  name: 'RunSignup',
  methods: {
    async initAutheticate() {
      sessionStorage.setItem('runSignupToken', this.$route.query.t);
      const resp = await sources.getRunSignupRequestToken();
      window.location.href = `https://runsignup.com/OAuth/Verify?oauth_token=${resp.data.token}`;
    },
  },
};
</script>
