<template>
  <v-row>
    <ignore-web-orders
      ref="ignoreWebOrders"
      :ignore-web-orders="ignoreWebOrders"
    />
    <v-col
      cols="12"
      md="6"
      lg="4"
    >
      <v-select
        v-model="tagsLocal"
        :items="tagColumns"
        :clearable="true"
        item-text="name"
        item-value="value"
        label="Tags"
        multiple
        :hint="`column(s): ${tagsLocal.join(', ')}`"
        persistent-hint
      />
      <div class="caption mt-3">
        Select all the fields that will be used to tag this product.
      </div>
    </v-col>
  </v-row>
</template>

<script>
import IgnoreWebOrders from '../Components/IgnoreWebOrders';

export default {
  name: 'CadenceRunitDestination',
  components: {
    IgnoreWebOrders,
  },
  props: {
    ignoreWebOrders: {
      type: Boolean,
      required: false,
      default: false,
    },
    tags: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      tagsLocal: this.tags,
      tagColumns: [
        {
          name: 'Department',
          value: 'Dept',
        },
        {
          name: 'Gender',
          value: 'Gender',
        },
        {
          name: 'Sub Type',
          value: 'SubType',
        },
        {
          name: 'Type',
          value: 'Typ',
        },
      ],
    };
  },
  methods: {
    getDetail() {
      return {
        tags: this.tagsLocal,
        ...this.$refs.ignoreWebOrders.getDetail(),
      };
    },
  },
};
</script>
