import axios from '@/utils/axios';

const getDashboard = (accountType, accountId, dashboardType) => axios.get(`/dashboards/${accountType}/${accountId}/${dashboardType}`);
const getAdminTest = (accountId, url) => axios.post(`/dashboards/test/${accountId}`, {
  url,
});
const getReports = (accountId) => axios.get(`/dashboards/reports/${accountId}`);
const getHiddenReports = (accountId) => axios.get(`/dashboards/hiddenReports/${accountId}`);
const getInternalReports = (accountId) => axios.get(`/dashboards/internalReports/${accountId}`);

export default {
  getDashboard,
  getAdminTest,
  getReports,
  getHiddenReports,
  getInternalReports,
};
