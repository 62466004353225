<template>
  <v-card>
    <v-card-title
      primary-title
      class="primary white--text"
    >
      <span class="title">Create Destination</span>
    </v-card-title>
    <v-card-text>
      <Destination />
    </v-card-text>
  </v-card>
</template>

<script>
import Destination from '../_components/Destinations/Destination';

export default {
  name: 'CreateDestination',
  components: {
    Destination,
  },
};
</script>
