<template>
  <v-alert
    :value="true"
    :class="{'ma-2': $vuetify.breakpoint.smAndDown, 'ma-5': $vuetify.breakpoint.mdAndUp}"
    type="error"
  >
    <h4>{{ getActiveAccount.name }} is not available!</h4>
    <hr>
    <p>
      {{ getActiveAccount.name }} is in a deactivated state.
      <a
        class="white--text text-decoration-underline"
        @click.prevent="contactSupport"
        @keypress.enter="contactSupport"
      >Contact support</a>
      to resolve the issue.
    </p>
  </v-alert>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'DeactivatedAccount',
  computed: {
    ...mapGetters([
      'getActiveAccount',
    ]),
  },
  methods: {
    contactSupport() {
      this.$zendesk.open();
    },
  },
};
</script>
