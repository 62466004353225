<template>
  <ValidationObserver ref="observer">
    <v-form
      class="mt-3"
      @submit.prevent="save"
    >
      <v-container
        grid-list-lg
        fluid
      >
        <v-row>
          <v-col
            cols="12"
            md="6"
            lg="4"
          >
            <ValidationProvider
              ref="nameValidation"
              v-slot="{ errors }"
              rules="required|max:100"
              name="Name"
            >
              <v-text-field
                v-model.trim="name"
                label="Name"
                :error-messages="errors"
                maxlength="100"
              />
            </ValidationProvider>
          </v-col>
          <v-col
            cols="12"
            md="6"
            lg="4"
          >
            <ValidationProvider
              ref="accountTypeValidation"
              v-slot="{ errors }"
              rules="required"
              name="Account Type"
              vid="accountType"
            >
              <v-select
                v-model="accountType"
                :items="accountTypes"
                :error-messages="errors"
                label="Account Type"
                @change="accountTypeChange"
              />
            </ValidationProvider>
            <div class="caption">
              Account type will change which pages are active for this account.
            </div>
          </v-col>
          <v-col
            cols="12"
            md="6"
            lg="4"
          >
            <v-checkbox
              v-model="isHidden"
              color="success"
              label="Hide Account"
            />
            <div class="caption">
              Setting an account as hidden will hide the account from appearing to other accounts.
            </div>
          </v-col>
          <v-col
            cols="12"
            md="6"
            lg="4"
          >
            <v-checkbox
              v-model="showPreview"
              color="success"
              label="Show Preview"
            />
            <div class="caption">
              When selected "(Preview)" will appear next to UQ Cadence in the top nav.
            </div>
          </v-col>
          <v-col
            cols="12"
            md="6"
            lg="4"
          >
            <ValidationProvider
              ref="verticalValidation"
              v-slot="{ errors }"
              rules="required"
              name="Vertical"
              vid="verticalId"
            >
              <v-select
                v-model="verticalId"
                :items="verticals"
                label="Vertical"
                item-text="name"
                item-value="id"
                :error-messages="errors"
                :disabled="id !== '' && verticalId !== '' && systemAdmin === false"
                @change="verticalChange"
              />
              <div class="caption">
                Verticals determine the tagging rules that are run, which reports are shown, and
                other accounts that show in account connections.
              </div>
            </ValidationProvider>
          </v-col>
          <v-col
            v-if="accountType === 'Retailer'"
            cols="12"
            md="6"
            lg="4"
          >
            <v-select
              v-model="parentAccount"
              :items="parentAccounts"
              label="Parent Account"
              item-text="name"
              item-value="id"
              clearable
            />
            <div class="caption">
              Selecting a parent account allows that account to see all the data from the current
              account and depending on configuration manage all brand connections.
            </div>
          </v-col>
          <v-col
            v-if="id"
            cols="12"
            md="6"
            lg="4"
          >
            <v-checkbox
              v-model="active"
              color="success"
              label="Active Account"
            />
            <div class="caption">
              Deactivating this account will set all the sources to deactive and will limit user
              access.
            </div>
          </v-col>
          <v-col
            cols="12"
            md="6"
            lg="4"
          >
            <v-select
              v-model="settings.selectedReports"
              label="Reports"
              :items="permissions"
              clearable
              multiple
              chips
              deletable-chips
            />
          </v-col>
          <template v-for="(item, i) in customFields">
            <v-col
              :key="i"
              cols="12"
              md="6"
              lg="4"
            >
              <ValidationProvider
                v-slot="{ errors }"
                :rules="`${item.required ? 'required' : ''}`"
                :name="item.label"
              >
                <v-text-field
                  v-if="item.type === 'text'"
                  v-model.trim="settings.customFields[item.property]"
                  :label="item.label"
                  :error-messages="errors"
                  maxlength="500"
                />
                <v-select
                  v-if="item.type === 'list'"
                  v-model="settings.customFields[item.property]"
                  :label="item.label"
                  :items="item.values"
                  :error-messages="errors"
                  clearable
                  return-object
                />
              </ValidationProvider>
            </v-col>
          </template>
        </v-row>
        <v-card
          v-if="accountType === 'Brand' || accountType === 'Retailer Rollup'"
          outlined
          class="mt-2"
        >
          <v-card-text>
            <span class="subtitle-1">{{ accountType }} Detail</span>
            <brand
              v-if="accountType === 'Brand'"
              ref="brandControl"
              :saved-account-type="savedAccountType"
              v-bind="settings"
            />
            <retailer-rollup
              v-if="accountType === 'Retailer Rollup'"
              ref="retailerRollupControl"
              v-bind="settings"
            />
          </v-card-text>
        </v-card>
      </v-container>
      <v-btn
        color="primary"
        type="submit"
        class="mt-2"
      >
        Save
      </v-btn>
      <v-btn
        type="submit"
        class="mt-2 ml-2"
        to="/admin/accounts"
      >
        Cancel
      </v-btn>
    </v-form>
  </ValidationObserver>
</template>

<script>
import { camelCase } from 'lodash';
import { mapGetters } from 'vuex';
import verticals from '@/api/verticals';
import accounts from '../_api/accounts';
import Brand from './Brand';
import RetailerRollup from './RetailerRollup';

export default {
  name: 'AdminAccount',
  components: {
    Brand,
    RetailerRollup,
  },
  props: {
    id: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      name: '',
      isHidden: false,
      active: true,
      accountType: '',
      savedAccountType: '',
      accountTypes: ['Brand', 'Connectors', 'Retailer', 'Retailer Rollup', 'Other'],
      brandDisabled: false,
      parentAccount: null,
      settings: {
        customFields: {},
      },
      parentAccounts: [],
      showPreview: false,
      verticalId: '',
      verticals: [],
      permissions: [],
    };
  },
  computed: {
    ...mapGetters([
      'systemAdmin',
    ]),
    customFields() {
      let fields = [];
      if (this.verticalId) {
        this.verticals.every((v) => {
          if (v.id === this.verticalId) {
            fields = v.metadata.accountCustomFields || [];
            return false;
          }
          return true;
        });
      }
      return fields;
    },
  },
  watch: {
    async verticalId() {
      await this.getParentAccounts();
    },
  },
  async created() {
    await Promise.all([this.getVerticals(), this.getAccount()]);
    this.setPermissionList();
  },
  methods: {
    accountTypeChange() {
      this.permissionChange();
      this.parentAccount = null;
    },
    verticalChange() {
      this.permissionChange();
      this.parentAccount = null;
    },
    permissionChange() {
      this.settings.selectedReports = [];
      this.setPermissionList();
    },
    setPermissionList() {
      if (this.accountType && this.verticalId) {
        this.verticals.every((v) => {
          if (v.id === this.verticalId) {
            this.permissions = v.metadata[`${camelCase(this.accountType)}ReportPermissions`] || [];
            return false;
          }
          return true;
        });
      } else {
        this.permissions = [];
        this.settings.selectedReports = [];
      }
    },
    async getParentAccounts() {
      if (this.verticalId) {
        const resp = await accounts.getParentAccounts(this.verticalId);
        if (resp.data) {
          this.parentAccounts = resp.data;
        }
      } else {
        this.parentAccounts = [];
      }
    },
    async getVerticals() {
      const resp = await verticals.getVerticals(true);
      if (resp.data) {
        this.verticals = resp.data;
      }
    },
    async getAccount() {
      if (this.id) {
        const resp = await accounts.getAccount(this.id);
        if (resp.data) {
          this.name = resp.data.name;
          this.active = resp.data.isActive;
          this.accountType = resp.data.accountType;
          this.savedAccountType = resp.data.accountType;
          this.isHidden = resp.data.isHidden;
          this.parentAccount = resp.data.parentAccountId;
          this.showPreview = resp.data.showPreview;
          this.verticalId = resp.data.verticalId || '';
          this.settings = {
            customFields: {},
            selectedReports: [],
            ...(resp.data.settings || {}),
          };
        } else {
          this.$router.push('/');
        }
      }
    },
    async save() {
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        try {
          let { settings } = this;
          switch (this.accountType) {
            case 'Brand':
              settings = {
                ...settings,
                ...this.$refs.brandControl.getSettings(),
              };
              break;

            case 'Retailer Rollup':
              settings = {
                ...settings,
                ...this.$refs.retailerRollupControl.getSettings(),
              };
              break;

            default:
              break;
          }
          if (this.id) {
            // update
            await accounts.updateAccount(
              this.id,
              this.name,
              this.active,
              this.accountType,
              settings,
              this.isHidden,
              this.parentAccount,
              this.showPreview,
              this.verticalId,
            );
            await this.$store.dispatch('ADMIN_UPDATE_ACCOUNT', this.id);
          } else {
            // insert
            const resp = await accounts.createAccount(
              this.name,
              this.accountType,
              settings,
              this.isHidden,
              this.parentAccount,
              this.showPreview,
              this.verticalId,
            );
            if (await this.$confirm(`Do you want to switch to account ${this.name}?`)) {
              await this.$store.dispatch('USER_GET_ACCOUNTS');
              await this.$store.dispatch('USER_CHANGE_ACCOUNT', resp.data.id);
              this.$router.push('/accountChange');
              return;
            }
          }
          this.$store.dispatch('USER_GET_ACCOUNTS');
          this.$router.push('/admin/accounts');
        } catch (e) {
          if (e.response && e.response.data) {
            if (e.response.data === 'DuplicateName') {
              this.$refs.nameValidation.setErrors(['Account names must be unique per vertical.']);
            } else {
              switch (this.accountType) {
                case 'Brand':
                  this.$refs.brandControl.handleError(e.response.data);
                  break;

                default:
                  break;
              }
            }
          }
        }
      }
    },
  },
};
</script>
