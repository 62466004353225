import axios from '@/utils/axios';

const createTestEvent = (eventKey, event) => axios.post('/destinations/blueshift/createTestEvent', {
  eventKey,
  event,
});

export default {
  createTestEvent,
};
