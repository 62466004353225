<template>
  <NavList :nav-items="navItems" />
</template>

<script>
import NavList from './NavList';

export default {
  name: 'BrandProducts',
  components: {
    NavList,
  },
  data() {
    return {
      navItems: [
        {
          text: 'PRODUCTS',
          header: true,
          requireSystemUser: true,
          requireAccountType: ['Brand'],
          requirePagePermission: 'brandTagging',
          requireSystemPermission: 'hasDataManagement',
        },
        {
          text: 'Tagging',
          route: '/product/tagging',
          icon: 'tag',
          requireSystemUser: true,
          requireAccountType: ['Brand'],
          requirePagePermission: 'brandTagging',
          requireSystemPermission: 'hasDataManagement',
        },
        {
          text: 'Upload',
          route: '/product/upload',
          icon: 'boxes-packing',
          requireSystemUser: true,
          requireAccountType: ['Brand'],
          requirePagePermission: 'brandTagging',
          requireSystemPermission: 'hasDataManagement',
        },
      ],
    };
  },
};
</script>
