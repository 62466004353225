<template>
  <div
    v-if="show"
    id="report-user-guide"
    class="v-overlay v-overlay--active theme--dark"
  >
    <div
      class="v-overlay__scrim"
      :style="{
        opacity: current === 0 ? '0.46' : '0.025',
        backgroundColor: 'rgb(33, 33, 33)',
        borderColor: 'rgb(33, 33, 33)'
      }"
    />

    <v-fade-transition
      appear
      mode="out-in"
    >
      <component
        :is="steps[current]"
        v-if="reveal"
      >
        <template #navigator>
          <v-container
            fluid
            class="pa-5"
          >
            <v-row>
              <v-col v-if="current !== 0">
                <v-btn
                  depressed
                  color="grey lighten-4"
                  @click="onPrevious"
                >
                  <v-icon>
                    mdi-menu-left
                  </v-icon>
                  Back
                </v-btn>
              </v-col>

              <v-col align="right">
                <v-btn
                  depressed
                  color="primary"
                  @click="onNext"
                >
                  <template v-if="current + 1 < steps.length">
                    Next
                    <v-icon>
                      mdi-menu-right
                    </v-icon>
                  </template>
                  <template v-else>
                    Finish
                    <v-icon
                      small
                      class="ml-1"
                    >
                      mdi-check-circle-outline
                    </v-icon>
                  </template>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>

          <v-sheet color="grey lighten-4">
            <v-progress-linear
              v-model="progress"
              color="primary"
            />
            <span class="mx-3 caption">
              {{ current + 1 }} / {{ steps.length }}
            </span>
          </v-sheet>
        </template>
      </component>
    </v-fade-transition>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import DownloadDataGuide from './DownloadDataGuide';
import TabsGuide from './TabsGuide';
import ToolbarGuide from './ToolbarGuide';
import WelcomeGuide from './WelcomeGuide';

export default {
  name: 'ReportUserGuide',
  components: {
    WelcomeGuide,
    DownloadDataGuide,
    ToolbarGuide,
    TabsGuide,
  },
  data() {
    return {
      reveal: false,
      current: 0,
      steps: [
        WelcomeGuide,
        DownloadDataGuide,
        ToolbarGuide,
        TabsGuide,
      ],
    };
  },
  computed: {
    ...mapState('page/report', ['workbook', 'guide']),
    ...mapState('settings', ['hasViewedReportGuide']),
    show() {
      return this.workbook.loaded && this.guide;
    },
    currentIndex() {
      return this.current;
    },
    progress() {
      return (this.current + 1) / (this.steps.length * 100);
    },
  },
  watch: {
    show: {
      immediate: true,
      handler(show) {
        if (show) {
          setTimeout(() => {
            this.reveal = true;
          }, 125);
        } else {
          this.current = 0;
          this.reveal = show;
        }
      },
    },
  },
  methods: {
    onPrevious() {
      if (this.current > 0) {
        this.current -= 1;
      }
    },
    onNext() {
      if (this.current < this.steps.length - 1) {
        this.current += 1;
      } else {
        this.$store.dispatch('page/report/setGuide', false);

        if (!this.hasViewedReportGuide) {
          this.$store.dispatch('settings/save', {
            name: 'hasViewedReportGuide',
            value: true,
          });
        }
      }
    },
  },
};
</script>

<style lang="css" scoped>
    #report-user-guide {
        z-index: 999999;
    }
</style>
