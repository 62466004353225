import * as views from './_views';

export default [
  {
    path: 'profile',
    name: 'Profile',
    component: views.Profile,
    meta: {
      requireActive: false,
    },
  },
];
