import Vue from 'vue';

/**
 * The workbook is the active representation of what a user is viewing
 * as the sigma report. Unlike a view which has an id and variables this
 * only has variables, and those variables should exactly match what is
 * configured on screen at all times.
 *
 * This is used when saving new views which may have deviated from the view
 * that was previously selected.
 */
export default {
  namespaced: true,
  state: {
    loaded: false,
    variables: {},
  },
  mutations: {
    SET_LOADED(state, loaded) {
      state.loaded = loaded;
    },
    SET_VARIABLES(state, variables) {
      Object.keys(variables).forEach((key) => {
        if (state.variables[key] !== variables[key]) {
          Vue.set(state.variables, key, variables[key]);
        }
      });
    },
    RESET(state) {
      state.loaded = false;
      state.variables = {};
    },
  },
  actions: {
    init({ commit, dispatch }, workbook) {
      commit('SET_LOADED', true);

      return dispatch('set', workbook);
    },
    set({ commit }, workbook) {
      commit('SET_VARIABLES', workbook.variables);
    },
    update({ dispatch }, workbook) {
      return dispatch('set', workbook);
    },
    reset({ commit }) {
      commit('RESET');
    },
  },
};
