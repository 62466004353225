import ComponentWrapper from '@/views/ComponentWrapper';
import * as views from './_views';

export default [
  {
    path: 'settings',
    component: ComponentWrapper,
    redirect: '/',
    children: [
      {
        path: 'users',
        component: views.AccountUsers,
        meta: {
          requiresAccountAdmin: true,
        },
      },
      {
        path: 'retailConnect',
        component: views.RetailConnect,
        meta: {
          requiresAccountAdmin: true,
          requiresBrandAccount: true,
          requirePagePermission: 'accountConnections',
        },
      },
      {
        path: 'dataPermissions',
        component: views.BrandConnect,
        meta: {
          requiresAccountAdmin: true,
          requiresRetailAccount: true,
          requirePagePermission: 'accountConnections',
        },
      },
      {
        path: 'locations',
        component: views.Locations,
        meta: {
          requiresRetailAccount: true,
          requirePagePermission: 'locations',
        },
      },
      {
        path: 'locations/edit/:accountSourceId/:storeId',
        component: views.EditLocation,
        meta: {
          requiresRetailAccount: true,
        },
      },
    ],
  },
];
