<template>
  <v-row>
    <v-col
      cols="12"
      md="6"
      lg="4"
    >
      <ValidationProvider
        ref="urlValidation"
        v-slot="{ errors }"
        rules="required"
        name="URL"
      >
        <v-text-field
          v-model="urlLocal"
          label="URL"
          :error-messages="errors"
        />
      </ValidationProvider>
    </v-col>
    <v-col
      cols="12"
      md="6"
      lg="4"
    >
      <ValidationProvider
        v-slot="{ errors }"
        rules="required"
        name="Store Id"
      >
        <v-text-field
          v-model="storeIdLocal"
          label="Store Id"
          :error-messages="errors"
        />
      </ValidationProvider>
    </v-col>
    <v-col
      cols="12"
      md="6"
      lg="4"
    >
      <timezone :timezone.sync="timezoneLocal" />
      <div class="caption">
        Runit returns dates without timezone information. The timezone that is selected here will be
        used for all orders.
      </div>
    </v-col>
    <v-col
      cols="12"
      md="6"
      lg="4"
    >
      <v-textarea
        v-model.trim="tenderTypeLocal"
        outlined
        rows="4"
        label="Tender Type Filter"
      />
      <div class="caption">
        Enter each tender type on a new row that flags the order as a web sale
      </div>
    </v-col>
  </v-row>
</template>

<script>
import Timezone from '../Timezone';
import runit from '../../../_api/runit';

export default {
  name: 'RunitSource',
  components: {
    Timezone,
  },
  props: {
    url: {
      type: String,
      required: false,
      default: '',
    },
    storeId: {
      type: String,
      required: false,
      default: '',
    },
    timezone: {
      type: String,
      required: false,
      default: '',
    },
    tenderType: {
      type: Array,
      required: false,
      default: () => [],
    },
    errorMessage: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      urlLocal: this.url,
      storeIdLocal: this.storeId,
      timezoneLocal: this.timezone,
      tenderTypeLocal: this.tenderType.join('\n'),
    };
  },
  watch: {
    errorMessage() {
      if (this.errorMessage === 'InvalidUrlStoreId') {
        this.$refs.urlValidation.setErrors(['Invalid URL or Store Id']);
      }
    },
  },
  methods: {
    getDetail() {
      return {
        url: this.urlLocal,
        storeId: this.storeIdLocal,
        timezone: this.timezoneLocal,
        tenderType: this.tenderTypeLocal.split('\n')
          .filter((x) => x !== '')
          .map((x) => x.toLowerCase().trim()),
      };
    },
    async preview() {
      const result = await runit.preview(this.urlLocal, this.storeIdLocal);
      return result.data;
    },
  },
};
</script>
