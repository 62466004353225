<template>
  <v-dialog
    v-model="dialog"
    transition="dialog-top-transition"
    content-class="white"
    max-width="525px"
  >
    <v-card
      flat
      tile
      elevation="0"
    >
      <v-toolbar
        flat
        tile
        dark
        class="primary"
      >
        <v-toolbar-title class="font-weight-medium text-white">
          Save View
        </v-toolbar-title>
        <v-spacer />
        <v-btn
          icon
          :disabled="processing"
          @click="dialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-form @submit.prevent="onSaveView">
        <v-container
          fluid
          class="pa-5"
        >
          <v-alert
            v-for="error in errors"
            :key="error.message"
            type="error"
            icon="mdi-alert-circle"
            text
          >
            {{ error.message }}
          </v-alert>
          <v-row>
            <v-col>
              <p>
                Saving a "view" allows UQ Cadence to remember your filter selections.
              </p>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col>
              <ValidationProvider
                ref="name"
                v-slot="{ errors: validationErrors }"
                rules="required"
                name="View Name"
                vid="View Name"
                mode="passive"
              >
                <v-text-field
                  v-model="name"
                  outlined
                  label="Name*"
                  hint="Enter the name for your view."
                  persistent-hint
                  :error-messages="validationErrors"
                />
              </ValidationProvider>
            </v-col>
          </v-row>
        </v-container>

        <v-divider />
        <v-container
          fluid
          class="pa-5"
        >
          <v-row>
            <v-col align="right">
              <v-btn
                v-show="!processing"
                text
                class="mr-4"
                @click="dialog = false"
              >
                Cancel
              </v-btn>
              <v-btn
                ripple
                depressed
                type="submit"
                color="primary"
                :loading="processing"
              >
                Save
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'ReportViewsModal',
  data() {
    return {
      dialog: false,
      processing: false,
      name: this.name,
      errors: [],
    };
  },
  computed: {
    ...mapState('page/report', [
      'workbook',
    ]),
  },
  watch: {
    dialog(showing) {
      if (showing) {
        this.$nextTick(() => {
          this.errors = [];
          this.$refs.name.reset();
        });
      }
    },
  },
  methods: {
    show() {
      this.dialog = true;
    },
    async onSaveView() {
      const { valid } = await this.$refs.name.validate();

      if (!valid) {
        return;
      }

      const payload = { name: this.name, variables: { ...this.workbook.variables } };
      this.processing = true;

      try {
        const view = await this.$store.dispatch('page/report/createView', payload);

        await this.$store.dispatch('page/report/setView', view);

        this.dialog = false;
        this.$nextTick(() => {
          this.name = '';
        });
      } catch (e) {
        if (e && e.response && typeof e.response.data === 'object' && 'errors' in e.response.data) {
          this.errors = e.response.data.errors;
        } else {
          this.errors = [{ message: 'An unknown error occurred.' }];
        }
      }

      this.$nextTick(() => {
        this.processing = false;
      });
    },
  },
};
</script>
